import { Transforms, Range } from 'slate';
import { isBlockActive } from './util';

const LINK_NODE_TYPE = 'link';

export const withLinks = (editor) => {
	const { isInline } = editor;
	editor.isInline = (element) =>
		element.type === LINK_NODE_TYPE ? true : isInline(element);
	return editor;
};

export const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' });
};

export const wrapLink = (editor, url) => {
	if (isBlockActive(editor, 'link')) {
		unwrapLink(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link = {
		type: 'link',
		url,
		children: isCollapsed ? [{ text: url }] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

export const insertLink = (editor, url) => {
	if (editor.selection) {
		wrapLink(editor, url);
	}
};
