import React, { useContext } from 'react';
import { ChoiceList } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const EnumSelect = ({ value, onChange, ...props }) => {
	const { enumerations } = useContext(EnvironmentContext);
	return (
		<ChoiceList
			{...props}
			allowMultiple={false}
			label="Enumeration"
			helpText="The chosen enumeration cannot be changed once the attribute has been created"
			value={value ? [value] : []}
			choices={enumerations.map((node) => ({
				label: node.name,
				value: node.id,
			}))}
			onChange={(values) => onChange(values[0])}
		/>
	);
};
