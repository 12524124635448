import { useFormik } from 'formik';
import { pathOr } from 'ramda';
import React, { useContext } from 'react';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Icon,
	Modal,
	ModalFooter,
	RadioField,
	TextField,
} from '@platformapp/ui';
import { INVENTORY_DELTA, INVENTORY_SET } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { extractGraphqlError } from '../util';
import ADJUST_INVENTORY from './AdjustInventory.gql';
import ChevronRightIcon from '../icons/chevron-right.svg';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '../common/ErrorBoundary';
import toast from 'react-hot-toast';

const calculateDiff = (available, action, quantity) => {
	switch (action) {
		case INVENTORY_DELTA:
			return available + quantity;
	}
	return quantity;
};

const Difference = ({ available, form }) => {
	const diff = calculateDiff(
		available,
		form.values.action,
		form.values.quantity
	);
	if (diff === available) {
		return diff;
	}
	return (
		<>
			{available}
			<Icon source={ChevronRightIcon} className="text-gray-600 mx-1" />
			<span className="bg-yellow-300 px-1 rounded">{diff}</span>
		</>
	);
};

export const AdjustInventoryModal = withErrorBoundary(
	({ inventoryItems, onCancel, onUpdate }) => {
		const { contentApiRequest } = useContext(EnvironmentContext);
		const form = useFormik({
			initialValues: {
				action: INVENTORY_DELTA,
				quantity: 0,
			},
			onSubmit: (values, { setSubmitting, setErrors }) => {
				contentApiRequest({
					query: ADJUST_INVENTORY,
					variables: {
						input: {
							adjustments: inventoryItems.map((invItem) => ({
								inventoryItemId: invItem.id,
								...values,
							})),
						},
					},
				})
					.then((res) => {
						const payload = res.data.data.bulkAdjustInventoryQuantity;
						if (payload.inventoryItems) {
							toast.success('Inventory updated');
							onUpdate(payload.inventoryItems);
						} else if (pathOr([], ['userErrors'], payload).length > 0) {
							setErrors(payload.userErrors);
							setSubmitting(false);
						}
					})
					.catch((err) => {
						console.error(err);
						setSubmitting(false);
					});
			},
		});
		const willChange = inventoryItems.some(
			(invItem) =>
				invItem.available !==
				calculateDiff(
					invItem.available,
					form.values.action,
					form.values.quantity
				)
		);
		return (
			<Modal
				title="Adjust quantities"
				small
				isOpen
				onRequestClose={() => onCancel()}
				shouldCloseOnEsc={!form.isSubmitting}
				shouldCloseOnOverlayClick={!form.isSubmitting}
			>
				{form.errors.length > 0 && (
					<div className="px-6 py-2">
						{form.errors.map((err, i) => (
							<ErrorMessageElement message={err.message} key={i} />
						))}
					</div>
				)}
				<form onSubmit={form.handleSubmit}>
					<div className="px-6 pt-4 pb-6">
						<div className="flex items-center">
							<div className="flex-shrink-0 flex">
								<div className="mr-6">
									<RadioField
										disabled={form.isSubmitting}
										label="Add"
										checked={form.values.action === INVENTORY_DELTA}
										onChange={() =>
											form.setFieldValue('action', INVENTORY_DELTA)
										}
									/>
								</div>
								<div className="mr-6">
									<RadioField
										disabled={form.isSubmitting}
										label="Set"
										checked={form.values.action === INVENTORY_SET}
										onChange={() => form.setFieldValue('action', INVENTORY_SET)}
									/>
								</div>
							</div>
							<div className="w-1/2">
								<TextField
									disabled={form.isSubmitting}
									type="number"
									name="quantity"
									value={form.values.quantity}
									onChange={form.handleChange}
									error={extractGraphqlError(['quantity'], form.errors)}
								/>
							</div>
						</div>
					</div>
					<div className="border-t border-gray-300 px-6 pt-6 pb-6 max-h-64 overflow-y-auto">
						<p className="mb-4">
							{inventoryItems.length} items will be adjusted:
						</p>
						<div>
							{inventoryItems.map((ii) => (
								<div
									key={ii.id}
									className="flex py-3 border-gray-300 border-b last:border-b-0"
								>
									<p className="flex-grow truncate">
										{ii.productName}
										{ii.variantOptions.length > 0 &&
											` - ${ii.variantOptions.join(' / ')}`}
									</p>
									<p className="flex-shrink-0">
										<Difference available={ii.available} form={form} />
									</p>
								</div>
							))}
						</div>
					</div>
					<ModalFooter>
						<ButtonGroup>
							<Button onClick={onCancel} disabled={form.isSubmitting}>
								Cancel
							</Button>
							<Button
								submit
								primary
								loading={form.isSubmitting}
								disabled={!form.dirty || form.isSubmitting || !willChange}
							>
								Save
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
);

AdjustInventoryModal.propTypes = {
	inventoryItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			available: PropTypes.number.isRequired,
			productName: PropTypes.string.isRequired,
			variantOptions: PropTypes.arrayOf(PropTypes.string),
		})
	),
};
