import React, { useContext } from 'react';
import Helpers from '../Helpers';
import {
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_UNIREF,
	TYPE_ASSET,
	TYPE_BOOLEAN,
	TYPE_DATE,
	TYPE_DATETIME,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_NUMBER,
	TYPE_TEXT,
} from '../constants';
import { TextStyle } from '@platformapp/ui';
import { ExtensionsContext } from '../extensions/ExtensionsContext';
import { B } from '../common/Text';

const TYPE_ENUM = 'ENUM';
const TYPE_COMPONENT = 'COMPONENT';
const TYPE_REFERENCE = 'REFERENCE';
const TYPE_BI_REFERENCE = 'BIREFERENCE';

const ALLOWED_TYPES_COLLECTION = Object.freeze([
	TYPE_TEXT,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_DATE,
	TYPE_DATETIME,
	TYPE_NUMBER,
	TYPE_BOOLEAN,
	TYPE_ASSET,
	TYPE_ENUM,
	TYPE_REFERENCE,
	TYPE_BI_REFERENCE,
	TYPE_COMPONENT,
]);

// Everything apart from references and components.
const ALLOWED_TYPES_COMPONENT = Object.freeze([
	TYPE_TEXT,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_DATE,
	TYPE_DATETIME,
	TYPE_NUMBER,
	TYPE_BOOLEAN,
	TYPE_ASSET,
	TYPE_ENUM,
]);

const ATTR_DATA_MAP = {
	[TYPE_ASSET]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_ASSET,
	},
	[TYPE_BOOLEAN]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_BOOLEAN,
	},
	[TYPE_COMPONENT]: {
		__typename: ATTRIBUTE_TYPENAME_COMPONENT,
	},
	[TYPE_DATE]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_DATE,
	},
	[TYPE_DATETIME]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_DATE,
	},
	[TYPE_ENUM]: {
		__typename: ATTRIBUTE_TYPENAME_ENUM,
	},
	[TYPE_LONG_TEXT]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_LONG_TEXT,
	},
	[TYPE_MARKDOWN]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_MARKDOWN,
	},
	[TYPE_NUMBER]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_NUMBER,
	},
	[TYPE_REFERENCE]: {
		__typename: ATTRIBUTE_TYPENAME_UNIREF,
	},
	[TYPE_BI_REFERENCE]: {
		__typename: ATTRIBUTE_TYPENAME_BIREF,
	},
	[TYPE_TEXT]: {
		__typename: ATTRIBUTE_TYPENAME_BASIC,
		type: TYPE_TEXT,
	},
};

const getAllowedTypes = (blueprint) => {
	if (blueprint.__typename === 'Component') {
		return ALLOWED_TYPES_COMPONENT;
	}
	return ALLOWED_TYPES_COLLECTION;
};

export const AttributeSelector = ({ blueprint, addAttribute }) => (
	<>
		<p className="mb-6">
			<B>Add new attribute</B>
		</p>
		{getAllowedTypes(blueprint).map((attributeType) => (
			<button
				key={attributeType}
				className="block w-full text-left bg-white border border-gray-300 rounded-md px-4 py-3 mb-3 cursor-pointer hover:bg-gray-100"
				onClick={() => addAttribute(ATTR_DATA_MAP[attributeType])}
			>
				<p>
					<B>{Helpers.t(`attr_types.${attributeType}`)}</B>
				</p>
				<p className="text-sm mt-2">
					<TextStyle subdued>
						{Helpers.t(`attr_type_desc.${attributeType}`)}
					</TextStyle>
				</p>
			</button>
		))}
		<AttributeExtensions addAttribute={addAttribute} />
	</>
);

const AttributeExtensions = ({ addAttribute }) => {
	const { installations } = useContext(ExtensionsContext);

	if (installations.isLoading) {
		return null;
	}

	const attributes = installations.data.filter((node) =>
		node.extension.points.some((p) => p.__typename === 'AttributePoint')
	);

	return attributes.map((node) => {
		const point = node.extension.points.find(
			(p) => p.__typename === 'AttributePoint'
		);
		return (
			<button
				key={node.extension.id}
				className="block w-full text-left bg-white border border-gray-300 rounded-md px-4 py-3 mb-3 cursor-pointer hover:bg-gray-100"
				onClick={() =>
					addAttribute({
						...ATTR_DATA_MAP[point.attributeType],
						extension: {
							id: node.extension.id,
							point,
						},
					})
				}
			>
				<p>
					<B>{point.name}</B>
				</p>
				{point.description && (
					<p className="text-sm mt-2">
						<TextStyle subdued>{point.description}</TextStyle>
					</p>
				)}
			</button>
		);
	});
};
