import React from 'react';
import Helpers from '../Helpers';
import { ActionList, Button, MoreIcon } from '@platformapp/ui';
import { NewContextMenu } from '../dropdown/ContextMenu';
import { STATUS_CHANGED, STATUS_PUBLISHED, STATUS_DRAFT } from '../constants';
import { getProductStatus, statusFromStages } from '../util/publishing';

export const EditPublishableDropdown = ({
	onUnpublishClick,
	onDeleteClick,
	status,
	isPublishable,
}) => {
	if (!isPublishable) {
		return (
			<ActionList
				placement="bottom-end"
				trigger={
					<Button>
						<MoreIcon className="h-4" viewBox="2 2 20 20" />
					</Button>
				}
				items={[
					{
						onAction: () => onDeleteClick(),
						label: Helpers.t('general.delete'),
						destructive: true,
					},
				]}
			/>
		);
	}

	const showUnpublish =
		(status === STATUS_PUBLISHED || status === STATUS_CHANGED) &&
		onUnpublishClick;

	// Assemble menu sections
	const sections = [
		// {
		// 	items: [
		// 		{
		// 			label: 'Create another Product',
		// 			to: '/'
		// 		}
		// 	],
		// },
		{
			items: [
				...(showUnpublish
					? [
							{
								onAction: () => onUnpublishClick(),
								label: Helpers.t('publishing.unpublish'),
							},
					  ]
					: []),
				{
					onAction: () => onDeleteClick(),
					label: Helpers.t('general.delete'),
					destructive: true,
				},
			],
		},
	];

	return (
		<ActionList
			placement="bottom-end"
			trigger={
				<Button>
					<MoreIcon className="h-4" viewBox="2 2 20 20" />
				</Button>
			}
			sections={sections}
		/>
	);
};

EditPublishableDropdown.defaultProps = {
	isPublishable: true,
	onDeleteClick: null,
};

export const PublishableContextMenu = ({
	isPublishable,
	publishable,
	togglePublish,
	onDeleteClick,
	styleOnHover,
	smallTrigger,
}) => (
	<NewContextMenu
		styleOnHover={styleOnHover}
		smallTrigger={smallTrigger}
		sections={[
			...getPublishableWorkflowItems({
				isPublishable,
				publishable,
				togglePublish,
				onDeleteClick,
			}),
		]}
	/>
);

PublishableContextMenu.defaultProps = {
	isPublishable: true,
	onDeleteClick: null,
	styleOnHover: true,
	smallTrigger: false,
};

export const getPublishableWorkflowItems = ({
	isPublishable,
	publishable,
	togglePublish,
	onDeleteClick,
}) => {
	const workflowSectionItems = [];

	if (isPublishable) {
		const status =
			publishable.__typename === 'Product'
				? getProductStatus(
						publishable.inStages,
						publishable.variants.edges.map((edge) => edge.node.inStages)
				  )
				: statusFromStages(publishable);

		// Publish
		if (status === STATUS_CHANGED || status === STATUS_DRAFT) {
			workflowSectionItems.push({
				onAction: () => togglePublish(STATUS_PUBLISHED),
				label: Helpers.t('publishing.publish'),
			});
		}

		// Unpublish
		if (status === STATUS_PUBLISHED || status === STATUS_CHANGED) {
			workflowSectionItems.push({
				onAction: () => togglePublish(STATUS_DRAFT),
				label: Helpers.t('publishing.unpublish'),
			});
		}
	}

	const dangerousItems = [];

	// Delete
	if (onDeleteClick) {
		dangerousItems.push({
			onAction: () => onDeleteClick(),
			label: Helpers.t('general.delete'),
			destructive: true,
		});
	}

	const result = [];

	if (publishable.__typename === 'Asset') {
		result.push({
			items: [
				{
					label: Helpers.t('general.download'),
					to: publishable.url,
					external: true,
					newWindow: true,
				},
			],
		});
	}

	if (workflowSectionItems.length > 0) {
		result.push({
			items: workflowSectionItems,
		});
	}

	result.push({
		items: dangerousItems,
	});

	return result;
};
