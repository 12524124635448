import React, { useContext, useState } from 'react';
import { graphql } from '../api';
import {
	Button,
	DataList,
	DataListItem,
	Loader,
	TextStyle,
} from '@platformapp/ui';
import { StatusBadge } from './StatusBadge';
import { StickyHeader } from '../common/StickyHeader';
import { BackArrow } from '../common/BackArrow';
import { Heading } from '../common/Heading';
import { ViewOrderTransactions } from './ViewOrderTransactions';
import { ViewOrderShipments } from './ViewOrderShipments';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Module } from '../common/Module';
import { longDateTime } from '../util';
import { ORDER_UNFULFILLED } from '../constants';
import { useParams } from 'react-router-dom';
import getOrderQuery from './GetOrder.gql';
import { OrderItemsTable } from './OrderItemsTable';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { NotFound } from '../NotFound';
import { Address } from './Address';
import { Link } from '../common/Link';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

const ViewOrderComponent = () => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	const { orderId } = useParams();

	const [order, setOrder] = useState({
		isError: false,
		isLoading: true,
		data: null,
	});

	useEnvironmentTitle(
		order.isLoading ? 'Order' : `Order ${order.data.reference}`
	);

	useAsyncEffect(async (isActive) => {
		const res = await graphql({
			query: getOrderQuery,
			variables: {
				id: orderId,
			},
		});
		if (!isActive()) {
			return;
		}
		setOrder({
			isError: false,
			isLoading: false,
			data: res.data.data.order,
		});
	}, []);

	if (order.isLoading) {
		return <Loader />;
	}

	if (!order.data) {
		return (
			<NotFound
				header="Order not found"
				body={<p>No such order: {orderId}</p>}
			/>
		);
	}

	const billingSameAsShipping =
		order.data.shippingAddress &&
		order.data.shippingAddress.id === order.data.billingAddress.id;

	return (
		<div className="mt-24 pt-6 mx-5 md:mx-10 md:pr-10">
			<StickyHeader>
				<div className="flex items-end">
					<div className="flex-grow">
						<BackArrow to={generateEnvPath('orders')} label="Orders" />
						<Heading>Order {order.data.reference}</Heading>
					</div>
					{order.data.shippingStatus === ORDER_UNFULFILLED && (
						<Button to={generateEnvPath(`orders/${order.data.id}/ship`)}>
							Ship items
						</Button>
					)}
				</div>
			</StickyHeader>

			<div className="fade-in md:grid gap-12 grid-cols-3 mt-5">
				<div className="col-span-2">
					<Module heading="Order items">
						<OrderItemsTable order={order.data} />
					</Module>

					{order.data.transactions.edges.length > 0 && (
						<ViewOrderTransactions order={order.data} />
					)}

					{order.data.shipments.edges.length > 0 && (
						<ViewOrderShipments order={order.data} />
					)}
				</div>
				<div className="col-span-1">
					<DataList className="mb-5">
						<DataListItem stacked title="Received">
							<p>{longDateTime(order.data.createdAt)}</p>
						</DataListItem>
						<DataListItem stacked title="Shipping status">
							<StatusBadge status={order.data.shippingStatus} />
						</DataListItem>
						<DataListItem stacked title="Email">
							<Link
								className="text-base"
								to={`mailto:${order.data.email}`}
								external
							>
								{order.data.email}
							</Link>
						</DataListItem>
					</DataList>

					{order.data.shippingAddress && (
						<Module bottomMargin heading="Shipping address">
							<Address address={order.data.shippingAddress} showMapLink />
						</Module>
					)}

					<Module bottomMargin heading="Billing address">
						{billingSameAsShipping && (
							<p>
								<TextStyle subdued>Same as shipping address</TextStyle>
							</p>
						)}
						{!billingSameAsShipping && (
							<Address address={order.data.billingAddress} />
						)}
					</Module>
				</div>
			</div>
		</div>
	);
};

export const ViewOrder = withErrorBoundary(ViewOrderComponent);
