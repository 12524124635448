import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AssetListContainer } from './assets-list/AssetListContainer';
import { OrderListContainer } from './order-list/OrderListContainer';
import { ViewOrder } from './orders/ViewOrder';
import { EditContentStack } from './content/EditContentStack';
import { OrderDispatch } from './order-dispatch/OrderDispatch';
import { Form } from './form-submissions/Form';
import { Blueprint } from './blueprint-editor/Blueprint';
import { Schema } from './schema/Schema';
import { ListInventory } from './inventory/ListInventory';
import { Landing } from './projects/Landing';
import { CreateProductContainer } from './products-create/CreateProductContainer';
import { EditProductContainer } from './products-edit/EditProductContainer';
import { Developers } from './developers/Developers';
import { Settings } from './project-settings/Settings';
import { ProductList } from './products-list/ProductList';
import { ContentLanding } from './content-list/ContentLanding';
import { ContentList } from './content-list/ContentList';
import { EcommerceOnboard } from './ecommerce-onboarding/EcommerceOnboard';
import { AppDock } from './app-dock/AppDock';

export const Project = () => (
	<>
		<Switch>
			{/* Routes that vary per environment, using default env */}
			<Route exact path="/:teamSlug/:projectId" component={Landing} />
			<Route
				exact
				path="/:teamSlug/:projectId/assets"
				component={AssetListContainer}
			/>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/blueprints/:blueprintId',
					'/:teamSlug/:projectId/blueprints/:blueprintId/settings',
				]}
				component={Blueprint}
			/>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/content',
					'/:teamSlug/:projectId/env/:envSlug/content',
				]}
				component={ContentLanding}
			/>
			<Route path="/:teamSlug/:projectId/content/:blueprintId">
				<Switch>
					<Route
						exact
						path="/:teamSlug/:projectId/content/:blueprintId"
						component={ContentList}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/content/:blueprintId/new"
						component={EditContentStack}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/content/:blueprintId/:id"
						component={EditContentStack}
					/>
				</Switch>
			</Route>
			<Route
				component={Developers}
				path={[
					'/:teamSlug/:projectId/api-keys',
					'/:teamSlug/:projectId/developers',
					'/:teamSlug/:projectId/webhooks',
					'/:teamSlug/:projectId/env/:envSlug/api-keys',
					'/:teamSlug/:projectId/env/:envSlug/developers',
					'/:teamSlug/:projectId/env/:envSlug/webhooks',
				]}
			/>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/forms/:formId',
					'/:teamSlug/:projectId/forms/:formId/settings',
				]}
				component={Form}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/inventory"
				component={ListInventory}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/orders"
				component={OrderListContainer}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/orders/:orderId"
				component={ViewOrder}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/orders/:id/ship"
				component={OrderDispatch}
			/>
			<Route path="/:teamSlug/:projectId/products">
				<Switch>
					<Route
						exact
						path="/:teamSlug/:projectId/products"
						component={ProductList}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/products/new"
						component={CreateProductContainer}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/products/:id"
						component={EditProductContainer}
					/>
				</Switch>
			</Route>
			<Route path="/:teamSlug/:projectId/schema" component={Schema} />
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/settings',
					'/:teamSlug/:projectId/settings/ecommerce',
					'/:teamSlug/:projectId/settings/payment-providers',
					'/:teamSlug/:projectId/settings/apps',
				]}
				component={Settings}
			/>
			<Route
				exact
				path={['/:teamSlug/:projectId/ecommerce-onboarding']}
				component={EcommerceOnboard}
			/>

			{/* Routes that vary per environment, using specific env */}
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug"
				component={Landing}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug/assets"
				component={AssetListContainer}
			/>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/env/:envSlug/blueprints/:blueprintId',
					'/:teamSlug/:projectId/env/:envSlug/blueprints/:blueprintId/settings',
				]}
				component={Blueprint}
			/>
			<Route path="/:teamSlug/:projectId/env/:envSlug/content/:blueprintId">
				<Switch>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/content/:blueprintId"
						component={ContentList}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/content/:blueprintId/new"
						component={EditContentStack}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/content/:blueprintId/:id"
						component={EditContentStack}
					/>
				</Switch>
			</Route>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/env/:envSlug/forms/:formId',
					'/:teamSlug/:projectId/env/:envSlug/forms/:formId/settings',
				]}
				component={Form}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug/inventory"
				component={ListInventory}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug/orders"
				component={OrderListContainer}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug/orders/:orderId"
				component={ViewOrder}
			/>
			<Route
				exact
				path="/:teamSlug/:projectId/env/:envSlug/orders/:id/ship"
				component={OrderDispatch}
			/>
			<Route path="/:teamSlug/:projectId/env/:envSlug/products">
				<Switch>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/products"
						component={ProductList}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/products/new"
						component={CreateProductContainer}
					/>
					<Route
						exact
						path="/:teamSlug/:projectId/env/:envSlug/products/:id"
						component={EditProductContainer}
					/>
				</Switch>
			</Route>
			<Route
				path="/:teamSlug/:projectId/env/:envSlug/schema"
				component={Schema}
			/>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/env/:envSlug/settings/ecommerce',
					'/:teamSlug/:projectId/env/:envSlug/settings/payment-providers',
					'/:teamSlug/:projectId/env/:envSlug/settings/apps',
				]}
				component={Settings}
			/>
		</Switch>
		<AppDock />
	</>
);
