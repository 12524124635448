import { append, without } from 'ramda';
import React from 'react';
import { CheckboxField, Field, RadioField } from '@platformapp/ui';
import { STAGE_DRAFT, STAGE_PUBLISHED } from '../constants';

export const TYPE_CREATE = 'createItem';
export const TYPE_READ = 'readItem';
export const TYPE_UPDATE = 'updateItem';
export const TYPE_PUBLISH = 'publishItem';
export const TYPE_UNPUBLISH = 'unpublishItem';
export const TYPE_DELETE = 'deleteItem';

export const PermissionSelector = ({
	disabled,
	onChange,
	value,
	isPublishable,
}) => (
	<Field label="Permission">
		<div className="mt-2">
			<div className="mb-2">
				<div>
					<RadioField
						disabled={disabled}
						label="Read items"
						checked={value.type === TYPE_READ}
						onChange={() =>
							onChange({
								type: TYPE_READ,
							})
						}
					/>
				</div>
				{isPublishable && value.type === TYPE_READ && (
					<div className="flex gap-4 mt-3 pb-2 pl-7">
						<div>
							<CheckboxField
								disabled={disabled}
								label="Draft"
								checked={value.stages.includes(STAGE_DRAFT)}
								onChange={(checked) =>
									onChange({
										stages: checked
											? append(STAGE_DRAFT, value.stages)
											: without([STAGE_DRAFT], value.stages),
									})
								}
							/>
						</div>
						<div>
							<CheckboxField
								disabled={disabled}
								label="Published"
								checked={value.stages.includes(STAGE_PUBLISHED)}
								onChange={(checked) =>
									onChange({
										stages: checked
											? append(STAGE_PUBLISHED, value.stages)
											: without([STAGE_PUBLISHED], value.stages),
									})
								}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="mb-2">
				<RadioField
					disabled={disabled}
					label="Create items"
					checked={value.type === TYPE_CREATE}
					onChange={() =>
						onChange({
							type: TYPE_CREATE,
						})
					}
				/>
			</div>
			<div className="mb-2">
				<RadioField
					disabled={disabled}
					label="Update items"
					checked={value.type === TYPE_UPDATE}
					onChange={() =>
						onChange({
							type: TYPE_UPDATE,
						})
					}
				/>
			</div>
			{isPublishable && (
				<>
					<div className="mb-2">
						<RadioField
							disabled={disabled}
							label="Publish items"
							checked={value.type === TYPE_PUBLISH}
							onChange={() =>
								onChange({
									type: TYPE_PUBLISH,
								})
							}
						/>
					</div>
					<div className="mb-2">
						<RadioField
							disabled={disabled}
							label="Unpublish items"
							checked={value.type === TYPE_UNPUBLISH}
							onChange={() =>
								onChange({
									type: TYPE_UNPUBLISH,
								})
							}
						/>
					</div>
				</>
			)}
			<div className="mb-2">
				<RadioField
					disabled={disabled}
					label="Delete items"
					checked={value.type === TYPE_DELETE}
					onChange={() =>
						onChange({
							type: TYPE_DELETE,
						})
					}
				/>
			</div>
		</div>
	</Field>
);
