import React from 'react';
import { Button } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { EventsChoiceList } from './EventsChoiceList';
import { updateWebhook } from './mutations';
import toast from 'react-hot-toast';

export const EventsSetting = ({ hook, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			events: pathOr('', ['node', 'events'], hook),
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			updateWebhook(hook.node.id, values)
				.then((updatedHook) => {
					onChange(updatedHook);
				})
				.catch((err) => {
					if (err.isUserError) {
						setErrors(err.userErrors);
					} else {
						toast.error('Failed to update webhook');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Events</p>
					<p className="mb-4">
						These are the events that will trigger this webhook.
					</p>
					<div className="w-full md:w-96">
						<EventsChoiceList
							error={extractGraphqlError('events', form.errors)}
							onChange={(values) => form.setFieldValue('events', values)}
							value={form.values.events}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
