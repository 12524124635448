import React from 'react';

const Avatar = ({ name }) => (
	<img
		className={`rounded-full border-gray-300 border w-8 h-8`}
		src={`https://avatar.tobi.sh/${name}.svg`}
	/>
);

export const UserAvatar = ({
	className,
	includeUsername,
	large,
	user,
	...props
}) => (
	<div className={`select-none ${className}`} {...props}>
		<Avatar name={user.id} />
		{includeUsername && (
			<p
				className={`ml-2 truncate font-medium flex-grow ${
					large ? 'text-md' : 'text-base'
				}`}
			>
				{user.name}
			</p>
		)}
	</div>
);

UserAvatar.defaultProps = {
	className: '',
	includeUsername: false,
};
