import { Module } from "../common/Module";
import { ItemInStage } from "../types/Item";
//@ts-ignore
import { DataList, DataListItem, DateTime } from '@platformapp/ui';
import { PublishStatusLabel } from "../common/PublishStatusLabel";
import { getProductStatus, statusFromStagesV2 } from "../util/publishing";
import { DATE_REFRESH } from "../constants";
import { ApiIdLabel } from "../common/ApiIdLabel";

type ItemSidebarProps = {
	isPublishable: boolean;
    item: any;
}

export const ItemSidebar = ({
	isPublishable,
	item,
}: ItemSidebarProps) => {
	const publishedVersion = isPublishable
		? item.inStages.find((i: ItemInStage) => i.stage === 'PUBLISHED')
		: null;
	const status = isPublishable
		? item.__typename === 'Product'
			? getProductStatus(item.inStages, item.variants.map((variant: any) => variant.inStages))
			: statusFromStagesV2(item.inStages)
		: null;

	return (
		<>
			{isPublishable && (
				<Module bottomMargin heading="Publishing">
					<DataList className="mb-5">
						<DataListItem stacked title="Status">
							<PublishStatusLabel status={status} />
						</DataListItem>
						{publishedVersion && (
							<DataListItem stacked title="Last published">
								<p>
									<DateTime
										refresh={DATE_REFRESH}
										value={publishedVersion.publishedAt}
									/>
								</p>
							</DataListItem>
						)}
					</DataList>
				</Module>
			)}
			<Module bottomMargin heading="Item information">
				<DataList className="mb-5">
					<DataListItem stacked title="Created">
						<p>
							<DateTime refresh={DATE_REFRESH} value={item.createdAt} />
						</p>
					</DataListItem>
					<DataListItem stacked title="Updated">
						<p>
							<DateTime refresh={DATE_REFRESH} value={item.updatedAt} />
						</p>
					</DataListItem>
					<DataListItem stacked title="Item ID">
						<ApiIdLabel
							textToCopy={item.id}
						>
							{item.id}
						</ApiIdLabel>
					</DataListItem>
				</DataList>
			</Module>
		</>
	)
}

ItemSidebar.defaultProps = {
	isPublishable: true,
}