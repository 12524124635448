// @ts-ignore
import { TextField } from '@platformapp/ui';
import { useContext } from 'react';
import {
	Fieldset,
	FieldsetContent,
	FieldsetHeader,
} from '../common/Fieldset';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const EndpointSetting = ({collection}: any) => {
	const {environment} = useContext(EnvironmentContext);
	const subUrl = collection.isLoading
		? ''
		: `https://v2.forms.platformapp.net/${environment?.node?.id}/${collection?.node?.id}`;
	return (
		<Fieldset>
			<FieldsetContent>
				<FieldsetHeader
					title="Submission endpoint"
					subtitle="Submissions can be made with HTML forms, via AJAX or from a backend via HTTP. Environment CORS origins rules apply."
				/>
				<TextField
					value={subUrl}
					readOnly
					showCopy
					selectOnFocus
				/>
			</FieldsetContent>
		</Fieldset>
	);
};
