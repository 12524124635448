import { append, ascend, path, sort, update } from "ramda";
import React, { createContext, useState, useEffect } from "react";
import { graphql } from "../api";
import LIST_TEAMS_QUERY from './ListTeams.gql';

export const TeamsContext = createContext({});

export const TeamsConsumer = TeamsContext.Consumer;

export const TeamsProvider = ({children}) => {
	const [teams, setTeams] = useState({
		isLoading: true,
		edges: []
	});

	useEffect(() => {
		graphql({
			query: LIST_TEAMS_QUERY,
		}).then(({data}) => {
			setTeams({
				isLoading: false,
				...data.data.viewer.teams,
			})
		});
	}, []);

	return (
		<TeamsContext.Provider value={{
			isLoading: teams.isLoading,
			addTeam: team => {
				const sortByName = sort(ascend(path(['node', 'name'])));
				const newEdges = sortByName(append({node: team}, teams.edges));
				setTeams({
					...teams,
					edges: newEdges,
				})
			},
			removeTeam: id => {
				setTeams({
					...teams,
					edges: teams.edges.filter(({node}) => node.id !== id),
				})
			},
			teams: teams.edges,
			updateTeam: (id, data) => {
				const teamIndex = teams.edges.findIndex(({node}) => node.id === id);
				const newTeamVal = {
					node: {
						...teams.edges[teamIndex].node,
						...data,
					}
				}
				setTeams({
					...teams,
					edges: update(teamIndex, newTeamVal, teams.edges)
				})
			}
        }}>
			{children}
		</TeamsContext.Provider>
	)
}