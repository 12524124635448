import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { useParams, useHistory } from 'react-router-dom';
import { Module } from '../common/Module';
import { Wrapper } from '../common/Wrapper';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { isEmpty, isNil, pick } from 'ramda';
import { StickyHeader } from '../common/StickyHeader';
import {
	Button,
	CheckboxField,
	DataList,
	DataListItem,
	LandingMessage,
	Loader,
	OrderIcon,
	TextField,
} from '@platformapp/ui';
import { BackArrow } from '../common/BackArrow';
import { ORDER_UNFULFILLED, ORDER_ITEM_UNFULFILLED } from '../constants';
import ORDER_DETAILS_QUERY from './GetOrderDetailsForShipping.gql';
import CREATE_SHIPMENT_MUTATION from './CreateShipment.gql';
import { Heading } from '../common/Heading';
import { DataTable } from '../common/DataTable';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Address } from '../orders/Address';
import { extractGraphqlError, formatMoney } from '../util';
import { useFormik } from 'formik';
import { OrderItemName } from '../orders/OrderItemName';
import { useAsyncEffect } from 'use-async-effect';
import toast from 'react-hot-toast';

const OrderDispatchComponent = () => {
	const { id } = useParams();
	const history = useHistory();
	const { generateEnvPath } = useContext(EnvironmentContext);
	const orderUrl = generateEnvPath(`orders/${id}`);

	const [order, setOrder] = useState({
		isLoading: true,
		data: {},
	});

	const hasDispatchableItems = order.isLoading
		? false
		: order.data.shippingStatus === ORDER_UNFULFILLED;
	const dispatchableItems = order.isLoading
		? []
		: order.data.items.edges.filter(
				({ node }) => node.status === ORDER_ITEM_UNFULFILLED
		  );

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		enableReinitialize: true,
		initialValues: {
			items: dispatchableItems.map(({ node: orderItem }) => ({
				...pick(
					['id', 'options', 'quantity', 'quantityShipped', 'title'],
					orderItem
				),
				shippableQty: orderItem.quantity - orderItem.quantityShipped,
				qtyToDispatch: 0,
			})),
			isNotifyCustomer: true,
			carrierName: '',
			trackingReference: '',
			trackingUrl: '',
		},
		validate: (values) => {
			const errors = [];

			for (let i = 0; i < values.items.length; i++) {
				const item = values.items[i];

				// Ensure quantity to dispatch is not negative
				if (item.qtyToDispatch < 0) {
					errors.push({
						path: ['input', 'items', i, 'quantity'],
						message: 'Quantity must be a positive value',
					});
					continue;
				}

				// Ensure the quantity being shipped is not more than the shippable quantity
				if (item.qtyToDispatch > item.shippableQty) {
					errors.push({
						path: ['input', 'items', i, 'quantity'],
						message: 'The quantity cannot be more than the shippable quantity',
					});
				}
			}

			if (errors.length > 0) {
				toast.error('Invalid values supplied for shipment');
			}

			return errors;
		},
		onSubmit: async (values, { setErrors }) => {
			const reqData = {
				orderId: id,
				notifyCustomer: values.isNotifyCustomer,
				items: values.items
					.filter((dq) => dq.qtyToDispatch > 0)
					.map((dq) => ({
						orderItemId: dq.id,
						quantity: dq.qtyToDispatch,
					})),
			};

			if (values.carrierName.trim().length > 0) {
				reqData.carrierName = values.carrierName;
			}

			if (values.trackingReference.trim().length > 0) {
				reqData.trackingReference = values.trackingReference;
			}

			if (values.trackingUrl.trim().length > 0) {
				reqData.trackingUrl = values.trackingUrl;
			}

			try {
				const res = await graphql({
					query: CREATE_SHIPMENT_MUTATION,
					variables: {
						input: reqData,
					},
				});
				if (isEmpty(res.data.data.createShipment.userErrors)) {
					toast.success('Items shipped');
					history.push(orderUrl);
				} else {
					setErrors(res.data.data.createShipment.userErrors);
					toast.error('Invalid values supplied for shipment');
				}
			} catch (err) {
				console.error(err);
				toast.error('An error occurred creating shipment');
			}
		},
	});

	useAsyncEffect(async (isActive) => {
		const res = await graphql({
			query: ORDER_DETAILS_QUERY,
			variables: {
				id: id,
			},
		});
		if (!isActive()) {
			return;
		}
		setOrder({
			isLoading: false,
			data: res.data.data.order,
		});
	}, []);

	if (order.isLoading) {
		return (
			<Wrapper>
				<Loader />
			</Wrapper>
		);
	}

	const hasItemsToShip = form.values.items.some(
		(item) => item.qtyToDispatch > 0
	);

	return (
		<div className="mt-24 pt-6 mx-5 md:mx-10 md:pr-10">
			<StickyHeader>
				<div className="flex items-end">
					<div className="flex-grow">
						<BackArrow to={orderUrl} label={`Order ${order.data.reference}`} />
						<Heading>Ship items in {order.data.reference}</Heading>
					</div>

					{hasDispatchableItems && (
						<Button
							primary
							disabled={!hasItemsToShip || form.isSubmitting}
							loading={form.isSubmitting}
							onClick={form.submitForm}
						>
							Ship items
						</Button>
					)}
				</div>
			</StickyHeader>
			<div className="mt-5">
				<div className="fade-in">
					<div>
						{!hasDispatchableItems && (
							<LandingMessage
								icon={<OrderIcon />}
								header="No items available for shipping"
								body="Either this order contains no shippable items, or all eligible items have already been shipped."
							/>
						)}

						{hasDispatchableItems && (
							<>
								<Module bottomMargin heading="Customer summary">
									<div className="flex items-end">
										<div className="flex-grow">
											<DataList>
												{!isNil(order.data.shippingRateName) && (
													<DataListItem title="Shipping rate">
														<p>
															{order.data.shippingRateName} (
															{formatMoney(order.data.shippingPrice)})
														</p>
													</DataListItem>
												)}
												<DataListItem alignTop title="Shipping address">
													<Address address={order.data.shippingAddress} />
												</DataListItem>
											</DataList>
										</div>
									</div>
								</Module>

								<Module heading="Items to ship">
									<DataTable
										columns={[
											{
												heading: 'Product',
											},
											{
												heading: 'Already shipped',
												type: 'numeric',
											},
											{
												heading: 'Shippable quantity',
												type: 'numeric',
											},
											{
												heading: 'Quantity being shipped',
											},
										]}
										rows={form.values.items.map((item, index) => [
											<OrderItemName item={item} key={0} />,
											item.quantityShipped,
											item.quantity,
											<TextField
												key={2}
												type="number"
												value={item.qtyToDispatch}
												min="0"
												max={item.quantity - item.quantityShipped}
												error={extractGraphqlError(
													['items', index, 'quantity'],
													form.errors
												)}
												onChange={(e) =>
													form.setFieldValue(
														`items[${index}].qtyToDispatch`,
														parseInt(e.target.value, 10)
													)
												}
											/>,
										])}
									/>
								</Module>

								<Module
									bottomMargin
									heading="Tracking"
									subheading="Optional. Tracking details will be included in shipment notification email sent to the customer."
								>
									<div className="grid gap-8 grid-cols-2">
										<TextField
											error={extractGraphqlError(
												'trackingReference',
												form.errors
											)}
											helpText="The tracking number or reference."
											label="Tracking reference"
											name="trackingReference"
											value={form.values.trackingReference}
											onChange={form.handleChange}
										/>
										<TextField
											error={extractGraphqlError('carrierName', form.errors)}
											helpText="FedEx or Royal Mail for example."
											label="Carrier"
											name="carrierName"
											value={form.values.carrierName}
											onChange={form.handleChange}
										/>
									</div>
									<TextField
										error={extractGraphqlError('trackingUrl', form.errors)}
										helpText="A URL the customer can access to track delivery status."
										label="Tracking URL"
										name="trackingUrl"
										value={form.values.trackingUrl}
										onChange={form.handleChange}
									/>
								</Module>

								<Module bottomMargin heading="Notification">
									<CheckboxField
										name="notify_customer"
										label="Notify the customer of this shipment."
										checked={form.values.isNotifyCustomer}
										onChange={(val) =>
											form.setFieldValue('isNotifyCustomer', val)
										}
									/>
								</Module>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export const OrderDispatch = withErrorBoundary(OrderDispatchComponent);
