import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Route } from 'react-router-dom';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { SettingsSidebar } from './SettingsSidebar';
import { GeneralSettings } from './GeneralSettings';
import { Security } from './Security';

const SettingsComponent = () => (
	<MainContentContainer>
		<MainContentNav>
			<SettingsSidebar />
		</MainContentNav>
		<MainContent>
			<Route exact path="/account" component={GeneralSettings} />
			<Route exact path="/account/security" component={Security} />
		</MainContent>
	</MainContentContainer>
);

export const Settings = withErrorBoundary(SettingsComponent);
