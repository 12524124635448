import { ModalStackLayer } from '../stacks/ModalStackLayer';
import { StickyHeader } from '../common/StickyHeader';
// @ts-ignore
import { Button, ButtonGroup, Icon } from '@platformapp/ui';
import { useFormik } from 'formik';
import { Heading } from '../common/Heading';
// @ts-ignore
import ArrowLeftIcon from '../icons/arrow-left.svg';
import { AttributeSettings } from './AttributeSettings';
import { graphql } from '../api';
// @ts-ignore
import ADD_ATTRIBUTE_MUTATION from './addAttributeMutation.gql';
// @ts-ignore
import ADD_REFERENCE_ATTRIBUTE_MUTATION from './addReferenceAttributeMutation.gql';
// @ts-ignore
import ADD_BI_REFERENCE_ATTRIBUTE_MUTATION from './addBiReferenceAttributeMutation.gql';
// @ts-ignore
import ADD_COMPONENT_ATTRIBUTE_MUTATION from './addComponentAttributeMutation.gql';
// @ts-ignore
import ADD_ENUM_ATTRIBUTE_MUTATION from './addEnumAttributeMutation.gql';
// @ts-ignore
import UPDATE_ATTRIBUTE_MUTATION from './updateAttributeMutation.gql';
// @ts-ignore
import UPDATE_COMPONENT_ATTRIBUTE_MUTATION from './updateComponentAttributeMutation.gql';
// @ts-ignore
import UPDATE_ENUM_ATTRIBUTE_MUTATION from './updateEnumAttributeMutation.gql';
// @ts-ignore
import UPDATE_REFERENCE_ATTRIBUTE_MUTATION from './updateReferenceAttributeMutation.gql';
// @ts-ignore
import UPDATE_BI_REFERENCE_ATTRIBUTE_MUTATION from './updateBiReferenceAttributeMutation.gql';
import {
	ATTR_EXTENSION_KEY,
	METADATA_ATTR_HELP_TEXT,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_BASIC,
} from '../constants';
import { isEmpty, omit } from 'ramda';
import { getAttributeLabel, getDataToSave } from './util';
import { AttributePreview } from './AttributePreview';
import { BiRefSettings } from './BiRefSettings';
import toast from 'react-hot-toast';

const UPDATE_TYPENAME_MAP: Record<string, string> = Object.freeze({
	[ATTRIBUTE_TYPENAME_BASIC]: UPDATE_ATTRIBUTE_MUTATION,
	[ATTRIBUTE_TYPENAME_COMPONENT]: UPDATE_COMPONENT_ATTRIBUTE_MUTATION,
	[ATTRIBUTE_TYPENAME_ENUM]: UPDATE_ENUM_ATTRIBUTE_MUTATION,
	[ATTRIBUTE_TYPENAME_UNIREF]: UPDATE_REFERENCE_ATTRIBUTE_MUTATION,
	[ATTRIBUTE_TYPENAME_BIREF]: UPDATE_BI_REFERENCE_ATTRIBUTE_MUTATION
});

type BasicAttributeLayerProps = any;

export type AttributeFormValues = {
	apiId: string;
	helpText?: string;
	name: string;
	referencedCollection?: string;
	inverseAttribute?: InverseAttributeConfig;
}

type InverseAttributeConfig = {
	apiId: string;
	helpText?: string;
	name: string;
}

export const BasicAttributeLayer = ({
	attribute,
	isNew,
	/**
	 * @deprecated
	 */
	type,
	onConfirm,
	onCancel,
	blueprint,
	extension,
}: BasicAttributeLayerProps) => {
	const form = useFormik<AttributeFormValues>({
		validateOnBlur: false,
		validateOnChange: false,
		initialStatus: {
			autoGenerateApiId: isNew,
			autoGenerateInverseApiId: isNew,
		},
		initialValues: isNew
			? {
					__typename: attribute.__typename,
					modelId: blueprint.id,
					type: attribute.type,
					name: '',
					apiId: '',
					helpText: '',
					isRequired: false,
					isList: false,
					enumerationId: '',
					allowedComponents: [],
					referencedCollection: null,
					inverseAttribute: {
						apiId: '',
						helpText: '',
						isList: false,
						name: ''
					}
			  }
			: attribute,
		validate: (values) => {
			const errors = [];

			if (isEmpty(values.name)) {
				errors.push({
					message: 'Name is required',
					path: ['input', 'name'],
				});
			}

			if (isEmpty(values.apiId)) {
				errors.push({
					message: 'API ID is required',
					path: ['input', 'apiId'],
				});
			}

			return errors;
		},
		onSubmit: async (values, { setErrors }) => {
			const cleanAttributeData: any = getDataToSave(values, isNew);

			if (!cleanAttributeData.metadata) {
				cleanAttributeData.metadata = [];
			}

			if (extension) {
				cleanAttributeData.metadata.push({
					key: ATTR_EXTENSION_KEY,
					value: extension.id,
				});
			}

			if (values.helpText) {
				cleanAttributeData.metadata.push({
					key: METADATA_ATTR_HELP_TEXT,
					value: values.helpText,
				});
			}

			let vars = {
				query: ADD_ATTRIBUTE_MUTATION,
				variables: {
					input: cleanAttributeData,
				},
			};

			switch (attribute.__typename) {
				case ATTRIBUTE_TYPENAME_COMPONENT: {
					vars = {
						query: ADD_COMPONENT_ATTRIBUTE_MUTATION,
						variables: {
							input: omit(['type'], cleanAttributeData),
						},
					};
					break;
				}
				case ATTRIBUTE_TYPENAME_UNIREF: {
					vars = {
						query: ADD_REFERENCE_ATTRIBUTE_MUTATION,
						variables: {
							input: omit(['type'], cleanAttributeData),
						},
					};
					break;
				}
				case ATTRIBUTE_TYPENAME_BIREF: {
					if (values.inverseAttribute.helpText) {
						cleanAttributeData.inverseAttribute.metadata = [
							{
								key: METADATA_ATTR_HELP_TEXT,
								value: values.inverseAttribute.helpText,
							}
						];
					}
					
					vars = {
						query: ADD_BI_REFERENCE_ATTRIBUTE_MUTATION,
						variables: {
							input: {
								...omit(['type'], cleanAttributeData),
								inverseAttribute: omit(['helpText'], cleanAttributeData.inverseAttribute),
							}
						},
					};
					break;
				}
				case ATTRIBUTE_TYPENAME_ENUM: {
					vars = {
						query: ADD_ENUM_ATTRIBUTE_MUTATION,
						variables: {
							input: omit(['type'], cleanAttributeData),
						},
					};
					break;
				}
			}

			if (isNew) {
				try {
					const { data } = await graphql(vars);
					if (data.data.addAttribute.userErrors.length) {
						setErrors(data.data.addAttribute.userErrors);
					} else {
						onConfirm(data.data.addAttribute.attribute.model);
					}
				} catch (err) {
					console.error(err);
					toast.error('Attribute add failed');
				}
			} else {
				try {
					const { data } = await graphql({
						query: UPDATE_TYPENAME_MAP[attribute.__typename],
						variables: {
							input: cleanAttributeData,
						},
					});
					if (data.data.updateMutation.userErrors.length) {
						setErrors(data.data.updateMutation.userErrors);
					} else {
						onConfirm(data.data.updateMutation.attribute.model);
					}
				} catch (err) {
					console.error(err);
					toast.error('Attribute update failed');
				}
			}
		},
	});

	let typeName;

	if (extension) {
		typeName = extension.point.name;
	} else {
		typeName = getAttributeLabel(attribute).toLowerCase();
	}

	return (
		<>
			<ModalStackLayer onRequestClose={onCancel}>
				<form className="h-full flex flex-col" onSubmit={form.handleSubmit}>
					{/* @ts-ignore */}
					<StickyHeader bordered hasPx hasPy sticky>
						<div className="flex items-center">
							<Button
								className="mr-4"
								disabled={form.isSubmitting}
								onClick={() => onCancel()}
								width="small"
							>
								<Icon source={ArrowLeftIcon} />
							</Button>
							{/* @ts-ignore */}
							<Heading className="flex-grow">
								{isNew
									? `Add new ${typeName} attribute`
									: `Editing ${attribute.name} attribute`}
							</Heading>
							<ButtonGroup>
								<Button
									disabled={form.isSubmitting || !form.dirty}
									primary
									type="submit"
								>
									{isNew ? 'Add attribute' : 'Save attribute'}
								</Button>
							</ButtonGroup>
						</div>
					</StickyHeader>

					<div className="flex-grow overflow-auto flex">
						<div className="w-1/2 pb-10 px-10 pt-5 border-r border-gray-200 overflow-y-scroll">
							{attribute.__typename === ATTRIBUTE_TYPENAME_BIREF && (
								<BiRefSettings
									// onUpdate={(val) => form.setValues(val)}
									// attribute={{
									// 	...form.values,
									// 	__typename: attribute.__typename,
									// }}
									isNew={isNew}
									// @ts-ignore
									userErrors={form.errors}
									collection={blueprint}
									form={form}
								/>
							)}
							{attribute.__typename !== ATTRIBUTE_TYPENAME_BIREF && (
								<AttributeSettings
									onUpdate={(val: any) => form.setValues(val)}
									attribute={{
										...form.values,
										__typename: attribute.__typename,
									}}
									isNew={isNew}
									userErrors={form.errors}
									blueprint={blueprint}
									form={form}
								/>
							)}
						</div>
						<div className="w-1/2 bg-gray-100 flex flex-col">
							<div className="flex-shrink-0 px-7 py-5 border-b border-gray-300">
								<p className="font-medium mb-3">Editor preview</p>
								<p>
									This is what team members will see when editing this attribute
									in the Dashboard.
								</p>
							</div>
							<div className="p-14 overflow-y-scroll relative flex-grow">
								<div className="left-0 right-0 top-0 bottom-0 absolute z-10" />

								<AttributePreview
									attribute={{
										__typename: attribute.__typename,
										name: form.values.name || 'New attribute',
										metadata: [
											{
												key: METADATA_ATTR_HELP_TEXT,
												value: form.values.helpText,
											},
											...(extension
												? [
														{
															key: ATTR_EXTENSION_KEY,
															value: extension.id,
														},
												  ]
												: []),
										],
									}}
									values={form.values}
								/>
							</div>
						</div>
					</div>
				</form>
			</ModalStackLayer>
		</>
	);
};
