import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateProject } from './mutations';
import toast from 'react-hot-toast';

export const NameSetting = ({ project, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: pathOr('', ['name'], project.data),
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			updateProject(project.data.id, values)
				.then((payload) => {
					toast.success('Project name updated');
					onChange(payload);
				})
				.catch((err) => {
					if (err.isUserError) {
						setErrors(err.userErrors);
					} else {
						console.error(err);
						toast.error('Failed to update project name.');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Project name</p>
					<p className="mb-4">
						This is the name that will be displayed on the Dashboard for this
						project.
					</p>
					<div className="w-full md:w-96">
						<TextField
							disabled={project.isLoading || form.isSubmitting}
							value={form.values.name}
							name="name"
							onChange={form.handleChange}
							error={extractGraphqlError('name', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
