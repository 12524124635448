import React from 'react';
import PropTypes from 'prop-types';

const getCellClassName = (column) => {
	const classNames = [
		'px-1',
		column.type === 'numeric' ? 'text-right' : 'text-left',
	];
	return classNames.join(' ');
};

export const DataTable = ({ columns, footers, rows, className }) => (
	<table className={`w-full ${className}`}>
		<thead>
			<tr className="border-b border-gray-300">
				{columns.map((column, columnIndex) => (
					<th
						key={columnIndex}
						className={[
							getCellClassName(column),
							'font-medium text-gray-700 text-base py-3',
						].join(' ')}
					>
						{column.heading}
					</th>
				))}
			</tr>
		</thead>
		<tbody>
			{rows.map((row, rowIndex) => (
				<tr key={rowIndex} className="border-b border-gray-200">
					{columns.map((column, columnIndex) => (
						<td
							key={columnIndex}
							className={[
								getCellClassName(column),
								'py-4 leading-normal',
								column.className ? column.className : '',
							].join(' ')}
						>
							{row[columnIndex]}
						</td>
					))}
				</tr>
			))}
		</tbody>
		{footers.length > 0 && (
			<tfoot className="border-b border-gray-300">
				{footers.map((footer, footerIndex) => (
					<tr key={footerIndex}>
						<td
							colSpan={columns.length - 1}
							className={[
								'text-right py-2 leading-normal',
								footer.className ? footer.className : '',
							].join(' ')}
						>
							{footer.heading}
						</td>
						<td
							className={[
								getCellClassName(footer),
								'py-2 leading-normal',
								footer.className ? footer.className : '',
							].join(' ')}
						>
							{footer.value}
						</td>
					</tr>
				))}
			</tfoot>
		)}
	</table>
);

DataTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			heading: PropTypes.string,
			type: PropTypes.oneOf(['text', 'numeric']),
		})
	),
	footers: PropTypes.array,
	rows: PropTypes.array,
};

DataTable.defaultProps = {
	className: '',
	footers: [],
	rows: [],
};
