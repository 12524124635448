import React from 'react';
import { EventsSetting } from './EventsSetting';
import { NameSetting } from './NameSetting';
import { UrlSetting } from './UrlSetting';
import { DeleteSetting } from './DeleteSetting';
import { DisableSetting } from './DisableSetting';
import { MainContent } from '../common/MainContent';
import { WebhookHeader } from './WebhookHeader';

export const Settings = ({ hook, onChange }) => (
	<MainContent>
		<WebhookHeader hook={hook} />
		<div className="py-5">
			<NameSetting hook={hook} onChange={onChange} />
			<UrlSetting hook={hook} onChange={onChange} />
			<EventsSetting hook={hook} onChange={onChange} />
			<DisableSetting hook={hook} onChange={onChange} />
			<DeleteSetting hook={hook} />
		</div>
	</MainContent>
);
