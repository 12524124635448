export const MainContentContainer = (props: any) => (
	<div className="md:px-10 md:pr-20 px-5 md:flex" {...props} />
);

export const MainContentNav = (props: any) => (
	<nav className="md:w-56 flex-shrink-0" {...props}>
		<div
			className="md:fixed top-0 bottom-0 md:w-52 mt-24 pt-6 pb-10 border-b md:border-b-0 pr-6 overflow-y-auto"
			{...props}
		/>
	</nav>
);

export const MainContent = (props: any) => (
	<main className="md:mt-24 pt-6 flex-grow min-w-0" {...props} />
);
