import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ConfirmDialog } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { B, P } from '../common/Text';
import { deleteProject } from './mutations';
import toast from 'react-hot-toast';

export const DeleteSetting = ({ project }) => {
	const { teamSlug } = useParams();
	const history = useHistory();
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDoingDelete, setIsDoingDelete] = useState(false);

	return (
		<>
			{isDeleting && (
				<ConfirmDialog
					title="Delete project"
					body={
						<>
							<P>
								Are you sure you want to delete <B>{project.name}</B>?
							</P>
							<P className="mt-1">
								All resources and data belonging to this project will also be
								deleted. This cannot be undone.
							</P>
						</>
					}
					onConfirm={() => {
						setIsDoingDelete(true);
						deleteProject(project.id)
							.then(() => {
								toast.success('Project deleted');
								history.push(`/${teamSlug}`);
							})
							.catch((err) => {
								console.log(err);
								toast.error('Error deleting project');
								setIsDoingDelete(false);
							});
					}}
					onCancel={() => setIsDeleting(false)}
					dangerous
					isWorking={isDoingDelete}
					confirmText="Delete project"
				/>
			)}
			<Fieldset danger>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Delete project</p>
					<P>This project will be permanently deleted.</P>
					<P className="mt-1">
						All resources and data associated with this project will also be
						deleted.
					</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={project.isLoading}
						onClick={() => setIsDeleting(true)}
						submit
						width="large"
						destructive
					>
						Delete
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
