import React from 'react';
import { DataTable } from '../common/DataTable';
import { formatMoney } from '../util';
import { isNil } from 'ramda';
import { OrderItemName } from './OrderItemName';
import { TextStyle } from '@platformapp/ui';

export const OrderItemsTable = ({ order }) => (
	<DataTable
		className="mb-10"
		columns={[
			{
				heading: 'Product',
			},
			{
				heading: 'Quantity',
				type: 'numeric',
			},
			{
				heading: 'Price',
				type: 'numeric',
			},
			{
				heading: 'Amount',
				type: 'numeric',
				className: 'font-medium',
			},
		]}
		footers={[
			{
				heading: 'Subtotal',
				value: formatMoney(order.subtotalPrice),
				type: 'numeric',
				className: 'font-medium',
			},
			{
				heading: (
					<>
						<p>Shipping</p>
						{!isNil(order.shippingRateName) && (
							<p className="mt-1 font-normal">
								<TextStyle subdued>{order.shippingRateName}</TextStyle>
							</p>
						)}
					</>
				),
				value: formatMoney(order.shippingPrice),
				type: 'numeric',
				className: 'font-medium',
			},
			{
				heading: 'Total',
				value: formatMoney(order.totalPrice),
				type: 'numeric',
				className: 'font-medium',
			},
		]}
		rows={order.items.edges.map(({ node: item }) => [
			<OrderItemName item={item} key={0} />,
			item.quantity,
			formatMoney(item.unitPrice),
			formatMoney(item.totalPrice),
		])}
	/>
);
