import { Element, Node, Text, Transforms, Range, Editor, Point } from 'slate';
import { ensureChildrenAreElements, isBlockActive } from './util';

const LIST_ITEM_NODE_TYPE = 'list-item';
const LIST_TYPES = Object.freeze(['ordered-list', 'unordered-list']);

const unwrapListItem = (editor) =>
	Editor.withoutNormalizing(editor, () => {
		// Unwrap the children of the list item...
		Transforms.unwrapNodes(editor, {
			match: (n) => n.type === LIST_ITEM_NODE_TYPE,
			split: true,
		});
		// Unwrap the list item from the list...
		Transforms.unwrapNodes(editor, {
			match: (n) => LIST_TYPES.includes(n.type),
			split: true,
		});
	});

export const toggleListBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format);

	unwrapListItem(editor);

	if (!isActive) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

export const withLists = (editor) => {
	const { deleteBackward, insertBreak, normalizeNode } = editor;

	editor.normalizeNode = (entry) => {
		const [node, path] = entry;

		// Ensure lists contain list items as children
		if (Element.isElement(node) && LIST_TYPES.includes(node.type)) {
			for (const [child, childPath] of Node.children(editor, path)) {
				// If the child is not an element, or it's not a list item, wrap
				// it in a list item.
				if (!Element.isElement(child) || child.type !== LIST_ITEM_NODE_TYPE) {
					console.log(
						`Element "${child.type}" is not a valid child of a list. Wrapping in list item.`
					);
					Transforms.wrapNodes(
						editor,
						{
							type: 'list-item',
							children: [],
						},
						{
							at: childPath,
						}
					);
					return;
				}
			}
		}

		// If the element is a list item, ensure it has only elements as children,
		// otherwise wrap the item in a paragraph;
		if (Element.isElement(node) && node.type === LIST_ITEM_NODE_TYPE) {
			ensureChildrenAreElements(editor, path);
			return;
		}

		// Fall back to the original `normalizeNode` to enforce other constraints.
		normalizeNode(entry);
	};

	editor.insertBreak = () => {
		const { selection } = editor;
		const currNode = Node.get(editor, selection.focus.path);
		const ancestors = Node.ancestors(editor, selection.focus.path, {
			reverse: true,
		});

		for (const [ancestorNode] of ancestors) {
			if (ancestorNode.type === LIST_ITEM_NODE_TYPE) {
				// If the selected item is empty, then terminate the list (essentially double enter).
				if (Text.isText(currNode) && currNode.text === '') {
					console.log('Breaking out of list item.');
					unwrapListItem(editor);
					return;
				}

				Transforms.splitNodes(editor, {
					always: true,
					match: (n) => n.type === LIST_ITEM_NODE_TYPE,
				});

				return;
			}
		}

		insertBreak();
	};

	editor.deleteBackward = (...args) => {
		const { selection } = editor;

		if (selection && Range.isCollapsed(selection)) {
			// This will be the parent of the text node, a paragraph, heading, etc.
			const match = Editor.above(editor, {
				match: (n) => Editor.isBlock(editor, n),
			});

			if (match) {
				const path = match[1];
				const start = Editor.start(editor, path);

				// If the selection anchor is at the start of this element, and this element has a block
				// quote as a parent, unwrap it.
				const parent = Node.parent(editor, path);

				if (
					Point.equals(selection.anchor, start) &&
					parent.type === LIST_ITEM_NODE_TYPE
				) {
					console.debug('Unwrapping list item');
					unwrapListItem(editor);
					return;
				}
			}
		}

		deleteBackward(...args);
	};

	return editor;
};
