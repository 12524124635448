import React, { useContext, useState } from 'react';
import { Button } from '@platformapp/ui';
import { graphql } from '../api';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { Link } from '../common/Link';
import { TableSkeleton } from '../common/Skeleton';
import { P } from '../common/Text';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { AddOriginModal } from './AddOriginModal';
import LIST_ORIGINS from './ListOrigins.gql';
import { CorsOriginContextMenu } from './OriginContextMenu';
import { useAsyncEffect } from 'use-async-effect';

export const CorsSettings = () => {
	const { environment } = useContext(EnvironmentContext);
	const [isAdding, setIsAdding] = useState(false);
	const [origins, setOrigins] = useState({
		isLoading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			setOrigins({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const res = await graphql({
					query: LIST_ORIGINS,
					variables: {
						envId: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setOrigins({
					isLoading: false,
					...res.data.data.environment.corsOrigins,
				});
			}
		},
		[environment.isLoading]
	);

	return (
		<>
			{isAdding && (
				<AddOriginModal
					onCancel={() => setIsAdding(false)}
					onConfirm={(res) => {
						setIsAdding(false);
						setOrigins({
							...origins,
							edges: [
								...origins.edges,
								{
									node: res,
								},
							],
						});
					}}
				/>
			)}
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Allowed CORS origins</p>
					<P className="mb-4">
						To query any APIs directly from a browser, all origins must be
						specified for security purposes. See the{' '}
						<Link to="https://docs.platformapp.net" external newWindow>
							Cross origin request documentation
						</Link>
						.
					</P>
					{origins.isLoading && <TableSkeleton className="w-1/2" rows={2} />}
					{!origins.isLoading && (
						<div className="border border-gray-300 rounded mt-5">
							<div className="px-4 py-2 border-b border-gray-300">
								<P weight="medium">Origin URI</P>
							</div>
							{origins.edges.length === 0 && (
								<div className="border-b border-gray-300 last:border-b-0 px-5 py-4">
									<P light>
										Only the default origin (http://localhost) is currently
										allowed
									</P>
								</div>
							)}
							{origins.edges.map(({ node }) => (
								<div
									key={node.id}
									className="flex items-center border-b border-gray-300 last:border-b-0 px-5 py-2"
								>
									<p className="flex-grow">{node.origin}</p>
									<CorsOriginContextMenu
										corsOrigin={node}
										onDelete={() => {
											setOrigins({
												...origins,
												edges: origins.edges.filter(
													({ node: corsNode }) => corsNode.id !== node.id
												),
											});
										}}
									/>
								</div>
							))}
						</div>
					)}
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						onClick={() => setIsAdding(true)}
						disabled={origins.isLoading}
						width="large"
					>
						Add origin URI
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
