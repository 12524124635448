import React from 'react';
import { NavLink } from 'react-router-dom';

export const Sidebar = ({ title, children }) => (
	<>
		<p className="text-md font-semibold text-gray-800 p-1 mb-3">{title}</p>
		{children}
	</>
);

export const SidebarLink = ({ to, ...props }) => (
	<NavLink
		activeClassName="text-blue-700 font-medium"
		className="p-1 mb-1 block hover:text-blue-700 text-gray-700 text-base"
		to={to}
		{...props}
	/>
);
