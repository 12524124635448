import { isBefore } from 'date-fns';
import {
	STAGE_DRAFT,
	STAGE_PUBLISHED,
	STATUS_CHANGED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
} from '../constants';
import { ItemStage } from '../types/Item';

export const statusFromStages = (publishable: any) => {
	const publishedVersion = publishable.inStages.find(
		(n: any) => n.stage === STAGE_PUBLISHED
	);
	if (!publishedVersion) {
		return STATUS_DRAFT;
	}
	
	// If the published date is before the date it was last updated
	const published = new Date(publishedVersion.publishedAt);
	const updated = new Date(publishable.updatedAt);

	return isBefore(published, updated) ? STATUS_CHANGED : STATUS_PUBLISHED;
};

interface ItemInStage {
	publishedAt: string;
	updatedAt: string;
	stage: ItemStage;
}

export const statusFromStagesV2 = (itemInStages: ItemInStage[]) => {
	const draftVersion = itemInStages.find(n => n.stage === STAGE_DRAFT);
	const publishedVersion = itemInStages.find(n => n.stage === STAGE_PUBLISHED);
	if (!publishedVersion) {
		return STATUS_DRAFT;
	} else if (draftVersion.updatedAt === publishedVersion.updatedAt) {
		return STATUS_PUBLISHED;
	}
	return STATUS_CHANGED;
};

export const getProductStatus = (productStages: ItemInStage[], variantStages: ItemInStage[][]) => {
	const prodStatus = statusFromStagesV2(productStages);

	// If the product is draft or changed, we can just return the status and not
	// check variants.
	if ([STAGE_DRAFT, STATUS_CHANGED].includes(prodStatus)) {
		return prodStatus;
	}

	// If the product is published, we need to check if any of the variants are changed
	const hasChanged = variantStages.some(v => statusFromStagesV2(v) === STATUS_CHANGED);

	return hasChanged ? STATUS_CHANGED : prodStatus;
}