import { useContext } from "react";
import { AppLogo } from "../extensions/AppLogo";
import { ExtensionsContext } from "../extensions/ExtensionsContext";
import { ExtensionInstallation } from "../types/Extension";

type AppButtonProps = {
    installation: ExtensionInstallation;
    onClick: () => void;
}

const AppButton = ({
    installation,
    onClick
}: AppButtonProps) => (
    <button
        title={installation.extension.name}
        onClick={onClick}
    >
        <AppLogo
            app={installation.extension}
            size="small"
        />
    </button>
)

type AppListProps = {
    onSelect: (installation: ExtensionInstallation) => void;
}

export const AppList = ({
    onSelect
}: AppListProps) => {
    const {installations} = useContext(ExtensionsContext);

    if (installations.isLoading) {
        return null;
    }

    const withPage = installations.data.filter(i => i.extension.points.some(p => p.__typename === 'PagePoint'));

    return (<>
        {withPage.map(inst => (
            <AppButton
                key={inst.id}
                installation={inst}
                onClick={() => onSelect(inst)}
            />
        ))}
    </>);
}