import React from 'react';
import { BasicLoader, Icon } from '@platformapp/ui';

const INF_SCROLL_BUTTON_CLASSNAMES =
	'flex items-center justify-center text-center font-medium text-sm w-full py-4 outline-none focus:outline-none';

export const InfiniteScrollButton = ({
	disabled,
	icon,
	loading,
	children,
	...props
}) => (
	<button
		className={`${INF_SCROLL_BUTTON_CLASSNAMES} ${
			disabled ? 'text-gray-500 cursor-default' : 'text-blue-700'
		}`}
		disabled={disabled || loading}
		{...props}
	>
		{!loading && icon && <Icon className="mr-1" source={icon} />}
		{loading && (
			<BasicLoader className="mr-1 w-4 h-4 text-blue-700 fill-current" />
		)}
		{children}
	</button>
);

InfiniteScrollButton.defaultProps = {
	disabled: false,
	loading: false,
};
