import React from 'react';
import { ConfirmDialog } from '@platformapp/ui';

export const DiscardChangesDialog = ({ onCancel, onConfirm }) => (
	<ConfirmDialog
		body={
			<p className="leading-loose">
				If you leave this page, all unsaved changes will be lost.
			</p>
		}
		confirmText="Discard changes"
		dangerous
		onCancel={onCancel}
		onConfirm={onConfirm}
		title="Unsaved changes"
	/>
);
