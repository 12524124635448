import React from 'react';
import { TextStyle } from '@platformapp/ui';

export const OrderItemName = ({ item }) => (
	<>
		<p>{item.title}</p>
		{item.options.length > 0 && (
			<p className="mt-2">
				<TextStyle subdued>
					{item.options.map((o) => o.value).join(' / ')}
				</TextStyle>
			</p>
		)}
	</>
);
