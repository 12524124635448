import React, { useState } from 'react';
import { CheckboxField, Icon } from '@platformapp/ui';
import {
	append,
	isNil,
	map,
	max,
	min,
	path,
	range,
	slice,
	without,
} from 'ramda';
import PropTypes from 'prop-types';
import DownArrowIcon from '../icons/arrow-down.svg';
import UpArrowIcon from '../icons/arrow-up.svg';
import { BulkActionBar } from './BulkActionBar';
import { Link } from 'react-router-dom';

const CHECKBOX_TD_CLASSNAMES = 'w-8 p-0 hidden md:table-cell';

export const SORT_ASC = 'ascending';
export const SORT_DESC = 'descending';

const ALIGNS = Object.freeze({
	left: 'text-left',
	right: 'text-right',
});

const getCellClassNames = (align) => `${ALIGNS[align]} h-full p-0`;

const Th = ({ align, className, ...props }) => (
	<th
		className={`${getCellClassNames(
			align
		)} ${className} font-medium text-gray-700 text-base select-none whitespace-nowrap`}
		{...props}
	/>
);

Th.defaultProps = {
	align: 'left',
	className: '',
};

/**
 * Component for rendering tabular resource data. Support keyboard shortcuts (mod+click to select multiple, shift+click to select range).
 */
export const ResourceTable = ({
	columns,
	data,
	disabled,
	idSource,
	onItemDoubleClick,
	onSelectionChange,
	onSort,
	bulkActions,
	rowClass,
	rowLink,
	selectable,
	selectOnRowClick,
	selection,
	showCheckBoxes,
	sortDirection,
	sortKey,
}) => {
	const getId = (item) => path(idSource, item);
	const [firstChecked, setFirstChecked] = useState(null);

	const changeSelection = (newSel) => {
		onSelectionChange(newSel);
		if (newSel.length === 0 && !isNil(firstChecked)) {
			setFirstChecked(null);
		}
	};

	const handleSelectAll = () => {
		changeSelection([]);
	};

	return (
		<>
			{bulkActions.length > 0 && selection.length > 0 && (
				<BulkActionBar
					bulkActions={bulkActions}
					disabled={disabled}
					onSelectAll={handleSelectAll}
					selection={selection}
				/>
			)}
			<table
				className={`w-full h-px table-auto ${
					selectable && selection.length > 0 ? 'select-none' : ''
				}`}
			>
				<thead>
					<tr className="border-b border-gray-200">
						{selectable && showCheckBoxes && (
							<Th className={CHECKBOX_TD_CLASSNAMES}>
								<div className="py-3 pr-2 pl-1">
									<CheckboxField
										checked={
											selection.length > 0 && selection.length === data.length
										}
										disabled={disabled}
										onChange={(checked) =>
											changeSelection(
												checked
													? idSource
														? data.map(getId)
														: range(0, data.length)
													: []
											)
										}
									/>
								</div>
							</Th>
						)}
						{columns.map((column, columnIndex) => (
							<Th
								key={columnIndex}
								className={`${column.className ? column.className : ''}`}
								align={column.align || 'left'}
							>
								<div className="py-3 px-2">
									{column.sortable && (
										<button
											className="text-left text-gray-700 text-base font-medium"
											onClick={() =>
												onSort(
													column.sortKey,
													sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC
												)
											}
										>
											{column.title || ''}
											{column.sortKey === sortKey && (
												<Icon
													className="ml-1"
													source={
														sortDirection === SORT_ASC
															? UpArrowIcon
															: DownArrowIcon
													}
												/>
											)}
										</button>
									)}
									{!column.sortable && column.title}
								</div>
							</Th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((item, rowIndex) => {
						const itemId = idSource ? getId(item) : rowIndex;
						const isSelected = selection.includes(itemId);
						return (
							<tr
								key={itemId}
								className={`${
									rowLink || selectable
										? 'hover:bg-gray-100 cursor-default'
										: ''
								} ${
									isSelected ? 'bg-gray-100' : ''
								} border-b border-gray-200 ${rowClass}`}
								onClick={(e) => {
									if (!selectable || disabled) {
										return;
									}
									if (e.metaKey) {
										if (selection.includes(itemId)) {
											changeSelection(without([itemId], selection));
										} else {
											changeSelection(append(itemId, selection));
										}
									} else if (selectOnRowClick) {
										if (!isNil(firstChecked) && e.shiftKey) {
											const firstItem = min(firstChecked, rowIndex);
											const lastItem = max(firstChecked, rowIndex) + 1;
											const sel = slice(firstItem, lastItem, data);
											const newSel = idSource
												? map(getId, sel)
												: range(firstItem, lastItem);
											changeSelection(newSel);
										} else {
											setFirstChecked(rowIndex);
											changeSelection([itemId]);
										}
									}
								}}
								onDoubleClick={() => {
									if (onItemDoubleClick) {
										onItemDoubleClick(item, { index: rowIndex });
									}
								}}
							>
								{selectable && showCheckBoxes && (
									<td className={CHECKBOX_TD_CLASSNAMES}>
										<div className="py-2 pr-2 pl-1">
											<CheckboxField
												checked={isSelected}
												disabled={disabled}
												onChange={(checked) =>
													onSelectionChange(
														checked
															? append(itemId, selection)
															: selection.filter((s) => s !== itemId)
													)
												}
											/>
										</div>
									</td>
								)}
								{columns.map((column, columnIndex) => (
									<td
										key={columnIndex}
										className={`${getCellClassNames(column.align || 'left')} ${
											column.className ? column.className : ''
										} text-gray-800 ${column.link ? '' : 'py-2 px-2'}`}
									>
										{column.link && (
											<Link
												className="flex items-center w-full h-full py-2 px-2"
												to={rowLink(item)}
											>
												{column.render(item, { index: rowIndex })}
											</Link>
										)}
										{!column.link && column.render(item, { index: rowIndex })}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

ResourceTable.defaultProps = {
	columns: [],
	data: [],
	disabled: false,
	idSource: ['id'],
	bulkActions: [],
	rowClass: '',
	selectable: false,
	selection: [],
	selectOnRowClick: false,
	showCheckBoxes: true,
};

ResourceTable.propTypes = {
	bulkActions: PropTypes.arrayOf(
		PropTypes.shape({
			destructive: PropTypes.bool,
			label: PropTypes.string.isRequired,
			onAction: PropTypes.func.isRequired,
		})
	),
	selection: PropTypes.array,
};
