import React, { useContext } from 'react';
import { ChoiceList } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const ComponentAttributeSettings = ({
	attribute,
	onChange,
	...props
}) => {
	const { components } = useContext(EnvironmentContext);

	if (components.length === 0) {
		return <p>Your environment does not have any components.</p>;
	}

	return (
		<ChoiceList
			allowMultiple
			label="Components"
			helpText="These are the components that can chosen when editing content."
			value={attribute.allowedComponents}
			choices={components.map((node) => ({
				label: node.name,
				value: node.id,
			}))}
			onChange={onChange}
			className="max-h-40"
			{...props}
		/>
	);
};
