import React, { forwardRef } from 'react';
import { FormSubmission } from './FormSubmission';
import DownArrow from '../icons/arrow-down.svg';
import UpArrow from '../icons/arrow-up.svg';
import { StringParam, useQueryParams } from 'use-query-params';
import { propOr } from 'ramda';
import { InfiniteScrollButton } from '../common/InfiniteScrollButton';

export const FormSubmissions = forwardRef(
	(
		{
			result,
			blueprint,
			onSelectionChange,
			selectedSubmission,
			selectedItemRefs,
		},
		ref
	) => {
		const [query, setQuery] = useQueryParams({
			after: StringParam,
			before: StringParam,
		});
		if (result.edges.length === 0) {
			return null;
		}
		const hasPrevious = propOr(false, 'hasPreviousPage', result.pageInfo);
		const hasNextPage =
			propOr(false, 'hasNextPage', result.pageInfo) || !!query.before;
		return (
			<div className="overflow-y-auto" role="menu" ref={ref}>
				{hasPrevious && (
					<InfiniteScrollButton
						icon={UpArrow}
						loading={result.isLoadingMore}
						onClick={() => {
							setQuery({
								after: undefined,
								before: result.pageInfo.startCursor,
							});
						}}
					>
						Load newer
					</InfiniteScrollButton>
				)}
				{result.edges.map((sub, index) => (
					<div key={sub.node.id} ref={selectedItemRefs[index]}>
						<FormSubmission
							submission={sub}
							attributes={blueprint.attributes}
							onClick={() => onSelectionChange(index)}
							selected={selectedSubmission === index}
						/>
					</div>
				))}
				<InfiniteScrollButton
					disabled={!hasNextPage}
					icon={DownArrow}
					loading={result.isLoadingMore}
					onClick={() =>
						setQuery({
							after: result.pageInfo.endCursor,
						})
					}
				>
					Load older
				</InfiniteScrollButton>
			</div>
		);
	}
);
