import { useState } from 'react';
import {
	Button,
	ErrorMessageElement,
	Field,
	Icon,
	TextField,
	// @ts-ignore
} from '@platformapp/ui';
import { append, omit, remove } from 'ramda';
// @ts-ignore
import PropTypes from 'prop-types';
// @ts-ignore
import DeleteIcon from '../icons/x.svg';

type ListItemProps = {
	onRemove: () => void;
	readOnly: boolean;
	value: string;
}

const ListItem = ({ onRemove, readOnly, value }: ListItemProps) => (
	<div className="inline-flex items-center rounded bg-gray-200 py-1 px-2 mb-1 mr-1">
		<p className="flex-grow">{value}</p>
		<button
			className={`${
				readOnly ? 'cursor-auto' : 'hover:text-gray-800'
			} ml-2 text-gray-600`}
			disabled={readOnly}
			onClick={(e) => {
				e.preventDefault();
				onRemove();
			}}
			title="Delete item"
			type="button"
		>
			<Icon source={DeleteIcon} />
		</button>
	</div>
);

type TextListProps = {
	addLabel?: string;
	error?: string;
	helpText?: string;
	label?: string;
	maxLength: number;
	onAddItem?: (val: string, newValue: string[]) => void;
	onChange?: (val: string[]) => void;
	onRemoveItem?: (val: string, newValue: string[]) => void;
	readOnly?: boolean;
	unique?: boolean;
	uniqueCaseInsensitive?: boolean;
	validate?: (value: string) => null | string;
	value: string[];
}

export const TextList = ({
	addLabel,
	error,
	maxLength,
	onAddItem,
	onChange,
	onRemoveItem,
	readOnly,
	unique,
	uniqueCaseInsensitive,
	validate,
	value,
	...props
	// onUpdateItem,
}: TextListProps) => {
	const [inputError, setInputError] = useState(null);
	const [newValue, setNewValue] = useState('');

	const isUnique = (valueToAdd: string) => {
		if (uniqueCaseInsensitive) {
			return !value.map(v => v.toUpperCase()).includes(valueToAdd.toUpperCase());
		}
		return !value.includes(valueToAdd);
	}

	const addItem = () => {
		setInputError(null);

		const trimmedVal = newValue.trim();

		if (trimmedVal.length === 0) {
			return;
		}

		// If items must be unique, check and set error
		if (unique && !isUnique(trimmedVal)) {
			setInputError('Values must be unique.');
			return;
		}

		// Check max length
		if (trimmedVal.length > maxLength) {
			setInputError(`Value cannot be more than ${maxLength} characters`);
			return;
		}

		if (validate) {
			const validateResult = validate(trimmedVal);
			if (validateResult !== null) {
				setInputError(validateResult);
				return;
			}
		}

		const newListValue = append(trimmedVal, value);
		if (onAddItem) {
			onAddItem(trimmedVal, newListValue);
		}
		if (onChange) {
			onChange(newListValue);
		}
		setNewValue('');
	};

	return (
		<Field {...omit(['validationErrors'], props)}>
			<div className="flex items-center">
				<div className="flex-grow mr-4">
					<TextField
						value={newValue}
						onChange={(e: any) => setNewValue(e.target.value)}
						onKeyPress={(e: any) => {
							if (e.key === 'Enter' && newValue.trim().length > 0) {
								e.preventDefault();
								addItem();
							}
						}}
						error={inputError}
						readOnly={readOnly}
					/>
				</div>
				<Button
					className="flex-shrink-0"
					disabled={readOnly || newValue.trim().length === 0}
					onClick={() => addItem()}
				>
					{addLabel}
				</Button>
			</div>
			{value.length > 0 && (
				<div className="mt-4">
					{value.map((optionValue, i) => (
						<ListItem
							key={i}
							onRemove={() => {
								const newListValue = remove(i, 1, value);
								if (onRemoveItem) {
									onRemoveItem(value[i], newListValue);
								}
								if (onChange) {
									onChange(newListValue);
								}
							}}
							readOnly={readOnly}
							value={optionValue}
						/>
					))}
				</div>
			)}
			<ErrorMessageElement message={error} />
		</Field>
	);
};

TextList.propTypes = {
	addLabel: PropTypes.string,
	maxLength: PropTypes.number,
	unique: PropTypes.bool,
	uniqueCaseInsensitive: PropTypes.bool,
	value: PropTypes.arrayOf(PropTypes.string),
};

TextList.defaultProps = {
	addLabel: 'Add',
	disabled: false,
	maxLength: Infinity,
	readOnly: false,
	unique: false,
	uniqueCaseInsensitive: false
};
