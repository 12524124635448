import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ArrowLeftIcon from '../icons/arrow-left.svg';
import { Icon } from '@platformapp/ui';

export const BackArrow = ({ label, to, className, ...props }) => (
	<p className={`mb-1 leading-relaxed ${className}`} {...props}>
		<Link
			to={to}
			className="text-gray-600 hover:text-gray-700 text-sm font-medium"
		>
			<Icon source={ArrowLeftIcon} className="mr-1" />
			{label}
		</Link>
	</p>
);

BackArrow.defaultProps = {
	className: '',
};

BackArrow.propTypes = {
	label: PropTypes.string.isRequired,
};
