import DELETE_PROJECT_MUTATION from './DeleteProject.gql';
import UPDATE_PROJECT_MUTATION from './updateProject.gql';
import { graphql } from '../api';

export const updateProject = (projectId, data) =>
	new Promise((resolve, reject) =>
		graphql({
			query: UPDATE_PROJECT_MUTATION,
			variables: {
				input: {
					projectId,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateProject;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.project);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);

export const deleteProject = (projectId) =>
	new Promise((resolve, reject) =>
		graphql({
			query: DELETE_PROJECT_MUTATION,
			variables: {
				input: {
					projectId: projectId,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteProject;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.project);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);
