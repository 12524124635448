import React, { useState } from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import { ConfirmDialog } from '@platformapp/ui';
import { deleteApiKeyPermission } from './mutations';

export const PermissionContextMenu = ({ onDelete, permission }) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = async () => {
		setIsDeleting(true);
		await deleteApiKeyPermission(permission.id);
		onDelete();
	};

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					dangerous
					title="Delete permission"
					body={
						<>
							<p className="leading-normal mb-2">
								Are you sure you want to delete this permission?
							</p>
							<p className="leading-normal">
								API keys will no longer be able to undertake the action with
								immediate effect.
							</p>
						</>
					}
					onConfirm={handleDelete}
					onCancel={() => setIsConfirmingDelete(false)}
					isWorking={isDeleting}
					confirmText="Delete permission"
				/>
			)}

			<NewContextMenu
				styleOnHover
				sections={[
					// {
					// 	items: [
					// 		{
					// 			label: 'Edit permission',
					// 			// onAction: () => setIsEditing(true),
					// 		},
					// 	],
					// },
					{
						items: [
							{
								label: 'Delete permission',
								onAction: () => setIsConfirmingDelete(true),
								destructive: true,
							},
						],
					},
				]}
			/>
		</>
	);
};
