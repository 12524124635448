import { createContext, useContext, useEffect, useState } from 'react';
import { api, graphql } from '../api';
import { EnvironmentContext } from '../context/EnvironmentContext';
// @ts-ignore
import EXTENSIONS_QUERY from './ListExtensions.gql';
// @ts-ignore
import * as zoid from 'zoid/dist/zoid.frameworks';
import { has } from 'ramda';
import { Extension, ExtensionInstallation } from '../types/Extension';
import { useAsyncEffect } from 'use-async-effect';

type ExtensionsContextProps = {
	allExtensions: any;
	installations: InstallationsData;
	findInstallation: (id: string) => ExtensionInstallation | null;
	findInstallationForExtension: (extensionId: string) => ExtensionInstallation | null;
	getToken: (installationId: string) => Promise<string>;
	extensionsWithPoint: (point: string) => ExtensionInstallation[];
	getZoidComponent: (extension: any, type: string) => any;
}

export const ExtensionsContext = createContext<ExtensionsContextProps | null>(null);

export const ExtensionsConsumer = ExtensionsContext.Consumer;

type ExtensionsProviderProps = {
	children: React.ReactNode;
}

type InstallationsData = {
	data: ExtensionInstallation[];
	isLoading: boolean;
}

type ExtensionsState = {
	isLoading: boolean;
	data?: any;
}

export const ExtensionsProvider = ({ children }: ExtensionsProviderProps) => {
	const { environment } = useContext(EnvironmentContext);

	const [zoidComponents, setZoidComponents] = useState({});
	const [extensions, setExtensions] = useState<ExtensionsState>({
		isLoading: true,
		data: []
	});

	useAsyncEffect(async () => {
		const res = await graphql({
			query: EXTENSIONS_QUERY,
		});

		setExtensions({
			isLoading: false,
			data: res.data.data.extensions,
		});
	}, []);

	useEffect(() => {
		if (environment.isLoading) {
			return;
		}

		// Generate Zoid components
		const components = environment.node.extensionInstallations.edges.reduce(
			(components, { node: installation }) => {
				const point = installation.extension.points.find(
					(p) => p.__typename === 'AttributePoint'
				);
				if (!point) {
					return components;
				}
				const tagName = `attribute-${installation.extension.id}`;
				const component = zoid.create({
					tag: tagName,
					// @ts-ignore
					url: `${point.editorUrl}?extensionId=${installation.extension.id}`,
					dimensions: {
						width: '100%',
					},
					autoResize: {
						height: true,
					},
				});
				return {
					...components,
					[tagName]: component,
				};
			},
			{}
		);
		setZoidComponents(components);

		return () => {
			zoid.destroy();
		};
	}, [environment.isLoading]);

	return (
		<ExtensionsContext.Provider
			value={{
				allExtensions: extensions,
				installations: {
					isLoading: environment.isLoading,
					data: environment?.node?.extensionInstallations?.edges.map(e => e.node),
				},
				// @ts-ignore
				extensionsWithPoint: (point: string) => {
					if (environment.isLoading) {
						return [];
					}
					return environment.node.extensionInstallations.edges.filter(
						({ node }) => {
							return node.extension.points.some((p) => p.__typename === point);
						}
					).map(n => n.node);
				},
				findInstallation: (id: string) => {
					console.log('Looking for', id);
					if (environment.isLoading) {
						return null;
					}
					const foundNode = environment.node.extensionInstallations.edges.find(
						(node) => node.node.id === id
					);
					if (!foundNode) {
						return null;
					}
					return foundNode.node;
				},
				findInstallationForExtension: (extensionId: string) => {
					if (environment.isLoading) {
						return null;
					}

					// Find the installation
					const inst = environment.node.extensionInstallations.edges.find(
						(inst) => inst.node.extension.id === extensionId
					);

					if (inst) {
						return inst.node;
					}

					return null;
				},
				getToken: async (installationId: string) => {
					const res = await api.post('/extension-jwt', {
						installationId
					});
					return res.data.token;
				},
				getZoidComponent: (extension: Extension, type: string) => {
					const tagName = `${type}-${extension.id}`;
					console.debug(`Looking for component '${tagName}'...`);
					if (has(tagName, zoidComponents)) {
						console.debug(`Found component '${tagName}'`);
						return zoidComponents[tagName];
					}
					console.warn(`Component not found '${tagName}'`);
				},
			}}
		>
			{children}
		</ExtensionsContext.Provider>
	);
};
