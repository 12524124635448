import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { assoc, equals, remove, update } from 'ramda';

const MetadataItem = ({
	disabled,
	item,
	keyError,
	onChange,
	onRemove,
	valueError,
}) => (
	<div className="flex mb-3 last:mb-0">
		<div className="w-48 mr-3 flex-shrink-0">
			<TextField
				disabled={disabled}
				error={keyError}
				onChange={(e) => {
					onChange(assoc('key', e.target.value, item));
				}}
				placeholder="Key"
				value={item.key}
			/>
		</div>
		<div className="flex-grow">
			<TextField
				disabled={disabled}
				error={valueError}
				onChange={(e) => {
					onChange(assoc('value', e.target.value, item));
				}}
				placeholder="Value"
				value={item.value}
			/>
		</div>
		<div className="ml-3">
			<Button disabled={disabled} onClick={() => onRemove()}>
				Remove
			</Button>
		</div>
	</div>
);

const errorForItem = (errors, path) => {
	const err = errors.find((err) => equals(err.path, path));
	if (err) {
		return err.message;
	}
	return null;
};

export const MetadataEditor = ({ disabled, errors, value, onChange }) => {
	return (
		<div>
			{value.map((item, index) => (
				<MetadataItem
					disabled={disabled}
					keyError={errorForItem(errors, [index.toString(), 'key'])}
					item={item}
					key={index}
					onChange={(val) => onChange(update(index, val, value))}
					onRemove={() => onChange(remove(index, 1, value))}
					valueError={errorForItem(errors, [index.toString(), 'value'])}
				/>
			))}
			<div className="mt-4">
				<Button
					disabled={disabled}
					onClick={() => {
						onChange([
							...value,
							{
								key: '',
								value: '',
							},
						]);
					}}
				>
					{value.length === 0 ? 'Add an item' : 'Add another item'}
				</Button>
			</div>
		</div>
	);
};

MetadataEditor.defaultProps = {
	disabled: false,
	errors: [],
};
