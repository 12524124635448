import { PublishStatusLabel } from '../common/PublishStatusLabel';
import { PublishableContextMenu } from '../publishable/EditPublishableDropdown';
import { ResourceTable } from '../common/ResourceTable';
import { find, propEq } from 'ramda';
import { TYPE_NUMBER } from '../constants';
import { getProductStatus, statusFromStages } from '../util/publishing';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { AttributeColumn } from './AttributeColumn';
import { Attribute } from '../types/Attribute';
import { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Collection } from '../types/Collection';
import { AssetTitle } from '../assets-list/AssetTitle';

type Column = {
	align?: 'left' | 'right';
	className?: string;
	title?: string;
	sortable?: boolean;
	sortKey?: string;
	render: (props: ColumnRenderProps) => JSX.Element;
	link?: boolean;
}

type ColumnRenderProps = {
	collection: any;
	node: any;
}

type PinnableColumn = {
	key: string;
	label: string;
}

type ContentResourceTableProps = {
	[key: string]: any;
	collection: Collection;
	linkRows?: boolean;
	pinnableColumns: PinnableColumn[];
	pinnedColumns: string[];
}

export const ContentResourceTable = ({
	collection,
	pinnableColumns,
	pinnedColumns,
	setDeleting,
	showContextMenu,
	updateContentStatus,
	linkRows,
	...props
}: ContentResourceTableProps) => {
	const {generateEnvPath} = useContext(EnvironmentContext);
	const actualColumns: Column[] = [];

	if (collection.apiId === 'Asset') {
		actualColumns.push({
			render: ({ node }) => <AssetTitle node={node} />,
			link: linkRows
		})
	}

	// for (const pc of pinnableColumns) {
	for (const pcKey of pinnedColumns) {
		const colDef = pinnableColumns.find(c => c.key === pcKey);

		if (!colDef) {
			continue;
		}

		if (pcKey === 'id') {
			actualColumns.push({
				title: colDef.label,
				sortable: true,
				sortKey: pcKey,
				render: ({ node }) => <ApiIdLabel>{node.id}</ApiIdLabel>,
				link: linkRows
			});
		} else {
			const attr = find<Attribute>(propEq('apiId', pcKey), collection.attributes);
			actualColumns.push({
				title: colDef.label,
				sortable: true,
				sortKey: pcKey,
				align: attr.__typename === 'BasicAttribute' && attr.type === TYPE_NUMBER ? 'right' : 'left',
				render: ({ node }) => (
					<AttributeColumn attribute={attr} node={node} />
				),
				link: linkRows
			});
		}
	}

	if (collection.isPublishable) {
		actualColumns.push({
			title: 'Status',
			link: linkRows,
			render: ({ node }: any) => (
				<PublishStatusLabel
					status={
						node.__typename === 'Product'
							? getProductStatus(
									node.inStages,
									node.variants.edges.map((edge: any) => edge.node.inStages)
							  )
							: statusFromStages(node)
					}
				/>
			),
		});
	}

	const tableDef = [
		...actualColumns,
		...(showContextMenu
			? [
					{
						className: 'w-12',
						render: ({ node }) => (
							<PublishableContextMenu
								isPublishable={collection.isPublishable}
								publishable={node}
								togglePublish={(status: any) => updateContentStatus(status, node)}
								onDeleteClick={() => setDeleting(node)}
								smallTrigger
							/>
						),
					} as Column,
			  ]
			: []),
	];

	return (
		// @ts-ignore
		<ResourceTable
			{...props}
			columns={tableDef}
			idSource={['node', 'id']}
			rowLink={(item: any) => generateEnvPath(collection.apiId === 'Product'
			? `products/${item.node.id}`
			: `content/${collection.id}/${item.node.id}`)}
		/>
	);
};

ContentResourceTable.defaultProps = {
	linkRows: true,
	pinnableColumns: [],
	pinnedColumns: [],
	showContextMenu: false,
};
