import React, { Component } from 'react';

export const withErrorBoundary = (BaseComponent) => (props) => (
	<ErrorBoundary>
		<BaseComponent {...props} />
	</ErrorBoundary>
);

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="rounded border border-red-400 p-4">
					<p className="text-red-700 font-medium mb-2">
						Something went wrong with the editor.
					</p>
					<p>
						Please try refreshing the page. If you continue to experience this
						issue, please contact{' '}
						<a href="mailto:support@platformapp.net" className="text-blue-700">
							support@platformapp.net
						</a>
						.
					</p>
				</div>
			);
		}
		return this.props.children;
	}
}
