import React, { useState } from 'react';
import { SubmissionFields } from './SubmissionFields';
import { Badge, ConfirmDialog, DateTime } from '@platformapp/ui';
import { SubmissionContextMenu } from './SubmissionContextMenu';
import { DATE_REFRESH } from '../constants';
import { B } from '../common/Text';

export const FormBody = ({
	archiveSubmission,
	deleteSubmission,
	blueprint,
	submission,
	flagSpam,
	flagNotSpam,
}) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					title="Are you sure you want to delete this form submission?"
					body="This submission and all associated data will be permanently removed."
					confirmText="Delete submission"
					dangerous
					onCancel={() => setIsConfirmingDelete(false)}
					onConfirm={() => {
						deleteSubmission(submission.id);
						setIsConfirmingDelete(false);
					}}
				/>
			)}
			<div className="flex items-center py-4">
				<div className="flex-grow flex items-center">
					<p className="mr-5">
						<B>
							<DateTime value={submission.createdAt} refresh={DATE_REFRESH} />
						</B>
					</p>
					{submission.isArchived && <Badge className="mr-2" text="Archived" />}
					{submission.isSpam && <Badge className="mr-2" warning text="Spam" />}
				</div>
				<div className="flex-shrink-0">
					<SubmissionContextMenu
						archiveSubmission={() => {
							archiveSubmission(submission.id, true);
						}}
						deleteSubmission={() => setIsConfirmingDelete(true)}
						submission={submission}
						unarchiveSubmission={() => {
							archiveSubmission(submission.id, false);
						}}
						flagSpam={() => flagSpam(submission.id)}
						flagNotSpam={() => flagNotSpam(submission.id)}
					/>
				</div>
			</div>
			<SubmissionFields
				attributes={blueprint.attributes}
				submission={submission}
			/>
		</>
	);
};
