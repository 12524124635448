import { Extension } from "../types/Extension"

type AppLogoProps = {
    app: Extension;
    size: 'small' | 'medium' | 'large';
}

const SIZE_CLASSNAMES = {
    'small': 'w-8 h-8',
    'medium': 'w-10 h-10',
    'large': 'w-12 h-12',
}

export const AppLogo = ({
    app,
    size
}: AppLogoProps) => (
    <img
        className={`rounded border border-gray-300 ${SIZE_CLASSNAMES[size]}`}
        src={`https://extensions-extensionimages-1kzc1dkl1j1xz.s3.eu-west-1.amazonaws.com/${app.id}.svg`}
    />
)

AppLogo.defaultProps = {
    size: 'medium'
}