import React from 'react';

import { path, split } from 'ramda';

import {
	Table,
	TableHead,
	TableCell,
	TableHeadCell,
	TableBody,
	TableRow,
} from '@platformapp/ui';

const getCellValue = (col, row) =>
	col.accessor ? path(split('.', col.accessor), row) : null;

export const DataTable = ({
	columns,
	data,
	rowIdPath,
	inline,

	// Row selection
	isSelectable,
	onSelectionChange,
	selectedRow,

	...remainder
}) => (
	<Table {...remainder}>
		<TableHead inline={inline}>
			<TableRow>
				{columns.map((col, i) => (
					<TableHeadCell
						key={i}
						className={col.className || ''}
						minWidth={col.id === 'contextmenu' || col.minWidth}
						right={col.right}
						inline={inline}
					>
						{col.Header}
					</TableHeadCell>
				))}
			</TableRow>
		</TableHead>
		<TableBody>
			{data.map((row, rowIndex) => (
				<TableRow
					key={path(rowIdPath, row)}
					isSelectable={isSelectable}
					isSelected={isSelectable && selectedRow === rowIndex}
					onClick={() => {
						if (isSelectable && onSelectionChange) {
							onSelectionChange(rowIndex);
						}
					}}
				>
					{columns.map((col, i) => (
						<TableCell
							key={i}
							className={col.className || ''}
							minWidth={col.id === 'contextmenu' || col.minWidth}
							right={col.right}
							inline={inline}
						>
							{col.Cell
								? col.Cell({
										cell: {
											value: getCellValue(col, row),
										},
										row: {
											id: path(rowIdPath, row),
											index: rowIndex,
											original: row,
										},
								  })
								: getCellValue(col, row)}
						</TableCell>
					))}
				</TableRow>
			))}
		</TableBody>
	</Table>
);

DataTable.defaultProps = {
	inline: false,
	rowIdPath: ['id'],
};
