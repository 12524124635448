import React from 'react';
import { ListPageHeader } from '../common/ListPageHeader';
import { useTeamTitle } from '../hooks/useTitle';
import { Invoices } from './Invoices';
// import { UsageSummary } from './UsageSummary';

export const Usage = () => {
	useTeamTitle('Usage');
	return (
		<div className="mt-24 pt-6 mx-5 md:mx-10">
			<ListPageHeader title="Usage" />
			<div className="mt-5">
				{/* <UsageSummary /> */}
				<Invoices />
			</div>
		</div>
	);
};
