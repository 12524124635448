import React from 'react';
import PropTypes from 'prop-types';

export const Heading = ({
	element,
	className,
	children,
	truncate,
	...props
}) => {
	const classNames = ['text-2xl font-medium leading-relaxed', className];

	if (truncate) {
		classNames.push('truncate');
	}

	return React.createElement(
		element,
		{
			className: classNames.join(' '),
			...props,
		},
		children
	);
};

Heading.defaultProps = {
	className: '',
	element: 'h1',
	size: 'xl',
	truncate: false,
};

Heading.propTypes = {
	truncate: PropTypes.bool,
};

// Platform supports:
// X-large
// Large
// Medium
