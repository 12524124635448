import { useState, useContext } from 'react';
// @ts-ignore
import { Button } from '@platformapp/ui';
import { AssetFilters } from './AssetFilters';
import { AssetDetailsLayer } from '../assets/AssetDetailsLayer';
import { ListPageHeader } from '../common/ListPageHeader';
import { AssetDetails } from './AssetDetails';
import { pick } from 'ramda';
import { Paginator } from '../common/Pagination';
import { AssetGrid } from './AssetGrid';
import { NoResults } from '../common/NoResults';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { TableSkeleton } from '../common/Skeleton';
import { ColumnPinMenu } from '../content-list/ColumnPinMenu';
import { getPinnableAttributes } from '../util/AttributeHelpers';
import { sortByLabel } from '../util';
import { AssetView, AssetViewToggle } from './AssetViewToggle';
import { ContentResourceTable } from '../content-list/ContentResourceTable';
import { ContentApiContext } from '../context/ContentApiContext';
import { ASSET_DEFAULT_PINNED, CollectionMetadataKeys, STATUS_PUBLISHED } from '../constants';
import { DeleteItemModal } from '../publishable/DeleteItemModal';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Attribute } from '../types/Attribute';
import { ItemStage } from '../types/Item';
import toast from 'react-hot-toast';
import Helpers from '../Helpers';
import { getMetadataCsv } from '../util/metadata';
import { Collection } from '../types/Collection';
import { AsyncNode } from '../types/Node';

const ASSET_VIEW_LS_KEY = 'pl:asset_view';

type AssetListProps = {
	[key: string]: any;
	assetBlueprint: AsyncNode<Collection>;
}

export const AssetList = ({
	assets,
	filters,
	onUpload,
	onDelete,
	onChange,
	selection,
	onSelectionChange,
	order,
	setOrder,
	assetBlueprint,
}: AssetListProps) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	const [isCreating, setIsCreating] = useState(false);
	const [viewingDetails, setViewingDetails] = useState(null);
	const [assetView, setAssetView] = useLocalStorage(ASSET_VIEW_LS_KEY, AssetView.List);
	const [pinnedColumns, setPinnedColumns] = useState(getMetadataCsv(assetBlueprint.node.metadata, CollectionMetadataKeys.PinnedAttributes, ASSET_DEFAULT_PINNED));
	const [deleting, setDeleting] = useState<string[]>([]);
	const {publishItems, unpublishItems} = useContext(ContentApiContext);

	const publish = async (ids: string[]) => {
		const promise = publishItems(ids);

		toast.promise(promise, {
			loading: Helpers.t('publishing.publishing_inprogress', ids.length),
			success: Helpers.t('publishing.publishing_success', ids.length),
			error: Helpers.t('publishing.publishing_failed', ids.length)
		});

		const res = await promise;

		onChange(ids.map(id => {
			const result = res.data[`publish_${id}`];
			return result.publishedNodes.find((n: any) => n.id === id);
		}));
	}

	const unpublish = async (ids: string[]) => {
		const promise = unpublishItems(ids);

		toast.promise(promise, {
			loading: Helpers.t('publishing.unpublishing_inprogress', ids.length),
			success: Helpers.t('publishing.unpublishing_success', ids.length),
			error: Helpers.t('publishing.unpublishing_failed', ids.length)
		});

		const res = await promise;

		onChange(ids.map(id => {
			const result = res.data[`unpublish_${id}`];
			return result.unpublishedNodes.find((n: any) => n.id === id);
		}));
	}

	const pinnableColumns = assetBlueprint.isLoading ? [] : sortByLabel([
		...getPinnableAttributes(assetBlueprint.node.attributes).map((attr: Attribute) => ({
			label: attr.name,
			key: attr.apiId,
		})),
		{
			label: 'ID',
			key: 'id',
		},
	]);

	const toggleStatus = (status: ItemStage, item: any) => status === STATUS_PUBLISHED ? publish([item.id]) : unpublish([item.id]);

	const bulkActions = [
		{
			label: Helpers.t('publishing.publish'),
			onAction: () => publish(selection)
		},
		{
			label: Helpers.t('publishing.unpublish'),
			onAction: () => unpublish(selection)
		},
		{
			destructive: true,
			label: 'Delete',
			onAction: () => setDeleting(selection),
		},
	];

	return (
		<>
			{isCreating && (<>
				{/* @ts-ignore */}
				<AssetDetailsLayer
					onCancel={() => setIsCreating(false)}
					onSave={(newItem: any) => {
						onUpload(newItem);
						setIsCreating(false);
					}}
				/>
			</>)}
			{viewingDetails !== null && (<>
				{/* @ts-ignore */}
				<AssetDetailsLayer
					onCancel={() => setViewingDetails(null)}
					onSave={(data: any) => {
						onChange(viewingDetails, data);
						setViewingDetails(null);
					}}
					assetId={viewingDetails}
				/>
			</>)}
			{deleting.length > 0 && (
				<DeleteItemModal
					collection={assetBlueprint.node}
					items={assets.edges
						.filter((edge: any) => deleting.includes(edge.node.id))
						.map((edge: any) => edge.node)
					}
					onCancel={() => setDeleting([])}
					onDelete={(ids) => {
						toast.success(Helpers.t('items.delete_success', deleting.length));
						onSelectionChange([]);
						setDeleting([]);
						onDelete(ids);
					}}
				/>
			)}
			<div className="flex flex-col h-full mt-24 pt-6 mx-5 md:mx-10 md:pr-10">
				{/* @ts-ignore */}
				<ListPageHeader
					sticky={false}
					title="Assets"
					right={
						<Button primary onClick={() => setIsCreating(true)}>
							Create asset
						</Button>
					}
				/>
				<div className="flex flex-grow">
					<div className="w-full lg:w-3/4 md:pr-5 mt-5">
						<div className="flex mb-3 items-center">
							<div className="flex-grow">
								<AssetFilters
									appliedFilters={filters}
									assetBlueprint={assetBlueprint}
								/>
							</div>
							<div className="flex flex-shrink-0">
								<ColumnPinMenu
									onChange={setPinnedColumns}
									pinnableColumns={pinnableColumns}
									pinnedColumns={pinnedColumns}
								/>
								<AssetViewToggle
									onChange={setAssetView}
									view={assetView}
								/>
							</div>
						</div>
						{assets.isLoading && <TableSkeleton className="mt-2" />}
						{!assets.isLoading && (
							<div className="fade-in">
								{assets.edges.length === 0 && <NoResults />}
								{assets.edges.length > 0 && (
									<>
										{assetView === AssetView.List && (<>
											{/* @ts-ignore */}
											<ContentResourceTable
												collection={assetBlueprint.node}
												bulkActions={bulkActions}
												data={assets.edges}
												onChange={onChange}
												onItemDoubleClick={({ node }: any) => setViewingDetails(node.id)}
												onSelectionChange={onSelectionChange}
												pinnableColumns={pinnableColumns}
												pinnedColumns={pinnedColumns}
												selectable
												selection={selection}
												sortDirection={order.dir}
												sortKey={order.field}
												linkRows={false}
												onSort={(field: string, dir: string) => setOrder({
													dir,
													field
												})}
												selectOnRowClick
												showContextMenu
												setViewingDetails={setViewingDetails}
												updateContentStatus={toggleStatus}
												setDeleting={(res: any) => setDeleting([res.id])}
											/>
										</>)}
										{assetView === AssetView.Grid && (<>
											{/* @ts-ignore */}
											<AssetGrid
												bulkActions={bulkActions}
												data={assets.edges}
												multiple
												onDelete={setDeleting}
												onSelectionChange={onSelectionChange}
												selection={selection}
												setViewingDetails={setViewingDetails}
												showContextMenu
												updateContentStatus={toggleStatus}
											/>
										</>)}
										{/* @ts-ignore */}
										<Paginator
											{...pick(['totalCount', 'pageInfo'], assets)}
											baseUrl={generateEnvPath('assets')}
											filters={filters}
										/>
									</>
								)}
							</div>
						)}
					</div>
					<div className="hidden lg:block w-1/4 pl-5">
						<AssetDetails
							assets={assets.edges.filter(({ node }: any) =>
								selection.includes(node.id)
							)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
