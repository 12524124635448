import React, { useState } from 'react';
import { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Badge, Button, TextStyle } from '@platformapp/ui';
import Helpers from '../Helpers';
import { PermissionContextMenu } from './PermissionContextMenu';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { AddPermissionModal } from './AddPermissionModal';
import { useParams } from 'react-router';
import { append } from 'ramda';

export const ContentPermissions = ({ disabled, initialPermissions }) => {
	const { apiKeyId } = useParams();
	const { environment, collections: allColls } = useContext(EnvironmentContext);
	const [addingPermission, setAddingPermission] = useState(false);
	const [permissions, setPermissions] = useState(initialPermissions);

	if (environment.isLoading) {
		return null;
	}

	const collections = allColls.reduce((all, collection) => {
		// Get permissions for this collection
		const collPerms = permissions.filter(
			(p) => p.collection && p.collection.id === collection.id
		);

		if (collPerms.length === 0) {
			return all;
		}

		return [
			...all,
			{
				collection,
				permissions: collPerms,
			},
		];
	}, []);

	const wildcardPermissions = permissions.filter((p) => !p.collection);

	const handleDelete = (id) =>
		setPermissions(permissions.filter((p) => p.id !== id));

	return (
		<>
			{addingPermission && (
				<AddPermissionModal
					apiKeyId={apiKeyId}
					onCancel={() => setAddingPermission(false)}
					onConfirm={(permission) => {
						setPermissions(append(permission, permissions));
						setAddingPermission(false);
					}}
				/>
			)}
			<Fieldset disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Collection permissions</p>
					<p className="mb-8">
						These permissions determine how this API key can interact with
						resources in the Content API.
					</p>
					{wildcardPermissions.length === 0 && collections.length === 0 && (
						<p>
							<TextStyle subdued>
								This API key has no collection permissions
							</TextStyle>
						</p>
					)}
					{wildcardPermissions.length > 0 && (
						<CollectionRow
							onDelete={handleDelete}
							permissions={wildcardPermissions}
						/>
					)}
					{collections.map(({ collection, permissions }) => (
						<CollectionRow
							collection={collection}
							onDelete={handleDelete}
							permissions={permissions}
							key={collection.id}
						/>
					))}
				</FieldsetContent>
				<FieldsetFooter>
					<Button onClick={() => setAddingPermission(true)}>
						Add permission
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};

const PermissionLabel = ({ permission }) => {
	if (permission.__typename === 'ReadItemPermission') {
		if (permission.stages.length === 0) {
			return (
				<p>
					{Helpers.t(`permission_type.${permission.__typename}`)} in any stage
				</p>
			);
		} else {
			return (
				<p>
					{Helpers.t(`permission_type.${permission.__typename}`)} in stages{' '}
					{permission.stages.map((stage) => (
						<Badge key={stage} text={Helpers.t(`publishing.status.${stage}`)} />
					))}
				</p>
			);
		}
	}
	return <p>{Helpers.t(`permission_type.${permission.__typename}`)}</p>;
};

export const CollectionRow = ({ collection, onDelete, permissions }) => {
	return (
		<div className="border rounded border-gray-300 mb-5 last:mb-0">
			<div className="px-5 py-4 border-b border-gray-300">
				<p className="text-gray-900 font-medium">
					{collection ? collection.name : 'All collections'}
				</p>
			</div>
			<div className="">
				{permissions.map((p) => (
					<PermissionRow
						key={p.id}
						onDelete={() => onDelete(p.id)}
						permission={p}
					/>
				))}
			</div>
		</div>
	);
};

CollectionRow.defaultProps = {
	collection: null,
};

const PermissionRow = ({ onDelete, permission }) => {
	return (
		<div className="border-gray-300 border-b last:border-b-0 py-2 px-5 flex items-center">
			<div className="flex-grow">
				<PermissionLabel permission={permission} />
			</div>
			<PermissionContextMenu onDelete={onDelete} permission={permission} />
		</div>
	);
};
