import React, { useEffect } from 'react';

import api from '../api';

import { Loader } from '@platformapp/ui';

export const Login = () => {
	useEffect(() => {
		api.post('/auth/authorize').then((res) => {
			window.location.replace(res.data.auth_uri);
		});
	}, []);

	return <Loader />;
};
