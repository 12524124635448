import React, { useContext } from 'react';
import { Sidebar, SidebarLink } from '../common/Sidebar';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const ProductsSidebar = () => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<Sidebar title="Products">
			<SidebarLink to={generateEnvPath('products')}>Products</SidebarLink>
			<SidebarLink exact to={generateEnvPath('inventory')}>
				Inventory
			</SidebarLink>
		</Sidebar>
	);
};
