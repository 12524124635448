import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Loader } from '@platformapp/ui';
import { AcceptInvite } from './team-members/AcceptInvite';
import { Team } from './team/Team';
import { page } from './util/events';
import { SignOut } from './user/SignOut';
import { VerificationWarning } from './VerificationWarning';
import { TeamsContext } from './context/TeamsContext';
import { find, isNil, pathEq } from 'ramda';
import { LS_LAST_TEAM_KEY } from './constants';
import { useLocalStorage } from './hooks/useLocalStorage';
import { DeepLink } from './deep-link/DeepLink';
import { TopNav } from './navigation/TopNav';
import { PrimaryNavigation } from './navigation/PrimaryNavigation';
import { StripeCallback } from './integrations/StripeCallback';

function usePageViews() {
	const location = useLocation();
	useEffect(() => {
		page(location.pathname);
	}, [location]);
}

const Landing = () => {
	const { teams, isLoading } = useContext(TeamsContext);
	const [lastTeamSlug] = useLocalStorage(LS_LAST_TEAM_KEY);
	if (isLoading) {
		return <Loader />;
	} else if (teams.length > 0) {
		if (
			isNil(lastTeamSlug) ||
			!find(pathEq(['node', 'slug'], lastTeamSlug), teams)
		) {
			return <Redirect to={`/${teams[0].node.slug}`} />;
		}
		return <Redirect to={`/${lastTeamSlug}`} />;
	}
	return <p>No teams found</p>;
};

export const Dashboard = () => {
	usePageViews();

	return (
		<>
			<div className="app-container transition-transform duration-200 ease-out">
				<Switch>
					<Route exact path="/sign-out" component={SignOut} />
					<Route exact path="/accept-invite/:id" component={AcceptInvite} />
					<Route exact path="/deep-link/:id" component={DeepLink} />
					<Route
						exact
						path="/integrations/callback/stripe"
						component={StripeCallback}
					/>
					<Route path="/:teamSlug">
						<LoggedIn />
					</Route>
					<Route exact path="/" component={Landing} />
				</Switch>
			</div>
		</>
	);
};

const LoggedIn = () => (
	<>
		<VerificationWarning />
		<div className="fixed top-0 left-0 right-0 z-10">
			<TopNav />
			<PrimaryNavigation />
		</div>
		<Route path="/:teamSlug" component={Team} />
	</>
);
