import { useState } from "react";
// @ts-ignore
import {formatCurrency, TextField} from '@platformapp/ui';
import { isNil } from "ramda";

type CurrencyFieldProps = {
    currency: string;
    error?: string[];
    label: string;
    onChange: (value: number) => void;
    readOnly?: boolean;
    value?: number;
}

export const CurrencyField = ({
	onChange,
	value,
	currency,
	...props
}: CurrencyFieldProps) => {
	const [ typedValue, setTypedValue ] = useState(null);

	const formattedValue = isNil(value)
        ? ''
        : formatCurrency(value, currency, false );

	const handleBlur = (e: any) => {

		setTypedValue(null);

		const value = e.target.value;

		// The user wants to set this to an empty value
		if (value === '') {
			onChange(null);
			return;
		}

		// Remove an commas and parse as float
		const withoutCommas = e.target.value.replace(/,/g, '');
		const floatVal = parseFloat(withoutCommas);

		// If the float parsing failed, this is not a valid
		// currency.
		if (isNaN(floatVal)) {
			onChange(null);
			return;
		}

		// Call onchange - round the float input to nearest 2 decimal places,
		// then multiply by 100 to get the integer currency representation.
		const intVal = Math.round((floatVal + Number.EPSILON) * 100);
		onChange(intVal);

	}

	// Either the typed value, or the input currency value
	const displayValue = typedValue !== null ? typedValue : formattedValue;

	return (
		<TextField
			onChange={(e: any) => setTypedValue(e.target.value)}
			onBlur={handleBlur}
            value={displayValue}
			{...props}
		/>
	);
}

CurrencyField.defaultProps = {
	prefix: "£"
}