import React, { useContext } from 'react';
import { matchPath } from 'react-router-dom';
import { ProjectContext } from '../context/ProjectContext';
import {
	BLUEPRINT_VIEW_METADATA_KEY,
	ROLE_OWNER,
	VIEW_INBOX,
} from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Toggle } from '../common/Toggle';
import { getMetadataValue } from '../util/metadata';
import { TopNavLink } from '../common/PrimaryNav';
import { hasAttributes } from '../util';

const EnvToggle = () => {
	const { environment, switchEnvironment } = useContext(EnvironmentContext);
	return (
		<div className="flex items-center pb-2">
			<Toggle
				checked={!environment.node.isDefault}
				className="mr-px"
				small
				onChange={() =>
					switchEnvironment(environment.node.isDefault ? 'test' : 'main')
				}
			/>
			<p className="text-sm font-medium">Test environment</p>
		</div>
	);
};

export const ProjectNav = () => {
	const { generateEnvPath, environment, collections } = useContext(
		EnvironmentContext
	);
	const { activeProject, generateProjectPath, isLoading } = useContext(
		ProjectContext
	);
	const formBps = environment.isLoading
		? []
		: collections
				.filter(
					(node) =>
						getMetadataValue(node.metadata, BLUEPRINT_VIEW_METADATA_KEY) ===
						VIEW_INBOX
				)
				.filter(hasAttributes);
	const hasFormsExtension = environment.isLoading
		? false
		: environment.node.extensionInstallations.edges.some(
				(edge) => edge.node.extension.slug === 'forms'
		  );
	return (
		<>
			{!isLoading && !environment.isLoading && (
				<>
					<div className="flex md:flex-grow flex-shrink-0">
						<TopNavLink to={generateEnvPath(`content`)}>Content</TopNavLink>
						<TopNavLink to={generateEnvPath('assets')}>Assets</TopNavLink>

						{activeProject.ecommerceEnabled && (
							<>
								<TopNavLink
									to={generateEnvPath('products')}
									isActive={(_, location) =>
										!!matchPath(location.pathname, {
											path: [
												'/:teamSlug/:projectId/products',
												'/:teamSlug/:projectId/env/:envSlug/products',
												'/:teamSlug/:projectId/inventory',
												'/:teamSlug/:projectId/env/:envSlug/inventory',
											],
										})
									}
								>
									Products
								</TopNavLink>
								<TopNavLink to={generateEnvPath('orders')}>Orders</TopNavLink>
							</>
						)}

						{hasFormsExtension && formBps.length > 0 && (
							<TopNavLink to={generateEnvPath(`forms/${formBps[0].apiId}`)}>
								Forms
							</TopNavLink>
						)}
					</div>

					<div className="flex-shrink-0 flex items-center">
						{activeProject.team.viewerRole === ROLE_OWNER && (
							<div>
								<TopNavLink
									to={generateEnvPath('schema/collections')}
									isActive={(_, location) =>
										!!matchPath(location.pathname, {
											path: [
												'/:teamSlug/:projectId/schema',
												'/:teamSlug/:projectId/env/:envSlug/schema',
												'/:teamSlug/:projectId/blueprints',
												'/:teamSlug/:projectId/env/:envSlug/blueprints',
											],
										})
									}
								>
									Schema
								</TopNavLink>
								<TopNavLink
									to={generateEnvPath('developers')}
									isActive={(_, location) =>
										!!matchPath(location.pathname, {
											path: [
												'/:teamSlug/:projectId/developers',
												'/:teamSlug/:projectId/env/:envSlug/developers',
												'/:teamSlug/:projectId/api-keys',
												'/:teamSlug/:projectId/env/:envSlug/api-keys',
												'/:teamSlug/:projectId/webhooks',
												'/:teamSlug/:projectId/env/:envSlug/webhooks',
											],
										})
									}
								>
									Developers
								</TopNavLink>
								<TopNavLink
									to={
										environment.node.isDefault
											? generateProjectPath('settings')
											: generateEnvPath('settings/ecommerce')
									}
								>
									Settings
								</TopNavLink>
							</div>
						)}
						<EnvToggle />
					</div>
				</>
			)}
		</>
	);
};
