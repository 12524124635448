import React, { useContext } from 'react';
import { ProjectContext } from '../context/ProjectContext';
import { Sidebar, SidebarLink } from '../common/Sidebar';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const SettingsSidebar = () => {
	const { activeProject, generateProjectPath } = useContext(ProjectContext);
	const { environment, generateEnvPath } = useContext(EnvironmentContext);
	return (
		<Sidebar title="Settings">
			{environment.node && environment.node.isDefault && (
				<SidebarLink exact to={generateProjectPath('settings')}>
					Project
				</SidebarLink>
			)}
			{activeProject && activeProject.ecommerceEnabled && (
				<>
					<SidebarLink exact to={generateEnvPath('settings/ecommerce')}>
						Ecommerce
					</SidebarLink>
					<SidebarLink exact to={generateEnvPath('settings/payment-providers')}>
						Payment providers
					</SidebarLink>
				</>
			)}
			{activeProject && (
				<SidebarLink exact to={generateEnvPath('settings/apps')}>
					Manage apps
				</SidebarLink>
			)}
		</Sidebar>
	);
};
