import { useContext } from "react";
import { EnvironmentContext } from "../context/EnvironmentContext";
// @ts-ignore
import { ChoiceList } from '@platformapp/ui';
import { ApiIdLabel } from "../common/ApiIdLabel";

type CollectionChoiceListProps = {
    disabled?: boolean;
    error?: string[];
    label: string;
    onChange?: (value: string[]) => void;
    value: string;
}

export const CollectionChoiceList = ({
    disabled,
    error,
    label,
    onChange,
    value
}: CollectionChoiceListProps) => {
    const { collections } = useContext(EnvironmentContext);
    return (
        <ChoiceList
            disabled={disabled || false}
            label={label}
            value={value}
            choices={collections
                .filter((node: any) => node.apiId !== 'Asset')
                .map((node: any) => ({
                    value: node.id,
                    label: (
                        <>
                            {node.name}{' '}
                            <ApiIdLabel className="ml-3">{node.apiId}</ApiIdLabel>
                        </>
                    ),
                }))}
            onChange={onChange}
            className="max-h-40"
            error={error}
        />
    )
}