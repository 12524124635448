import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { UserContext, UserProvider } from './context/UserContext';
import { Dashboard } from './Dashboard';
import { init } from './api';
import './styles/style.css';
import '@platformapp/ui/src/styles/index.css';
import './styles/pl-stacks.css';
import './styles/index.css';
import './styles/animation.css';
import { QueryParamProvider } from 'use-query-params';
import { TeamsProvider } from './context/TeamsContext';
import { TeamProvider } from './context/TeamContext';
import { ProjectProvider } from './context/ProjectContext';
import { NotFound } from './NotFound';
import { EnvironmentProvider } from './context/EnvironmentContext';
import { Login } from './auth/Login';
import { AuthCallback } from './auth/AuthCallback';
import { ExtensionsProvider } from './extensions/ExtensionsContext';
import { User } from './user/User';
import { ContentApiProvider } from './context/ContentApiContext';
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';

const TestInit = ({ children }) => {
	init(() => {
		window.location.replace(`/login`);
	});
	return children;
};

export const ReactApp = () => (
	<React.StrictMode>
		<BrowserRouter basename="/">
			<Toaster position="top-center" />
			<ReactTooltip effect="solid" />
			<Switch>
				<Route
					exact
					path={[
						'/',
						'/login/callback',
						'/login',
						'/sign-out',
						'/accept-invite/:id',
						'/account',
						'/account/security',
						'/deep-link/:id',
						'/integrations/callback/stripe',

						// All team routes
						'/:teamSlug',
						'/:teamSlug/members',
						'/:teamSlug/billing',
						'/:teamSlug/change-plan',
						'/:teamSlug/plans',
						'/:teamSlug/usage',
						'/:teamSlug/settings',

						// Project routes that can have optional env
						'/:teamSlug/:projectId',
						'/:teamSlug/:projectId/assets',
						'/:teamSlug/:projectId/blueprints/:id',
						'/:teamSlug/:projectId/blueprints/:id/settings',
						'/:teamSlug/:projectId/content',
						'/:teamSlug/:projectId/content/:blueprintId',
						'/:teamSlug/:projectId/content/:blueprintId/new',
						'/:teamSlug/:projectId/content/:blueprintId/:id',
						'/:teamSlug/:projectId/developers',
						'/:teamSlug/:projectId/forms/:formId',
						'/:teamSlug/:projectId/forms/:formId/settings',
						'/:teamSlug/:projectId/inventory',
						'/:teamSlug/:projectId/orders',
						'/:teamSlug/:projectId/orders/:id',
						'/:teamSlug/:projectId/orders/:id/ship',
						'/:teamSlug/:projectId/products',
						'/:teamSlug/:projectId/products/new',
						'/:teamSlug/:projectId/products/:id',
						'/:teamSlug/:projectId/schema/collections',
						'/:teamSlug/:projectId/schema/components',
						'/:teamSlug/:projectId/schema/enumerations',
						'/:teamSlug/:projectId/shipping-zones',
						'/:teamSlug/:projectId/webhooks',
						'/:teamSlug/:projectId/webhooks/:webhookId',
						'/:teamSlug/:projectId/webhooks/:webhookId/settings',
						'/:teamSlug/:projectId/api-keys',
						'/:teamSlug/:projectId/api-keys/new',
						'/:teamSlug/:projectId/api-keys/:id',
						'/:teamSlug/:projectId/apps',
						'/:teamSlug/:projectId/apps/:id',
						'/:teamSlug/:projectId/settings',
						'/:teamSlug/:projectId/settings/ecommerce',
						'/:teamSlug/:projectId/settings/payment-providers',
						'/:teamSlug/:projectId/settings/apps',
						'/:teamSlug/:projectId/ecommerce-onboarding',

						// All project routes, with environment
						'/:teamSlug/:projectId/env/:envSlug',
						'/:teamSlug/:projectId/env/:envSlug/assets',
						'/:teamSlug/:projectId/env/:envSlug/blueprints/:id',
						'/:teamSlug/:projectId/env/:envSlug/blueprints/:id/settings',
						'/:teamSlug/:projectId/env/:envSlug/content',
						'/:teamSlug/:projectId/env/:envSlug/content/:blueprintId',
						'/:teamSlug/:projectId/env/:envSlug/content/:blueprintId/new',
						'/:teamSlug/:projectId/env/:envSlug/content/:blueprintId/:id',
						'/:teamSlug/:projectId/env/:envSlug/developers',
						'/:teamSlug/:projectId/env/:envSlug/forms/:formId',
						'/:teamSlug/:projectId/env/:envSlug/forms/:formId/settings',
						'/:teamSlug/:projectId/env/:envSlug/inventory',
						'/:teamSlug/:projectId/env/:envSlug/orders',
						'/:teamSlug/:projectId/env/:envSlug/orders/:id',
						'/:teamSlug/:projectId/env/:envSlug/orders/:id/ship',
						'/:teamSlug/:projectId/env/:envSlug/products',
						'/:teamSlug/:projectId/env/:envSlug/products/new',
						'/:teamSlug/:projectId/env/:envSlug/products/:id',
						'/:teamSlug/:projectId/env/:envSlug/schema/collections',
						'/:teamSlug/:projectId/env/:envSlug/schema/components',
						'/:teamSlug/:projectId/env/:envSlug/schema/enumerations',
						'/:teamSlug/:projectId/env/:envSlug/shipping-zones',
						'/:teamSlug/:projectId/env/:envSlug/webhooks',
						'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId',
						'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId/settings',
						'/:teamSlug/:projectId/env/:envSlug/api-keys',
						'/:teamSlug/:projectId/env/:envSlug/api-keys/new',
						'/:teamSlug/:projectId/env/:envSlug/api-keys/:id',
						'/:teamSlug/:projectId/env/:envSlug/apps',
						'/:teamSlug/:projectId/env/:envSlug/apps/:id',
						'/:teamSlug/:projectId/env/:envSlug/settings/ecommerce',
						'/:teamSlug/:projectId/env/:envSlug/settings/payment-providers',
						'/:teamSlug/:projectId/env/:envSlug/settings/apps',
					]}
				>
					<QueryParamProvider ReactRouterRoute={Route}>
						<UserProvider>
							<Route exact path="/login" component={Login} />
							<Route exact path="/login/callback" component={AuthCallback} />
							<LoggedInRoutes />
						</UserProvider>
					</QueryParamProvider>
				</Route>
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	</React.StrictMode>
);

const LoggedInRoutes = () => {
	const user = useContext(UserContext);
	if (!user.isLoggedIn) {
		return null;
	}
	return (
		<Switch>
			<Route exact path={['/account', '/account/security']} component={User} />
			<Route>
				<TeamsProvider>
					<TeamProvider>
						<ProjectProvider>
							<EnvironmentProvider>
								<ContentApiProvider>
									<ExtensionsProvider>
										<TestInit>
											<Dashboard />
										</TestInit>
									</ExtensionsProvider>
								</ContentApiProvider>
							</EnvironmentProvider>
						</ProjectProvider>
					</TeamProvider>
				</TeamsProvider>
			</Route>
		</Switch>
	);
};
