import React from 'react';
import { Button, TextStyle } from '@platformapp/ui';
import { assetTypeFromMime } from '../util';
import { ASSET_FILTER_IMAGE, ASSET_FILTER_VIDEO } from '../constants';

const NoPreview = ({ asset }) => (
	<div className="flex items-center flex-col">
		<p className="leading-normal mb-5">
			<TextStyle subdued>
				Preview is not available for this file type.
			</TextStyle>
		</p>
		<Button to={asset.url} external target="_blank">
			Download
		</Button>
	</div>
);

export const Preview = ({ asset }) => {
	const type = assetTypeFromMime(asset.mimeType);
	const isImage = type === ASSET_FILTER_IMAGE;
	const isPdf = asset.mimeType === 'application/pdf';
	const isVideo = type === ASSET_FILTER_VIDEO;
	const hasPreview = isImage || isPdf || isVideo;
	return (
		<>
			{isImage && (
				<img src={asset.url} className="w-full h-full p-8 object-contain" />
			)}
			{isPdf && (
				<object
					data={asset.url}
					type="application/pdf"
					className="w-full h-full"
				>
					<NoPreview asset={asset} />
				</object>
			)}
			{isVideo && (
				<video autoPlay={false} controls className="w-full h-full">
					<source src={asset.url} type={asset.mimeType} />
					<NoPreview asset={asset} />
				</video>
			)}
			{!hasPreview && <NoPreview asset={asset} />}
		</>
	);
};
