import React from 'react';
import Helpers from '../Helpers';
import { set, lensProp } from 'ramda';
import { B } from '../common/Text';
import { RadioFields } from '@platformapp/ui';

// const KEYCODE = {
// 	DOWN: 40,
// 	LEFT: 37,
// 	RIGHT: 39,
// 	SPACE: 32,
// 	UP: 38,
// };

export const EnumFilterLabel = ({ definition, predicate }) => {
	const selected = definition.values.find((x) => x.value === predicate.value);
	const val = typeof selected !== 'undefined' ? selected.label : 'Any';

	return (
		<>
			<B>{definition.label}</B>{' '}
			{Helpers.t(`filters.enum.${predicate.operator}`)} {val}
		</>
	);
};

export const EnumFilter = ({ definition, predicate, onChange }) => {
	const onOptionChange = (value) =>
		onChange(set(lensProp('value'), value, predicate));

	return (
		<div className="py-2 px-3" role="menu">
			<RadioFields
				onChange={onOptionChange}
				options={definition.values}
				value={predicate.value}
			/>
			{/* {definition.values.map((item, index) => (
				<div
					key={item.value}
					className="cursor-pointer px-4 py-1 hover:bg-gray-200"
					role="menuitem"
					tabIndex={predicate.value === item.value ? 0 : -1}
					aria-disabled={false}
					onClick={() => onOptionChange(item.value)}
					onKeyDown={(e) => {
						switch (e.keyCode) {
							case KEYCODE.DOWN:
							case KEYCODE.RIGHT:
								if (index < definition.values.length - 1) {
									const newValue = definition.values[index + 1].value;
									onOptionChange(newValue);
								}
								break;
							case KEYCODE.UP:
							case KEYCODE.LEFT:
								if (index > 0) {
									const newValue = definition.values[index - 1].value;
									onOptionChange(newValue);
								}
								break;
						}
					}}
				>
					<RadioButton
						name={`filter-${definition.key}`}
						value={item.value}
						checked={predicate.value === item.value}
						onChange={onOptionChange}
						label={item.label}
					/>
				</div>
			))} */}
		</div>
	);
};
