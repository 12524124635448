import React from 'react';
import { AddFilterDropdown } from './AddFilterDropdown';
import { objectFromListWith } from '../util';
import {
	remove,
	update,
	append,
	find,
	filter,
	propEq,
	concat,
	pathOr,
	prop,
	keys,
	reject,
	clone,
} from 'ramda';
import { Filter } from './Filter';

const makeDefaultFilter = (definition) => ({
	key: definition.key,
	operator: pathOr('eq', ['default', 'operator'], definition),
	value: pathOr('', ['default', 'value'], definition),
});

const makeFiltersStructure = (definitions, filters) => {
	// Key the defintions by key on the object
	const keyedDefs = objectFromListWith(prop('key'), definitions);
	const validFilterKeys = keys(keyedDefs);

	// Remove invalid filters
	const validFilters = filter((x) => validFilterKeys.includes(x.key), filters);

	// Create initial array containing just the defaults for persistent filters
	const persistent = filter(propEq('persistent', true), definitions);

	const persistentFilters = persistent.map((f) => {
		// Check if this filter is applied by finding the first occurrence
		// (subsequent occurrences are ignored)
		const applied = find(propEq('key', f.key), validFilters);

		if (applied) {
			// This persistent filter is applied, so return a clone of the
			// applied value
			return clone(applied);
		}

		// This persistent filter is not applied - use the default
		return makeDefaultFilter(f);
	});

	// Get all non-persistent filters
	const nonPersistentFilters = reject(
		(f) => keyedDefs[f.key].persistent,
		validFilters
	);

	return concat(persistentFilters, nonPersistentFilters);
};

export const Filters = ({ definitions, filters, onChange }) => {
	// Get all persistent filters first, regardless of whether they're applied
	const parsedFilters = makeFiltersStructure(definitions, filters);
	const keyedDefs = objectFromListWith(prop('key'), definitions);

	const handleRemove = (index) => onChange(remove(index, 1, parsedFilters));

	const handleChange = (index, value) =>
		onChange(update(index, value, parsedFilters));

	const addFilter = (key) => {
		onChange(append(makeDefaultFilter(keyedDefs[key]), filters));
	};

	// Get all non-persistent filter definitions
	const nonPersistentFilters = filter(propEq('persistent', false), definitions);

	return (
		<div className="flex items-center md:flex-wrap">
			{parsedFilters.map((filter, i) => (
				<Filter
					key={i}
					definition={keyedDefs[filter.key]}
					predicate={filter}
					onRemove={() => handleRemove(i)}
					onChange={(value) => handleChange(i, value)}
				/>
			))}
			{nonPersistentFilters.length > 0 && (
				<AddFilterDropdown
					onSelect={addFilter}
					definitions={nonPersistentFilters}
				/>
			)}
		</div>
	);
};
