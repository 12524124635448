import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { createEditor, Transforms, Editor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact } from 'slate-react';
import { deserialise } from './mdast-to-slate';
import { serialise } from './slate-to-mdast';
import { Toolbar } from './Toolbar';
import { compose, last, append, isNil, isEmpty } from 'ramda';
import debounce from 'lodash.debounce';
import { withBlockQuote } from './plugins/block-quote';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { SlateElement, Leaf } from './render';
import { withLists } from './plugins/lists';
import { withBreakOut } from './plugins/break-out';
import { withLinks } from './plugins/links';
import { withSoftBreak } from './plugins/soft-break';
import { withCodeBlock } from './plugins/code-block';
import { withImage } from './plugins/image';
import { withLastEditable } from './plugins/last-editable';
import { toggleMark } from './plugins/util';

const toggleBoldMark = (editor) => toggleMark(editor, 'bold');
const toggleCodeMark = (editor) => toggleMark(editor, 'code');
const toggleItalicMark = (editor) => toggleMark(editor, 'italic');

const markdownToSlate = (markdown) => {
	const slateVal = deserialise(
		unified().use(remarkParse, { gfm: true }).parse(markdown)
	);
	if (isNil(slateVal) || isEmpty(slateVal)) {
		return [
			{
				type: 'paragraph',
				children: [{ text: '' }],
			},
		];
	}
	const lastNode = last(slateVal);
	if (lastNode && lastNode.type && lastNode.type === 'code-block') {
		return append(
			{
				type: 'paragraph',
				children: [{ text: '' }],
			},
			slateVal
		);
	}
	return slateVal;
};

const slateToMarkdown = (slateAst) =>
	unified()
		.use(remarkStringify, {
			gfm: true,
			emphasis: '*',
			bullet: '*',
			listItemIndent: '1',
			fences: true,
			ruleSpaces: false,
		})
		.stringify(serialise(slateAst));

export const RichEditor = ({
	autoFocus,
	mode,
	readOnly,
	setMode,
	value,
	onChange,
}) => {
	const [slateValue, setSlateValue] = useState(markdownToSlate(value));

	const renderElement = useCallback((props) => <SlateElement {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	const fns = compose(
		withLastEditable,
		withBreakOut,
		withLists,
		withBlockQuote,
		withLinks,
		withSoftBreak,
		withImage,
		withCodeBlock,
		withHistory,
		withReact
	);
	const editor = useMemo(() => fns(createEditor()), []);

	useEffect(() => {
		Editor.normalize(editor, {
			force: true,
		});
	}, []);

	const handleMarkdownChange = debounce((value) => {
		onChange(slateToMarkdown(value));
	}, 150);

	return (
		<Slate
			editor={editor}
			value={slateValue}
			onChange={(value) => {
				setSlateValue(value);
				handleMarkdownChange(value);
			}}
		>
			<Toolbar
				mode={mode}
				disabled={readOnly}
				setMode={setMode}
				toggleDisabled={readOnly}
			/>
			<Editable
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				readOnly={readOnly}
				spellCheck
				autoFocus={autoFocus}
				className="p-4 pb-0"
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						if (event.shiftKey) {
							// Soft break (Shift+Enter)
							event.preventDefault();
							Transforms.insertNodes(editor, {
								type: 'break',
								children: [{ text: '' }],
							});

							// Set the selection to the line after the soft break
							Transforms.move(editor, {
								distance: 1,
								unit: 'line',
							});

							return;
						}
					}

					// Ensure CTRL key is pressed (Windows) or CMD key (meta key, Mac)
					if (!event.ctrlKey && !event.metaKey) {
						return;
					}

					switch (event.key) {
						case 'p':
							event.preventDefault();
							toggleCodeMark(editor);
							break;
						case 'b':
							event.preventDefault();
							toggleBoldMark(editor);
							break;
						case 'i':
							event.preventDefault();
							toggleItalicMark(editor);
							break;
						// case 'l':
						//     event.preventDefault();
						//     // TODO: open "add link" popup
						//     break;
						// case 'o':
						//     event.preventDefault();
						//     // TODO: toggle ordered list
						//     break;
						// case 'u':
						//     event.preventDefault();
						//     // TODO: toggle unordered list
						//     break;
						// case '1':
						//     if (event.altKey) {
						//         // TODO: make `heading-one`
						//     }
						//     break;
					}
				}}
			/>
		</Slate>
	);
};
