import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { CollectionListContainer } from '../collections/CollectionListContainer';
import { ComponentListContainer } from '../components/ComponentListContainer';
import { EnumListContainer } from '../enums/EnumListContainer';
import { SchemaSidebar } from './SchemaSidebar';
import { Route, Switch } from 'react-router';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';

export const Schema = withErrorBoundary(() => (
	<MainContentContainer>
		<MainContentNav>
			<SchemaSidebar />
		</MainContentNav>
		<MainContent>
			<Switch>
				<Route
					path={[
						'/:teamSlug/:projectId/schema/collections',
						'/:teamSlug/:projectId/env/:envSlug/schema/collections',
					]}
					component={CollectionListContainer}
				>
					<CollectionListContainer />
				</Route>
				<Route
					path={[
						'/:teamSlug/:projectId/schema/components',
						'/:teamSlug/:projectId/env/:envSlug/schema/components',
					]}
					component={ComponentListContainer}
				>
					<ComponentListContainer />
				</Route>
				<Route
					path={[
						'/:teamSlug/:projectId/schema/enumerations',
						'/:teamSlug/:projectId/env/:envSlug/schema/enumerations',
					]}
					component={EnumListContainer}
				/>
			</Switch>
		</MainContent>
	</MainContentContainer>
));
