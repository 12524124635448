import React, { useState } from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import GET_API_KEY from './GetApiKey.gql';
import { graphql } from '../api';
import { ApiKeyForm } from './ApiKeyForm';
import { useParams } from 'react-router-dom';
import { Loader } from '@platformapp/ui';
import { NotFound } from '../NotFound';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

const EditApiKeyComponent = () => {
	useEnvironmentTitle('Edit API key');
	const { apiKeyId } = useParams();
	const [apiKey, setApiKey] = useState({
		isLoading: true,
	});

	useAsyncEffect(async (isActive) => {
		try {
			const res = await graphql({
				query: GET_API_KEY,
				variables: {
					apiKeyId,
				},
			});
			if (!isActive()) {
				return;
			}
			setApiKey({
				isLoading: false,
				...res.data.data,
			});
		} catch (err) {
			console.error(err);
		}
	}, []);

	if (apiKeyId && !apiKey.isLoading && !apiKey.node) {
		return (
			<NotFound
				header="API key not found"
				body={<p>No such API key: {apiKeyId}</p>}
			/>
		);
	}

	return (
		<>
			{apiKey.isLoading && <Loader />}
			{!apiKey.isLoading && <ApiKeyForm existingApiKey={apiKey.node} />}
		</>
	);
};

export const EditApiKey = withErrorBoundary(EditApiKeyComponent);
