import { Element, Node, Text, Transforms, Range, Editor, Point } from 'slate';
import { ensureChildrenAreElements } from './util';

const BLOCK_QUOTE_NODE_TYPE = 'block-quote';

export const withBlockQuote = (editor) => {
	const { deleteBackward, insertBreak, normalizeNode } = editor;

	editor.normalizeNode = (entry) => {
		const [node, path] = entry;

		// If the element is a `block-quote`, ensure it has only elements as children,
		// otherwise wrap the item in a `paragraph`;
		if (Element.isElement(node) && node.type === BLOCK_QUOTE_NODE_TYPE) {
			ensureChildrenAreElements(editor, path);
			return;
		}

		// Fall back to the original `normalizeNode` to enforce other constraints.
		normalizeNode(entry);
	};

	editor.deleteBackward = (...args) => {
		const { selection } = editor;

		if (selection && Range.isCollapsed(selection)) {
			// This will be the parent of the text node, a paragraph, heading, etc.
			const match = Editor.above(editor, {
				match: (n) => Editor.isBlock(editor, n),
			});

			if (match) {
				const path = match[1];
				const start = Editor.start(editor, path);

				// If the selection anchor is at the start of this element, and this element has a block
				// quote as a parent, unwrap it.
				const parent = Node.parent(editor, path);

				if (
					Point.equals(selection.anchor, start) &&
					parent.type === BLOCK_QUOTE_NODE_TYPE
				) {
					Transforms.unwrapNodes(editor, {
						match: (n) => n.type === BLOCK_QUOTE_NODE_TYPE,
						split: true,
					});
					return;
				}
			}
		}

		deleteBackward(...args);
	};

	editor.insertBreak = () => {
		const currNode = Node.get(editor, editor.selection.focus.path);

		// If the parent of the current node is a `block-quote`, and the current node is a text node,
		// and it is empty.
		if (Text.isText(currNode) && currNode.text === '') {
			// Find any ancestors of this node that are of type `block-quote`.
			const ancestors = Node.ancestors(editor, editor.selection.focus.path, {
				reverse: true,
			});

			for (const [ancestorNode] of ancestors) {
				if (ancestorNode.type === BLOCK_QUOTE_NODE_TYPE) {
					console.log('Breaking out of block quote.');
					Transforms.unwrapNodes(editor, {
						match: (ancestorNode) =>
							ancestorNode.type === BLOCK_QUOTE_NODE_TYPE,
						split: true,
					});
					return;
				}
			}
		}

		insertBreak();
	};

	return editor;
};
