import { createContext } from "react";

interface AttributeEditorContextInterface {
	initialValues: any;
	/**
	 * Whether the item being edited is new. False if an existing items is being edited.
	 */
	isNew: boolean;
	readOnly: boolean;
}

export const AttributeEditorContext = createContext<AttributeEditorContextInterface | null>(null);

export const AttributeEditorConsumer = AttributeEditorContext.Consumer;

type AttributeEditorProviderProps = {
	children: React.ReactNode;
	initialValues: any;
	isNew: boolean;
	readOnly: boolean;
}

export const AttributeEditorProvider = ({ children, initialValues, isNew, readOnly }: AttributeEditorProviderProps) => (
	<AttributeEditorContext.Provider
		value={{
			initialValues,
			isNew,
			readOnly
		}}
	>
		{children}
	</AttributeEditorContext.Provider>
);