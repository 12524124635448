import React, { useState, useEffect } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { useParams } from 'react-router-dom';
import GET_USER_QUERY from './GetUser.gql';
import { Heading } from '../common/Heading';
import { TwoFactorAuthSetting } from './TwoFactorAuthSetting';

const SecurityComponent = () => {
	const { teamSlug } = useParams();

	const [result, setResult] = useState({
		isLoading: true,
		viewer: {},
	});

	// Fetch user
	useEffect(() => {
		graphql({
			query: GET_USER_QUERY,
			variables: {
				id: teamSlug,
			},
		}).then(({ data }) => {
			setResult({
				isLoading: false,
				...data.data,
			});
		});
	}, [teamSlug]);

	return (
		<div className="lg:mr-48">
			<Heading className="mb-6">Security settings</Heading>

			<TwoFactorAuthSetting
				user={result}
				updateUser={(updates) =>
					setResult({
						...result,
						viewer: {
							...result.viewer,
							...updates,
						},
					})
				}
			/>
		</div>
	);
};

export const Security = withErrorBoundary(SecurityComponent);
