import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
    TextField,
    Loader,
// @ts-ignore
} from '@platformapp/ui';
import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { graphql } from '../api';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { P } from '../common/Text';
// @ts-ignore
import ENABLE_MFA_MUTATION from './EnableMfa.gql';
// @ts-ignore
import CONFIRM_MFA_MUTATION from './ConfirmMfa.gql';
import { pathOr } from 'ramda';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';

type Props = {
    onCancel: () => void;
    onEnabled: () => void;
};

type State = {
    isLoading: boolean;
    data: any;
    step: Step;
    backupCode?: string;
}

type Step = 'enable' | 'confirm' | 'backup_codes';

export const EnableMfaModal = ({
    onCancel,
    onEnabled
}: Props) => {
    const [state, setState] = useState<State>({
        isLoading: true,
        data: null,
        step: 'enable'
    });

    useAsyncEffect(async isActive => {
        try {
			const res = await graphql(
				{
					query: ENABLE_MFA_MUTATION,
				},
				'users'
			);

            if (isActive) {
                setState({
                    isLoading: false,
                    data: res.data.data.enableMfa,
                    step: 'enable'
                });
            }
		} catch (err) {
			console.error(err);
			toast.error('Failed to enable two-factor authentication');
		}
    }, []);

    return (
        <Modal
			title="Enable two-factor authentication"
			tiny
			isOpen
			onRequestClose={() => onCancel()}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick={false}
		>
            {state.isLoading && (
                <>
                    <ModalContent>
                        <div className="h-40">
                            <Loader />
                        </div>
                    </ModalContent>
                </>
            )}
            {!state.isLoading && (
                <>
                    {state.step === 'enable' && (
                        <EnableStep
                            onCancel={onCancel}
                            setState={setState}
                            state={state}
                        />
                    )}
                    {state.step === 'confirm' && (
                        <ConfirmStep
                            onBack={() => setState({
                                ...state,
                                step: 'enable'
                            })}
                            onEnabled={backupCode => setState({
                                ...state,
                                step: 'backup_codes',
                                backupCode
                            })}
                        />
                    )}
                    {state.step === 'backup_codes' && (
                        <BackupCode
                            code={state.backupCode}
                            onComplete={onEnabled}
                        />
                    )}
                </>
            )}
		</Modal>
    )
}

type EntryMode = 'manual' | 'qrcode';

const EnableStep = ({
    onCancel,
    state,
    setState
}: {
    onCancel: () => void,
    state: State,
    setState: (state: State) => void,
}) => {
    const [entryMode, setEntryMode] = useState<EntryMode>('qrcode');

    return (<>
        <ModalContent>
            {entryMode === 'qrcode' && <ScanQrCode setEntryMode={setEntryMode} state={state} />}
            {entryMode === 'manual' && <EnterManually setEntryMode={setEntryMode} state={state} />}
        </ModalContent>
        <ModalFooter>
            <ButtonGroup>
                <Button
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    disabled={state.isLoading}
                    onClick={() => setState({
                        ...state,
                        step: 'confirm'
                    })}
                >
                    Continue
                </Button>
            </ButtonGroup>
        </ModalFooter>
    </>)
}

const ScanQrCode = ({
    state,
    setEntryMode
}: {
    state: State,
    setEntryMode: (manual: EntryMode) => void,
}) => (
    <>
        <P>Download an authenticator app such as Google Authenticator, add a new account, then scan this QR code:</P>
        <div className="flex justify-center">
            <img src={state.data.qrcode} />
        </div>
        <div className="flex justify-center mt-1">
            <button
                className="text-blue-700 text-base"
                onClick={() => setEntryMode('manual')}
            >
                Enter code manually
            </button>
        </div>
    </>
)

const EnterManually = ({
    state,
    setEntryMode
}: {
    state: State,
    setEntryMode: (manual: EntryMode) => void,
}) => (
    <>
        <P>Download an authenticator app such as Google Authenticator, add a new account, then enter this code:</P>
        <div className="flex justify-center mt-4 mb-5">
            <ApiIdLabel>
                {state.data.secret}
            </ApiIdLabel>
        </div>
        <div className="flex justify-center">
            <button
                className="text-blue-700 text-base"
                onClick={() => setEntryMode('qrcode')}
            >
                Scan QR code instead
            </button>
        </div>
    </>
)

const ConfirmStep = ({
    onBack,
    onEnabled
}: {
    onBack: () => void,
    onEnabled: (backupCode: string) => void,
}) => {
    const form = useFormik({
        initialValues: {
            code: '',
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: values => {
            if (values.code.replace(/ /g, '').length !== 6) {
                return {
                    code: 'Code must be 6 digits'
                }
            }
        },
        onSubmit: async (values, { setErrors }) => {
            try {
                const res = await graphql(
                    {
                        query: CONFIRM_MFA_MUTATION,
                        variables: {
                            input: {
                                code: values.code.replace(/ /g, '')
                            }
                        }
                    },
                    'users'
                );
    
                const confirmed = pathOr(false, ['data', 'confirmMfa', 'isConfirmed'], res.data);
    
                if (confirmed) {
                    onEnabled(res.data.data.confirmMfa.backupCode);
                } else {
                    setErrors({
                        code: 'Two-factor code is not correct'
                    })
                }
            } catch (err) {
                console.error(err);
                toast.error('Failed to confirm two-factor code');
            }
        }
    });

    return (
        <form
            onSubmit={form.handleSubmit}
        >
            <ModalContent>
                <div className="mb-3">
                    <P>Enter the 6 digit code shown in your authenticator app.</P>
                </div>
                <TextField
                    autoFocus
                    value={form.values.code}
                    onChange={form.handleChange}
                    name="code"
                    disabled={form.isSubmitting}
                    error={form.errors.code}
                />
            </ModalContent>
            <ModalFooter>
                <ButtonGroup>
                    <Button
                        disabled={form.isSubmitting} 
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        submit
                        primary
                        loading={form.isSubmitting}
                    >
                        Continue
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </form>
    )
}

const BackupCode = ({
    code,
    onComplete
}: {
    code: string,
    onComplete: () => void
}) => (
    <div>
        <ModalContent>
            <P>You can use this emergency code to disable two-factor authentication on your account. Keep this somewhere safe - we reccommend storing it in a password manager like 1Password.</P>
            <div className="flex justify-center mt-4 mb-5">
                <ApiIdLabel>
                    {code}
                </ApiIdLabel>
            </div>
        </ModalContent>
        <ModalFooter>
            <ButtonGroup>
                <Button
                    primary
                    onClick={onComplete}
                >
                    I have a copy
                </Button>
            </ButtonGroup>
        </ModalFooter>
    </div>
)