import React, { useContext, useState } from 'react';
import Helpers from '../Helpers';
import { DragHandle } from '../common/DragHandle';
import { Card, ConfirmDialog, TextStyle } from '@platformapp/ui';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { NewContextMenu } from '../dropdown/ContextMenu';
import { ExtensionsContext } from '../extensions/ExtensionsContext';
import { ATTR_EXTENSION_KEY } from '../constants';
import { getMetadataValue } from '../util/metadata';
import { getAttributeLabel } from './util';
import { B } from '../common/Text';

export const AttributeRow = ({
	attribute: attr,
	deleteAttribute,
	editAttribute,
	dragHandleProps,
	isDeletingAttribute,
}) => {
	const { findInstallationForExtension } = useContext(ExtensionsContext);
	const [isDeleting, setIsDeleting] = useState(false);
	let typeName = getAttributeLabel(attr);

	const extensionAttributeMeta = getMetadataValue(
		attr.metadata,
		ATTR_EXTENSION_KEY
	);

	if (extensionAttributeMeta) {
		// Try and find the extension by ID
		const inst = findInstallationForExtension(extensionAttributeMeta);
		if (inst) {
			const point = inst.extension.points.find(
				(p) => p.__typename === 'AttributePoint'
			);
			if (point) {
				typeName = point.name;
			}
		}
	}

	return (
		<>
			{isDeleting && (
				<ConfirmDialog
					title={`Are you sure you want to delete this attribute?`}
					body={
						<p>
							Any existing data for <B>{attr.name}</B> will be deleted, and this
							cannot be undone.
						</p>
					}
					onConfirm={() => deleteAttribute(attr.id)}
					onCancel={() => setIsDeleting(false)}
					isWorking={isDeletingAttribute}
					dangerous
					confirmText="Delete attribute"
				/>
			)}

			<Card
				className={`p-4 flex items-center ${attr.isHidden && 'opacity-60'}`}
			>
				<DragHandle {...dragHandleProps} />

				<p className="flex-grow">
					<B>{attr.name}</B>
					<span className="hidden md:inline ml-4">
						<TextStyle subdued>
							{typeName}
							{attr.isList ? ' (list)' : ''}
						</TextStyle>
					</span>
				</p>

				<ApiIdLabel className="hidden md:block mr-5">{attr.apiId}</ApiIdLabel>

				<NewContextMenu
					styleOnHover
					sections={[
						{
							items: [
								{
									label: 'Settings',
									onAction: () => editAttribute(attr.id),
								},
							],
						},
						{
							items: [
								{
									label: Helpers.t('general.delete'),
									onAction: () => setIsDeleting(true),
									destructive: true,
								},
							],
						},
					]}
				/>
			</Card>
		</>
	);
};
