import React from 'react';
import { assoc, has, isNil, omit, propOr } from 'ramda';
import { useQs } from '../hooks/useQs';

import Helpers from '../Helpers';
import { stringify } from 'qs';
import { encodeFiltersForQs } from '../util';

import { Button, ButtonGroup, TextStyle } from '@platformapp/ui';
import { useLocation } from 'react-router-dom';

export const Paginator = ({ pageInfo, totalCount, baseUrl, filters }) => (
	<RouterPaginator
		pageInfo={pageInfo}
		totalCount={totalCount}
		baseUrl={baseUrl}
		filters={filters}
	/>
);

Paginator.defaultProps = {
	filters: [],
};

const RouterPaginator = ({ baseUrl, filters, totalCount, pageInfo }) => {
	const qs = useQs();

	// If qs has `before`, assume there is a next page
	const hasNextPage =
		propOr(false, 'hasNextPage', pageInfo) || has('before', qs);

	// If qs has `after`, assume there is a previous page
	const hasPreviousPage =
		propOr(false, 'hasPreviousPage', pageInfo) || has('after', qs);

	const buildNextUrl = () => {
		// If there are no next results, return null
		if (!hasNextPage) {
			return null;
		}

		const params = {
			after: pageInfo.endCursor,
		};

		if (filters.length) {
			params.filters = encodeFiltersForQs(filters);
		}

		return `${baseUrl}?${stringify(params)}`;
	};

	const buildPreviousUrl = () => {
		// If there are no next results, return null
		if (!hasPreviousPage) {
			return null;
		}

		const params = {
			before: pageInfo.startCursor,
		};

		if (filters.length) {
			params.filters = encodeFiltersForQs(filters);
		}

		return `${baseUrl}?${stringify(params)}`;
	};

	const prevButton = hasPreviousPage ? (
		<Button to={buildPreviousUrl()}>{Helpers.t('pagination.previous')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.previous')}</Button>
	);

	const nextButton = hasNextPage ? (
		<Button to={buildNextUrl()}>{Helpers.t('pagination.next')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.next')}</Button>
	);

	return (
		<div className="py-3 md:py-4 px-3 md:px-0">
			<div className="flex items-center">
				<p className="flex-grow">
					<TextStyle subdued>
						{Helpers.t('pagination.total_found', { total: totalCount })}
					</TextStyle>
				</p>
				<ButtonGroup>
					{prevButton}
					{nextButton}
				</ButtonGroup>
			</div>
		</div>
	);
};

RouterPaginator.defaultProps = {
	filters: [],
};

export const BasicPaginator = ({
	totalCount,
	pageInfo,
	before,
	after,
	onNext,
	onPrevious,
}) => {
	// If `before is set`, assume there is a next page
	const hasNextPage = propOr(false, 'hasNextPage', pageInfo) || before;

	// If `after` is set, assume there is a previous page
	const hasPreviousPage = propOr(false, 'hasPreviousPage', pageInfo) || after;

	const prevButton = hasPreviousPage ? (
		<Button onClick={onPrevious}>{Helpers.t('pagination.previous')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.previous')}</Button>
	);

	const nextButton = hasNextPage ? (
		<Button onClick={onNext}>{Helpers.t('pagination.next')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.next')}</Button>
	);

	return (
		<div className="py-3 md:py-4">
			<div className="flex items-center">
				<p className="flex-grow">
					<TextStyle subdued>
						{isNil(totalCount)
							? '...'
							: Helpers.t('pagination.total_found', { total: totalCount })}
					</TextStyle>
				</p>
				<ButtonGroup>
					{prevButton}
					{nextButton}
				</ButtonGroup>
			</div>
		</div>
	);
};

export const QueryStringPaginator = ({ pageInfo, totalCount }) => {
	const { pathname } = useLocation();
	const qs = useQs();

	// If qs has `before`, assume there is a next page
	const hasNextPage =
		propOr(false, 'hasNextPage', pageInfo) || has('before', qs);

	// If qs has `after`, assume there is a previous page
	const hasPreviousPage =
		propOr(false, 'hasPreviousPage', pageInfo) || has('after', qs);

	const buildNextUrl = () => {
		// If there are no next results, return null
		if (!hasNextPage) {
			return null;
		}
		const newQs = assoc(
			'after',
			pageInfo.endCursor,
			omit(['after', 'before'], qs)
		);
		return `${pathname}?${stringify(newQs)}`;
	};

	const buildPreviousUrl = () => {
		// If there are no next results, return null
		if (!hasPreviousPage) {
			return null;
		}
		const newQs = assoc(
			'before',
			pageInfo.startCursor,
			omit(['after', 'before'], qs)
		);
		return `${pathname}?${stringify(newQs)}`;
	};

	const prevButton = hasPreviousPage ? (
		<Button to={buildPreviousUrl()}>{Helpers.t('pagination.previous')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.previous')}</Button>
	);

	const nextButton = hasNextPage ? (
		<Button to={buildNextUrl()}>{Helpers.t('pagination.next')}</Button>
	) : (
		<Button disabled>{Helpers.t('pagination.next')}</Button>
	);

	return (
		<div className="py-3 md:py-4 px-3 md:px-0">
			<div className="flex items-center">
				<p className="flex-grow">
					<TextStyle subdued>
						{Helpers.t('pagination.total_found', { total: totalCount })}
					</TextStyle>
				</p>
				<ButtonGroup>
					{prevButton}
					{nextButton}
				</ButtonGroup>
			</div>
		</div>
	);
};
