import { useState, useEffect, useContext, useReducer } from 'react';
import { ContentListInner } from './ContentListInner';
import { withErrorBoundary } from '../common/ErrorBoundary';
import {
	mergeDeepRight,
	omit,
	intersection,
} from 'ramda';
import { SORT_ASC, SORT_DESC } from '../common/ResourceTable';
import { FilterParam } from '../util';
import { filtersToApiParams } from '../filters/util';
import { useQueryParams, StringParam } from 'use-query-params';
import { NotFound } from '../NotFound';
import { DataLoadErrorMessage } from '../common/DataLoadErrorMessage';
import { getPinnableAttributes } from '../util/AttributeHelpers';
import { getMetadataCsv } from '../util/metadata';
import { CollectionMetadataKeys, DEFAULT_PER_PAGE } from '../constants';
import { Attribute } from '../types/Attribute';
import { ContentApiContext } from '../context/ContentApiContext';
import {useAsyncEffect} from 'use-async-effect';
import { getDefaultPinned } from '../util/attributes';

const DEFAULT_ORDER = Object.freeze({
	dir: SORT_DESC,
	field: 'updatedAt',
});

type ItemsState = {
	isError: boolean;
	isLoading: boolean;
	totalCount?: number;
	edges?: any;
	collectionId?: string;

	// TODO
	pinnedColumns?: string[];
	order?: any;
};

type SetItemsAction = {
	type: 'set',
	data: any[]
}

type DeleteItemsAction = {
	type: 'delete',
	ids: string[]
}

type UpdateItemsAction = {
	type: 'update',
	items: any[]
}

type LoadingItemsAction = {
	type: 'loading'
}

type ErrorItemsAction = {
	type: 'error'
}

export type ItemsDispatchAction = SetItemsAction | DeleteItemsAction | UpdateItemsAction | LoadingItemsAction | ErrorItemsAction;

const DEFAULT_STATE = {
	isLoading: true,
	isError: false
}

const dispatcher = (state: ItemsState, action: ItemsDispatchAction): ItemsState => {
	switch (action.type) {
		case 'loading':
			return DEFAULT_STATE;
		case 'error':
			return {
				isLoading: false,
				isError: true
			}
		case 'set':
			// @ts-ignore
			return action.data;
		case 'delete':
			return {
				...state,
				edges: state.edges.filter((edge: any) => !action.ids.includes(edge.node.id)),
				totalCount: state.totalCount - action.ids.length,
			}
		case 'update':
			return {
				...state,
				edges: state.edges.map((edge: any) => {
					// Check if this item needs to be updated
					const updatedNode = action.items.find(i => i.id === edge.node.id);

					if (!updatedNode) {
						return edge;
					}

					return {
						...edge,
						node: mergeDeepRight(edge.node, updatedNode)
					}
				}),
			}
		default:
			return state;
	}
}

export const ContentListContainer = withErrorBoundary(({ blueprint }: any) => {
	const {findItems} = useContext(ContentApiContext);

	const [items, itemsDispatch] = useReducer(dispatcher, DEFAULT_STATE);

	const [query, setQuery] = useQueryParams({
		after: StringParam,
		before: StringParam,
		filters: FilterParam,
	});

	const [pinnedColumns, setPinnedColumns] = useState<any>();
	const [order, setOrder] = useState(DEFAULT_ORDER);

	useEffect(() => {
		setOrder(DEFAULT_ORDER);
		if (!blueprint.isLoading) {
			const pinnableColumns = getPinnableAttributes(
				blueprint.node.attributes
			).map((attr: Attribute) => attr.apiId);
			const metaPinned = getMetadataCsv(
				blueprint.node.metadata,
				CollectionMetadataKeys.PinnedAttributes
			);
			const defaultPinned =
				metaPinned.length > 0
					? intersection(metaPinned, pinnableColumns)
					: getDefaultPinned(blueprint.node.attributes);
			setPinnedColumns(defaultPinned);
		}
	}, [blueprint]);

	// Fetch results when blueprint has loaded, the query hash changes,
	// or user navigates to different project.
	useAsyncEffect(async active => {
		if (!blueprint.isLoading && blueprint.node) {
			itemsDispatch({
				type: 'loading'
			});

			const apiParams = filtersToApiParams(query.filters);
			const params: any = {
				filter: omit(['status', 'before', 'after'], apiParams),
				order: {
					[order.field]: order.dir === SORT_ASC ? 'ASC' : 'DESC',
				},
			};

			if (query.after) {
				params.after = query.after;
				params.first = DEFAULT_PER_PAGE;
			} else if (query.before) {
				params.before = query.before;
				params.last = DEFAULT_PER_PAGE;
			} else {
				params.first = DEFAULT_PER_PAGE;
			}

			try {
				const res = await findItems(blueprint.node.apiId, params);

				if (!active()) {
					return;
				}

				itemsDispatch({
					type: 'set',
					data: {
						collectionId: blueprint.node.id,
						isLoading: false,
						isError: false,
						...res,
					}
				});
			} catch (err: any) {
				console.error(err);
				itemsDispatch({type: 'error'});
			}
		}
	}, [blueprint, blueprint.isLoading, order, query]);

	if (blueprint.isLoading) {
		return null;
	}

	if (!blueprint.node) {
		return (
			<NotFound
				header="Collection not found"
				body={
					<p>Try going to the content list page and searching from there</p>
				}
			/>
		);
	}

	if (items.isError) {
		return <DataLoadErrorMessage />;
	}

	return (
		<ContentListInner
			items={items}
			itemsDispatch={itemsDispatch}
			filter={query.filters}
			blueprint={blueprint.node}
			onFilterChange={(filters: any) => setQuery({filters})}
			pinnedColumns={pinnedColumns}
			setOrder={setOrder}
			setPinnedColumns={setPinnedColumns}
			order={order}
		/>
	);
});
