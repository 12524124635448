import { useState } from 'react';
// @ts-ignore
import { Button, Field, TextField } from '@platformapp/ui';
import { AdjustInventoryModal } from '../inventory/AdjustInventoryModal';
import { InventoryItem } from '../types/Item';

type InventoryQuantityInputProps = {
	disabled: boolean;
	error: string[] | null;
	inventoryItem: any;
	onUpdate: (inventoryItem: InventoryItem) => void;
}

export const InventoryQuantityInput = ({
	disabled,
	error,
	inventoryItem,
	onUpdate,
}: InventoryQuantityInputProps) => {
	const [adjustingInventory, setAdjustingInventory] = useState(false);
	return (
		<>
			{adjustingInventory && (
				<AdjustInventoryModal
					inventoryItems={[inventoryItem]}
					onCancel={() => setAdjustingInventory(false)}
					onUpdate={(ii: any) => {
						setAdjustingInventory(false);
						onUpdate(ii[0]);
					}}
				/>
			)}
			<Field label="Quantity available">
				<div className="flex items-center">
					<div className="flex-grow">
						<TextField
							disabled={disabled}
							max="5000"
							min="0"
							name="available"
							value={inventoryItem.available}
							onChange={(e: any) => onUpdate({
								...inventoryItem,
								available: parseInt(e.target.value, 10)
							})}
							readOnly={true}
							error={error}
						/>
					</div>
					<Button
						className="flex-shrink-0 ml-4"
						disabled={disabled}
						onClick={() => setAdjustingInventory(true)}
					>
						Adjust quantity
					</Button>
				</div>
			</Field>
		</>
	);
};

InventoryQuantityInput.defaultProps = {
	disabled: false,
	error: null,
	readOnly: false,
};
