import React, { useContext, useState } from 'react';
import { Toggle } from '../common/Toggle';
import { TextStyle } from '@platformapp/ui';
import BoldIcon from '../icons/bold.svg';
import ItalicIcon from '../icons/italic.svg';
import LinkIcon from '../icons/link-2.svg';
import CodeIcon from '../icons/code-2.svg';
import ImageIcon from '../icons/image.svg';
import UlIcon from '../icons/list-unordered.svg';
import OlIcon from '../icons/list-ordered.svg';
import QuoteIcon from '../icons/quote.svg';
// import CodeBlockIcon from '../icons/code-block.svg';
import { useEditor } from 'slate-react';
import { toggleListBlock } from './plugins/lists';
import {
	isBlockActive,
	isMarkActive,
	toggleBlock,
	toggleMark,
} from './plugins/util';
import { insertLink } from './plugins/links';
import Helpers from '../Helpers';
import { insertImage } from './plugins/image';
import { ItemChooserLayer } from '../content-chooser/ItemChooserLayer';
import { EnvironmentContext } from '../context/EnvironmentContext';

const BASE_ICON_STYLES = 'inline-block fill-current';
const BASE_BUTTON_STYLES =
	'text-center block w-8 h-8 mr-1 text-gray-800 rounded focus:outline-none transition-color duration-200 hover:text-gray-800';
const BUTTON_HOVER_STYLES = 'hover:bg-gray-300 hover:text-gray-900';
const BUTTON_DISABLED_STYLES = 'cursor-default opacity-50';
const BUTTON_ACTIVE_STYLES = 'bg-blue-200 text-blue-700';

const ToolbarButton = ({ active, className, disabled, ...props }) => (
	<button
		className={`${BASE_BUTTON_STYLES} ${className} ${
			active ? BUTTON_ACTIVE_STYLES : ''
		} ${disabled ? BUTTON_DISABLED_STYLES : BUTTON_HOVER_STYLES}`}
		disabled={disabled}
		{...props}
	/>
);

ToolbarButton.defaultProps = {
	className: '',
};

const MarkButton = ({ mark, ...props }) => {
	const editor = useEditor();
	return (
		<ToolbarButton
			active={isMarkActive(editor, mark)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, mark);
			}}
			{...props}
		/>
	);
};

const BlockButton = ({ block, ...props }) => {
	const editor = useEditor();
	return (
		<ToolbarButton
			active={isBlockActive(editor, block)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleBlock(editor, block);
			}}
			{...props}
		/>
	);
};

const ListButton = ({ block, ...props }) => {
	const editor = useEditor();
	return (
		<ToolbarButton
			active={isBlockActive(editor, block)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleListBlock(editor, block);
			}}
			{...props}
		/>
	);
};

const ImageButton = (props) => {
	const { collectionByApiId } = useContext(EnvironmentContext);
	const editor = useEditor();
	const [choosingImage, setChoosingImage] = useState({
		isChoosing: false,
		editorSelection: null,
	});
	return (
		<>
			<ToolbarButton
				title="Image"
				onClick={(event) => {
					event.preventDefault();
					setChoosingImage({
						isChoosing: true,
						editorSelection: editor.selection,
					});
				}}
				{...props}
			>
				<ImageIcon className={BASE_ICON_STYLES} />
			</ToolbarButton>
			{choosingImage.isChoosing && (
				<ItemChooserLayer
					allowedBlueprints={[collectionByApiId('Asset').node.id]}
					onSelect={(image) => {
						insertImage(
							editor,
							{
								type: 'image',
								url: image.url,
								children: [{ text: '' }],
							},
							{
								at: choosingImage.editorSelection,
							}
						);
						setChoosingImage({
							...choosingImage,
							isChoosing: false,
						});
					}}
					onCancel={() =>
						setChoosingImage({
							...choosingImage,
							isChoosing: false,
						})
					}
				/>
			)}
		</>
	);
};

const LinkButton = (props) => {
	const editor = useEditor();
	return (
		<>
			<ToolbarButton
				active={isBlockActive(editor, 'link')}
				title="Link"
				onClick={(event) => {
					event.preventDefault();
					const url = prompt('Link URL:');
					if (url) {
						insertLink(editor, url);
					}
				}}
				{...props}
			>
				<LinkIcon className={BASE_ICON_STYLES} />
			</ToolbarButton>
		</>
	);
};

const ButtonGroup = ({ ...props }) => (
	<div className="mr-8 last:mr-0 flex items-center" {...props} />
);

export const Toolbar = ({ mode, disabled, setMode, toggleDisabled }) => (
	<div className="scrollbar-hidden flex items-center py-2 px-3 bg-gray-200 sticky top-22 overflow-x-auto rounded-tl rounded-tr">
		<div className="flex-grow flex items-center">
			<ButtonGroup>
				<MarkButton mark="bold" disabled={disabled} title="Bold (&#8984;+B)">
					<BoldIcon width="16" height="16" className={BASE_ICON_STYLES} />
				</MarkButton>
				<MarkButton
					mark="italic"
					disabled={disabled}
					title="Italic (&#8984;+I)"
				>
					<ItalicIcon className={BASE_ICON_STYLES} />
				</MarkButton>
				<MarkButton
					mark="code"
					disabled={disabled}
					title="Inline code (&#8984;+P)"
				>
					<CodeIcon className={BASE_ICON_STYLES} />
				</MarkButton>
			</ButtonGroup>
			<ButtonGroup>
				<LinkButton disabled={disabled} />
				{/* <ToolbarButton disabled={disabled}>H</ToolbarButton> */}
				<BlockButton
					block="block-quote"
					disabled={disabled}
					title="Block quote"
				>
					<QuoteIcon className={BASE_ICON_STYLES} />
				</BlockButton>
			</ButtonGroup>
			<ButtonGroup>
				<ListButton
					block="unordered-list"
					disabled={disabled}
					title="Bulleted list"
				>
					<UlIcon className={BASE_ICON_STYLES} />
				</ListButton>
				<ListButton
					block="ordered-list"
					disabled={disabled}
					title="Numbered list"
				>
					<OlIcon className={BASE_ICON_STYLES} />
				</ListButton>
			</ButtonGroup>
			<ButtonGroup>
				<ImageButton disabled={disabled} />
				{/* <ToolbarButton disabled={disabled} title="Code block">
            <CodeBlockIcon className={BASE_ICON_STYLES} />
          </ToolbarButton> */}
			</ButtonGroup>
		</div>
		<div className="flex-shrink-0 pl-5">
			<div className="flex items-center">
				<p className="cursor-default">
					<TextStyle subdued>
						{Helpers.t('markdown_editor.mode.rich')}
					</TextStyle>
				</p>
				<Toggle
					checked={mode === 'raw'}
					className="mx-2"
					disabled={toggleDisabled}
					onChange={(checked) => setMode(checked ? 'raw' : 'rich')}
				/>
				<p className="cursor-default">
					<TextStyle subdued>{Helpers.t('markdown_editor.mode.raw')}</TextStyle>
				</p>
			</div>
		</div>
	</div>
);

Toolbar.defaultProps = {
	toggleDisabled: false,
};
