import React from 'react';
import { StickyHeader } from '../common/StickyHeader';
import { PublishStatusLabel } from '../common/PublishStatusLabel';
import {
	ActionList,
	Button,
	ButtonGroup,
	ChevronDownIcon,
	Icon,
} from '@platformapp/ui';
import { Heading } from '../common/Heading';
import Helpers from '../Helpers';
import PropTypes from 'prop-types';
import ArrowLeftIcon from '../icons/arrow-left.svg';
import { statusFromStages } from '../util/publishing';

export const EditAssetHeader = ({
	asset,
	form,
	isNew,
	onCancel,
	readOnly,
	defaultAction,
}) => {
	const isPublishDefault = isNew && defaultAction === 'publish';

	const saveAndPublish = () => {
		form.setStatus({
			...form.status,
			isPublish: true,
		});
		form.submitForm();
	};

	// The default save action handler (the button to the left of the secondary
	// dropdown save actions)
	const defaultActionHandler = () => {
		if (isPublishDefault) {
			// Publishing is the default action
			saveAndPublish();
		} else {
			// Just plain saving - for new items, will be DRAFT by default, for existing
			// items, will be CHANGED.
			form.submitForm();
		}
	};

	const saveItems = [
		// If the default action is to publish, the secondary menu
		// item should be saving new item as draft (just submitting the form).
		isPublishDefault
			? {
					label: Helpers.t('publishing.save_draft'),
					onAction: () => form.submitForm(),
			  }
			: {
					// Publishing is not the default action, so publishing should be
					// the secondary menu item.
					label: Helpers.t('publishing.save_publish'),
					onAction: () => saveAndPublish(),
			  },
	];

	return (
		<StickyHeader bordered hasPx hasPy sticky>
			<div className="flex items-center">
				<Button
					className="mr-4"
					disabled={form.isSubmitting}
					onClick={() => onCancel()}
					width="small"
				>
					<Icon source={ArrowLeftIcon} />
				</Button>
				<Heading className="flex-grow truncate mr-6">
					{!asset.isLoading && (
						<>
							{isNew ? 'Create asset' : asset.data.title}
							{!isNew && (
								<PublishStatusLabel
									className="ml-4"
									status={statusFromStages(asset.data)}
								/>
							)}
						</>
					)}
					{asset.isLoading && '...'}
				</Heading>
				<ButtonGroup className="flex-shrink-0">
					<div className="flex">
						<Button
							width="large"
							className="rounded-r-none"
							loading={form.isSubmitting}
							primary
							onClick={defaultActionHandler}
							disabled={readOnly || asset.isLoading}
						>
							{isPublishDefault
								? Helpers.t('publishing.save_publish')
								: isNew
								? Helpers.t('publishing.save_draft')
								: Helpers.t('general.save')}
						</Button>
						<ActionList
							placement="bottom-end"
							trigger={
								<Button
									primary
									width="small"
									className="rounded-l-none border-l border-blue-800"
									disabled={form.isSubmitting || readOnly || asset.isLoading}
								>
									<ChevronDownIcon className="h-4" />
								</Button>
							}
							items={saveItems}
						/>
					</div>
				</ButtonGroup>
			</div>
		</StickyHeader>
	);
};

EditAssetHeader.defaultProps = {
	defaultAction: 'saveDraft',
};

EditAssetHeader.propTypes = {
	defaultAction: PropTypes.oneOf(['saveDraft', 'publish']),
};
