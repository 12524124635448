import React from 'react';
import { Badge, DataList, DataListItem } from '@platformapp/ui';
import Helpers from '../Helpers';

export const Details = ({ hook }) => (
	<div className="mt-6 border-b border-gray-400">
		<DataList className="flex gap-10">
			<DataListItem stacked title="URL">
				<p>{hook.url}</p>
			</DataListItem>
			<DataListItem stacked title="Status">
				<Badge
					positive={hook.isEnabled}
					text={hook.isEnabled ? 'Enabled' : 'Disabled'}
				/>
			</DataListItem>
			<DataListItem stacked title="Events" alignTop>
				{hook.events.length === 1 && (
					<p>{Helpers.t(`events.${hook.events[0]}`)}</p>
				)}
				{hook.events.length > 1 && <p>{hook.events.length} events</p>}
			</DataListItem>
		</DataList>
	</div>
);
