import React from 'react';
import { Button } from '@platformapp/ui';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { DefaultStageRadioGroup } from './DefaultStageRadioGroup';
import { updateApiKey } from './mutations';
import toast from 'react-hot-toast';

export const DefaultStageSetting = ({ apiKey, disabled }) => {
	const form = useFormik({
		// enableReinitialize: true,
		initialValues: {
			defaultStage: pathOr('', ['defaultStage'], apiKey),
		},
		onSubmit: async (values, { setErrors, resetForm }) => {
			try {
				await updateApiKey(apiKey.id, values);
				toast.success('Default stage updated');
				resetForm({
					values,
				});
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to update default stage');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Default stage</p>
					<p className="mb-8">
						The default stage will be used in the Content API when a stage is
						not explicitly set.
					</p>
					<div className="w-full md:w-96">
						<DefaultStageRadioGroup
							disabled={form.isSubmitting}
							onChange={(val) => form.setFieldValue('defaultStage', val)}
							value={form.values.defaultStage}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
