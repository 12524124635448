import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import { isJsonString } from '../util';
import theme from 'prism-react-renderer/themes/ultramin';
import { isNil } from 'ramda';

const Line = (props) => <div className="table-row" {...props} />;

const LineNo = (props) => (
	<div
		className="select-none table-cell text-sm text-right pr-3 text-gray-500"
		{...props}
	/>
);

const LineContent = (props) => (
	<div className="table-cell text-sm" {...props} />
);

const Pre = ({ className, ...props }) => (
	<pre className={`${className} overflow-scroll`} {...props} />
);

const formatJsonString = (json) => JSON.stringify(JSON.parse(json), null, 2);

export const TextPayload = ({ payload }) => {
	if (isNil(payload)) {
		return null;
	}
	const isJson = isJsonString(payload);
	const language = isJson ? 'json' : null;
	return (
		<Highlight
			{...defaultProps}
			theme={theme}
			code={isJson ? formatJsonString(payload) : payload}
			language={language}
		>
			{({ className, style, tokens, getLineProps, getTokenProps }) => (
				<Pre className={className} style={style}>
					{tokens.map((line, i) => (
						<Line key={i} {...getLineProps({ line, key: i })}>
							<LineNo>{i + 1}</LineNo>
							<LineContent>
								{line.map((token, key) => (
									<span key={key} {...getTokenProps({ token, key })} />
								))}
							</LineContent>
						</Line>
					))}
				</Pre>
			)}
		</Highlight>
	);
};
