import React, { useContext } from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr, pick } from 'ramda';
import { useFormik } from 'formik';
import { updateTeam as updateTeamMutation } from './mutations';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { TeamsContext } from '../context/TeamsContext';
import toast from 'react-hot-toast';

export const TeamNameSetting = ({ team, onChange }) => {
	const { updateTeam } = useContext(TeamsContext);
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: pathOr('', ['node', 'name'], team),
		},
		onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
			updateTeamMutation(team.node.id, values)
				.then((updatedTeam) => {
					resetForm({ values: pick(['name'], updatedTeam) });
					updateTeam(team.node.id, updatedTeam);
					onChange(updatedTeam);
					toast.success('Team name updated');
				})
				.catch((error) => {
					if (error.isUserError) {
						setErrors(error.userErrors);
					} else {
						toast.error('Failed to update team name');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Team name</p>
					<p className="mb-4">
						This is your team&apos;s visible name on the Dashboard. This could
						be the name of your company, department or client.
					</p>
					<div className="w-full md:w-96">
						<TextField
							disabled={team.isLoading || form.isSubmitting}
							value={form.values.name}
							name="name"
							onChange={form.handleChange}
							error={extractGraphqlError('name', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
