import React, { useState } from 'react';
import { Button, Icon, Popover } from '@platformapp/ui';
import DeleteIcon from '../icons/x.svg';
import Helpers from '../Helpers';
import { EnumFilter, EnumFilterLabel } from './EnumFilter';
import { DateFilter } from './DateFilter';
import { BooleanFilter } from './BooleanFilter';
import {
	FILTER_DATE,
	FILTER_ENUM,
	FILTER_STRING,
	FILTER_INTEGER,
	FILTER_BOOLEAN,
} from '../constants';
import ChevronDownIcon from '../icons/chevron-down.svg';
import { StringFilter } from './StringFilter';
import { IntegerFilter } from './IntegerFilter';
import { isEmpty, isNil } from 'ramda';
import { B } from '../common/Text';

const getTriggerClassNames = (isOpen, persistent) => {
	const triggerClassNames = [
		'cursor-pointer pl-3 pr-2 py-2 text-sm focus:outline-none text-left',
		'hover:bg-gray-300',
		'whitespace-nowrap',
	];

	if (isOpen) {
		triggerClassNames.push('bg-gray-300');
	}

	if (!persistent) {
		triggerClassNames.push('pr-1 rounded-r-none border-r-0');
	}

	return triggerClassNames.join(' ');
};

const FilterLabel = ({ definition, predicate }) => {
	switch (definition.type) {
		case FILTER_ENUM:
			return <EnumFilterLabel definition={definition} predicate={predicate} />;
		case FILTER_BOOLEAN:
			return (
				<>
					<B>{definition.label}</B>{' '}
					{Helpers.t(`filters.${definition.type}.${predicate.operator}`)}{' '}
					{isNil(predicate.value) || isEmpty(predicate.value)
						? 'any'
						: predicate.value
						? 'true'
						: 'false'}
				</>
			);
		default:
			return (
				<>
					<B>{definition.label}</B>{' '}
					{Helpers.t(`filters.${definition.type}.${predicate.operator}`)}{' '}
					{predicate.value}
				</>
			);
	}
};

const FilterComponent = ({ definition, ...props }) => {
	const commonProps = {
		definition,
		...props,
	};

	switch (definition.type) {
		case FILTER_ENUM:
			return <EnumFilter {...commonProps} />;
		case FILTER_DATE:
			return <DateFilter {...commonProps} />;
		case FILTER_STRING:
			return <StringFilter {...commonProps} />;
		case FILTER_INTEGER:
			return <IntegerFilter {...commonProps} />;
		case FILTER_BOOLEAN:
			return <BooleanFilter {...commonProps} />;
	}

	return null;
};

export const Filter = ({ definition, predicate, onChange, onRemove }) => {
	const [isActive, setIsActive] = useState(false);

	const handleChange = (value) => {
		onChange(value);
		if (definition.type === FILTER_ENUM) {
			setIsActive(false);
		}
	};

	return (
		<div
			// className="flex items-center rounded mr-3 overflow-hidden"
			className="flex items-center rounded mr-3"
		>
			<Popover
				active={isActive}
				onClose={() => setIsActive(false)}
				trigger={
					<Button
						className={getTriggerClassNames(isActive, definition.persistent)}
						onClick={() => setIsActive(!isActive)}
						tabIndex="0"
					>
						<FilterLabel definition={definition} predicate={predicate} />
						<Icon source={ChevronDownIcon} className="text-gray-600 ml-1" />
					</Button>
				}
			>
				<FilterComponent
					definition={definition}
					predicate={predicate}
					onChange={handleChange}
					onRequestClose={() => setIsActive(false)}
				/>
			</Popover>

			{!definition.persistent && (
				<Button
					className="rounded-l-none"
					width="small"
					onClick={() => onRemove()}
					title="Remove filter"
				>
					<Icon source={DeleteIcon} className="text-gray-600" />
				</Button>
			)}
		</div>
	);
};
