import React, { useContext } from 'react';
import { DateTime } from '@platformapp/ui';
import { formatMoney } from '../util';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { StatusBadge } from '../orders/StatusBadge';
import { DATE_REFRESH } from '../constants';
import { ResourceTable } from '../common/ResourceTable';

export const OrderTable = ({ orders }) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<ResourceTable
			columns={[
				{
					title: 'Order ID',
					render: ({ node }) => node.reference,
					link: true,
				},
				{
					accessor: 'node.createdAt',
					title: 'Date',
					render: ({ node }) => (
						<DateTime value={node.createdAt} refresh={DATE_REFRESH} />
					),
					link: true,
				},
				{
					title: 'Customer',
					render: ({ node }) => node.customer.name,
					link: true,
				},
				{
					title: 'Shipping status',
					render: ({ node }) => <StatusBadge status={node.shippingStatus} />,
					link: true,
				},
				{
					title: 'Total',
					align: 'right',
					render: ({ node }) => formatMoney(node.totalPrice),
					link: true,
				},
			]}
			data={orders}
			idSource={['node', 'id']}
			rowLink={({ node }) => generateEnvPath(`orders/${node.id}`)}
		/>
	);
};
