import React, { useContext, useState } from 'react';
import { PublishStatusLabel } from '../common/PublishStatusLabel';
//@ts-ignore
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Icon,
} from '@platformapp/ui';
import { isEmpty, isNil, move } from 'ramda';
import { plural } from 'pluralize';
import { ItemChooserLayer } from '../content-chooser/ItemChooserLayer';
import { DragHandle } from '../common/DragHandle';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { extractGraphqlError, makeId, pluckPath } from '../util';
//@ts-ignore
import DeleteIcon from '../icons/x.svg';
import Helpers from '../Helpers';
import { statusFromStages } from '../util/publishing';
import { AttributeEditorContext } from '../attribute-editor/AttributeEditorContext';

const ItemSlat = ({
	blueprint,
	canReorder,
	dragHandleProps,
	onRemove,
	readOnly,
	value,
}) => (
	<div className="flex rounded px-4 py-3 border border-gray-300 items-center bg-white">
		<div className="min-w-0 flex-grow flex items-center">
			{canReorder && <DragHandle {...dragHandleProps} />}
			<p className="truncate font-medium mr-4">
				{!value.__title || isEmpty(value.__title)
					? Helpers.t('general.untitled')
					: value.__title}
			</p>
			<p className="text-gray-700 bg-gray-200 hidden md:block rounded p-1 px-2 text-sm">
				{blueprint.name}
			</p>
		</div>
		<div className="flex-shrink-0 flex items-center">
			<PublishStatusLabel status={statusFromStages(value)} />
			{onRemove && (
				<button
					disabled={readOnly}
					className={`ml-3 ${
						readOnly
							? 'cursor-auto text-gray-500'
							: 'cursor-pointer text-gray-600 hover:text-gray-700'
					}`}
					title="Remove item from list"
					onClick={() => onRemove()}
				>
					<Icon source={DeleteIcon} />
				</button>
			)}
		</div>
	</div>
);

ItemSlat.defaultProps = {
	canReorder: false,
};

const ItemSlatList = ({ blueprints, onMove, onRemove, readOnly, value }) => {
	if (isNil(value)) {
		return null;
	}
	return (
		<DragDropContext
			onDragEnd={(res) => {
				if (!res.destination || res.source.index === res.destination.index) {
					return;
				}
				onMove(res.source.index, res.destination.index);
			}}
		>
			<Droppable droppableId={makeId()} isDropDisabled={readOnly}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{value.edges.map(({ node }, i) => (
							<Draggable
								key={node.id}
								draggableId={node.id}
								index={i}
								isDragDisabled={readOnly}
							>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										style={provided.draggableProps.style}
										className="mb-3"
									>
										<ItemSlat
											blueprint={blueprints.find(
												(bp) => bp.apiId === node.__typename
											)}
											canReorder
											dragHandleProps={provided.dragHandleProps}
											onRemove={() => onRemove(node.id)}
											readOnly={readOnly}
											value={node}
										/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

const Toolbar = ({ attribute, onClear, readOnly, setIsSelecting, value }) => (
	<div className="mt-4">
		<ButtonGroup>
			<Button disabled={readOnly} onClick={() => setIsSelecting(true)}>
				Select existing{' '}
				{attribute.isList
					? plural(attribute.referencedCollection.name)
					: attribute.referencedCollection.name}
				{/* {attribute.referencedCollections.length === 1
					? attribute.isList
						? plural(attribute.referencedCollections[0].name)
						: attribute.referencedCollections[0].name
					: attribute.isList
					? 'items'
					: 'item'} */}
			</Button>
			{!attribute.isRequired && (
				<Button
					disabled={
						readOnly ||
						(attribute.isList
							? isNil(value) || value.edges.length === 0
							: isNil(value))
					}
					onClick={onClear}
				>
					{attribute.isList ? 'Clear all' : 'Clear'}
				</Button>
			)}
		</ButtonGroup>
	</div>
);

export const ReferenceAttribute = ({
	attribute,
	onChange,
	pathPrefix,
	validationErrors,
	helpText,
}) => {
	const { initialValues, isNew, readOnly } = useContext(AttributeEditorContext);
	const [isSelecting, setIsSelecting] = useState(false);
	const [items, setItems] = useState(initialValues[attribute.apiId]);
	const error = extractGraphqlError(pathPrefix, validationErrors);

	return (
		<>
			{isSelecting && (
				<ItemChooserLayer
					allowedBlueprints={[attribute.referencedCollection.id]}
					disabledItems={
						isNil(items)
							? []
							: attribute.isList
							? items.edges.map(({ node }) => node.id)
							: [items.id]
					}
					multiple={attribute.isList}
					onCancel={() => setIsSelecting(false)}
					onSelect={(newValue) => {
						if (attribute.isList) {
							// Set the local data (full objects)
							if (!items) {
								setItems({
									edges: newValue.edges,
								});
							} else {
								setItems({
									...items,
									edges: [...items.edges, ...newValue.edges],
								});
							}

							// Set the form data (actions and IDs)
							if (isNew) {
								const edges = [
									...(items ? items.edges : []),
									...newValue.edges,
								];
								onChange(
									edges.map(({ node }) => ({
										link: node.id,
									}))
								);
							} else {
								onChange({
									set: [...items.edges, ...newValue.edges].map(
										({ node }) => node.id
									),
								});
							}
						} else {
							setItems(newValue);
							onChange(
								isNew
									? {
											link: newValue.id,
									  }
									: {
											set: newValue.id,
									  }
							);
						}
						setIsSelecting(false);
					}}
				/>
			)}

			{attribute.isList && (
				<ItemSlatList
					blueprints={[attribute.referencedCollection]}
					onMove={(from, to) => {
						const edges = move(from, to, items.edges);
						setItems({
							edges,
						});
						onChange({
							set: pluckPath(['node', 'id'], edges),
						});
					}}
					onRemove={(id) => {
						console.debug('Removing', id);
						const edges = items.edges.filter(({ node }) => node.id !== id);
						setItems({
							edges,
						});
						onChange({
							set: edges.map(({ node }) => node.id),
						});
					}}
					readOnly={readOnly}
					value={items}
				/>
			)}

			{!attribute.isList && !isNil(items) && (
				<ItemSlat
					blueprint={attribute.referencedCollection}
					readOnly={readOnly}
					value={items}
				/>
			)}

			<Toolbar
				attribute={attribute}
				onClear={() => {
					setItems(attribute.isList ? { edges: [] } : null);
					onChange(
						isNew
							? null
							: {
									set: attribute.isList ? [] : null,
							  }
					);
				}}
				readOnly={readOnly}
				setIsSelecting={setIsSelecting}
				value={items}
			/>

			<ErrorMessageElement message={error} />

			{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
		</>
	);
};
