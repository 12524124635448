import {
	query as generateQuery,
	mutation as generateMutation,
} from 'gql-query-builder';
import {
	TYPE_DATE,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_NUMBER,
	TYPE_TEXT,
	TYPE_ASSET,
	TYPE_BOOLEAN,
	TYPE_DATETIME,
	TYPE_MONEY,
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_ENUM,
} from '../constants';
import { Attribute } from '../types/Attribute';
import { Collection } from '../types/Collection';

const SCALARS = Object.freeze([
	TYPE_TEXT,
	TYPE_NUMBER,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_DATE,
	TYPE_DATETIME,
	TYPE_BOOLEAN,
]);

const IN_STAGES = {
	'inStages (stages: [DRAFT, PUBLISHED])': [
		'stage',
		'publishedAt',
		'updatedAt'
	],
};

const expandReferences = (referencedCollections: Collection[]) => {
	return referencedCollections.reduce((allQueries, refdBp) => {
		const titleAttr = refdBp.titleApiId;
		if (!titleAttr) {
			return [
				...allQueries,
				{
					[`... on ${refdBp.apiId}`]: [
						IN_STAGES
					],
				},
			];
		}
		return [
			...allQueries,
			{
				[`... on ${refdBp.apiId}`]: [
					`__title: ${titleAttr}`,
					IN_STAGES
				],
			},
		];
	}, []);
};

const expandAsset = () => [
	'id',
	'filename',
	'mimeType',
	'title',
	'thumbnailUrl: url(transforms: { width: 200, height: 200 })',
	'updatedAt',
	{
		'inStages (stages: [PUBLISHED])': [
			'stage',
			'publishedAt'
		],
	},
];

const isScalar = (attribute: Attribute) => {
	if (attribute.__typename === ATTRIBUTE_TYPENAME_ENUM) {
		return true;
	}
	return (
		attribute.__typename === ATTRIBUTE_TYPENAME_BASIC &&
		SCALARS.includes(attribute.type)
	);
};

const expandAttributes = (attributes: Attribute[], prefix: string = null): any =>
	attributes
		// .filter((a) => a.isEditable)
		.reduce((fields, attribute) => {
			const fieldName = prefix
				? `${prefix}${attribute.apiId}: ${attribute.apiId}`
				: attribute.apiId;

			if (isScalar(attribute)) {
				return [...fields, fieldName];
			}

			switch (attribute.__typename) {
				case ATTRIBUTE_TYPENAME_BASIC: {
					switch (attribute.type) {
						case TYPE_ASSET:
							if (attribute.isList) {
								return [
									...fields,
									{
										operation: fieldName,
										variables: {
											first: {
												type: 'Int',
												required: true
											}
										},
										fields: [
											'totalCount',
											{
												edges: [
													{
														node: expandAsset(),
													},
												],
											},
										],
									},
								];
							}
							return [
								...fields,
								{
									[fieldName]: expandAsset(),
								},
							];
						case TYPE_MONEY:
							return [
								...fields,
								{
									[fieldName]: ['amount', 'currency'],
								},
							];
					}
					break;
				}
				case ATTRIBUTE_TYPENAME_COMPONENT:
					return [
						...fields,
						{
							[fieldName]: [
								'__typename',
								...attribute.allowedComponents.map((type) => ({
									[`... on ${type.apiId}`]: expandAttributes(
										type.attributes,
										`${type.apiId}_`
									),
								})),
							],
						},
					];
				case ATTRIBUTE_TYPENAME_UNIREF:
				case ATTRIBUTE_TYPENAME_BIREF:
					if (attribute.isList) {
						return [
							...fields,
							{
								operation: fieldName,
								fields: [
									'totalCount',
									{
										edges: [
											{
												node: [
													'__typename',
													{
														'... on Publishable': ['id'],
													},
													...expandReferences([attribute.referencedCollection]),
												],
											},
										],
									},
								],
								variables: {
									first: {
										type: "Int!",
										// value: 100,
									},
								}
							},
						];
					}
					return [
						...fields,
						{
							[fieldName]: [
								'__typename',
								{
									'... on Publishable': ['id'],
								},
								...expandReferences([attribute.referencedCollection]),
							],
						},
					];
			}

			// This attribute type isn't handled yet; ignore it.
			return fields;
		}, []);

export const buildConnectionQuery = (blueprint: Collection) =>
	generateQuery({
		operation: `results: all${blueprint.apiIdPlural}`,
		fields: [
			'totalCount',
			{
				pageInfo: [
					'hasNextPage',
					'hasPreviousPage',
					'startCursor',
					'endCursor',
				],
			},
			{
				edges: [
					{
						node: [
							'id',
							'createdAt',
							'isArchived',
							'isSpam',
							...expandAttributes(blueprint.attributes),
						],
					},
				],
			},
		],
		variables: {
			first: {
				type: 'Int',
			},
			last: {
				type: 'Int',
			},
			before: {
				type: 'String',
			},
			after: {
				type: 'String',
			},
			filter: {
				type: `${blueprint.apiId}FilterInput`,
			},
			order: {
				type: `[${blueprint.apiId}OrderInput!]`,
			},
		},
	}).query;

export const deleteItem = (collection: Collection) =>
	generateMutation({
		operation: `delete${collection.apiId}`,
		fields: [
			{
				userErrors: ['message', 'path'],
			},
		],
		variables: {
			input: {
				type: `Delete${collection.apiId}Input!`,
			},
		},
	}).query;

export const updateItem = (collection: Collection) =>
	generateMutation({
		operation: `update${collection.apiId}`,
		fields: [
			{
				userErrors: ['message', 'path'],
			},
		],
		variables: {
			input: {
				type: `Update${collection.apiId}Input!`,
			},
		},
	}).query;

export class NodeQueryBuilder {
	private _collections: Collection[];
	private _resolveReferences: string[] = [];
	private _additionalNodeFields: any[] = [];

	constructor(collections: Collection[]) {
		this._collections = collections;
		this._resolveReferences = [];
		this._additionalNodeFields = [];
	}

	static make(blueprints: Collection[]) {
		return new NodeQueryBuilder(blueprints);
	}

	_blueprintById(id: string) {
		return this._collections.find((bp) => bp.id === id);
	}

	_collectionByApiId(apiId: string) {
		return this._collections.find((bp) => bp.apiId === apiId);
	}

	resolveReferences(references: string[]) {
		this._resolveReferences = references;
		return this;
	}

	additionalNodeFields(fields: any) {
		this._additionalNodeFields = fields;
		return this;
	}

	generate(apiId: string) {
		const collection = this._collectionByApiId(apiId);
		const fields = [
			'__typename',
			'id',
			{
				[`... on ${collection.apiId}`]: [
					...this._additionalNodeFields,
					// Add the basic attributes
					...expandAttributes(collection.attributes.filter(a => !this._resolveReferences.includes(a.apiId))),
					// Add the title, if any is set
					...(collection.titleApiId ? [`__title: ${collection.titleApiId}`] : []),
					// Resolve any requested references
					...this._resolveReferences.map((ref) => {
						const attr = collection.attributes.find((a) => a.apiId === ref);
						if (attr.__typename !== 'BidirectionalReferenceAttribute' && attr.__typename !== 'UnidirectionalReferenceAttribute') {
							return '';
						}

						const refBp = this._blueprintById(attr.referencedCollection.id);

						if (attr.isList) {
							return {
								operation: attr.apiId,
								variables: {
									first: {
										type: 'Int',
										required: true
									}
								},
								fields: [
									{
										edges: [
											{
												node: [
													'id',
													{
														[`... on ${refBp.apiId}`]: [
															...expandAttributes(refBp.attributes),
															...(refBp.apiId === 'ProductVariant' ? [{
																inventoryItem: ['id', 'available', 'isTracked'],
															}] : []),
															{
																operation: 'inStages (stages: [DRAFT, PUBLISHED])',
																fields: [
																	'stage',
																	'publishedAt',
																	'updatedAt'
																],
																variables: [],
															},
														],
													},
												],
											},
										],
									},
								],
							};
						}

						return {
							[attr.apiId]: [
								'id',
								...expandAttributes(refBp.attributes),
							]
						}
					}),
					// Add `inStages`
					...(collection.isPublishable
						? [
								{
									operation: 'inStages (stages: [DRAFT, PUBLISHED])',
									fields: [
										'stage',
										'publishedAt',
										'updatedAt'
									],
									variables: [],
								},
						  ]
						: []),
				],
			},
		];

		return generateQuery({
			operation: 'node',
			fields,
			variables: {
				id: {
					type: 'ID!',
				},
			},
		}).query;
	}
}

export const buildBulkDeleteMutation = (collection: Collection, itemIds: string[]) => {
	return generateMutation(
		itemIds.map((id, index) => ({
			operation: `delete_${id}: delete${collection.apiId}`,
			fields: [
				'deletedNodes',
				{
					userErrors: ['message', 'path'],
				},
			],
			variables: {
				[`input${index}`]: {
					name: 'input',
					type: `Delete${collection.apiId}Input`,
					required: true,
				},
			},
		}))
	).query;
};

export const buildBulkPublishMutation = (itemIds: string[], isEcommerce: boolean) => {
	return generateMutation(
		itemIds.map((id, index) => ({
			operation: `publish_${id}: publishPublishable`,
			fields: [
				{
					publishedNodes: [
						'__typename',
						'id',
						'updatedAt',
						...(isEcommerce ? [
						`
						... on Product {
							variants (first: 200) {
								edges {
									node {
										inStages (stages: $stages) {
											stage
											publishedAt
											updatedAt
										}
									}
								}
							}
						}
						`] : []),
						{
							operation: 'inStages',
							variables: {
								stages: {
									type: '[Stage!]!',
								},
							},
							fields: [
								'stage',
								'publishedAt',
								'updatedAt'
							],
						},
					],
					userErrors: ['message', 'path'],
				},
			],
			variables: {
				[`input${index}`]: {
					name: 'input',
					type: `PublishPublishableInput`,
					required: true,
				},
			},
		}))
	).query;
};

export const buildBulkUnpublishMutation = (itemIds: string[]) => {
	return generateMutation(
		itemIds.map((id, index) => ({
			operation: `unpublish_${id}: unpublishPublishable`,
			fields: [
				{
					unpublishedNodes: [
						'__typename',
						'id',
						'updatedAt',
						{
							operation: 'inStages',
							variables: {
								stages: {
									type: '[Stage!]!',
								},
							},
							fields: [
								'stage',
								'publishedAt',
								'updatedAt'
							],
						},
					],
					userErrors: ['message', 'path'],
				},
			],
			variables: {
				[`input${index}`]: {
					name: 'input',
					type: `UnpublishPublishableInput`,
					required: true,
				},
			},
		}))
	).query;
};