import React from 'react';

export const SideNav = ({ children }) => (
	<nav className="bg-white flex px-4 md:px-10 items-end h-11 border-b border-gray-300 overflow-x-auto scrollbar-hidden">
		{children}
	</nav>
);

SideNav.defaultProps = {
	className: '',
};
