import React, { useState } from 'react';
import { CategoryTab } from './CategoryTab';
import { AttributeSelector } from './AttributeSelector';
import { clone, propEq, find, pick } from 'ramda';
import {
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_UNIREF,
	METADATA_ATTR_HELP_TEXT,
} from '../constants';
import { SkeletonBox } from '../common/Skeleton';
import { BasicAttributeLayer } from './BasicAttributeLayer';
import { getMetadataValue } from '../util/metadata';

const SkeletonLoader = () => (
	<>
		<SkeletonBox className="h-16 mb-5" />
		<SkeletonBox className="h-16 mb-5" />
		<SkeletonBox className="h-16 mb-5" />
	</>
);

export const BlueprintEditorInner = ({
	result,
	blueprint,
	updateAll,
	deleteAttribute,
	moveAttribute,
	isDeletingAttribute,
}) => {
	// Track which attribute is being edited, if any
	const [editingAttribute, setEditingAttribute] = useState(null);
	const [addingAttribute, setAddingAttribute] = useState(null);

	const editAttribute = (id) => {
		const attr = clone(find(propEq('id', id), blueprint.attributes));
		const attrSubset = pick(
			[
				'allowedTypes',
				'apiId',
				'enumeration',
				'isList',
				'isRequired',
				'isUnique',
				'name',
				'validations',
				'__typename',
				'type',
				'inverseAttribute',
			],
			attr
		);
		attrSubset.attributeId = id;

		if (attr.__typename === ATTRIBUTE_TYPENAME_COMPONENT) {
			attrSubset.allowedComponents = attr.allowedComponents.map((x) => x.id);
		}

		if (attr.__typename === ATTRIBUTE_TYPENAME_ENUM) {
			attrSubset.enumerationId = attr.enumeration.id;
		}

		if (
			[ATTRIBUTE_TYPENAME_UNIREF, ATTRIBUTE_TYPENAME_BIREF].includes(
				attr.__typename
			)
		) {
			attrSubset.referencedCollection = attr.referencedCollection.id;
		}

		const helpText = getMetadataValue(attr.metadata, METADATA_ATTR_HELP_TEXT);
		attrSubset.helpText = helpText;

		setEditingAttribute({
			data: attrSubset,
			type: attr.type,
		});
	};

	const okEditSettings = (data) => {
		setEditingAttribute(null);
		setAddingAttribute(null);
		updateAll(data);
	};

	const onAddAttribute = (attrData) => {
		setAddingAttribute(attrData);
	};

	return (
		<>
			{addingAttribute && (
				<BasicAttributeLayer
					attribute={addingAttribute}
					blueprint={blueprint}
					extension={addingAttribute.extension}
					isNew={true}
					onCancel={() => setAddingAttribute(false)}
					onConfirm={okEditSettings}
					type={addingAttribute.type}
				/>
			)}

			{editingAttribute && (
				<BasicAttributeLayer
					attribute={editingAttribute.data}
					blueprint={blueprint}
					// extension={addingAttribute.extension}
					isNew={false}
					onCancel={() => setEditingAttribute(false)}
					onConfirm={okEditSettings}
					type={editingAttribute.type}
				/>
			)}
			<div className="flex-grow md:overflow-hidden lg:flex fade-in">
				<div className="md:pr-8 py-5 md:py-6 md:flex-grow overflow-y-auto">
					{result.isLoading && <SkeletonLoader />}
					{!result.isLoading && (
						<CategoryTab
							blueprint={blueprint}
							deleteAttribute={deleteAttribute}
							editAttribute={editAttribute}
							moveAttribute={moveAttribute}
							isDeletingAttribute={isDeletingAttribute}
						/>
					)}
				</div>

				<div className="md:p-8 md:border-l lg:w-104 overflow-y-auto">
					{!result.isLoading && (
						<AttributeSelector
							blueprint={blueprint}
							addAttribute={(attrData) => onAddAttribute(attrData)}
						/>
					)}
				</div>
			</div>
		</>
	);
};
