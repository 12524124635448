import React from 'react';

export const StickyHeader = ({
	sticky,
	children,
	bordered,
	hasTabs,
	hasPx,
	hasPy,
	fixedHeight,
}) => (
	<div
		className={`flex items-center ${
			fixedHeight ? 'h-24' : hasPy ? 'py-2 md:py-5' : ''
		} ${
			hasTabs
				? `pt-2 md:pt-5 ${hasPx ? 'mx-3 md:mx-10' : ''}`
				: `${hasPx ? 'px-4 md:px-10' : ''}`
		} ${sticky ? 'sticky top-0 z-10 bg-white' : ''} ${
			bordered ? ' border-gray-300 border-b' : ''
		}`}
	>
		<div className="w-full">{children}</div>
	</div>
);

StickyHeader.defaultProps = {
	bordered: false,
	hasPx: false,
	hasPy: false,
	fixedHeight: false,
	sticky: false,
};
