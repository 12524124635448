import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import ENUMS_QUERY from './GetEnums.gql';
import { EnumList } from './EnumList';
import { reject, pathEq, append, update, findIndex } from 'ramda';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const EnumListContainer = withErrorBoundary(() => {
	useEnvironmentTitle('Enumerations');
	const { environment, refresh } = useContext(EnvironmentContext);

	const [result, setResult] = useState({
		isLoading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			setResult({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const res = await graphql({
					query: ENUMS_QUERY,
					variables: {
						envId: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setResult({
					isLoading: false,
					...res.data.data.environment.enumerations,
				});
			}
		},
		[environment.isLoading]
	);

	return (
		<EnumList
			enums={result}
			onDelete={(id) => {
				refresh();
				setResult({
					...result,
					edges: reject(pathEq(['node', 'id'], id), result.edges),
				});
			}}
			onAdd={(item) => {
				refresh();
				setResult({
					...result,
					edges: append({ node: item }, result.edges),
				});
			}}
			onUpdate={(item) => {
				refresh();
				setResult({
					...result,
					edges: update(
						findIndex(pathEq(['node', 'id'], item.id), result.edges),
						{ node: item },
						result.edges
					),
				});
			}}
		/>
	);
});
