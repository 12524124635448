import React from 'react';
import { Button } from '@platformapp/ui';
import { difference, pathOr, pick, remove } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateModel } from './mutations';
import { MetadataEditor } from '../metadata/MetadataEditor';
import toast from 'react-hot-toast';

export const MetadataSetting = ({ blueprint, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		initialErrors: [],
		initialValues: {
			metadata: pathOr('', ['data', 'metadata'], blueprint),
		},
		validate: () => [],
		onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
			// Determine if any metadata is being deleted. Find any keys that were
			// in the initial data, but are not in the form values.
			const originalKeys = blueprint.data.metadata.map((item) => item.key);
			const updatedKeys = values.metadata.map((item) => item.key);
			const removedKeys = difference(originalKeys, updatedKeys);

			updateModel(blueprint.data.id, {
				metadata: [
					...values.metadata,
					...removedKeys.map((key) => ({
						key,
						value: null,
					})),
				],
			})
				.then((updatedBp) => {
					resetForm({ values: pick(['metadata'], updatedBp) });
					onChange(updatedBp);
					toast.success('Metadata updated');
				})
				.catch((error) => {
					if (error.isUserError) {
						setErrors(error.userErrors);
					} else {
						console.error(error);
						toast.error('Failed to update metadata');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Metadata</p>
					<p className="mb-4 leading-relaxed">
						Metadata is used by the Dashboard and extensions to enhance
						functionality.
					</p>
					<MetadataEditor
						disabled={blueprint.isLoading || form.isSubmitting}
						errors={form.errors.map((err) => ({
							...err,
							path: remove(0, 1, err.path),
						}))}
						onChange={(val) => form.setFieldValue('metadata', val)}
						value={form.values.metadata}
					/>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
