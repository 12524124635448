import {
	useState,
	useEffect,
	useContext,
	useRef,
	createRef,
} from 'react';
import { FormSubmissions } from './FormSubmissions';
import { NavLink, useParams } from 'react-router-dom';
// @ts-ignore
import { Icon } from '@platformapp/ui';
import { FormSubmissionFilters } from './FormSubmissionFilters';
import { NoResults } from '../common/NoResults';
import { FormBody } from './FormBody';
import {
	isNil,
	pathEq,
	assocPath,
	curry,
	map,
	when,
	omit,
	hasPath,
} from 'ramda';
// @ts-ignore
import ArrowLeftIcon from '../icons/arrow-left.svg';
import { useLog } from '../hooks/useLog';
import { EnvironmentContext } from '../context/EnvironmentContext';
import {
	buildConnectionQuery,
	deleteItem,
	updateItem,
} from '../util/query-builder';
import { ListPageHeader } from '../common/ListPageHeader';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { FilterParam } from '../util';
import { filtersToApiParams } from '../filters/util';
import { SkeletonBox } from '../common/Skeleton';
import toast from 'react-hot-toast';
import { Nav } from './Nav';

const PER_PAGE = 30;
const MOBILE_CLASSNAMES =
	'w-full h-full p-6 sm:p-0 block fixed top-0 left-0 z-10 sm:z-auto sm:top-auto sm:left-auto sm:relative';

const SkeletonItem = () => (
	<div className="py-5 px-2 border-b border-gray-200">
		<SkeletonBox className="h-3 mb-5 w-4/5" />
		<SkeletonBox className="h-3 w-2/3" />
	</div>
);

type Params = {
	formId: string;
}

export const FormSubmissionsList = ({ blueprint }: any) => {
	const { generateEnvPath, contentApiRequest } = useContext(EnvironmentContext);
	const log = useLog();
	const [selectedSubmission, setSelectedSubmission] = useState({
		index: null,
		wasUserSelected: false,
	});
	const { formId } = useParams<Params>();
	const [query] = useQueryParams({
		after: StringParam,
		before: StringParam,
		filters: FilterParam,
	});
	const apiParams: any = filtersToApiParams(query.filters);
	const [selectedItemRefs, setSelectedItemRefs] = useState([]);
	const listContainerRef = useRef(null);
	const [filters] = useQueryParam('filters', FilterParam);

	const [submissions, setSubmissions] = useState<any>({
		isLoading: true,
		isLoadingMore: false,
		edges: [],
	});

	const fetchSubmissions = (additive = false) => {
		if (blueprint.isLoading) {
			return;
		}
		const variables: any = {
			filter: {
				...omit(['folder', 'before', 'after'], apiParams),
			},
			order: [{ createdAt: 'DESC' }],
		};

		if (hasPath(['folder', 'eq'], apiParams)) {
			switch (apiParams.folder.eq) {
				case 'archive':
					variables.filter.isArchived = { eq: true };
					break;
				case 'inbox':
					variables.filter.isArchived = { eq: false };
					variables.filter.isSpam = { eq: false };
					break;
				case 'spam':
					variables.filter.isArchived = { eq: false };
					variables.filter.isSpam = { eq: true };
					break;
			}
		} else {
			variables.filter.isSpam = { eq: false };
			variables.filter.isArchived = { eq: false };
		}

		if (query.after) {
			variables.after = query.after;
		} else if (query.before) {
			variables.before = query.before;
		}

		if (query.before) {
			variables.last = PER_PAGE;
		} else {
			variables.first = PER_PAGE;
		}

		contentApiRequest({
			query: buildConnectionQuery(blueprint.node),
			variables,
		}).then((res) => {
			// TODO: ensure `statrCursor` represents the first in the list
			// TODO: ensure `endCursor` represents the last in the list
			const newEdges = additive
				? variables.before
					? [...res.data.data.results.edges, ...submissions.edges]
					: [...submissions.edges, ...res.data.data.results.edges]
				: res.data.data.results.edges;
			setSubmissions({
				isLoading: false,
				// @ts-ignore
				pageInfo: res.data.data.results.pageInfo,
				totalCount: res.data.data.results.totalCount,
				edges: newEdges,
			});
			setSelectedItemRefs(
				Array(newEdges.length)
					// .fill()
					.map(() => createRef())
			);
			if (!additive && res.data.data.results.edges.length > 0) {
				setSelectedSubmission({
					index: 0,
					wasUserSelected: false,
				});
			}
		});
	};

	const fullRefetch = () => {
		setSelectedSubmission({
			index: null,
			wasUserSelected: false,
		});
		setSubmissions({
			isLoading: true,
			edges: [],
		});
		fetchSubmissions();
	};

	// The filters have changed - reset the data to the default and refetch
	useEffect(() => fullRefetch(), [filters, blueprint.isLoading]);

	useEffect(() => {
		const isRefetch = !query.after && !query.before;
		if (isRefetch) {
			fullRefetch();
		} else {
			setSubmissions({
				...submissions,
				isLoadingMore: true,
			});
			fetchSubmissions(true);
		}
	}, [query.after, query.before]);

	const removeSubmission = (id: string) => {
		const newEdges = submissions.edges.filter(({ node }: any) => node.id !== id);
		if (newEdges.length === 0) {
			setSelectedSubmission({
				index: null,
				wasUserSelected: false,
			});
		} else if (selectedSubmission.index > newEdges.length - 1) {
			setSelectedSubmission({
				index: newEdges.length - 1,
				wasUserSelected: false,
			});
		}
		setSubmissions({
			...submissions,
			edges: newEdges,
		});
	};

	const archiveSubmission = (id: string, isArchived: boolean) => {
		contentApiRequest({
			query: updateItem(blueprint.node),
			variables: {
				input: {
					id,
					isArchived,
				},
			},
		}).then(() => {
			// We can always remove the submission from the current list - if it's archived, the user
			// is on inbox, if it's being unarchived, the user is viewing archived submissions.
			removeSubmission(id);
		});
	};

	const deleteSubmission = (id: string) => {
		contentApiRequest({
			query: deleteItem(blueprint.node),
			variables: {
				input: {
					id,
				},
			},
		}).then((res) => {
			if (
				res.data.data[`delete${blueprint.node.apiId}`].userErrors.length === 0
			) {
				removeSubmission(id);
			} else {
				toast.error('Failed to delete form submission');
			}
		});
	};

	const updateSubmission = (value: any, id: string, property: any) =>
		setSubmissions({
			...submissions,
			edges: curry((value, id, property) =>
				map(
					when(
						pathEq(['node', 'id'], id),
						assocPath(['node', property], value)
					),
					submissions.edges
				)
			)(value, id, property),
		});

	const flagSpam = (id: string) => {
		contentApiRequest({
			query: updateItem(blueprint.node),
			variables: {
				input: {
					id,
					isSpam: true,
				},
			},
		}).then((res) => {
			log.debug(res.data.data.markFormSubmissionAsSpam);
			toast.success('Submission marked as spam');
			if (apiParams.folder === 'archive') {
				updateSubmission(true, id, 'isSpam');
			} else {
				removeSubmission(id);
			}
		});
	};

	const flagNotSpam = (id: string) => {
		contentApiRequest({
			query: updateItem(blueprint.node),
			variables: {
				input: {
					id,
					isSpam: false,
				},
			},
		}).then((res) => {
			log.debug(res.data.data.markFormSubmissionAsNotSpam);
			toast.success('Submission marked as not spam');
			if (apiParams.folder === 'archive') {
				updateSubmission(false, id, 'isSpam');
			} else {
				removeSubmission(id);
			}
		});
	};

	const allLoaded = !blueprint.isLoading && !submissions.isLoading;

	return (
		<div
			className="h-full flex flex-col outline-none pt-30"
			onKeyDown={(e) => {
				if (
					(e.key !== 'ArrowDown' && e.key !== 'ArrowUp') ||
					submissions.isLoading
				) {
					return;
				}
				let newIndex;
				if (
					e.key === 'ArrowDown' &&
					selectedSubmission.index < submissions.edges.length - 1
				) {
					newIndex = e.metaKey
						? submissions.edges.length - 1
						: selectedSubmission.index + 1;
				} else if (e.key === 'ArrowUp' && selectedSubmission.index > 0) {
					newIndex = e.metaKey ? 0 : selectedSubmission.index - 1;
				} else {
					return;
				}
				setSelectedSubmission({
					index: newIndex,
					wasUserSelected: true,
				});
				if (newIndex === submissions.edges.length - 1) {
					// This is the last item in the list - scroll to the bottom of
					// the list container, to reveal "Load older" button
					listContainerRef.current.scrollIntoView(false);
				} else {
					selectedItemRefs[newIndex].current.scrollIntoView({
						block: 'nearest',
					});
				}
				return false;
			}}
			tabIndex={-1}
		>
			<Nav collection={blueprint} />
			<div className="py-4">
				<FormSubmissionFilters
					appliedFilters={query.filters}
					blueprint={blueprint}
				/>
			</div>
			<div className="overflow-auto flex h-full border-t border-gray-300">
				<div className="w-full sm:w-1/3 flex-shrink-0 overflow-y-auto sm:border-r border-gray-300">
					{!allLoaded && (
						<>
							<SkeletonItem />
							<SkeletonItem />
							<SkeletonItem />
						</>
					)}
					{allLoaded && (
						<FormSubmissions
							ref={listContainerRef}
							// @ts-ignore
							blueprint={blueprint.node}
							result={submissions}
							selectedItemRefs={selectedItemRefs}
							selectedSubmission={selectedSubmission.index}
							onSelectionChange={(index: number) => {
								setSelectedSubmission({
									index,
									wasUserSelected: true,
								});
							}}
						/>
					)}
				</div>

				<div
					className={`sm:flex-grow overflow-y-auto sm:pl-8 sm:block bg-white ${
						selectedSubmission.wasUserSelected ? MOBILE_CLASSNAMES : 'hidden'
					}`}
				>
					<div className="sm:hidden mb-3">
						<button
							className="text-gray-600 hover:text-gray-700 text-sm font-medium"
							onClick={() =>
								setSelectedSubmission({
									index: null,
									wasUserSelected: false,
								})
							}
						>
							<Icon source={ArrowLeftIcon} />
							Submissions
						</button>
					</div>
					{allLoaded && submissions.edges.length === 0 && <NoResults />}
					{allLoaded &&
						submissions.edges.length > 0 &&
						!isNil(selectedSubmission.index) && (
							<FormBody
								archiveSubmission={archiveSubmission}
								deleteSubmission={deleteSubmission}
								flagSpam={flagSpam}
								flagNotSpam={flagNotSpam}
								blueprint={blueprint.node}
								submission={submissions.edges[selectedSubmission.index].node}
							/>
						)}
				</div>
			</div>
		</div>
	);
};
