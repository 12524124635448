import React from 'react';

export const Toggle = ({
	checked,
	disabled,
	onChange,
	className,
	small,
	...props
}) => (
	<div
		aria-checked={checked}
		aria-disabled={disabled}
		className={`rounded-full w-10 bg-white border border-gray-300 p-1 shadow-inner ${
			disabled ? '' : 'cursor-pointer'
		} ${className} ${small ? 'transform scale-75' : ''}`}
		role="switch"
		onClick={() => !disabled && onChange(!checked)}
		{...props}
	>
		<div
			className={`rounded-full h-4 w-4 ${
				disabled ? 'bg-gray-500' : 'bg-blue-700'
			} shadow transition-transform duration-300 ease-in-out transform ${
				checked ? 'translate-x-4' : 'translate-x-0'
			}`}
		/>
	</div>
);

Toggle.defaultProps = {
	checked: false,
	disabled: false,
	small: false,
};
