import React, { useState } from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import Helpers from '../Helpers';
import DELETE_ORIGIN from './DeleteOrigin.gql';
import { ConfirmDialog } from '@platformapp/ui';
import { graphql } from '../api';
import { pathOr } from 'ramda';
import { B } from '../common/Text';
import toast from 'react-hot-toast';

export const CorsOriginContextMenu = ({ corsOrigin, onDelete }) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					title="Delete CORS origin"
					body={
						<>
							<p className="leading-normal mb-2">
								Are you sure you want to delete <B>{corsOrigin.origin}</B>?
							</p>
							<p className="leading-normal">
								This CORS origin will no longer be able to access the Content
								API from the browser.
							</p>
						</>
					}
					onConfirm={() => {
						setIsDeleting(true);

						graphql({
							query: DELETE_ORIGIN,
							variables: {
								input: {
									corsOriginId: corsOrigin.id,
								},
							},
						})
							.then((res) => {
								const userInputErrs = pathOr(
									[],
									['data', 'deleteCorsOrigin', 'userErrors'],
									res.data
								);
								const errs = pathOr(
									[],
									['data', 'deleteCorsOrigin', 'errors'],
									res.data
								);
								if (userInputErrs.length > 0 || errs.length > 0) {
									toast.error('Failed to delete CORS origin');
									setIsDeleting(false);
								} else {
									onDelete();
								}
							})
							.catch((err) => {
								console.error(err);
								toast.error(`Failed to delete CORS origin`);
								setIsDeleting(false);
							})
							.then(() => setIsConfirmingDelete(false));
					}}
					onCancel={() => setIsConfirmingDelete(false)}
					dangerous
					isWorking={isDeleting}
					confirmText="Delete CORS origin"
				/>
			)}
			<NewContextMenu
				styleOnHover
				sections={[
					{
						items: [
							{
								label: Helpers.t('general.delete'),
								onAction: () => setIsConfirmingDelete(true),
								destructive: true,
							},
						],
					},
				]}
			/>
		</>
	);
};
