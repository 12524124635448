import React, { useState } from 'react';
import { Button } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { P } from '../common/Text';
import Helpers from '../Helpers';
import { updateWebhook } from './mutations';
import toast from 'react-hot-toast';

export const DisableSetting = ({ hook, onChange }) => {
	const [isDisabling, setIsDisabling] = useState(false);
	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Disable webhook</p>
				<P>
					Events will not triggers request to this webhook while it is disabled.
				</P>
				<P className="mt-1 mb-4">
					The webhook can be enabled at any time, and all delivery attempt logs
					will be kept.
				</P>
			</FieldsetContent>
			<FieldsetFooter>
				<Button
					loading={isDisabling}
					submit
					width="large"
					destructive={!hook.isLoading && hook.node.isEnabled}
					onClick={() => {
						setIsDisabling(true);
						updateWebhook(hook.node.id, {
							isEnabled: !hook.node.isEnabled,
						})
							.then((updatedHook) => {
								onChange(updatedHook);
							})
							.catch(() => {
								toast.error('Failed to update webhook');
							})
							.finally(() => setIsDisabling(false));
					}}
				>
					{hook.isLoading
						? ''
						: Helpers.t(
								`general.${hook.node.isEnabled ? 'disable' : 'enable'}`
						  )}
				</Button>
			</FieldsetFooter>
		</Fieldset>
	);
};
