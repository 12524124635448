// @ts-ignore
import { Button, CheckboxField } from '@platformapp/ui';

interface BulkActionBarProps {
    bulkActions: BulkAction[];
    disabled?: boolean;
    onSelectAll: () => void;
    selection: string[];
}

export interface BulkAction {
    destructive?: boolean;
    label: string;
    onAction: () => void;
}

export const BulkActionBar = ({
    bulkActions,
    disabled,
    onSelectAll,
    selection
}: BulkActionBarProps) => (
    <div className="relative">
        <div className="absolute flex -ml-2 w-full bg-white fade-in pt-px">
            <Button
                className="flex items-center rounded-r-none"
                disabled={disabled}
                onClick={onSelectAll}
            >
                <CheckboxField
                    checked
                    className="mr-2 -ml-px"
                    disabled={disabled}
                    onChange={onSelectAll}
                />
                {selection.length} selected
            </Button>
            {bulkActions.map((a, i) => (
                <Button
                    className={`${
                        i === bulkActions.length - 1
                            ? 'rounded-l-none'
                            : 'rounded-r-none rounded-l-none'
                    } ${a.destructive ? 'text-red-700' : ''} -ml-px`}
                    disabled={disabled}
                    key={i}
                    onClick={() => a.onAction()}
                >
                    {a.label}
                </Button>
            ))}
        </div>
    </div>
)