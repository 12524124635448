import React from 'react';
import { Filters } from '../filters/Filters';
import { FILTER_ENUM } from '../constants';
import Helpers from '../Helpers';
import { FilterParam, filtersForAttributes } from '../util';
import { useQueryParams } from 'use-query-params';

const EXCLUDED_FILTER_ATTRIBUTES = Object.freeze(['isArchived', 'isSpam']);

export const FormSubmissionFilters = ({ appliedFilters, blueprint }) => {
	const [, setQuery] = useQueryParams({
		filters: FilterParam,
	});

	const filterChanged = (newFilters) => {
		const actualFilters = newFilters.filter((filter) => filter.value !== null);
		setQuery({
			after: undefined,
			before: undefined,
			filters: actualFilters.length > 0 ? actualFilters : undefined,
		});
	};

	const filterDefs = [
		{
			key: 'folder',
			type: FILTER_ENUM,
			label: Helpers.t('general.folder'),
			persistent: true,
			values: [
				{
					label: Helpers.t('forms.filters.inbox'),
					value: '',
				},
				{
					label: Helpers.t('forms.filters.spam'),
					value: 'spam',
				},
				{
					label: Helpers.t('forms.filters.archive'),
					value: 'archive',
				},
				{
					label: Helpers.t('forms.filters.all'),
					value: 'all',
				},
			],
		},
		...(blueprint.isLoading
			? []
			: filtersForAttributes(
					blueprint.node.attributes.filter(
						(a) => !EXCLUDED_FILTER_ATTRIBUTES.includes(a.apiId)
					)
			  )),
	];

	return (
		<Filters
			definitions={filterDefs}
			filters={appliedFilters}
			onChange={(filters) => filterChanged(filters)}
		/>
	);
};
