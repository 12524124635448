import React from 'react';
import { CheckboxField } from '@platformapp/ui';
import { append, without } from 'ramda';

export const CrudScopeRow = ({
	disabled,
	resourceName,
	createScope = true,
	readScope = true,
	updateScope = true,
	deleteScope = true,
	selectedScope,
	prefix,
	onChange,
}) => {
	const handleChange = (scope, checked) => {
		if (checked) {
			onChange(append(scope, selectedScope));
		} else {
			onChange(without(scope, selectedScope));
		}
	};
	return (
		<div className="flex items-center border-b border-gray-300 last:border-b-0 px-5 py-4">
			<div className="flex-grow">
				<p className="text-gray-900">{resourceName}</p>
			</div>
			<div className="flex-shrink-0 flex">
				{readScope && (
					<div className="mr-6">
						<CheckboxField
							disabled={disabled}
							label="Read"
							checked={selectedScope.includes(`READ_${prefix}`)}
							onChange={(checked) => handleChange(`READ_${prefix}`, checked)}
						/>
					</div>
				)}
				{createScope && (
					<div className="mr-6">
						<CheckboxField
							disabled={disabled}
							label="Create"
							checked={selectedScope.includes(`CREATE_${prefix}`)}
							onChange={(checked) => handleChange(`CREATE_${prefix}`, checked)}
						/>
					</div>
				)}
				{updateScope && (
					<div className="mr-6">
						<CheckboxField
							disabled={disabled}
							label="Update"
							checked={selectedScope.includes(`UPDATE_${prefix}`)}
							onChange={(checked) => handleChange(`UPDATE_${prefix}`, checked)}
						/>
					</div>
				)}
				{deleteScope && (
					<div className="mr-6">
						<CheckboxField
							disabled={disabled}
							label="Delete"
							checked={selectedScope.includes(`DELETE_${prefix}`)}
							onChange={(checked) => handleChange(`DELETE_${prefix}`, checked)}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

CrudScopeRow.defaultProps = {
	disabled: false,
};
