import React from 'react';
import { EditContentContainer } from './EditContentContainer';
import {
	EDITOR_MODE_NEW,
	EDITOR_MODE_COPY,
	EDITOR_MODE_EDIT,
} from '../constants';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { useRouteMatch } from 'react-router-dom';

const NEW_PATH_PATTERNS = [
	'/:teamSlug/:projectId/content/:blueprintId/new',
	'/:teamSlug/:projectId/env/:envSlug/content/:blueprintId/new',
];

const COPY_PATH_PATTERNS = [
	'/:teamSlug/:projectId/content/copy/:blueprintId/:id',
	'/:teamSlug/:projectId/env/:envSlug/content/copy/:blueprintId/:id',
];

const EditContentStackComponent = () => {
	const match = useRouteMatch();

	const props = {
		mode: EDITOR_MODE_EDIT,
	};

	if (COPY_PATH_PATTERNS.includes(match.path)) {
		props.mode = EDITOR_MODE_COPY;
		props.sourceId = match.params.id;
	} else if (NEW_PATH_PATTERNS.includes(match.path)) {
		props.mode = EDITOR_MODE_NEW;
	}

	return <EditContentContainer {...props} />;
};

export const EditContentStack = withErrorBoundary(EditContentStackComponent);
