import Helpers from '../Helpers';
import React from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';

export const ComponentListItemActions = ({
	attribute,
	onAddAfter,
	onAddBefore,
	onRemove,
	disabled,
}) => (
	<NewContextMenu
		disabled={disabled}
		sections={[
			{
				items: attribute.allowedComponents.map((type) => ({
					onAction: () => onAddBefore(type.apiId),
					label: type.name,
				})),
				label: 'Add before',
			},
			{
				items: attribute.allowedComponents.map((type) => ({
					onAction: () => onAddAfter(type.apiId),
					label: type.name,
				})),
				label: 'Add after',
			},
			{
				items: [
					{
						destructive: true,
						label: Helpers.t('general.remove'),
						onAction: () => onRemove(),
					},
				],
			},
		]}
		styleOnHover
	/>
);
