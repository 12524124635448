import { createContext, useContext } from 'react';
import { DEFAULT_PER_PAGE, STAGE_DRAFT, STAGE_PUBLISHED } from '../constants';
import { generateBlueprintContentQuery, generateCreateContentMutation, generateUpdateContentMutation } from '../content/Mutations';
import { buildBulkPublishMutation, buildBulkUnpublishMutation, NodeQueryBuilder } from '../util/query-builder';
import { EnvironmentContext } from './EnvironmentContext';
// @ts-ignore
import UNPUBLISH_ITEM from './UnpublishItem.gql';
// @ts-ignore
import PUBLISH_ITEM from './PublishItem.gql';
import { ProjectContext } from './ProjectContext';

type GetItemParams = {
	additionalNodeFields?: any[];
	resolveReferences?: string[];
}

type ContentApiContextProps = {
	createItem: (collection: string, variables: any) => Promise<CreateItemResponse>;
	deleteItem: (id: string) => Promise<void>;
	publishItem: (id: string) => Promise<PublishItemResponse>;
	publishItems: (ids: string[]) => Promise<any>;
	unpublishItem: (id: string) => Promise<UnpublishItemResponse>;
	unpublishItems: (ids: string[]) => Promise<any>;
	updateItem: (collection: string, variables: any) => Promise<UpdateItemResponse>;
	findItems: (collection: string, variables: FindItemsParams) => Promise<any>;
	getItem: (collection: string, id: string, params?: GetItemParams) => Promise<any>;
}

type PublishItemResult = {
	__typename: string;
	id: string;
	updatedAt: string;
	inStages: any[];
}

type PublishItemResponse = PublishItemResult[];

type UnpublishItemResult = {
	__typename: string;
	id: string;
	updatedAt: string;
	inStages: any[];
}

type UnpublishItemResponse = UnpublishItemResult[];

interface Node {
	id: string;
	[key: string]: any;
}

interface CreateItemResponse {
	userErrors: UserError[];
	node: Node;
}

interface UpdateItemResponse {
	userErrors: UserError[];
	node: Node;
}

interface UserError {
	message: [];
	path: string[];
}

interface FindItemsParams {
	first?: number;
	last?: number;
	before?: string;
	after?: string;
	filter: Filter[];
	order: OrderItem[];
}

interface Filter {
	// TODO: union of possible filters, eq, neq, gte, etc
	[key: string]: any;
}

interface OrderItem {
	[key: string]: 'ASC' | 'DESC'
}

export const ContentApiContext = createContext<ContentApiContextProps | null>(null);

export const ContentApiConsumer = ContentApiContext.Consumer;

type ContentApiProviderProps = {
	children: React.ReactNode;
}

export const ContentApiProvider = ({ children }: ContentApiProviderProps) => {
	const {collectionByApiId, contentApiRequest2, collections} = useContext(EnvironmentContext);
	// @ts-ignore
	const {activeProject} = useContext(ProjectContext);

	return (
		<ContentApiContext.Provider
			value={{
				createItem: async (collection: string, variables: any): Promise<any> => {
					const coll = collectionByApiId(collection);

					const res = await contentApiRequest2({
						query: generateCreateContentMutation(coll.node, true),
						variables
					});

					return res.data.result;
				},
				deleteItem: async (id: string): Promise<void> => {
					// TODO: implement this
				},
				publishItems: async (ids: string[]): Promise<any> => {
					const res = await contentApiRequest2({
						query: buildBulkPublishMutation(ids, activeProject.ecommerceEnabled),
						variables: {
							...ids.reduce(
								(all, id, index) => ({
									...all,
									[`input${index}`]: {
										id,
									},
								}),
								{}
							),
							stages: [STAGE_DRAFT, STAGE_PUBLISHED],
						}
					});

					if (res.errors) {
						throw new Error('Failed to publish items');
					}

					return res;
				},
				publishItem: async (id: string): Promise<PublishItemResponse> => {
					try {
						const { data } = await contentApiRequest2({
							query: PUBLISH_ITEM,
							variables: {
								input: {
									id,
								},
								stages: [STAGE_DRAFT, STAGE_PUBLISHED],
							},
						});
						return data.publishPublishable.publishedNodes;
					} catch (err) {
						console.error(err);
						throw err;
					}
				},
				unpublishItem: async (id: string): Promise<UnpublishItemResponse> => {
					try {
						const { data } = await contentApiRequest2({
							query: UNPUBLISH_ITEM,
							variables: {
								input: {
									id,
								},
								stages: [STAGE_PUBLISHED],
							},
						});
						return data.unpublishPublishable.unpublishedNodes;
					} catch (err) {
						console.error(err);
						throw err;
					}
				},
				unpublishItems: async (ids: string[]): Promise<any> => {
					const res = await contentApiRequest2({
						query: buildBulkUnpublishMutation(ids),
						variables: {
							...ids.reduce(
								(all, id, index) => ({
									...all,
									[`input${index}`]: {
										id,
									},
								}),
								{}
							),
							stages: [STAGE_DRAFT, STAGE_PUBLISHED],
						}
					});

					if (res.errors) {
						throw new Error('Failed to unpublish items');
					}

					return res;
				},
				updateItem: async (collection: string, variables: any): Promise<UpdateItemResponse> => {
					const coll = collectionByApiId(collection);

					const res = await contentApiRequest2({
						query: generateUpdateContentMutation(coll.node),
						variables
					});

					return res.data.result;
				},
				findItems: async (collection: string, variables: FindItemsParams): Promise<void> => {
					const coll = collectionByApiId(collection);

					const res = await contentApiRequest2({
						query: generateBlueprintContentQuery(coll.node, {
							includeTitle: true,
						}),
						variables
					});

					return res.data.items;
				},
				getItem: async (collection: string, id: string, params: GetItemParams = null): Promise<any> => {
					const res = await contentApiRequest2({
						query: NodeQueryBuilder.make(collections)
							.additionalNodeFields(params?.additionalNodeFields || [])
							.resolveReferences(params?.resolveReferences || [])
							.generate(collection),
						variables: {
							first: DEFAULT_PER_PAGE,
							id: id,
						},
					});

					const coll = collectionByApiId(collection);
					const componentAttrs = coll.node.attributes.filter(a => a.__typename === 'ComponentAttribute');

					const finalData = componentAttrs.reduce((values, attr) => ({
						...values,
						[attr.apiId]: attr.isList
							? values[attr.apiId].map(processComponentInstance)
							: processComponentInstance(values[attr.apiId])
					}), res.data.node);

					return finalData;
				}
			}}
		>
			{children}
		</ContentApiContext.Provider>
	);
};

const processComponentInstance = (componentInstance: any) => {
	const fields = Object.keys(componentInstance);

	return fields.reduce((values, field) => {
		const aliasPrefix = `${componentInstance.__typename}_`;

		if (field.startsWith(aliasPrefix)) {
			return {
				...values,
				[field.substring(aliasPrefix.length)]: componentInstance[field]
			}
		}
		return {
			...values,
			[field]: componentInstance[field]
		}
	}, {} as any);
}