import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { EditContentLayer } from './EditContentLayer';
import { Prompt, useHistory } from 'react-router-dom';
import * as Mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';
import {
	getReferenceAttributeApiIds,
	getValuesForSave,
} from '../util/AttributeHelpers';
import {
	generateCreateContentMutation,
	generateUpdateContentMutation,
} from './Mutations';
import { EDITOR_MODE_EDIT } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { validateAttributeValues } from '../util/validation';
import { mergeDeepRight, omit, pathOr } from 'ramda';
import { ContentApiContext } from '../context/ContentApiContext';
import toast from 'react-hot-toast';
import Helpers from '../Helpers';

export const EditContentForm = ({
	blueprint,
	content,
	mode,
	setContent,
	setMode,
	values,
}: any) => {
	const history = useHistory();
	const { generateEnvPath, contentApiRequest } = useContext(
		EnvironmentContext
	);
	const {publishItem, unpublishItem} = useContext(ContentApiContext);
	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		// @ts-ignore
		initialErrors: [],
		initialValues: values,
		validate: (values) => {
			const errors = validateAttributeValues(
				getValuesForSave(values, blueprint.node),
				blueprint.node
			);

			if (errors.length !== 0) {
				toast.error('Some fields are invalid; correct these and save again.');
			}

			return errors;
		},
		onSubmit: async (formValues, { setErrors, resetForm }) => {
			const isNew = mode !== EDITOR_MODE_EDIT;
			const input: any = getValuesForSave(formValues, blueprint.node);

			// If this content is being edited, add its current ID to the input
			if (mode === EDITOR_MODE_EDIT) {
				input.id = content.data.id;
			}

			const mutation =
				mode === EDITOR_MODE_EDIT
					? generateUpdateContentMutation(blueprint.node)
					: generateCreateContentMutation(blueprint.node);

			const res = await contentApiRequest({
				query: mutation,
				variables: {
					input,
				},
			});

			const payload = pathOr(null, ['data', 'result'], res.data);

			// If no `data` key is returned or it has no `node`/`userErrors` properties,
			// then something went wrong server side.
			if (!payload || (!payload.node && !payload.userErrors)) {
				console.error(res.data);
				toast.error('Something went wrong saving this item.');
				return;
			}

			if (payload.userErrors.length > 0) {
				setErrors(payload.userErrors);
				toast.error('Some fields are invalid; correct these and save again.');
				return;
			}

			const contentNode = {
				...content,
				data: {
					...formValues,
					...content.data,
					...payload.node,
				},
			};

			// Check if the resource needs to be published/unpublished
			if (form.status) {
				if (form.status.action === 'publish') {
					const res = await publishItem(contentNode.data.id);

					const node = res.find(n => n.id === contentNode.data.id);

					toast.success(`Item published`);
					setContent({
						...content,
						data: mergeDeepRight(contentNode.data, node),
					});
				}
			} else {
				setContent(contentNode);
				toast.success(`Item saved`);
			}

			resetForm({
				values: omit(
					getReferenceAttributeApiIds(blueprint.node.attributes),
					formValues
				),
				status: null,
			});

			if (isNew) {
				history.replace(
					generateEnvPath(`content/${blueprint.node.id}/${payload.node.id}`)
				);
				setMode(EDITOR_MODE_EDIT);
			}
		},
	});

	const onUnpublish = async () => {
		const promise = unpublishItem(content.data.id);

		toast.promise(promise, {
			loading: Helpers.t('publishing.unpublishing_inprogress', 1),
			success: Helpers.t('publishing.unpublishing_success', 1),
			error: Helpers.t('publishing.unpublishing_failed', 1)
		});

		const res = await promise;

		setContent({
			...content,
			data: {
				...form.values,
				...content.data,
				...res.find(r => r.id === content.data.id),
			},
		});
	};

	useEffect(() => {
		Mousetrap.bindGlobal('mod+s', () => {
			form.submitForm();
			return false;
		});
		return function cleanup() {
			Mousetrap.unbind('mod+s');
		};
	}, []);

	return (
		<>
			<EditContentLayer
				blueprint={blueprint}
				content={content}
				mode={mode}
				form={form}
				onUnpublish={onUnpublish}
			/>
			<Prompt
				when={form.dirty}
				message="You have unsaved changes. Are you sure you want to leave this page?"
			/>
		</>
	);
};
