import React from 'react';

const BASE_CLASS_NAMES = 'px-4 md:px-10';

export const Wrapper = ({
	children,
	padBottom,
	padVertical,
	className,
	...props
}) => (
	<div
		className={`${BASE_CLASS_NAMES} ${className} ${padBottom ? 'pb-6' : ''} ${
			padVertical ? 'py-3 md:py-8' : 'py-2'
		}`}
		{...props}
	>
		{children}
	</div>
);

Wrapper.defaultProps = {
	className: '',
	padBottom: false,
	padVertical: false,
};
