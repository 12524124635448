import { useContext, useState } from 'react';
import {
	Button,
	ButtonGroup,
	CheckboxField,
	ErrorMessageElement,
	Icon,
	TextField,
	// @ts-ignore
} from '@platformapp/ui';
import { StickyHeader } from '../common/StickyHeader';
import { Heading } from '../common/Heading';
import { Module } from '../common/Module';
import { extractGraphqlError } from '../util';
import { InventoryQuantityInput } from '../common/InventoryQuantityInput';
// @ts-ignore
import ArrowLeftIcon from '../icons/arrow-left.svg';
import { EDITOR_MODE_NEW, EDITOR_MODE_EDIT } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { AssetSelector } from '../common/AssetSelector';
import { equals, isNil } from 'ramda';
import { ProductVariant } from '../types/Item';
import { CurrencyField } from '../common/CurrencyField';
import { Attribute } from '../types/Attribute';

const ERROR_BASE = Object.freeze(['variants']);

type EditVariantForm = {
	form: any;
	item: ProductVariant;
	mode: 'edit' | 'new';
	onCancel: () => void;
	product: any;
	productOptions: string[];
	title: string;
}

export const EditVariantForm = ({
	item,
	form,
	mode,
	onCancel,
	product,
	productOptions,
	title,
}: EditVariantForm) => {
	const { baseCurrency, collectionByApiId } = useContext(
		EnvironmentContext
	);
	const customAttrs = collectionByApiId(
		'ProductVariant'
	).node.attributes.filter(
		(a: Attribute) => !a.isSystem && a.isEditable && a.apiId !== 'images'
	);
	const [images, setImages] = useState(
		mode === EDITOR_MODE_NEW
			? {
					edges: [],
			  }
			: item.images
	);

	return (
		<form onSubmit={form.handleSubmit}>
			{/* @ts-ignore */}
			<StickyHeader bordered hasPx hasPy sticky>
				<div className="flex items-center">
					<Button
						className="mr-4"
						disabled={form.isSubmitting}
						onClick={() => onCancel()}
						width="small"
					>
						<Icon source={ArrowLeftIcon} />
					</Button>
					<div className="flex-grow flex items-center gap-3">
						{/* @ts-ignore */}
						<Heading>
							{mode === EDITOR_MODE_NEW ? 'Create product variant' : title}
						</Heading>
					</div>
					<ButtonGroup>
						<Button
							disabled={form.isSubmitting || !form.dirty}
							primary
							type="submit"
						>
							Save
						</Button>
					</ButtonGroup>
				</div>
			</StickyHeader>
			<div className="pb-10 mx-10 mt-5">
				{customAttrs.length > 0 && (
					<div className="mb-12">
						<AttributeEditor
							readOnly={form.isSubmitting}
							attributes={customAttrs}
							values={form.values}
							onAttributeChange={(apiId: string, val: any) => form.setFieldValue(apiId, val)}
							validationErrors={form.errors}
							pathPrefix={ERROR_BASE}
							isNew={mode !== EDITOR_MODE_EDIT}
							initialValues={item}
						/>
					</div>
				)}
				<Module bottomMargin heading="Options">
					{productOptions.map((option, i) => (
						<TextField
							key={i}
							label={option}
							name={`option-${i}`}
							value={form.values.options[i]}
							onChange={(e: any) =>
								form.setFieldValue(`options[${i}]`, e.target.value)
							}
							error={extractGraphqlError(
								[...ERROR_BASE, 'options', i.toString()],
								form.errors
							)}
							readOnly={form.isSubmitting}
						/>
					))}
					<ErrorMessageElement
						message={extractGraphqlError(
							[...ERROR_BASE, 'options'],
							form.errors
						)}
					/>
				</Module>
				<Module bottomMargin heading="Images">
					{/* @ts-ignore */}
					<AssetSelector
						multiple
						onChange={(val: any) => {
							const valChanged = !equals(
								images.edges.map((edge: any) => edge.node.id),
								val.edges.map((edge: any) => edge.node.id)
							);

							setImages(val);

							// Only update the form if the IDs have changed
							if (valChanged) {
								form.setFieldValue(
									'images',
									val.edges.map((edge: any) => edge.node.id)
								);
							}
						}}
						error={extractGraphqlError([...ERROR_BASE, 'images'], form.errors)}
						readOnly={form.isSubmitting}
						value={images}
					/>
				</Module>
				<Module bottomMargin heading="Pricing">
					<div className="flex gap-6">
						<div className="w-1/2 mb-8">
							<CurrencyField
								currency={form.values.price.currency}
								error={extractGraphqlError('price', form.errors, true)}
								label="Price"
								onChange={(amount: any) => {
									form.setFieldValue('price.amount', amount)
								}}
								readOnly={form.isSubmitting}
								value={form.values.price.amount}
							/>
						</div>
						<div className="w-1/2">
							<CurrencyField
								currency={baseCurrency.code}
								error={extractGraphqlError('salePrice', form.errors, true)}
								label="Sale price"
								onChange={(amount: any) => {
									if (isNil(amount)) {
										form.setFieldValue('salePrice', null);
									} else {
										form.setFieldValue('salePrice', {
											currency: baseCurrency.code,
											amount
										});
									}
								}}
								readOnly={form.isSubmitting}
								value={form.values.salePrice?.amount}
							/>
						</div>
					</div>
					<CheckboxField
						name="onSale"
						label="On sale"
						helpText="Enable to use the sale price when displaying the product and calculating cart totals."
						checked={form.values.onSale}
						onChange={(checked: boolean) =>
							form.setFieldValue('onSale', checked)
						}
						error={extractGraphqlError(
							['onSale'],
							form.errors
						)}
						readOnly={form.isSubmitting}
					/>
				</Module>
				<Module bottomMargin heading="Inventory">
					<TextField
						error={extractGraphqlError([...ERROR_BASE, 'sku'], form.errors)}
						label="SKU"
						name="sku"
						onChange={(e: any) => form.setFieldValue('sku', e.target.value)}
						readOnly={form.isSubmitting}
						value={form.values.sku}
					/>
					<CheckboxField
						name="trackInventory"
						label="Track inventory"
						checked={form.values.inventoryItem.isTracked}
						onChange={(checked: boolean) =>
							form.setFieldValue('inventoryItem.isTracked', checked)
						}
						disabled={form.isSubmitting}
					/>
					{mode === EDITOR_MODE_NEW && form.values.inventoryItem.isTracked && (
						<TextField
							label="Quantity available"
							name="available"
							value={form.values.inventoryItem.available}
							onChange={(e: any) =>
								form.setFieldValue(
									'inventoryItem.available',
									parseInt(e.target.value, 10)
								)
							}
							error={extractGraphqlError(
								['variants', 'inventoryItem', 'available'],
								form.errors
							)}
							type="number"
							max="5000"
							min="0"
							readOnly={form.isSubmitting}
						/>
					)}
					{mode === EDITOR_MODE_EDIT && form.values.inventoryItem.isTracked && (
						<InventoryQuantityInput
							error={extractGraphqlError(
								[...ERROR_BASE, 'inventoryItem', 'available'],
								form.errors
							)}
							inventoryItem={{
								...form.values.inventoryItem,
								productName: product.name,
								variantOptions: form.values.options,
							}}
							onUpdate={(ii) =>
								form.setFieldValue('inventoryItem.available', ii.available)
							}
							disabled={form.isSubmitting}
						/>
					)}
				</Module>
				<Module bottomMargin heading="Shipping">
					<CheckboxField
						name="requiresShipping"
						label="This product requires shipping."
						helpText="Customers will be required to enter a shipping address and choose a shipping rate at checkout."
						checked={form.values.requiresShipping}
						onChange={(checked: boolean) =>
							form.setFieldValue('requiresShipping', checked)
						}
						error={extractGraphqlError(
							[...ERROR_BASE, 'requiresShipping'],
							form.errors
						)}
						disabled={form.isSubmitting}
					/>
				</Module>
			</div>
		</form>
	);
};
