import React, { useContext, useState } from 'react';
import { Button, ConfirmDialog } from '@platformapp/ui';
import { graphql } from '../api';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { B, P } from '../common/Text';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useHistory } from 'react-router-dom';
import DELETE_COLLECTION from './DeleteCollection.gql';
import DELETE_COMPONENT from './DeleteComponent.gql';
import toast from 'react-hot-toast';

const MUTATIONS = Object.freeze({
	Collection: DELETE_COLLECTION,
	Component: DELETE_COMPONENT,
});

export const DeleteSetting = ({ blueprint }) => {
	const history = useHistory();
	const { generateEnvPath, refresh } = useContext(EnvironmentContext);
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDoingDelete, setIsDoingDelete] = useState(false);
	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					title={`Delete ${blueprint.data.__typename.toLowerCase()}`}
					body={
						<p className="leading-loose">
							Are you sure you want to delete <B>{blueprint.data.name}</B>?
						</p>
					}
					onConfirm={async () => {
						setIsDoingDelete(true);
						try {
							const res = await graphql({
								query: MUTATIONS[blueprint.data.__typename],
								variables: {
									input: {
										id: blueprint.data.id,
									},
								},
							});
							if (res.data.data.deleteRes.userErrors.length > 0) {
								toast.error(res.data.data.deleteRes.userErrors[0].message);
								setIsDoingDelete(false);
							} else if (res.data.data.deleteRes.errors) {
								toast.error(`${blueprint.data.__typename} deletion failed`);
								setIsDoingDelete(false);
							} else {
								refresh();
								const path =
									blueprint.data.__typename === 'Collection'
										? 'schema/collections'
										: 'schema/components';
								history.replace(generateEnvPath(path));
							}
						} catch (err) {
							console.error(err);
							toast.error(`${blueprint.data.__typename} deletion failed`);
						}
					}}
					onCancel={() => setIsConfirmingDelete(false)}
					dangerous
					isWorking={isDoingDelete}
					confirmText={`Delete ${blueprint.data.__typename.toLowerCase()}`}
				/>
			)}
			<Fieldset danger>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">
						Delete {blueprint.data.__typename.toLowerCase()}
					</p>
					<P>
						This {blueprint.data.__typename.toLowerCase()} will be permanently
						deleted. This cannot be undone.
					</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						onClick={() => setIsConfirmingDelete(true)}
						submit
						width="large"
						destructive
						disabled={blueprint.isLoading}
					>
						Delete {blueprint.data.__typename.toLowerCase()}
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
