const MDAST_PARAGRAPH = 'paragraph';
const MDAST_HEADING = 'heading';
const MDAST_STRONG = 'strong';
const MDAST_EMPHASIS = 'emphasis';
const MDAST_BLOCKQUOTE = 'blockquote';
const MDAST_LIST = 'list';
const MDAST_LIST_ITEM = 'listItem';
const MDAST_CODE = 'code';
const MDAST_INLINE_CODE = 'inlineCode';
const MDAST_LINK = 'link';
const MDAST_IMAGE = 'image';
const MDAST_HORIZONTAL_RULE = 'thematicBreak';
const MDAST_BREAK = 'break'; // single line break, like in an address

const MDAST_TO_SLATE = Object.freeze({
    paragraph: 'paragraph',
    blockquote: 'block-quote',
    link: 'link',
    heading: {
        1: 'heading-one',
        2: 'heading-two',
        3: 'heading-three',
        4: 'heading-four',
        5: 'heading-five',
        6: 'heading-six',
    }
})

const deserialiseNode = node => {

    let children = [{ text: '' }];

    // Process children first, if node has any
    if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        children = node.children.map(child => deserialiseNode(child));
    }

    switch (node.type) {
        case MDAST_PARAGRAPH:
            return {
                type: 'paragraph',
                children
            }
        case MDAST_HEADING:
            return {
                type: MDAST_TO_SLATE.heading[node.depth],
                children
            }
        case MDAST_IMAGE:
            return {
                type: 'image',
                url: node.url,
                title: node.title,
                alt: node.alt,
                children: [{ text: '' }],
            }
        case MDAST_HORIZONTAL_RULE:
            return {
                type: 'horizontal-rule',
                children: [{ text: '' }],
            }
        case MDAST_BLOCKQUOTE:
            return {
                type: 'block-quote',
                children
            }
        case MDAST_CODE:
            // TODO: MDAST returns `node.value` for code... this should probably be a text
            // array for Slate...
            return {
                type: 'code-block',
                value: node.value,
                children: [{ text: '' }],
            }
        case MDAST_LINK:
            return {
                type: 'link',
                title: node.title,
                url: node.url,
                children
            }
        case MDAST_LIST:
            return {
                type: node.ordered ? 'ordered-list' : 'unordered-list',
                children
            }
        case MDAST_LIST_ITEM:
            return {
                type: 'list-item',
                children
            }
        case MDAST_STRONG:
            return {
                bold: true,
                ...forceLeafNode(children),
                ...persistLeafFormats(children)
            }
        case MDAST_INLINE_CODE:
            return {
                code: true,
                text: node.value
            }
        case MDAST_EMPHASIS:
            return {
                italic: true,
                ...forceLeafNode(children),
                ...persistLeafFormats(children)
            }
        case MDAST_BREAK:
            // Soft breaks are converted to an inline, void element that contains a `br` tag.
            return {
                type: 'break',
                children: [{ text: '' }],
            }
        default:
            return {
                text: node.value || ''
            }
    }

}

const forceLeafNode = children => ({
    text: children.map(c => c.text || '').join('')
})

const persistLeafFormats = children => {
    return children.reduce((acc, node) => {
      Object.keys(node).forEach(function (key) {
        if (key === 'children' || key === 'type' || key === 'text') {
            return;
        }
        acc[key] = node[key];
      });
  
      return acc;
    }, {});
}

export const deserialise = mdast => mdast.children.map(c => deserialiseNode(c));