import { Collection } from "./Collection";
import { Component } from "./Component";
import { Enumeration } from "./Enumeration";

export enum BasicAttributeType {
    Text = 'TEXT',
    Number = 'NUMBER',
    Markdown = 'MARKDOWN',
    LongText = 'LONG_TEXT',
    Date = 'DATE',
    Datetime = 'DATETIME',
    Boolean = 'BOOLEAN',
    Money = 'MONEY',
    Asset = 'ASSET',
}

export type Attribute = {
    id: string;
    apiId: string;
    __typename: 'BasicAttribute';
    type: BasicAttributeType;
    isList: boolean;
    isEditable: boolean;
    isSystem: boolean;
    isRequired: boolean;
    name: string;
} | {
    id: string;
    apiId: string;
    __typename: 'ComponentAttribute';
    isList: boolean;
    isEditable: boolean;
    isSystem: boolean;
    isRequired: boolean;
    name: string;
    allowedComponents: Component[];
} | {
    id: string;
    apiId: string;
    __typename: 'UnidirectionalReferenceAttribute' | 'BidirectionalReferenceAttribute';
    isList: boolean;
    isEditable: boolean;
    isSystem: boolean;
    isRequired: boolean;
    name: string;
    referencedCollection: Collection;
} | {
    id: string;
    apiId: string;
    __typename: 'EnumerationAttribute';
    isList: boolean;
    isEditable: boolean;
    isSystem: boolean;
    isRequired: boolean;
    name: string;
    enumeration: Enumeration;
}