import { Node, Transforms, Range } from 'slate'

const BREAK_OUT_TYPES = [
    'heading-one',
    'heading-two',
    'heading-three',
    'heading-four',
    'heading-five',
    'heading-six',
]

const BREAK_OUT_TYPE = 'paragraph';

export const withBreakOut = editor => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const parentNode = Node.parent(editor, selection.focus.path);

            if (BREAK_OUT_TYPES.includes(parentNode.type)) {
                // If the previous node is of the type that we need to break out from,
                // insert a new node.
                console.log('Breaking out to paragraph element.')
                Transforms.insertNodes(editor, {
                    type: BREAK_OUT_TYPE,
                    children: [
                        { text: '' }
                    ]
                })
                return;
            }
        }

        insertBreak();
    }

    return editor
}