import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GET_NODE from './GetNode.gql';
import { graphql } from '../api';
import { Loader } from '@platformapp/ui';
import { pathOr } from 'ramda';
import { NotFound } from '../NotFound';
import { Link } from '../common/Link';

const generatePath = (node) => {
	if (node.__typename === 'Order') {
		return [
			'',
			node.environment.project.team.slug,
			node.environment.project.id,
			'orders',
			node.id,
		].join('/');
	}
	return null;
};

export const DeepLink = () => {
	const history = useHistory();
	const { id } = useParams();
	const [notFound, setNotFound] = useState(false);

	useEffect(() => {
		(async () => {
			const res = await graphql({
				query: GET_NODE,
				variables: {
					id,
				},
			});

			const item = pathOr(null, ['data', 'data', 'node'], res);

			if (!item) {
				setNotFound(true);
				return;
			}

			const path = generatePath(item);

			if (path) {
				history.push(path);
			} else {
				setNotFound(true);
			}
		})();
	}, []);

	if (notFound) {
		return (
			<div className="mx-auto">
				<NotFound
					header={`Resource not found`}
					body={
						<p>
							Resource {id} could not be found or you do not have access to it.
							Try going to the <Link to="/">home page</Link>
						</p>
					}
				/>
			</div>
		);
	}

	return <Loader />;
};
