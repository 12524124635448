import React from 'react';
import { InfoDialog } from '../common/InfoDialog';
import { TextField } from '@platformapp/ui';

export const ApiKeySecretDialog = ({ onConfirm, secret }) => (
	<InfoDialog onConfirm={onConfirm} title="API key created">
		<p className="mb-4 leading-normal">
			Your API key is shown below. This will only be shown once, so make sure to
			copy it and save it securely:
		</p>
		<TextField
			label="API key secret"
			value={secret}
			readOnly
			showCopy
			selectOnFocus
		/>
	</InfoDialog>
);
