import api from "../api";
import CREATE_UPLOAD_MUTATION from './CreateUpload.gql';

export const upload = (file, projectId, env) => api.post(`content/${projectId}/${env}`, {
	query: CREATE_UPLOAD_MUTATION,
	variables: {
		input: {
			mimeType: file.type,
			// name: file.name,
			size: file.size
		}
	}
}).then(res => {
	const uploadSession = res.data.data.createUpload.upload;

	const formData = new FormData();
	for (const {key, value} of uploadSession.uploadFields) {
		formData.append(key, value);
	}

	// Actual file has to be appended last.
	formData.append("file", file);

	// Upload the file body to S3
	const config = {
		headers: {
			"Content-Type": file.type
		}
	};

	return new Promise((resolve, reject) => {
		// Perform the file upload
		api.post(uploadSession.uploadUrl, formData, config)
			.then(() => {
				resolve(uploadSession);
			}).catch(reject);
	});
});