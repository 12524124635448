import { ContentListContainer } from '../content-list/ContentListContainer';
import React, { useContext } from 'react';
import { ProductsSidebar } from './ProductsSidebar';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useEnvironmentTitle } from '../hooks/useTitle';

export const ProductList = () => {
	useEnvironmentTitle('Products');
	const { collectionByApiId } = useContext(EnvironmentContext);
	return (
		<MainContentContainer>
			<MainContentNav>
				<ProductsSidebar />
			</MainContentNav>
			<MainContent>
				<ContentListContainer blueprint={collectionByApiId('Product')} />
			</MainContent>
		</MainContentContainer>
	);
};
