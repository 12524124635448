import React from 'react';
import { ActionList, Button, Icon } from '@platformapp/ui';
import Kebab from '../icons/kebab-horizontal.svg';

export const NewContextMenu = ({
	disabled,
	styleOnHover,
	smallTrigger,
	...props
}) => (
	<ActionList
		trigger={
			<Button
				className="text-gray-700"
				disabled={disabled}
				height={smallTrigger ? 'small' : 'normal'}
				styleOnHover={styleOnHover}
				tabIndex="0"
				width="small"
			>
				<Icon source={Kebab} />
			</Button>
		}
		placement="bottom-end"
		{...props}
	/>
);

NewContextMenu.defaultProps = {
	disabled: false,
	smallTrigger: false,
	styleOnHover: false,
};
