import { ASSET_FILTER_IMAGE } from "../constants";
import { assetTypeFromMime } from "../util";
import { FileThumbnail } from "./FileThumbnail";
//@ts-ignore
import { Image } from '@platformapp/ui';

interface ThumbnailProps {
    asset: any;
}

const Thumbnail = ({ asset }: ThumbnailProps) => {
	const type = assetTypeFromMime(asset.mimeType);
	return (
		<div className="w-12 h-12 flex-shrink-0">
			{type === ASSET_FILTER_IMAGE && (
				<div
					className={`bg-gray-100 overflow-hidden focus:outline-none focus:shadow-outline flex-shrink-0`}
				>
					<Image src={asset.thumbnailUrl} />
				</div>
			)}
			{type !== ASSET_FILTER_IMAGE && (
				<FileThumbnail asset={asset} className="w-8 h-10" fixedWidth small />
			)}
		</div>
	);
};

interface AssetTitleProps {
    node: any;
}

export const AssetTitle = ({
    node,
}: AssetTitleProps) => (
	<div className="flex items-center">
		<Thumbnail asset={node} />
	</div>
)