export const NAMED_ROUTES: {[key: string]: string} = Object.freeze({
	'/:teamSlug/:projectId/api-keys': 'apiKeys',
	'/:teamSlug/:projectId/env/:envSlug/api-keys': 'apiKeys',
	'/:teamSlug/:projectId/assets': 'assets',
	'/:teamSlug/:projectId/env/:envSlug/assets': 'assets',
	'/:teamSlug/:projectId/developers': 'developers',
	'/:teamSlug/:projectId/env/:envSlug/developers': 'developers',
	'/:teamSlug/:projectId/apps': 'apps',
	'/:teamSlug/:projectId/env/:envSlug/apps': 'apps',
	'/:teamSlug/:projectId/inventory': 'inventory',
	'/:teamSlug/:projectId/env/:envSlug/inventory': 'inventory',
	'/:teamSlug/:projectId/orders': 'orders',
	'/:teamSlug/:projectId/env/:envSlug/orders': 'orders',
	'/:teamSlug/:projectId/schema/collections': 'collections',
	'/:teamSlug/:projectId/env/:envSlug/schema/collections': 'collections',
	'/:teamSlug/:projectId/shipping-zones': 'shipping',
	'/:teamSlug/:projectId/env/:envSlug/shipping-zones': 'shipping',
	'/:teamSlug/:projectId/webhooks': 'webhooks',
	'/:teamSlug/:projectId/env/:envSlug/webhooks': 'webhooks',
});

export type RouteMapping = {
	default: string;
	env: string;
}

export const SWAPPABLE_ROUTES: {[key: string]: RouteMapping} = Object.freeze({
	apiKeys: {
		default: '/:teamSlug/:projectId/api-keys',
		env: '/:teamSlug/:projectId/env/:envSlug/api-keys',
	},
	assets: {
		default: '/:teamSlug/:projectId/assets',
		env: '/:teamSlug/:projectId/env/:envSlug/assets',
	},
	developers: {
		default: '/:teamSlug/:projectId/developers',
		env: '/:teamSlug/:projectId/env/:envSlug/developers',
	},
	apps: {
		default: '/:teamSlug/:projectId/apps',
		env: '/:teamSlug/:projectId/env/:envSlug/apps',
	},
	inventory: {
		default: '/:teamSlug/:projectId/inventory',
		env: '/:teamSlug/:projectId/env/:envSlug/inventory',
	},
	orders: {
		default: '/:teamSlug/:projectId/orders',
		env: '/:teamSlug/:projectId/env/:envSlug/orders',
	},
	collections: {
		default: '/:teamSlug/:projectId/schema/collections',
		env: '/:teamSlug/:projectId/env/:envSlug/schema/collections',
	},
	shipping: {
		default: '/:teamSlug/:projectId/shipping-zones',
		env: '/:teamSlug/:projectId/env/:envSlug/shipping-zones',
	},
	webhooks: {
		default: '/:teamSlug/:projectId/webhooks',
		env: '/:teamSlug/:projectId/env/:envSlug/webhooks',
	},
});

export const DEFAULT_ROUTE: RouteMapping = Object.freeze({
	default: '/:teamSlug/:projectId',
	env: '/:teamSlug/:projectId/env/:envSlug',
});