import React, { useContext, useState } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { EditVariantForm } from './EditVariantForm';
import UPDATE_VARIANT from './UpdateVariant.gql';
import CREATE_VARIANT from './create-variant.gql';
import UPDATE_INVENTORY_ITEM from './UpdateInventoryItem.gql';
import { ModalStackLayer } from '../stacks/ModalStackLayer';
import { useFormik } from 'formik';
import { DiscardChangesDialog } from '../common/DiscardChangesDialog';
import { EDITOR_MODE_EDIT, EDITOR_MODE_NEW } from '../constants';
import { getValuesForSave } from '../util/AttributeHelpers';
import { validateAttributeValues, validateVariant } from '../util/validation';
import { mergeDeepRight, omit } from 'ramda';
import { TableSkeleton } from '../common/Skeleton';
import toast from 'react-hot-toast';

export const EditVariantLayer = ({
	initialValues,
	isLoading,
	item,
	onCancel,
	onSave,
	product,
	productId,
	productOptions,
	variantId,
}) => {
	const { collectionByApiId, contentApiRequest } = useContext(
		EnvironmentContext
	);
	const [isConfirmingClose, setIsConfirmingClose] = useState(false);
	const isNew = !variantId;
	const variantBp = collectionByApiId('ProductVariant');

	const form = useFormik({
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues,
		initialErrors: [],
		validate: (values) => {
			const errors = [
				...validateAttributeValues(
					getValuesForSave(values, variantBp.node),
					variantBp.node
				),
				...validateVariant(values),
			];

			if (errors.length !== 0) {
				console.info(errors);
				toast.error('Some fields are invalid; correct these and save again.');
			}

			return errors;
		},
		onSubmit: async (values, { setErrors }) => {
			const res = await contentApiRequest({
				query: isNew ? CREATE_VARIANT : UPDATE_VARIANT,
				variables: {
					input: {
						...omit(['product'], getValuesForSave(values, variantBp.node)),
						...(isNew
							? {
									inventoryItem: values.inventoryItem,
									product: {
										link: productId,
									},
							  }
							: {
									id: variantId,
							  }),
					},
				},
			});
			const data = res.data.data;
			if (data.variantMutation.node) {
				if (
					!isNew &&
					values.inventoryItem.isTracked !==
						initialValues.inventoryItem.isTracked
				) {
					const invRes = await contentApiRequest({
						query: UPDATE_INVENTORY_ITEM,
						variables: {
							id: values.inventoryItem.id,
							isTracked: values.inventoryItem.isTracked,
						},
					});

					onSave(
						mergeDeepRight(data.variantMutation.node, {
							inventoryItem:
								invRes.data.data.updateInventoryItems.inventoryItems[0],
						})
					);
				} else {
					onSave(data.variantMutation.node);
				}
			} else if (data.variantMutation.userErrors.length > 0) {
				setErrors(data.variantMutation.userErrors);
			}
		},
	});

	const handleCancel = () => {
		if (form.dirty) {
			setIsConfirmingClose(true);
		} else {
			onCancel();
		}
	};

	const readyToDisplay =
		!isLoading && form.values && Object.keys(form.values).length > 0;

	return (
		<>
			{isConfirmingClose && (
				<DiscardChangesDialog
					onConfirm={() => onCancel()}
					onCancel={() => setIsConfirmingClose(false)}
				/>
			)}
			<ModalStackLayer onRequestClose={handleCancel}>
				{!readyToDisplay && (
					<div className="p-10">
						<TableSkeleton />
					</div>
				)}
				{readyToDisplay && (
					<EditVariantForm
						item={item}
						form={form}
						mode={variantId ? EDITOR_MODE_EDIT : EDITOR_MODE_NEW}
						onCancel={handleCancel}
						product={product}
						productOptions={productOptions}
						title={initialValues.options.join(' / ')}
					/>
				)}
			</ModalStackLayer>
		</>
	);
};

EditVariantLayer.defaultProps = {
	item: {},
	productOptions: [],
	variantId: null,
};
