import React, { useState } from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import { EditEnumModal } from './EditEnumModal';
import Helpers from '../Helpers';
import DELETE_ENUM_MUTATION from './DeleteEnum.gql';
import { ConfirmDialog } from '@platformapp/ui';
import { graphql } from '../api';
import { B } from '../common/Text';
import toast from 'react-hot-toast';

export const EnumContextMenu = ({ enumItem, onDelete, onUpdate }) => {
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDoingDelete, setIsDoingDelete] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleDeleteEnum = () => {
		setIsDoingDelete(true);

		graphql({
			query: DELETE_ENUM_MUTATION,
			variables: {
				id: enumItem.id,
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteEnumeration;

				if (payload.userErrors.length || payload.errors) {
					toast.error(payload.userErrors[0].message);
					setIsDoingDelete(false);
					setIsDeleting(false);
				} else {
					onDelete(enumItem.id);
				}
			})
			.catch((err) => {
				console.error(err);
				toast.error('Error deleting enumeration');
				setIsDoingDelete(false);
			});
	};

	return (
		<>
			{isDeleting && (
				<ConfirmDialog
					title="Delete enumeration"
					body={
						<>
							<p className="leading-loose">
								Are you sure you want to delete <B>{enumItem.name}</B>?
							</p>
							<p className="leading-loose">
								Any data stored for this enumeration will be permanently
								removed.
							</p>
						</>
					}
					onConfirm={handleDeleteEnum}
					onCancel={() => setIsDeleting(false)}
					dangerous
					isWorking={isDoingDelete}
					confirmText="Delete enumeration"
				/>
			)}

			{isEditing && (
				<EditEnumModal
					existingEnum={enumItem}
					onCancel={() => setIsEditing(false)}
					onSave={(item) => {
						onUpdate(item);
						setIsEditing(false);
					}}
				/>
			)}

			<NewContextMenu
				styleOnHover
				sections={[
					{
						items: [
							{
								label: Helpers.t('general.edit'),
								onAction: () => setIsEditing(true),
							},
						],
					},
					{
						items: [
							{
								label: 'Delete enumeration',
								onAction: () => setIsDeleting(true),
								destructive: true,
							},
						],
					},
				]}
			/>
		</>
	);
};
