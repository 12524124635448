import React, { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Sidebar, SidebarLink } from '../common/Sidebar';

export const DevelopersSidebar = () => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<Sidebar title="Developers">
			<SidebarLink to={generateEnvPath('developers')}>API access</SidebarLink>
			<SidebarLink to={generateEnvPath('api-keys')}>API keys</SidebarLink>
			<SidebarLink to={generateEnvPath('webhooks')}>Webhooks</SidebarLink>
		</Sidebar>
	);
};
