import React, { useContext } from 'react';
import { MarkdownEditor } from '../markdown/MarkdownEditor';
import { DateTimeField, TextField, CheckboxField } from '@platformapp/ui';
import { ComponentListAttribute } from '../attributes/ComponentListAttribute';
import { ComponentAttribute } from '../attributes/ComponentAttribute';
import { EnumAttribute } from '../attributes/EnumAttribute';
import {
	TYPE_NUMBER,
	TYPE_TEXT,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_DATE,
	TYPE_ASSET,
	TYPE_DATETIME,
	TYPE_BOOLEAN,
	ATTR_EXTENSION_KEY,
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_BIREF,
	METADATA_ATTR_HELP_TEXT,
} from '../constants';
import { extractGraphqlError } from '../util';
import { concat, isEmpty } from 'ramda';
import { ReferenceAttribute } from '../attributes/ReferenceAttribute';
import { TextList } from '../common/TextList';
import { getMetadataValue } from '../util/metadata';
import { ExtensionsContext } from '../extensions/ExtensionsContext';
import { ExtensionAttributeEditor } from './ExtensionAttributeEditor';
import { AssetAttribute } from '../attributes/AssetAttribute';
import { AttributeEditorContext } from './AttributeEditorContext';

export const AttributeInput = ({
	attribute,
	value,
	readOnly,
	onChange,
	validationErrors,
	isFirst,
	pathPrefix,
}) => {
	const { findInstallationForExtension } = useContext(ExtensionsContext);
	const { initialValues } = useContext(AttributeEditorContext);
	const attrPath = concat(pathPrefix, [attribute.apiId]);
	const error = extractGraphqlError(attrPath, validationErrors);
	const helpText = getMetadataValue(
		attribute.metadata,
		METADATA_ATTR_HELP_TEXT
	);

	// Check if this should be rendered with an attribute extension
	const attrExtId = getMetadataValue(attribute.metadata, ATTR_EXTENSION_KEY);
	if (attrExtId) {
		const inst = findInstallationForExtension(attrExtId);
		if (inst) {
			const hasPoint = inst.extension.points.some(
				(p) =>
					p.__typename === 'AttributePoint' &&
					p.attributeType === attribute.type
			);
			if (hasPoint) {
				return (
					<ExtensionAttributeEditor
						installation={inst}
						onChange={onChange}
						readOnly={readOnly}
						value={value}
						helpText={helpText}
					/>
				);
			}
		}
	}

	switch (attribute.__typename) {
		case ATTRIBUTE_TYPENAME_BASIC: {
			switch (attribute.type) {
				case TYPE_TEXT:
				case TYPE_LONG_TEXT:
					if (attribute.isList) {
						return (
							<TextList
								value={value}
								maxLength={250}
								onChange={(e) => onChange(e)}
								readOnly={readOnly}
								validationErrors={validationErrors}
								helpText={helpText}
								error={error}
							/>
						);
					}
					return (
						<TextField
							type="text"
							value={value === null ? '' : value}
							readOnly={readOnly}
							onChange={(e) => onChange(e.target.value)}
							multiline={attribute.type === TYPE_LONG_TEXT}
							showCharacterCount={attribute.type === TYPE_TEXT}
							maxChars={250}
							autoFocus={isFirst}
							error={error}
							helpText={helpText}
						/>
					);
				case TYPE_NUMBER:
					if (attribute.isList) {
						return (
							<TextList
								value={value}
								maxLength={250}
								onChange={(e) => onChange(e)}
								readOnly={readOnly}
								validationErrors={validationErrors}
								helpText={helpText}
							/>
						);
					}
					return (
						<TextField
							type="number"
							value={value === null ? '' : value}
							readOnly={readOnly}
							onChange={(e) =>
								onChange(
									isEmpty(e.target.value) ? null : parseInt(e.target.value, 10)
								)
							}
							multiline={attribute.type === TYPE_LONG_TEXT}
							showCharacterCount={attribute.type === TYPE_TEXT}
							maxChars={250}
							autoFocus={isFirst}
							error={error}
							helpText={helpText}
						/>
					);
				case TYPE_MARKDOWN:
					return (
						<MarkdownEditor
							value={value === null ? '' : value}
							onChange={(e) => onChange(e)}
							readOnly={readOnly}
							error={error}
							autoFocus={isFirst}
							helpText={helpText}
						/>
					);
				case TYPE_DATE:
				case TYPE_DATETIME:
					return (
						<DateTimeField
							value={value}
							disabled={readOnly}
							onChange={(value) => onChange(value)}
							error={error}
							autoFocus={isFirst}
							hasTime={attribute.type === TYPE_DATETIME}
							helpText={helpText}
						/>
					);
				case TYPE_ASSET:
					return (
						<AssetAttribute
							attribute={attribute}
							error={error}
							onChange={(e) => onChange(e)}
							value={value}
							helpText={helpText}
						/>
					);
				case TYPE_BOOLEAN:
					return (
						<CheckboxField
							checked={value}
							disabled={readOnly}
							error={error}
							autoFocus={isFirst}
							onChange={(val) => onChange(val)}
							helpText={helpText}
							label={attribute.name}
						/>
					);
			}
			break;
		}
		case ATTRIBUTE_TYPENAME_ENUM:
			return (
				<EnumAttribute
					attribute={attribute}
					value={value}
					onChange={(e) => {
						console.log(e);
						onChange(e);
					}}
					readOnly={readOnly}
					error={error}
					helpText={helpText}
				/>
			);
		case ATTRIBUTE_TYPENAME_COMPONENT: {
			if (attribute.isList) {
				return (
					<ComponentListAttribute
						attribute={attribute}
						value={value}
						onChange={(e) => onChange(e)}
						readOnly={readOnly}
						validationErrors={validationErrors}
						pathPrefix={attrPath}
						helpText={helpText}
					/>
				);
			}
			return (
				<ComponentAttribute
					attribute={attribute}
					initialValue={initialValues[attribute.apiId]}
					value={value}
					readOnly={readOnly}
					onChange={(val) => onChange(val)}
					validationErrors={validationErrors}
					pathPrefix={
						value?.__typename ? concat(attrPath, [value.__typename]) : attrPath
					}
					helpText={helpText}
				/>
			);
		}
		case ATTRIBUTE_TYPENAME_UNIREF:
		case ATTRIBUTE_TYPENAME_BIREF:
			return (
				<ReferenceAttribute
					attribute={attribute}
					value={value}
					onChange={(val) => onChange(val)}
					validationErrors={validationErrors}
					pathPrefix={attrPath}
					helpText={helpText}
				/>
			);
	}

	return (
		<p>
			Attributes of type &quot;{attribute.__typename}&quot; cannot currently be
			edited in the Dashboard.
		</p>
	);
};

AttributeInput.defaultProps = {
	readOnly: false,
	isFirst: false,
	pathPrefix: [],
};
