import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateSettings } from './mutations';
import { ApiIdLabel } from '../common/ApiIdLabel';
import toast from 'react-hot-toast';

export const SuccessUrlSetting = ({ settings, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			successUrl: pathOr('', ['successUrl'], settings.data.ecommerceSettings),
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await updateSettings(settings.data.id, values);
				toast.success('Success URL updated');
				onChange(res);
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to update success URL.');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Success URL</p>
					<p className="mb-4">
						This is where customers will be redirected when a cart is paid for
						using web based checkout. The placeholders{' '}
						<ApiIdLabel textToCopy="{ORDER_REFERENCE}">
							{'{ORDER_REFERENCE}'}
						</ApiIdLabel>{' '}
						and <ApiIdLabel textToCopy="{ORDER_ID}">{'{ORDER_ID}'}</ApiIdLabel>{' '}
						can be used.
					</p>
					<TextField
						disabled={settings.isLoading || form.isSubmitting}
						value={form.values.successUrl}
						name="successUrl"
						onChange={form.handleChange}
						error={extractGraphqlError('successUrl', form.errors)}
						placeholder="https://test.com/"
					/>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
