import React, { useMemo, useEffect, useState, useContext } from 'react';
import { graphql } from '../api';
import { formatCurrency, formatShortDate } from '../util';
import { DataTable } from '../table/DataTable';
import { Badge, BillingIcon, LandingMessage } from '@platformapp/ui';
import { Link } from '../common/Link';
import GET_RECEIPTS from './ListInvoices.gql';
import { Fieldset, FieldsetContent } from '../common/Fieldset';
import { InfiniteScrollButton } from '../common/InfiniteScrollButton';
import { concat } from 'ramda';
import { TeamContext } from '../context/TeamContext';
import { TableSkeleton } from '../common/Skeleton';
import { P } from '../common/Text';
import { withErrorBoundary } from '../common/ErrorBoundary';

const PER_PAGE = 10;

const InvoicesComponent = () => {
	const { activeTeam } = useContext(TeamContext);

	const [receipts, setReceipts] = useState({
		isLoading: true,
		isLoadingMore: false,
		edges: [],
	});

	const fetchReceipts = (fetchingMore = false) => {
		setReceipts({
			...receipts,
			isLoadingMore: fetchingMore,
		});
		graphql({
			query: GET_RECEIPTS,
			variables: {
				id: activeTeam.id,
				first: PER_PAGE,
				...(fetchingMore
					? {
							after: receipts.pageInfo.endCursor,
					  }
					: {}),
			},
		}).then((res) => {
			const payload = res.data.data.node.invoices;
			setReceipts({
				isLoading: false,
				isLoadingMore: false,
				edges: concat(receipts.edges, payload.edges),
				pageInfo: payload.pageInfo,
			});
		});
	};

	useEffect(() => {
		if (activeTeam) {
			fetchReceipts();
		}
	}, [activeTeam]);

	const tableDef = useMemo(() => [
		{
			accessor: 'node.period',
			Header: 'Billing period',
			Cell: (m) => (
				<>
					<p>
						{formatShortDate(m.cell.value.start)} -{' '}
						{formatShortDate(m.cell.value.end)}
					</p>
				</>
			),
		},
		{
			accessor: 'node.total',
			Header: 'Amount',
			Cell: (m) => formatCurrency(m.cell.value.amount, m.cell.value.currency),
		},
		{
			accessor: 'node.status',
			Header: 'Status',
			Cell: (m) => (
				<>
					{m.cell.value == 'PAID' && <Badge positive text="Paid" />}
					{m.cell.value != 'PAID' && <Badge negative text="Payment failed" />}
				</>
			),
		},
		{
			id: 'receipt',
			Header: 'Receipt',
			Cell: (m) => (
				<>
					{m.row.original.node.status == 'PAID' && (
						<Link external to={m.row.original.node.pdfUrl}>
							Download
						</Link>
					)}
				</>
			),
		},
	]);

	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Payment history</p>
				<P className="mb-4">Invoices for previous billing periods.</P>

				{receipts.isLoading && <TableSkeleton rows={3} />}

				{!receipts.isLoading && receipts.edges.length === 0 && (
					<div className="fade-in">
						<LandingMessage icon={<BillingIcon />} header="No payments found" />
					</div>
				)}

				{!receipts.isLoading && receipts.edges.length > 0 && (
					<>
						<DataTable
							columns={tableDef}
							data={receipts.edges}
							rowIdPath={['node', 'id']}
						/>
						<InfiniteScrollButton
							disabled={!receipts.pageInfo.hasNextPage}
							loading={receipts.isLoadingMore}
							onClick={() => fetchReceipts(true)}
						>
							Load more
						</InfiniteScrollButton>
					</>
				)}
			</FieldsetContent>
		</Fieldset>
	);
};

export const Invoices = withErrorBoundary(InvoicesComponent);
