import React, { useContext, useState } from 'react';
import { graphql } from '../api';
import { Module } from '../common/Module';
import GET_API_KEYS from './GetApiKeys.gql';
import { Button, DateTime } from '@platformapp/ui';
import { updateListItemById } from '../util';
import { ApiKeyContextMenu } from './ApiKeyContextMenu';
import { ResourceTable } from '../common/ResourceTable';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { TableSkeleton } from '../common/Skeleton';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const ApiKeysList = () => {
	const { environment, generateEnvPath } = useContext(EnvironmentContext);
	useEnvironmentTitle('API keys');

	const [apiKeys, setApiKeys] = useState({
		isLoading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			setApiKeys({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const { data } = await graphql({
					query: GET_API_KEYS,
					variables: {
						envId: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setApiKeys({
					isLoading: false,
					...data.data.node.apiKeys,
				});
			}
		},
		[environment.isLoading]
	);

	return (
		<>
			<Module
				bottomMargin
				heading="API keys"
				subheading="API keys can be used to access environment resources from applications and build tools."
				actions={
					<Button to={generateEnvPath('api-keys/new')}>Add API key</Button>
				}
			>
				{!apiKeys.isLoading && apiKeys.edges.length === 0 && (
					<p>No API keys exist for this project</p>
				)}
				{!apiKeys.isLoading && apiKeys.edges.length > 0 && (
					<ResourceTable
						columns={[
							{
								title: 'Name',
								link: true,
								render: ({ node }) => node.name,
							},
							{
								title: 'Last active',
								link: true,
								render: ({ node }) =>
									node.lastUsedAt ? (
										<DateTime value={node.lastUsedAt} />
									) : (
										'Never'
									),
							},
							{
								className: 'w-12',
								render: ({ node }) => (
									<ApiKeyContextMenu
										apiKey={node}
										onDelete={() => {
											setApiKeys({
												...apiKeys,
												edges: apiKeys.edges.filter(
													({ node: apiKeyInList }) =>
														apiKeyInList.id !== node.id
												),
											});
										}}
										onUpdate={(apiKey) => {
											setApiKeys({
												...apiKeys,
												edges: updateListItemById(
													{
														node: apiKey,
													},
													apiKey.id,
													apiKeys.edges
												),
											});
										}}
									/>
								),
							},
						]}
						data={apiKeys.edges}
						idSource={['node', 'id']}
						rowLink={({ node }) => generateEnvPath(`api-keys/${node.id}`)}
					/>
				)}
				{apiKeys.isLoading && <TableSkeleton rows={2} />}
			</Module>
		</>
	);
};
