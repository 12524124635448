import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { BLUEPRINT_VIEW_METADATA_KEY, VIEW_INBOX } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { hasAttributes } from '../util';
import { getMetadataValue } from '../util/metadata';

export const ContentLanding = () => {
	const { generateEnvPath, collections, environment } = useContext(
		EnvironmentContext
	);
	const contentBps = environment.isLoading
		? []
		: collections
				.filter(
					(node) =>
						getMetadataValue(node.metadata, BLUEPRINT_VIEW_METADATA_KEY) !==
							VIEW_INBOX &&
						!['Asset', 'Product', 'ProductVariant'].includes(node.apiId)
				)
				.filter(hasAttributes);

	if (contentBps.length > 0) {
		return <Redirect to={generateEnvPath(`content/${contentBps[0].id}`)} />;
	}
	return <p>Content</p>;
};
