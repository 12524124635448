import React from 'react';
import { LandingMessage, WarningTriangleIcon } from '@platformapp/ui';
import { Link } from './common/Link';

export const NotFound = ({
	body = (
		<p>
			Sorry, that page could not be found. Try going to the{' '}
			<Link to="/">home page</Link>
		</p>
	),
	header = 'Not found',
}) => (
	<LandingMessage header={header} body={body} icon={<WarningTriangleIcon />} />
);
