import React from 'react';
import { Fieldset, FieldsetContent } from '../common/Fieldset';

export const CurrencySetting = ({ settings }) => {
	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Currencies</p>
				<p className="mb-4">The currencies that products will be sold in.</p>
				<div className="w-full md:w-96">
					{!settings.isLoading &&
						settings.data.ecommerceSettings.currencies.map((c) => (
							<div key={c.code}>
								<p>
									{c.name} ({c.code})
								</p>
							</div>
						))}
				</div>
			</FieldsetContent>
		</Fieldset>
	);
};
