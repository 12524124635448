// @ts-ignore
import { Button } from '@platformapp/ui';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { P } from '../common/Text';
import { DisableMfaModal } from './DisableMfaModal';
import { EnableMfaModal } from './EnableMfaModal';

type Props = {
	updateUser: (updates: any) => void;
	user: any;
}

export const TwoFactorAuthSetting = ({
	updateUser,
	user
}: Props) => {
	const [enabling, setEnabling] = useState(false);
	const [disabling, setDisabling] = useState(false);

	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Two-factor authentication</p>

				{enabling && (<>
					<EnableMfaModal
						onCancel={() => setEnabling(false)}
						onEnabled={() => {
							setEnabling(false);
							toast.success('Two-factor authentication enabled');
							updateUser({
								isMfaEnabled: true
							});
						}}
					/>
				</>)}

				{disabling && (<>
					<DisableMfaModal
						onCancel={() => setDisabling(false)}
						onDisabled={() => {
							setDisabling(false);
							toast.success('Two-factor authentication disabled');
							updateUser({
								isMfaEnabled: false
							});
						}}
					/>
				</>)}

				{!user.isLoading && !user.viewer.isMfaEnabled && <NotEnabled />}

				{!user.isLoading && user.viewer.isMfaEnabled && <P>Two-factor authentication is enabled for your account.</P>}
			</FieldsetContent>
			<FieldsetFooter>
				<Button
					width="large"
					disabled={user.isLoading}
					onClick={() => {
						if (user.viewer.isMfaEnabled) {
							setDisabling(true);
						} else {
							setEnabling(true);
						}
					}}
				>
					{user.isLoading && '...'}
					{!user.isLoading && !user.viewer.isMfaEnabled && 'Enable two-factor authentication'}
					{!user.isLoading && user.viewer.isMfaEnabled && 'Disable two-factor authentication'}
				</Button>
			</FieldsetFooter>
		</Fieldset>
	);
}

const NotEnabled = () => (<>
	<p className="mb-4 mt-6">
		Enable two-factor authentication to add an extra layer of security to
		your account.
	</p>
	<p>
		Use an app like Google Authenticator or 1Password to obtain a
		login code when prompted.
	</p>
</>);