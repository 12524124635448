import React, { useState, useEffect, useContext } from 'react';
import { graphql } from '../api';
import { Loader } from '@platformapp/ui';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Wrapper } from '../common/Wrapper';
import SUB_QUERY from './getSubscription.gql';
import { SubscriptionDelinquent } from './SubscriptionDelinquent';
import { Heading } from '../common/Heading';
import { PlanSetting } from './PlanSetting';
import { PaymentMethodSetting } from './PaymentMethodSetting';
import { FormSubmissionsAddOn } from './FormSubmissionsAddOn';
import { AssetStorageAddOn } from './AssetStorageAddOn';
import { TeamContext } from '../context/TeamContext';
import { mergeDeepRight } from 'ramda';

export const Billing = withErrorBoundary(() => {
	const { activeTeam } = useContext(TeamContext);

	const [subscription, setSubscription] = useState({
		isLoading: true,
		data: null,
	});

	useEffect(() => {
		if (activeTeam) {
			graphql(
				{
					query: SUB_QUERY,
					variables: {
						id: activeTeam.subscriptionId,
					},
				},
				'billing'
			).then(({ data }) => {
				setSubscription({
					isLoading: false,
					data: data.data.node,
				});
			});
		}
	}, [activeTeam]);

	return (
		<Wrapper padVertical className="lg:mr-48">
			{!subscription.isLoading && (
				<>
					<Heading className="mb-6">Billing</Heading>
					<SubscriptionDelinquent subscription={subscription.data} />
					<PlanSetting subscription={subscription.data} />
					<FormSubmissionsAddOn subscription={subscription.data} />
					<AssetStorageAddOn subscription={subscription.data} />
					<PaymentMethodSetting
						paymentMethodUpdated={(sub) =>
							setSubscription({
								isLoading: false,
								data: mergeDeepRight(subscription.data, sub),
							})
						}
						subscription={subscription.data}
					/>
				</>
			)}
			{subscription.isLoading && <Loader />}
		</Wrapper>
	);
});
