import { useContext } from 'react';
// @ts-ignore
import { Button, TextField } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';
import {
	Fieldset,
	FieldsetContent,
	FieldsetFooter,
	FieldsetHeader,
} from '../common/Fieldset';
import { P } from '../common/Text';

export const ContentApiEndpoint = () => {
	const { environment, generateEnvPath } = useContext(EnvironmentContext);
	return (
		// @ts-ignore
		<Fieldset bottomMargin>
			<FieldsetContent>
				<FieldsetHeader
					title="Content API endpoint"
					subtitle="Use this endpoint URL to query your content using the GraphQL Content API."
				/>
				{!environment.isLoading && (
					<TextField
						value={environment.node.contentEndpoint}
						readOnly
						showCopy
						selectOnFocus
					/>
				)}
			</FieldsetContent>
			<FieldsetFooter className="flex items-center">
				<div className="flex-grow">
					<P>You need to use an API key to authenticate Content API requests</P>
				</div>
				<Button to={generateEnvPath('api-keys')}>Manage API keys</Button>
			</FieldsetFooter>
		</Fieldset>
	);
};
