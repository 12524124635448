import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ConfirmDialog } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { P } from '../common/Text';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { deleteApiKey } from './mutations';
import toast from 'react-hot-toast';

export const DeleteSetting = ({ apiKey, disabled }) => {
	const history = useHistory();
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDoingDelete, setIsDoingDelete] = useState(false);
	const { generateEnvPath } = useContext(EnvironmentContext);

	return (
		<>
			{isDeleting && (
				<ConfirmDialog
					title="Delete API key"
					body={
						<>
							<P>Are you sure you want to delete this API key?</P>
							<P className="mt-1">
								Any applications or processes using this API key will no longer
								have access to Platform APIs. This cannot be undone.
							</P>
						</>
					}
					onConfirm={() => {
						setIsDoingDelete(true);
						deleteApiKey(apiKey.id)
							.then(() => {
								toast.success('API key deleted');
								history.push(generateEnvPath('api-keys'));
							})
							.catch((err) => {
								console.log(err);
								toast.error('Error deleting API key');
								setIsDoingDelete(false);
							});
					}}
					onCancel={() => setIsDeleting(false)}
					dangerous
					isWorking={isDoingDelete}
					confirmText="Delete API key"
				/>
			)}
			<Fieldset danger disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Delete API key</p>
					<P>This API key will be permanently deleted.</P>
					<P className="mt-1">
						Any applications or processes using this API key will no longer have
						access to Platform APIs.
					</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						onClick={() => setIsDeleting(true)}
						submit
						width="large"
						destructive
					>
						Delete
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};

DeleteSetting.defaultProps = {
	disabled: false,
};
