import React, { useContext, useState } from 'react';
import { EditVariantLayer } from './EditVariantLayer';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { createAttributeValues } from '../util/AttributeHelpers';
import { useAsyncEffect } from 'use-async-effect';
import { ContentApiContext } from '../context/ContentApiContext';

export const VariantLayer = ({ onCancel, variantId, ...props }) => {
	const { collectionByApiId } = useContext(EnvironmentContext);
	const { getItem } = useContext(ContentApiContext);
	const blueprint = collectionByApiId('ProductVariant');
	const [variant, setVariant] = useState({
		isLoading: true,
		node: null,
	});

	useAsyncEffect(async (isActive) => {
		const variantData = await getItem(blueprint.node.apiId, variantId, {
			additionalNodeFields: [
				{
					inventoryItem: ['id', 'available', 'isTracked'],
				},
			],
			resolveReferences: ['product'],
		});

		if (!isActive()) {
			return;
		}

		setVariant({
			isLoading: false,
			node: variantData,
		});
	}, []);

	return (
		<EditVariantLayer
			{...props}
			initialValues={
				variant.isLoading
					? {}
					: {
							...createAttributeValues(variant.node, blueprint.node),
							inventoryItem: variant.node.inventoryItem,
					  }
			}
			isLoading={variant.isLoading}
			item={variant.node}
			onCancel={onCancel}
			product={variant.isLoading ? null : variant.node.product}
			productOptions={variant.isLoading ? null : variant.node.product.options}
			variantId={variantId}
		/>
	);
};

VariantLayer.defaultProps = {
	variantId: null,
};
