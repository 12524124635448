import React, { useState } from 'react';
import { Button } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { Link } from '../common/Link';
import { TransferProject } from './TransferProject';
import { useHistory } from 'react-router-dom';

export const OwnerSetting = ({ project, onChange }) => {
	const history = useHistory();
	const [isTransferring, setIsTransferring] = useState(false);
	return (
		<>
			{isTransferring && (
				<TransferProject
					onCancel={() => setIsTransferring(false)}
					onUpdate={(data) => {
						setIsTransferring(false);
						onChange({
							team: data.team,
						});
						history.replace(`/${data.team.slug}/${data.id}/settings`);
					}}
					project={project.data}
				/>
			)}
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Project owner</p>
					<p className="mb-4">
						This is the team that the project belongs to, for billing and user
						access purposes.
					</p>
					<div className="flex items-center">
						{project.isLoading && (
							<div className="rounded-full bg-gray-300 w-8 h-8" />
						)}
						{!project.isLoading && (
							<>
								<p>
									<Link to={`/${project.data.team.slug}`}>
										{project.data.team.name}
									</Link>
								</p>
							</>
						)}
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={project.isLoading}
						onClick={() => setIsTransferring(true)}
						width="large"
					>
						Transfer project
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
