import React from 'react';
import Helpers from '../Helpers';
import { longDateTime } from '../util';
import { Module } from '../common/Module';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { formatMoney } from '../util';
import VisaImage from '../images/visa.png';
import AmexImage from '../images/american-express.png';
import MastercardImage from '../images/mastercard.png';
import PayPalImage from '../images/paypal.png';
import { DataTable } from '../common/DataTable';

const TransactionDetails = ({ transaction }) => {
	let imageSource = null;
	switch (transaction.gateway) {
		case 'STRIPE':
			switch (transaction.card.brand) {
				case 'Visa':
					imageSource = VisaImage;
					break;
				case 'American Express':
					imageSource = AmexImage;
					break;
				case 'MasterCard':
					imageSource = MastercardImage;
					break;
			}

			return (
				<p>
					{imageSource !== null && (
						<img
							className="inline-block mr-2 align-top h-5"
							src={imageSource}
						/>
					)}
					{transaction.card.brand} ending in {transaction.card.lastFour}
				</p>
			);
		case 'PAYPAL_EXPRESS':
			return (
				<p>
					<img className="inline-block align-top h-5 mr-2" src={PayPalImage} />
					PayPal Express Checkout
				</p>
			);
	}
};

export const ViewOrderTransactions = ({ order }) => (
	<Module heading="Transactions">
		<DataTable
			columns={[
				{
					heading: 'Type',
				},
				{
					heading: 'Details',
				},
				{
					heading: 'Reference',
				},
				{
					heading: 'Date',
				},
				{
					heading: 'Amount',
					type: 'numeric',
				},
			]}
			rows={order.transactions.edges.map(({ node: txn }) => [
				Helpers.t(`transactions.types.${txn.type}`),
				<TransactionDetails key={1} transaction={txn} />,
				txn.externalId ? <ApiIdLabel>{txn.externalId}</ApiIdLabel> : 'Unknown',
				longDateTime(txn.createdAt),
				formatMoney(txn.amount),
			])}
		/>
	</Module>
);
