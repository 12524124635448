import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { graphql } from '../api';
import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
	TextField,
} from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { withErrorBoundary } from '../common/ErrorBoundary';
import CREATE_ORIGIN from './CreateOrigin.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import toast from 'react-hot-toast';

export const AddOriginModal = withErrorBoundary(({ onCancel, onConfirm }) => {
	const { environment } = useContext(EnvironmentContext);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			origin: '',
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			graphql({
				query: CREATE_ORIGIN,
				variables: {
					input: {
						environmentId: environment.node.id,
						...values,
					},
				},
			})
				.then((res) => {
					if (res.data.data.createCorsOrigin.userErrors.length > 0) {
						setErrors(res.data.data.createCorsOrigin.userErrors);
					} else if (res.data.data.createCorsOrigin.errors) {
						toast.error(`Failed to add origin`);
					} else {
						onConfirm(res.data.data.createCorsOrigin.corsOrigin);
					}
				})
				.catch((err) => {
					console.error(err);
					toast.error(`Failed to add origin`);
				})
				.then(() => setSubmitting(false));
		},
	});

	return (
		<Modal
			title="Add origin URI"
			tiny
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					<TextField
						name="origin"
						label="Origin URI"
						onBlur={form.handleBlur}
						onChange={form.handleChange}
						value={form.values.origin}
						autoFocus
						placeholder="https://"
						error={extractGraphqlError('origin', form.errors, true)}
						disabled={form.isSubmitting}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							Add origin URI
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
});
