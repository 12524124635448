import React, { useContext } from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr, pick } from 'ramda';
import { useFormik } from 'formik';
import { updateTeam as updateTeamMutation } from './mutations';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { TeamsContext } from '../context/TeamsContext';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

export const TeamSlugSetting = ({ team, onChange }) => {
	const history = useHistory();
	const { updateTeam } = useContext(TeamsContext);
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			slug: pathOr('', ['node', 'slug'], team),
		},
		onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
			updateTeamMutation(team.node.id, values)
				.then((updatedTeam) => {
					resetForm({ values: pick(['slug'], updatedTeam) });
					updateTeam(team.node.id, updatedTeam);
					onChange(updatedTeam);
					toast.success('Team URL updated');
					history.replace(`/${updatedTeam.slug}/settings`);
				})
				.catch((error) => {
					if (error.isUserError) {
						setErrors(error.userErrors);
					} else {
						toast.error('Failed to update team URL');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Team URL</p>
					<p className="mb-4 leading-relaxed">
						This is your team URL within Platform. Your team members can access
						projects, configure settings and view billing information at this
						URL.
					</p>
					<div className="w-full md:w-96">
						<TextField
							disabled={team.isLoading || form.isSubmitting}
							error={extractGraphqlError('slug', form.errors)}
							name="slug"
							onChange={form.handleChange}
							prefix="dashboard.platformapp.net/"
							value={form.values.slug}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
