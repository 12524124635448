import React, { useContext, useState } from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { createApiKey, updateApiKey } from './mutations';
import { ApiKeySecretDialog } from './ApiKeySecretDialog';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

export const NameSetting = ({ apiKey }) => {
	const isNew = !apiKey;
	const history = useHistory();
	const { environment, generateEnvPath } = useContext(EnvironmentContext);
	const [createdKey, setCreatedKey] = useState(null);
	const form = useFormik({
		initialValues: {
			name: pathOr('', ['name'], apiKey),
		},
		onSubmit: async (values, { setErrors, resetForm }) => {
			try {
				if (isNew) {
					const res = await createApiKey({
						...values,
						environmentId: environment.node.id,
					});
					setCreatedKey(res);
				} else {
					await updateApiKey(apiKey.id, values);
					toast.success('API key name saved');
					resetForm({
						values,
					});
				}
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error(
						isNew
							? 'Failed to create API key'
							: 'Failed to update API key name.'
					);
				}
			}
		},
	});
	return (
		<>
			{createdKey && (
				<ApiKeySecretDialog
					onConfirm={() =>
						history.push(generateEnvPath(`api-keys/${createdKey.apiKey.id}`))
					}
					secret={createdKey.secret}
				/>
			)}
			<form onSubmit={form.handleSubmit}>
				<Fieldset>
					<FieldsetContent>
						<p className="font-medium text-lg mb-4">API key name</p>
						<p className="mb-4">
							This will help to identify the API key in the Dashboard.
						</p>
						<div className="w-full md:w-96">
							<TextField
								disabled={(!isNew && apiKey.isLoading) || form.isSubmitting}
								value={form.values.name}
								name="name"
								onChange={form.handleChange}
								error={extractGraphqlError('name', form.errors)}
							/>
						</div>
					</FieldsetContent>
					<FieldsetFooter>
						<Button
							disabled={!form.dirty}
							loading={form.isSubmitting}
							submit
							width="large"
						>
							Save
						</Button>
					</FieldsetFooter>
				</Fieldset>
			</form>
		</>
	);
};
