// @ts-ignore
import { Button } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import toast from 'react-hot-toast';
import { EmailList } from '../common/EmailList';
import { updateModel } from '../blueprint-settings/mutations';
import { getMetadataCsv } from '../util/metadata';
import { AsyncNode } from '../types/Node';
import { Collection } from '../types/Collection';
import { CollectionMetadataKeys } from '../constants';

type OrderNotifcationsSettingProps = {
	collection: AsyncNode<Collection>;
	onChange: (data: any) => void;
}

export const NotificationSetting = ({ collection, onChange }: OrderNotifcationsSettingProps) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			emails: getMetadataCsv(collection?.node?.metadata || [], CollectionMetadataKeys.FormNotifications),
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await updateModel(collection.node.id, {
					metadata: [
						{
							key: CollectionMetadataKeys.FormNotifications,
							value: values.emails.length > 0 ? values.emails.join(',') : null
						}
					]
				})
				toast.success('Form updated');
				onChange(res);
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to update form');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Email notifications</p>
					<p className="mb-4">
						These email addresses will receive notifications every time a non-spam submission is received. Spam submissions are still stored, but will not trigger notifications.
					</p>
					<EmailList
						readOnly={collection.isLoading || form.isSubmitting}
						// @ts-ignore
						error={extractGraphqlError('emails', form.errors, true)}
						onChange={val => form.setFieldValue('emails', val)}
						value={form.values.emails}
					/>
				</FieldsetContent>
				{/* @ts-ignore */}
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
