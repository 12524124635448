import { graphql } from '../api';
import DELETE_HOOK_MUTATION from './deleteHook.gql';
import UPDATE_HOOK_MUTATION from './updateHook.gql';

export const deleteWebhook = (id) =>
	new Promise((resolve, reject) => {
		graphql({
			query: DELETE_HOOK_MUTATION,
			variables: {
				input: {
					webhookId: id,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteWebhook;
				if (payload.userErrors.length) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve();
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});

export const updateWebhook = (id, data) =>
	new Promise((resolve, reject) => {
		graphql({
			query: UPDATE_HOOK_MUTATION,
			variables: {
				input: {
					webhookId: id,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateWebhook;
				if (payload.userErrors.length) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.webhook);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
