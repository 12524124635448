import { lensPath, mergeDeepRight, set } from "ramda"
import { STAGE_DRAFT } from "../constants"
import { ProductVariant } from "../types/Item"

export type Product = {
	__typename: 'Product';
	__title: string;
	id: string;
	name: string;
	options: string[];
	publishedAt: string;
	createdAt: string;
	updatedAt: string;
	variants: ProductVariant[];
	inStages: any[];
}

type ProductState = {
	isLoading: boolean;
	isError: boolean;
	data?: Product;
}

type SetProductAction = {
	type: 'set';
	data: any;
}

type AddVariantAction = {
	type: 'add_variant';
	data: any;
}

type DeleteVariantAction = {
	type: 'delete_variant';
	ids: string[];
}

type UpdateVariantAction = {
	type: 'update_variant';
	data: any[];
}

type UpdateProductAction = {
	type: 'update_product';
	data: any;
}

type UpdateVariantInventoryData = {
    available: number;
    id: string;
}

type UpdateVariantInventoryAction = {
    type: 'inventory_update';
    data: UpdateVariantInventoryData[];
}

type PublishProductAction = {
    type: 'publish'
    data: any[]
}

export type ProductAction = SetProductAction | AddVariantAction | UpdateProductAction | DeleteVariantAction | UpdateVariantAction | UpdateVariantInventoryAction | PublishProductAction;

export type ProductDispatch = (action: ProductAction) => void;

export const reducer = (state: ProductState, action: ProductAction): ProductState => {
	console.log(action);
	switch (action.type) {
		case 'set':
			return action.data;
		case 'update_product':
			return {
				...state,
				data: mergeDeepRight(state.data, action.data)
			}
		case 'add_variant': {
            const now = new Date();

            // Update `updatedAt` on the draft product in `inStages`
            const draftStage = state.data.inStages.find(i => i.stage === STAGE_DRAFT);

			return {
				...state,
				data: {
					...state.data,
                    inStages: [
                        ...state.data.inStages.filter(i => i.stage !== STAGE_DRAFT),
                        set(lensPath(['updatedAt']), now, draftStage)
                    ],
                    updatedAt: now.toISOString(),
					variants: [
						...state.data.variants,
						action.data
					]
				}
			}
        }
		case 'delete_variant': {
            const now = new Date();

            // Update `updatedAt` on the draft product in `inStages`
            const draftStage = state.data.inStages.find(i => i.stage === STAGE_DRAFT);

			return {
				...state,
				data: {
					...state.data,
                    inStages: [
                        ...state.data.inStages.filter(i => i.stage !== STAGE_DRAFT),
                        set(lensPath(['updatedAt']), now, draftStage)
                    ],
                    updatedAt: now.toISOString(),
					variants: state.data.variants.filter(v => !action.ids.includes(v.id))
				}
			}
        }
		case 'update_variant': {
            const newVariants = state.data.variants.map(existingVariant => {
                // Find an updated variant
                const updatedVariant = action.data.find(v => v.id === existingVariant.id);

                if (!updatedVariant) {
                    return existingVariant;
                }

                return mergeDeepRight(existingVariant, updatedVariant);
            })

			return {
				...state,
				data: {
					...state.data,
                    variants: newVariants
				}
			}
        }
        case 'inventory_update': {
            const newVariants = state.data.variants.map(existingVariant => {
                // Find an updated variant
                const updatedInvItem = action.data.find(v => v.id === existingVariant.inventoryItem.id);

                if (!updatedInvItem) {
                    return existingVariant;
                }

                return {
                    ...existingVariant,
                    inventoryItem: {
                        ...existingVariant.inventoryItem,
                        ...updatedInvItem
                    }
                }
            })

			return {
				...state,
				data: {
					...state.data,
                    variants: newVariants
				}
			}
        }
        case 'publish': {
            const product = action.data.find(i => i.id === state.data.id);

            const newProduct = mergeDeepRight(state.data, product);

            // Update each variant
            newProduct.variants = state.data.variants.map(existingVariant => {
                // Find an updated variant
                const updatedVariant = action.data.find(v => v.id === existingVariant.id);

                if (!updatedVariant) {
                    return existingVariant;
                }

                return mergeDeepRight(existingVariant, updatedVariant);
            });

            return {
                ...state,
                data: newProduct
            };
        }
		default:
			return state;
	}
}