import React, { useContext } from 'react';
import { ProjectContext } from '../context/ProjectContext';
import { BLUEPRINT_VIEW_METADATA_KEY, VIEW_INBOX } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { getMetadataValue } from '../util/metadata';
import { Sidebar, SidebarLink } from '../common/Sidebar';
import { hasAttributes } from '../util';

export const ContentSidebar = () => {
	const { generateEnvPath, environment, collections } = useContext(
		EnvironmentContext
	);
	const { isLoading } = useContext(ProjectContext);
	const contentBps = environment.isLoading
		? []
		: collections
				.filter(
					(node) =>
						getMetadataValue(node.metadata, BLUEPRINT_VIEW_METADATA_KEY) !==
							VIEW_INBOX &&
						!['Asset', 'Product', 'ProductVariant'].includes(node.apiId)
				)
				.filter(hasAttributes);
	return (
		<Sidebar title="Content">
			{!isLoading && !environment.isLoading && (
				<>
					{contentBps.map((node) => (
						<SidebarLink
							key={node.id}
							to={generateEnvPath(`content/${node.id}`)}
						>
							{node.name}
						</SidebarLink>
					))}
				</>
			)}
		</Sidebar>
	);
};
