import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Route, Switch } from 'react-router';
import { SettingsSidebar } from './SettingsSidebar';
import { ProjectSettings } from './ProjectSettings';
import { EcommerceSettings } from '../ecommerce-settings/EcommerceSettings';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { PaymentProviders } from '../ecommerce-settings/PaymentProviders';
import { InstalledExtensions } from '../extensions/InstalledExtensions';

export const Settings = withErrorBoundary(() => (
	<MainContentContainer>
		<MainContentNav>
			<SettingsSidebar />
		</MainContentNav>
		<MainContent>
			<Switch>
				<Route
					exact
					path="/:teamSlug/:projectId/settings"
					component={ProjectSettings}
				/>
				<Route
					exact
					path={[
						'/:teamSlug/:projectId/settings/ecommerce',
						'/:teamSlug/:projectId/env/:envSlug/settings/ecommerce',
					]}
					component={EcommerceSettings}
				/>
				<Route
					exact
					path={[
						'/:teamSlug/:projectId/settings/payment-providers',
						'/:teamSlug/:projectId/env/:envSlug/settings/payment-providers',
					]}
					component={PaymentProviders}
				/>
				<Route
					exact
					path={[
						'/:teamSlug/:projectId/settings/apps',
						'/:teamSlug/:projectId/env/:envSlug/settings/apps',
					]}
					component={InstalledExtensions}
				/>
			</Switch>
		</MainContent>
	</MainContentContainer>
));
