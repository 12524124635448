import React, { useState, useEffect, useContext } from 'react';
import { extractGraphqlError, stripExtension } from '../util';
import { Wrapper } from '../common/Wrapper';
import { ModalStackLayer } from '../stacks/ModalStackLayer';
import { useFormik } from 'formik';
import { has, isNil, isEmpty, mergeDeepRight } from 'ramda';
import { STATUS_DRAFT } from '../constants';
import { AssetDropzoneField } from './AssetDropzoneField';
import { Preview } from './AssetPreview';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { getValuesForSave } from '../util/AttributeHelpers';
import { generateContentNodeQuery } from '../content/Mutations';
import { EditAssetHeader } from './EditAssetHeader';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ContentApiContext } from '../context/ContentApiContext';
import toast from 'react-hot-toast';

export const AssetDetailsLayer = ({
	onCancel,
	onSave,
	assetId,
	defaultAction,
}) => {
	const { environment, collectionByApiId, contentApiRequest } = useContext(
		EnvironmentContext
	);
	const { createItem, updateItem, publishItem } = useContext(ContentApiContext);
	const isNew = isNil(assetId);
	const assetCollection = collectionByApiId('Asset');

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		enableReinitialize: true,
		initialValues: {},
		initialStatus: {
			isAutoTitle: isNew,
			isPublish: false,
			isUnpublish: false,
		},
		validate: (values) => {
			const errors = [];
			if (isNil(values.uploadId) && isNew) {
				errors.push({
					path: ['input', 'uploadId'],
					message: 'Required',
				});
			}
			if (isNil(values.title) || isEmpty(values.title)) {
				errors.push({
					path: ['input', 'title'],
					message: 'Required',
				});
			}
			return errors;
		},
		onSubmit: async (values, { setErrors }) => {
			const input = getValuesForSave(values, assetCollection.node);

			if (has('uploadId', values)) {
				input.uploadId = values.uploadId;
				input.filename = values.filename;
			}

			if (!isNew) {
				input.id = assetId;
			}

			try {
				const res = await (isNew ? createItem : updateItem)('Asset', { input });

				if (res.userErrors.length) {
					setErrors(res.userErrors);
					toast.error('Some fields are invalid; correct these and save again.');
				} else if (res.errors) {
					toast.error('Failed to update asset');
				} else {
					if (form.status.isPublish) {
						try {
							const publishRes = await publishItem(res.node.id);
							onSave(
								mergeDeepRight(
									res.node,
									publishRes.find((n) => n.id === res.node.id)
								)
							);
						} catch (err) {
							console.error(err);
							toast.error('Asset saved but publishing failed');
						}
					} else {
						onSave(res.node);
					}
				}
			} catch (err) {
				console.error(err);
				toast.error(
					isNew ? 'Failed to create asset' : 'Failed to update asset'
				);
			}
		},
	});

	useEffect(() => {
		if (!isNew && !environment.isLoading) {
			contentApiRequest({
				query: generateContentNodeQuery(assetCollection.node, [
					'filename',
					'mimeType',
					'url',
				]),
				variables: {
					id: assetId,
				},
			}).then((res) => {
				setAsset({
					isLoading: false,
					data: res.data.data.node,
				});
				form.setValues(res.data.data.node);
			});
		}
	}, [environment]);

	const [asset, setAsset] = useState({
		isLoading: !isNew,
		data: {
			title: '',
			description: '',
			status: STATUS_DRAFT,
		},
	});

	return (
		<ModalStackLayer onRequestClose={onCancel}>
			<div className="flex flex-col h-full">
				<EditAssetHeader
					asset={asset}
					form={form}
					isNew={isNew}
					onCancel={onCancel}
					defaultAction={defaultAction}
				/>
				<div className={`flex-grow overflow-scroll ${isNew ? '' : 'md:flex'}`}>
					{!isNew && (
						<div className="md:w-2/3 bg-gray-100 border-b md:border-b-0 md:border-r border-gray-300 flex items-center justify-center">
							{!asset.isLoading && <Preview asset={asset.data} />}
						</div>
					)}
					{!asset.isLoading && !assetCollection.isLoading && (
						<Wrapper
							className={`${
								isNew ? '' : 'md:w-1/3 '
							}py-6 fade-in overflow-y-auto`}
						>
							<AssetDropzoneField
								label="File"
								name="file"
								disabled={form.isSubmitting}
								onUpload={(uploadSession, file) => {
									if (form.status.isAutoTitle) {
										form.setValues({
											...form.values,
											uploadId: uploadSession.id,
											// TODO: get the actual title attribute
											title: stripExtension(file.name),
											filename: file.name,
										});
									} else {
										form.setValues({
											...form.values,
											uploadId: uploadSession.id,
											filename: file.name,
										});
									}
								}}
								onError={() => form.setFieldValue('uploadId', null)}
								error={extractGraphqlError('uploadId', form.errors)}
							/>
							<AttributeEditor
								readOnly={form.isSubmitting}
								attributes={assetCollection.node.attributes.filter(
									(a) => a.isEditable
								)}
								values={form.values}
								onAttributeChange={(apiId, val) =>
									form.setFieldValue(apiId, val)
								}
								pathPrefix={['input']}
								validationErrors={form.errors}
								isNew={isNew}
								// TODO: set this
								// initialValues={}
							/>
						</Wrapper>
					)}
				</div>
			</div>
		</ModalStackLayer>
	);
};

AssetDetailsLayer.defaultProps = {
	assetId: null,
	onCancel: null,
};
