import { TopNavLink } from '../common/PrimaryNav';
import React from 'react';

export const UserNav = () => (
	<>
		<TopNavLink to="/account">Settings</TopNavLink>
		{/* <TopNavLink to="/applications" exact>
			Applications
		</TopNavLink> */}
		{/* <TopNavLink to="/extensions" exact>
			Extensions
		</TopNavLink> */}
	</>
);
