import { useState } from "react"
import { ExtensionLayer } from "../extensions/ExtensionLayer";
import { AppList } from "./AppList";

export const AppDock = () => {
    const [active, setActive] = useState(null);
    const [visible, setVisible] = useState(false);
	return (
        <>
            <div className="fixed bottom-0 right-0 border-l border-l-gray-300 w-12 flex flex-col justify-center items-center bg-white gap-3" style={{top: "88px"}}>
                <button
                    className="w-8 h-8 rounded border border-gray-300 bg-indigo-100"
                    onClick={() => setVisible(!visible)}
                >
                    +
                </button>
                <AppList
                    onSelect={setActive}
                />
            </div>
            {active && (
                <ExtensionLayer
                    installation={active}
                    onCancel={() => setActive(null)}
                />
            )}
            {/* {visible && (
                <div className="fixed right-12 bottom-0 border-l border-l-gray-300 bg-white w-80" style={{top: "88px"}}>
                    <div className="p-4 border-b border-b-gray-300">
                        <p className="font-medium">Digital Downloads</p>
                    </div>
                    <div>
                        <p>iframe goes here</p>
                    </div>
                </div>
            )} */}
        </>
	)
}