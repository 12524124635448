import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr, pick } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateModel } from './mutations';
import toast from 'react-hot-toast';

export const ApiIdsSetting = ({ blueprint, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			apiId: pathOr('', ['data', 'apiId'], blueprint),
			apiIdPlural: pathOr('', ['data', 'apiIdPlural'], blueprint),
		},
		onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
			updateModel(blueprint.data.id, values)
				.then((updatedBp) => {
					resetForm({ values: pick(['apiId', 'apiIdPlural'], updatedBp) });
					onChange(updatedBp);
					toast.success('Blueprint API IDs updated');
				})
				.catch((error) => {
					if (error.isUserError) {
						setErrors(error.userErrors);
					} else {
						console.error(error);
						toast.error('Failed to update blueprint API IDs');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">API IDs</p>
					<p className="mb-4 leading-relaxed">
						These IDs determine the object and query types that will be
						generated in the Content API.
					</p>
					<div className="md:grid grid-cols-2 gap-8">
						<TextField
							label="Singular API ID"
							disabled={
								blueprint.isLoading ||
								form.isSubmitting ||
								pathOr(false, ['data', 'isSystem'], blueprint)
							}
							value={form.values.apiId}
							name="apiId"
							onChange={form.handleChange}
							inputClassName="font-mono"
							error={extractGraphqlError('apiId', form.errors)}
						/>
						<TextField
							label="Plural API ID"
							disabled={
								blueprint.isLoading ||
								form.isSubmitting ||
								pathOr(false, ['data', 'isSystem'], blueprint)
							}
							value={form.values.apiIdPlural}
							name="apiIdPlural"
							onChange={form.handleChange}
							inputClassName="font-mono"
							error={extractGraphqlError('apiIdPlural', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
