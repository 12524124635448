import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
    TextField,
// @ts-ignore
} from '@platformapp/ui';
import { graphql } from '../api';
import { P } from '../common/Text';
// @ts-ignore
import DISABLE_MFA_MUTATION from './DisableMfa.gql';
import { pathOr } from 'ramda';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';

type Props = {
    onCancel: () => void;
    onDisabled: () => void;
};

export const DisableMfaModal = ({
    onCancel,
    onDisabled,
}: Props) => {
    const form = useFormik({
        initialValues: {
            code: '',
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: values => {
            if (values.code.replace(/ /g, '').length !== 6) {
                return {
                    code: 'Code must be 6 digits'
                }
            }
        },
        onSubmit: async (values, { setErrors }) => {
            try {
                const res = await graphql(
                    {
                        query: DISABLE_MFA_MUTATION,
                        variables: {
                            input: {
                                code: values.code.replace(/ /g, '')
                            }
                        }
                    },
                    'users'
                );
    
                const removed = pathOr(false, ['data', 'disableMfa', 'isDisabled'], res.data);
    
                if (removed) {
                    onDisabled();
                } else {
                    setErrors({
                        code: 'Two-factor code is not correct'
                    })
                }
            } catch (err) {
                console.error(err);
                toast.error('Failed to disable two-factor authentication');
            }
        }
    });

    return (
        <Modal
			title="Disable two-factor authentication"
			tiny
			isOpen
			onRequestClose={() => onCancel()}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick={false}
		>
            <form
                onSubmit={form.handleSubmit}
            >
                <ModalContent>
                    <div className="mb-3">
                        <P>To confirm that you would like to disable two-factor authentication on your account, enter the 6 digit code shown in your authenticator app.</P>
                    </div>
                    <TextField
                        autoFocus
                        value={form.values.code}
                        onChange={form.handleChange}
                        name="code"
                        disabled={form.isSubmitting}
                        error={form.errors.code}
                    />
                </ModalContent>
                <ModalFooter>
                    <ButtonGroup>
                        <Button
                            disabled={form.isSubmitting} 
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            submit
                            primary
                            loading={form.isSubmitting}
                        >
                            Disable two-factor authentication
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </form>
		</Modal>
    )
}