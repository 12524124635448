import React, { useContext } from 'react';
import Helpers from '../Helpers';
import { graphql } from '../api';
import { ROLE_OWNER } from '../constants';
import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
	SelectField,
} from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import UPDATE_PROJECT_MUTATION from './updateProject.gql';
import { useFormik } from 'formik';
import { TeamsContext } from '../context/TeamsContext';
import { B } from '../common/Text';
import toast from 'react-hot-toast';

export const TransferProject = ({ onCancel, onUpdate, project }) => {
	const { teams } = useContext(TeamsContext);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			teamId: '',
		},
		onSubmit: (values, { setErrors }) => {
			graphql({
				query: UPDATE_PROJECT_MUTATION,
				variables: {
					input: {
						projectId: project.id,
						...values,
					},
				},
			})
				.then((res) => {
					// Update project context with changes
					const payload = res.data.data.updateProject;

					if (payload.userErrors.length > 0) {
						setErrors(payload.userErrors);
					} else {
						onUpdate(payload.project);
					}
				})
				.catch((err) => {
					console.error(err);
					toast.error('Failed to transfer project.');
				});
		},
	});

	return (
		<Modal
			title="Transfer project"
			small
			isOpen={true}
			onRequestClose={() => onCancel()}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					{teams.length - 1 === 0 && (
						<p>No teams are available to transfer this project to.</p>
					)}
					{teams.length - 1 > 0 && (
						<>
							<p className="leading-relaxed mb-4">
								Select a new owner for <B>{project.name}</B>. Transferring a
								project will give all users in the new team access to it.
							</p>
							<SelectField
								name="teamId"
								label="New team"
								value={form.values.teamId}
								onChange={form.handleChange}
								options={teams
									.filter(
										(t) =>
											t.node.viewerRole === ROLE_OWNER &&
											t.node.id !== project.team.id
									)
									.map((team) => ({
										label: team.node.name,
										value: team.node.id,
									}))}
								error={extractGraphqlError('teamId', form.errors)}
							/>
						</>
					)}
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button disabled={form.isSubmitting} onClick={() => onCancel()}>
							{Helpers.t('general.cancel')}
						</Button>
						<Button
							disabled={!form.dirty}
							loading={form.isSubmitting}
							primary
							type="submit"
							// onClick={() => handleConfirm()}
						>
							Transfer project
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};
