// @ts-ignore
import { Field, Icon } from '@platformapp/ui';
import { append, move, remove } from 'ramda';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { makeId } from '../util';
import { DragHandle } from './DragHandle';
// @ts-ignore
import DeleteIcon from '../icons/x.svg';
// @ts-ignore
import { ActionList, Icon } from '@platformapp/ui';
// @ts-ignore
import PlusIcon from '../icons/plus.svg';

export type TagFieldItem = {
    label: string;
    value: string;
}

type TagInputProps = {
    availableValues?: TagFieldItem[];
    disabled?: boolean;
    helpText?: string;
    label?: string;
    onChange?: (value: TagFieldItem[]) => void;
    value: TagFieldItem[];
}

export const TagField = ({
    availableValues,
    disabled,
    helpText,
    label,
    onChange,
    value,
}: TagInputProps) => (
    <Field
        helpText={helpText}
        label={label}
    >
        <div className="flex">
            <DragDropContext
                onDragEnd={val => {
                    const newVal = move(val.source.index, val.destination.index, value);
                    onChange(newVal);
                }}
            >
                <Droppable
                    droppableId={makeId()}
                    direction="horizontal"
                >
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="flex"
                        >
                            {value.map((v, index) => (
                                <Draggable
                                    key={v.value}
                                    draggableId={v.value}
                                    index={index}
                                    isDragDisabled={disabled}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className={`flex mr-4 items-center rounded bg-gray-300 h-8 ${disabled ? 'opacity-75' : ''}`}
                                        >
                                            <DragHandle
                                                className="px-1 text-gray-600"
                                                disabled={disabled}
                                                {...provided.dragHandleProps}
                                            />
                                            <p className="select-none font-medium text-gray-800 mr-1">{v.label}</p>
                                            <button
                                                className="px-1"
                                                disabled={disabled}
                                                type="button"
                                                onClick={() => onChange(remove(index, 1, value))}
                                                title="Remove attribute"
                                            >
                                                <Icon
                                                    className="text-gray-600"
                                                    source={DeleteIcon}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <ActionList
                trigger={
                    <button
                        className={`font-medium py-2 text-sm focus:outline-none whitespace-nowrap ${disabled ? 'text-gray-500' : 'cursor-pointer text-blue-700'}`}
                        disabled={disabled}
                        type="button"
                    >
                        <Icon source={PlusIcon} /> Add attribute
                    </button>
                }
                items={availableValues.filter(v => !value.some(s => s.value === v.value)).map(v => ({
                    onAction: () => onChange(append(v, value)),
                    label: v.label
                }))}
            />
        </div>
    </Field>
)

TagField.defaultProps = {
    availableValues: [],
    disabled: false,
}