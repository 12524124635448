import React from 'react';
import { ResourceTable } from '../common/ResourceTable';
import { Button, formatCurrency } from '@platformapp/ui';
import { isEmpty, isNil, pathOr } from 'ramda';
import { EmptyTextValue } from '../common/EmptyTextValue';
import { AssetSelector } from '../common/AssetSelector';
import Helpers from '../Helpers';

export const VariantsTable = ({
	onAdjustQuantitiesClick,
	onDeleteClick,
	onEditClick,
	readOnly,
	options,
	...props
}) => (
	<ResourceTable
		columns={[
			{
				className: 'w-px',
				render: (variant) => (
					<AssetSelector
						readOnly
						small
						value={pathOr(null, ['images', 'edges', 0, 'node'], variant)}
					/>
				),
			},
			...options.map((o, i) => ({
				title: o,
				render: (variant) => variant.options[i],
			})),
			{
				title: 'Price',
				align: 'right',
				render: (variant) => {
					if (variant.onSale) {
						return (
							<p>
								<span className="line-through text-gray-600 mr-2">
									{formatCurrency(variant.price.amount, variant.price.currency)}
								</span>
								{formatCurrency(
									variant.salePrice.amount,
									variant.salePrice.currency
								)}
							</p>
						);
					}
					return formatCurrency(variant.price.amount, variant.price.currency);
				},
			},
			{
				title: 'Quantity',
				align: 'right',
				render: (variant) =>
					variant.inventoryItem.isTracked
						? variant.inventoryItem.available
						: 'N/A',
			},
			{
				title: 'SKU',
				render: (variant) =>
					isNil(variant.sku) || isEmpty(variant.sku) ? (
						<EmptyTextValue />
					) : (
						variant.sku
					),
			},
			{
				className: 'w-px',
				render: (variant) => (
					<div className="flex">
						<Button
							// className="flex items-center rounded-r-none"
							disabled={readOnly}
							onClick={(e) => {
								e.stopPropagation();
								if (onEditClick) {
									onEditClick(variant.id);
								}
							}}
						>
							Edit
						</Button>
						{/* <Button
							className="-ml-1 flex items-center rounded-l-none"
							disabled={readOnly}
							width="small"
						>
							<Icon source={Kebab} />
						</Button> */}
					</div>
				),
			},
		]}
		idSource={['id']}
		bulkActions={[
			...(readOnly
				? []
				: [
						{
							label: 'Adjust quantities',
							onAction: () => onAdjustQuantitiesClick(),
						},
				  ]),
			{
				destructive: true,
				label: Helpers.t('general.delete'),
				onAction: () => onDeleteClick(),
			},
		]}
		disabled={readOnly}
		{...props}
	/>
);

VariantsTable.defaultProps = {
	readOnly: false,
};
