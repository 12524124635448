import { useContext, useState } from 'react';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { useHistory } from 'react-router-dom';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Module } from '../common/Module';
import { EDITOR_MODE_EDIT, EDITOR_MODE_NEW } from '../constants';
import { DeleteItemModal } from '../publishable/DeleteItemModal';
import { EditItemHeader } from '../publishable/EditItemHeader';
import { ItemSidebar } from '../publishable/ItemSidebar';
import toast from 'react-hot-toast';
import { Attribute } from '../types/Attribute';
import { statusFromStagesV2 } from '../util/publishing';
import { Collection } from '../types/Collection';
import { AsyncNode } from '../types/Node';

type EditContentLayer = {
	blueprint: AsyncNode<Collection>;
	content: any;
	form: any;
	mode: 'edit' | 'new';
	onUnpublish: any;
}

export const EditContentLayer = ({
	mode,
	content,
	blueprint,
	form,
	onUnpublish,
}: EditContentLayer) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	const history = useHistory();
	const [confirmingDelete, setConfirmingDelete] = useState(false);
	const titleAttr = blueprint.node.titleApiId;
	const isNew = mode === EDITOR_MODE_NEW;

	return (
		<>
			{confirmingDelete && (
				<DeleteItemModal
					collection={blueprint.node}
					items={[content.data]}
					onCancel={() => setConfirmingDelete(false)}
					onDelete={() => {
						toast.success('Item deleted');
						history.push(generateEnvPath(`content/${blueprint.node.id}`));
					}}
				/>
			)}

			<EditItemHeader
				isSaving={form.isSubmitting}
				isPublishable={blueprint.node.isPublishable}
				isDirty={form.dirty}
				collectionName={blueprint.node.name}
				onSaveClick={() => form.submitForm()}
				onPublishClick={() => {
					form.setStatus({ action: 'publish' });
					form.submitForm();
				}}
				onUnpublishClick={() => onUnpublish()}
				onDeleteClick={isNew ? null : () => setConfirmingDelete(true)}
				title={form.values[titleAttr] || (isNew ? `New ${blueprint.node.name}` : 'Untitled')}
				status={isNew || !blueprint.node.isPublishable ? null : statusFromStagesV2(content.data.inStages)}
			/>
			<div className="fade-in my-8 md:flex gap-14">
				<div className="flex-grow">
					<Module>
						<AttributeEditor
							attributes={blueprint.node.attributes.filter((a: Attribute) => a.isEditable)}
							onAttributeChange={(apiId: string, val: any) => form.setFieldValue(apiId, val)}
							validationErrors={form.errors}
							values={form.values}
							pathPrefix={['input']}
							readOnly={form.isSubmitting}
							isNew={mode !== EDITOR_MODE_EDIT}
							initialValues={content.data}
						/>
					</Module>
				</div>
				<div className="mt-6 pb-5 md:mt-0 md:w-80 md:sticky md:top-24 self-start flex-shrink-0">
					{!isNew && (
						<ItemSidebar
							isPublishable={blueprint.node.isPublishable}
							item={content.data}
						/>
					)}
				</div>
			</div>
		</>
	);
};
