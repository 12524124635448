// @ts-ignore
import { ConfirmDialog } from '@platformapp/ui';
import { useState } from 'react';

type AsyncConfirmDialogProps = {
    body: any;
    confirmText?: string;
    dangerous?: boolean;
    onCancel: () => void;
    onConfirm: () => Promise<boolean>;
    onComplete: () => void;
    title: string;
}

export const AsyncConfirmDialog = ({
    body,
    confirmText,
    dangerous,
    onCancel,
    onConfirm,
    onComplete,
    title
}: AsyncConfirmDialogProps) => {
    const [isDoingDelete, setIsDoingDelete] = useState(false);
    return (
        <ConfirmDialog
            title={title}
            body={body}
            onConfirm={async () => {
                setIsDoingDelete(true);
                try {
                    const res = await onConfirm();
                    if (res) {
                        onComplete();
                    } else {
                        setIsDoingDelete(false);
                    }
                } catch (err) {
                    console.error(err);
                    setIsDoingDelete(true);
                }
            }}
            dangerous={dangerous}
            onCancel={onCancel}
            isWorking={isDoingDelete}
            confirmText={confirmText}
        />
    )
}