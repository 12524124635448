import { useContext } from 'react';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { lensProp, set } from 'ramda';
// @ts-ignore
import { Button, ErrorMessageElement, TextStyle } from '@platformapp/ui';
import { AddComponentListItemDropdown } from './AddComponentListItemDropdown';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { initialAttributeValues } from '../util/AttributeHelpers';
import { AttributeEditorContext } from '../attribute-editor/AttributeEditorContext';
import { extractGraphqlError } from '../util';
import { Attribute } from '../types/Attribute';

type ComponentAttributeProps = {
	attribute: Attribute;
	helpText: string;
	initialValue: any;
	onChange: (value: any) => void;
	pathPrefix: string[];
	readOnly: boolean;
	showClear: boolean;
	validationErrors: string[];
	value: any;
}

export const ComponentAttribute = ({
	attribute,
	helpText,
	initialValue,
	onChange,
	pathPrefix,
	readOnly,
	showClear,
	validationErrors,
	value,
}: ComponentAttributeProps) => {
	const { isNew } = useContext(AttributeEditorContext);
	const { componentByApiId } = useContext(EnvironmentContext);

	const addItem = (componentApiId: string) => {
		console.log(`Add component of type '${componentApiId}'`);

		// Get the initial values for this blueprint type
		const component: any = componentByApiId(componentApiId);

		onChange({
			__typename: componentApiId,
			...initialAttributeValues(component.node),
		});
	};

	if (!value) {
		return (
			<>
				<div className="py-8 border-dashed border-2 rounded border-gray-300">
					<p className="text-center select-none mb-5">
						<TextStyle subdued>This component is currently empty.</TextStyle>
					</p>
					<div className="flex justify-center">
						<AddComponentListItemDropdown
							attribute={attribute}
							onAddItem={addItem}
							readOnly={readOnly}
						/>
					</div>
				</div>
				{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
				<ErrorMessageElement
					message={extractGraphqlError(pathPrefix, validationErrors)}
				/>
			</>
		);
	}

	const component = componentByApiId(value.__typename);

	return (
		<>
			<div className="border rounded px-5 py-3">
				<AttributeEditor
					attributes={component.node.attributes}
					values={value}
					readOnly={readOnly}
					validationErrors={validationErrors}
					onAttributeChange={(apiId: string, attrValue: any) =>
						onChange(set(lensProp(apiId), attrValue, value))
					}
					preventAutofocus
					pathPrefix={pathPrefix}
					isNew={isNew}
					initialValues={initialValue}
				/>
				{showClear && (
					<div className="mt-6">
						<Button disabled={readOnly} onClick={() => onChange(null)}>
							Clear
						</Button>
					</div>
				)}
			</div>
			{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
		</>
	);
};

ComponentAttribute.defaultProps = {
	showClear: true,
};
