import { Transforms } from 'slate'

const IMAGE_NODE_TYPE = 'image';

export const withImage = editor => {
    const { isInline, isVoid } = editor
    editor.isVoid = element => element.type === IMAGE_NODE_TYPE ? true : isVoid(element);
    editor.isInline = element => element.type === IMAGE_NODE_TYPE ? true : isInline(element);
    return editor
}

export const insertImage = (editor, node, options) => {
    Transforms.insertNodes(editor, node, options);
}