import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Loader } from '@platformapp/ui';
import { graphql } from '../api';
import ACCEPT_INVITE_MUTATION from './acceptInvite.gql';
import toast from 'react-hot-toast';

export const AcceptInvite = () => {
	const history = useHistory();
	const { id } = useParams();

	graphql({
		query: ACCEPT_INVITE_MUTATION,
		variables: {
			input: {
				inviteId: id,
			},
		},
	})
		.then((res) => {
			const payload = res.data.data.acceptTeamMemberInvite;

			if (payload.userErrors.length > 0 || payload.errors) {
				toast.error('Failed to accept invite');
			} else {
				history.replace('/');
			}
		})
		.catch((err) => {
			console.error(err);
			toast.error('Failed to accept invite');
		});

	return <Loader />;
};
