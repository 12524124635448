import React from 'react';
import { prepend } from 'ramda';
import { ChoiceList, SelectField } from '@platformapp/ui';

export const EnumAttribute = ({
	attribute,
	onChange,
	value,
	readOnly,
	error,
	helpText,
}) => {
	let options = attribute.enumeration.values.edges.map(({ node }) => ({
		label: node.name,
		value: node.apiId,
	}));

	if (!attribute.isRequired && !attribute.isList) {
		options = prepend(
			{
				label: '',
				value: '',
			},
			options
		);
	}

	if (!attribute.isList) {
		return (
			<SelectField
				options={options}
				onChange={(e) =>
					onChange(e.target.value === '' ? null : e.target.value)
				}
				value={value || ''}
				disabled={readOnly}
				error={error}
				helpText={helpText}
			/>
		);
	}

	return (
		<ChoiceList
			allowMultiple
			choices={options}
			disabled={readOnly}
			value={value}
			onChange={onChange}
			helpText={helpText}
		/>
	);
};
