import React, { useCallback, useState, useEffect, useContext } from 'react';
import {
	BasicLoader,
	DropzoneField,
	ErrorIcon,
	TextStyle,
} from '@platformapp/ui';
import { useParams } from 'react-router-dom';
import { upload } from '../util/upload';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { B } from '../common/Text';

export const AssetDropzoneField = ({
	onUpload,
	onError,
	disabled,
	...props
}) => {
	const { envId } = useContext(EnvironmentContext);
	const { projectId } = useParams();
	const [uploading, setUploading] = useState({
		file: null,
		isComplete: false,
		isError: false,
		isUploading: false,
	});

	const handleUpload = useCallback((files) => {
		setUploading({
			file: files[0],
			isComplete: false,
			isUploading: true,
		});
	});

	// An effect is needed, so that the upload callback isn't fired before the state is
	// updated with the file.
	useEffect(() => {
		if (!uploading.isComplete && uploading.isUploading) {
			upload(uploading.file, projectId, envId)
				.then((uploadSession) => {
					setUploading({
						...uploading,
						isComplete: true,
						isUploading: false,
					});
					onUpload(uploadSession, uploading.file);
				})
				.catch((err) => {
					console.error(err);
					setUploading({
						...uploading,
						isUploading: false,
						isError: true,
					});
					onError();
				});
		}
	}, [uploading]);

	return (
		<DropzoneField
			onDrop={handleUpload}
			disabled={uploading.isUploading || disabled}
			{...props}
		>
			<div className="h-32 flex items-center justify-center">
				{/* Initial state */}
				{!uploading.isUploading && !uploading.isComplete && !uploading.isError && (
					<div className="text-center">
						<p>
							<TextStyle subdued>Drag and drop or</TextStyle>{' '}
							<span className="text-blue-700">upload from your device</span>
						</p>
					</div>
				)}

				{/* Uploading in progress state */}
				{uploading.isUploading && (
					<div className="text-center">
						<BasicLoader className="inline-block h-8 w-8 text-blue-700 fill-current" />
						<p>
							<TextStyle subdued>
								Uploading <B>{uploading.file.name}</B>
							</TextStyle>
						</p>
					</div>
				)}

				{/* Upload completed state */}
				{uploading.isComplete && (
					<div className="text-center">
						<p>
							<TextStyle subdued>
								Upload of <B>{uploading.file.name}</B> complete
							</TextStyle>
						</p>
					</div>
				)}

				{/* Error state */}
				{uploading.isError && (
					<div className="text-center">
						<ErrorIcon className="opacity-75 inline-block w-20 h-20 mb-4 text-red-700" />
						<p className="mt-4 text-red-700">
							Upload of <B>{uploading.file.name}</B> failed
						</p>
						<p className="mt-3">
							<TextStyle subdued>
								Click or drag files here to attempt another upload.
							</TextStyle>
						</p>
					</div>
				)}
			</div>
		</DropzoneField>
	);
};
