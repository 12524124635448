import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';
import {
	getValuesForSave,
	initialAttributeValues,
	initialVariantValues,
} from '../util/AttributeHelpers';
import { generateCreateContentMutation } from '../content/Mutations';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { validateAttributeValues, validateVariant } from '../util/validation';
import { omit, pathOr } from 'ramda';
import { useMousetrap } from '../hooks/useMousetrap';
import { CreateProductLayer } from './CreateProductLayer';
import { ContentApiContext } from '../context/ContentApiContext';
import toast from 'react-hot-toast';

export const CreateProductForm = ({ blueprint }) => {
	const history = useHistory();
	const { publishItem } = useContext(ContentApiContext);
	const {
		baseCurrency,
		collectionByApiId,
		generateEnvPath,
		contentApiRequest,
	} = useContext(EnvironmentContext);
	const productBp = collectionByApiId('Product');
	const variantBp = collectionByApiId('ProductVariant');
	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialErrors: [],
		initialValues: {
			...initialAttributeValues(productBp.node),
			variants: [initialVariantValues(variantBp.node, baseCurrency)],
		},
		validate: (values) => {
			try {
				const errors = [
					...validateAttributeValues(
						getValuesForSave(values, blueprint.node),
						blueprint.node
					),
					...values.variants.reduce(
						(variantErrors, variant, index) => [
							...variantErrors,
							...validateAttributeValues(
								getValuesForSave(variant, variantBp.node),
								variantBp.node,
								['input', 'variants', index.toString()]
							),
							...validateVariant(variant, [
								'input',
								'variants',
								index.toString(),
							]),
						],
						[]
					),
				];

				if (errors.length !== 0) {
					toast.error('Some fields are invalid; correct these and save again.');
				}

				return errors;
			} catch (err) {
				toast.error('Something went wrong saving this product');
				return false;
			}
		},
		onSubmit: async (formValues, { setErrors, resetForm }) => {
			let variantsFailed = false;
			const input = {
				...getValuesForSave(formValues, blueprint.node),
				variants: formValues.variants.map((variant) => ({
					create: {
						...omit(['product'], getValuesForSave(variant, variantBp.node)),
						inventoryItem: variant.inventoryItem,
					},
				})),
			};

			const res = await contentApiRequest({
				query: generateCreateContentMutation(blueprint.node),
				variables: {
					input,
				},
			});

			const payload = pathOr(null, ['data', 'result'], res.data);

			// If no `data` key is returned or it has no `node`/`userErrors` properties,
			// then something went wrong server side.
			if (!payload || (!payload.node && !payload.userErrors)) {
				console.error(res.data);
				toast.error('Something went wrong saving this item.');
				return;
			}

			if (payload.userErrors.length > 0) {
				setErrors(payload.userErrors);
				toast.error('Some fields are invalid; correct these and save again.');
				return;
			}

			// Check if the resource needs to be published/unpublished
			if (form.status) {
				if (form.status.action === 'publish') {
					try {
						await publishItem(payload.node.id);
						toast.success(`Product published`);
					} catch (err) {
						console.error(err);
						toast.error(`Product saved, but not published`);
					}
				}
			} else {
				if (!variantsFailed) {
					toast.success(`Product saved`);
				}
			}

			resetForm({
				values: {
					...formValues,
				},
				status: null,
			});

			history.replace(generateEnvPath(`products/${payload.node.id}`));
		},
	});

	useMousetrap('mod+s', () => {
		if (form.dirty && !form.isSubmitting) {
			form.submitForm();
		}
		return false;
	});

	return (
		<>
			<CreateProductLayer blueprint={blueprint} form={form} />
			<Prompt
				when={form.dirty}
				message="You have unsaved changes. Are you sure you want to leave this page?"
			/>
		</>
	);
};
