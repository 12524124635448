import React, { useContext } from 'react';
import { ChoiceList } from '@platformapp/ui';
import {
	EVENT_ITEM_DELETED,
	EVENT_ITEM_PUBLISHED,
	EVENT_ITEM_UNPUBLISHED,
	EVENT_ITEM_CREATED,
	EVENT_ITEM_UPDATED,
	EVENT_ORDER_CREATED,
} from '../constants';
import Helpers from '../Helpers';
import { ProjectContext } from '../context/ProjectContext';
import { concat } from 'ramda';

export const EventsChoiceList = ({ error, onChange, value }) => {
	const { activeProject } = useContext(ProjectContext);
	return (
		<ChoiceList
			allowMultiple
			value={value}
			error={error}
			className="max-h-64"
			sections={concat(
				[
					{
						name: 'Collection items',
						choices: [
							EVENT_ITEM_CREATED,
							EVENT_ITEM_UPDATED,
							EVENT_ITEM_PUBLISHED,
							EVENT_ITEM_UNPUBLISHED,
							EVENT_ITEM_DELETED,
						].map((wh) => ({
							label: Helpers.t(`events.${wh}`),
							value: wh,
						})),
					},
				],
				activeProject && activeProject.ecommerceEnabled
					? [
							{
								name: 'Orders',
								choices: [EVENT_ORDER_CREATED].map((wh) => ({
									label: Helpers.t(`events.${wh}`),
									value: wh,
								})),
							},
					  ]
					: []
			)}
			onChange={onChange}
		/>
	);
};
