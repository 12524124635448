import { useContext, useEffect, useState } from 'react';
import { NoResults } from '../common/NoResults';
import { ListPageHeader } from '../common/ListPageHeader';
// @ts-ignore
import { Button } from '@platformapp/ui';
import { ItemFilters } from './ItemFilters';
import { STATUS_PUBLISHED } from '../constants';
import { QueryStringPaginator } from '../common/Pagination';
import { pick } from 'ramda';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ContentResourceTable } from './ContentResourceTable';
import { getPinnableAttributes } from '../util/AttributeHelpers';
import { sortByLabel } from '../util';
import { TableSkeleton } from '../common/Skeleton';
import { DeleteItemModal } from '../publishable/DeleteItemModal';
import { ColumnPinMenu } from './ColumnPinMenu';
import toast from 'react-hot-toast';
import Helpers from '../Helpers';
import { ContentApiContext } from '../context/ContentApiContext';
import { Attribute } from '../types/Attribute';
import { ItemsDispatchAction } from './ContentListContainer';
import { EmptyMessage } from '../common/EmptyMessage';
// @ts-ignore
import PlusIcon from '../icons/plus.svg';

type ContentListInnerProps = {
	itemsDispatch: (action: ItemsDispatchAction) => void;
	[key: string]: any;
};

export const ContentListInner = ({
	items,
	filter,
	blueprint,
	order,
	setOrder,
	pinnedColumns,
	setPinnedColumns,
	onFilterChange,
	itemsDispatch
}: ContentListInnerProps) => {
	const { generateEnvPath } = useContext(
		EnvironmentContext
	);
	const [deleting, setDeleting] = useState([]);
	const [selection, setSelection] = useState<string[]>([]);
	const {publishItems, unpublishItems} = useContext(ContentApiContext);

	const publish = async (ids: string[]) => {
		const promise = publishItems(ids);

		toast.promise(promise, {
			loading: Helpers.t('publishing.publishing_inprogress', ids.length),
			success: Helpers.t('publishing.publishing_success', ids.length),
			error: Helpers.t('publishing.publishing_failed', ids.length)
		});

		const res = await promise;

		itemsDispatch({
			type: 'update',
			items: ids.map(id => {
				const result = res.data[`publish_${id}`];
				return result.publishedNodes.find((n: any) => n.id === id);
			})
		});
	}

	const unpublish = async (ids: string[]) => {
		const promise = unpublishItems(ids);

		toast.promise(promise, {
			loading: Helpers.t('publishing.unpublishing_inprogress', ids.length),
			success: Helpers.t('publishing.unpublishing_success', ids.length),
			error: Helpers.t('publishing.unpublishing_failed', ids.length)
		});

		const res = await promise;

		itemsDispatch({
			type: 'update',
			items: ids.map(id => {
				const result = res.data[`unpublish_${id}`];
				return result.unpublishedNodes.find((n: any) => n.id === id);
			})
		});
	}

	const updateContentStatus = (newStatus: any, item: any) => newStatus === STATUS_PUBLISHED ? publish([item.id]) : unpublish([item.id]);

	useEffect(() => {
		setSelection([]);
	}, [blueprint]);

	const showLoading = items.isLoading || blueprint.id !== items.collectionId;
	const pinnableColumns = sortByLabel([
		...getPinnableAttributes(blueprint.attributes).map((attr: Attribute) => ({
			label: attr.name,
			key: attr.apiId,
		})),
		{
			label: 'ID',
			key: 'id',
		},
	]);

	return (
		<>
			{deleting.length > 0 && (
				<DeleteItemModal
					collection={blueprint}
					items={items.edges
						.filter((edge: any) => deleting.includes(edge.node.id))
						.map((edge: any) => edge.node)}
					onCancel={() => setDeleting([])}
					onDelete={(ids) => {
						toast.success(Helpers.t('items.delete_success', deleting.length));
						setSelection([]);
						setDeleting([]);
						itemsDispatch({
							type: 'delete',
							ids
						});
					}}
				/>
			)}

			{/* @ts-ignore */}
			<ListPageHeader
				hasPx={false}
				title={blueprint.name}
				right={
					<>
						{blueprint.isActive && (
							<Button
								primary
								to={generateEnvPath(
									blueprint.apiId === 'Product'
										? 'products/new'
										: `content/${blueprint.id}/new`
								)}
							>
								<span className="hidden md:inline">Create {blueprint.name}</span>
								<PlusIcon className="md:hidden fill-current" />
							</Button>
						)}
					</>
				}
				bottom={
					<div className="flex w-full">
						<div className="flex-grow">
							<ItemFilters
								appliedFilters={filter}
								blueprint={blueprint}
								onChange={onFilterChange}
							/>
						</div>
						<div className="">
							<ColumnPinMenu
								onChange={setPinnedColumns}
								pinnableColumns={pinnableColumns}
								pinnedColumns={pinnedColumns}
							/>
						</div>
					</div>
				}
			/>
			{showLoading && <TableSkeleton className="mt-5" />}

			{!showLoading &&
				items.edges.length === 0 &&
				(filter.length === 0 ? (
					// @ts-ignore
					<EmptyMessage
						title="You don't have any items in this collection"
					/>
				) : (
					<NoResults />
				))}

			{!showLoading && items.edges.length > 0 && (
				<>
					{/* @ts-ignore */}
					<ContentResourceTable
						collection={blueprint}
						data={items.edges}
						sortKey={order.field}
						sortDirection={order.dir}
						onSelectionChange={(value: any) => setSelection(value)}
						onSort={(sortKey: string, direction: string) =>
							setOrder({
								dir: direction,
								field: sortKey,
							})
						}
						pinnedColumns={pinnedColumns}
						pinnableColumns={pinnableColumns}
						bulkActions={[
							{
								label: Helpers.t('publishing.publish'),
								onAction: () => publish(selection)
							},
							{
								label: Helpers.t('publishing.unpublish'),
								onAction: () => unpublish(selection)
							},
							{
								destructive: true,
								label: Helpers.t('general.delete'),
								onAction: () => setDeleting(selection),
							},
						]}
						selectable
						selection={selection}
						setDeleting={(res: any) => setDeleting([res.id])}
						showContextMenu
						updateContentStatus={updateContentStatus}
					/>
					{/* @ts-ignore */}
					<QueryStringPaginator {...pick(['totalCount', 'pageInfo'], items)} />
				</>
			)}
		</>
	);
};
