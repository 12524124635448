import React from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { PrimaryNavigation } from '../navigation/PrimaryNavigation';
import { TopNav2 } from '../navigation/TopNav';
import { VerificationWarning } from '../VerificationWarning';
import { Settings } from './Settings';

export const User = () => (
	<div className="app-container transition-transform duration-200 ease-out">
		<VerificationWarning />
		<div className="fixed top-0 left-0 right-0 z-10">
			<TopNav2>
				<div className="flex items-center">
					<Link className="text-base text-gray-900" to="/">
						Platform
					</Link>
					<span className="text-gray-400 mx-3 select-none">/</span>
					<Link className="text-base text-gray-900" to="/account">
						Account
					</Link>
				</div>
			</TopNav2>
			<PrimaryNavigation />
		</div>
		<Route path={['/account', '/account/security']} component={Settings} />
	</div>
);
