import UPDATE_COLLECTION from './UpdateCollection.gql';
import UPDATE_COMPONENT from './UpdateComponent.gql';
import { graphql } from '../api';

export const updateModel = (id, data) => {
	const isComponent = id.startsWith('comp');
	return new Promise((resolve, reject) => {
		graphql({
			query: isComponent ? UPDATE_COMPONENT : UPDATE_COLLECTION,
			variables: {
				input: {
					id,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateRes;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.model);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			});
	});
};
