import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { useParams } from 'react-router-dom';
import PROJECT_QUERY from './GetProject.gql';
import { Heading } from '../common/Heading';
import { NameSetting } from './NameSetting';
import { OwnerSetting } from './OwnerSetting';
import { DeleteSetting } from './DeleteSetting';
import { ProjectContext } from '../context/ProjectContext';
import { mergeDeepRight } from 'ramda';
import { useTitle } from '../hooks/useTitle';
import { Message } from '@platformapp/ui';
import { useAsyncEffect } from 'use-async-effect';

const ProjectSettingsComponent = () => {
	const { projectId } = useParams();
	const { updateProject } = useContext(ProjectContext);
	const [project, setProject] = useState({
		isLoading: true,
		data: {},
	});
	useTitle(project.isLoading ? null : ['Project settings', project.data.name]);

	useAsyncEffect(
		async (isActive) => {
			const { data } = await graphql({
				query: PROJECT_QUERY,
				variables: {
					id: projectId,
				},
			});
			if (!isActive()) {
				return;
			}
			setProject({
				isLoading: false,
				data: data.data.project,
			});
		},
		[projectId]
	);

	const onChange = (data) => {
		setProject({
			isLoading: false,
			data: mergeDeepRight(project.data, data),
		});
		updateProject(data);
	};

	return (
		<>
			<Heading className="mb-6">Project settings</Heading>

			<div className="lg:mr-48">
				<Message className="mb-6">
					<p>These settings apply to the project and all environments.</p>
				</Message>
				<NameSetting onChange={onChange} project={project} />
				<OwnerSetting onChange={onChange} project={project} />
				<DeleteSetting project={project.data} />
			</div>
		</>
	);
};

export const ProjectSettings = withErrorBoundary(ProjectSettingsComponent);
