import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageNotFound } from '../common/PageNotFound';
import PROJECT_QUERY from './GetProject.gql';
import { graphql } from '../api';
import { mergeDeepRight } from 'ramda';

export const ProjectContext = createContext({});

export const ProjectConsumer = ProjectContext.Consumer;

export const ProjectProvider = ({ children }) => {
	const { projectId, teamSlug } = useParams();
	const [project, setProject] = useState({
		isLoading: true,
		node: null,
	});

	useEffect(() => {
		setProject({
			isLoading: true,
			node: null,
		});
		graphql({
			query: PROJECT_QUERY,
			variables: {
				projectId,
			},
		}).then(({ data }) => {
			setProject({
				isLoading: false,
				...data.data,
			});
		});
	}, [projectId]);

	// If the project is loaded, and the node is null, show not found page...
	if (projectId && !project.isLoading && !project.node) {
		return <PageNotFound />;
	}

	// If teams are loaded, and this project does not belong to the specified
	// team, show not found page...
	if (projectId && !project.isLoading && teamSlug !== project.node.team.slug) {
		return <PageNotFound />;
	}

	return (
		<ProjectContext.Provider
			value={{
				activeProject: project.node,
				generateProjectPath: (path = '') => `/${teamSlug}/${projectId}/${path}`,
				isLoading: project.isLoading,
				updateProject: (newProject) =>
					setProject({
						isLoading: false,
						node: mergeDeepRight(project.node, newProject),
					}),
			}}
		>
			{children}
		</ProjectContext.Provider>
	);
};
