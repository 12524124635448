import React, { useContext, useState } from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { ListPageHeader } from '../common/ListPageHeader';
import { ContentPermissions } from '../api-key/ContentPermissions';
import { AddPermissionModal } from '../api-key/AddPermissionModal';
import { NameSetting } from '../api-key/NameSetting';
import { DefaultStageSetting } from '../api-key/DefaultStageSetting';
import { ManagementPermissions } from '../api-key/ManagementPermissions';
// import { Regenerate } from '../api-key/Regenerate';
import { DeleteSetting } from '../api-key/DeleteSetting';
import { ProjectContext } from '../context/ProjectContext';
import { EcommercePermissions } from './EcommercePermissions';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { IntrospectionSetting } from './IntrospectionSetting';

const ApiKeyFormComponent = ({ existingApiKey }) => {
	const { activeProject } = useContext(ProjectContext);
	const isEditing = !!existingApiKey;
	const [addingPermission, setAddingPermission] = useState(false);

	useEnvironmentTitle(existingApiKey ? null : 'New API key');

	return (
		<>
			{addingPermission && (
				<AddPermissionModal onCancel={() => setAddingPermission(false)} />
			)}

			<div className="fade-in mb-5">
				<ListPageHeader
					sticky={false}
					title={isEditing ? 'Edit API key' : 'New API key'}
				/>

				<div className="mt-8">
					<NameSetting apiKey={existingApiKey} />

					{/* <ErrorMessageElement
						message={extractGraphqlError(['scope'], form.errors)}
					/> */}

					<ContentPermissions
						disabled={!isEditing}
						initialPermissions={
							existingApiKey ? existingApiKey.contentPermissions : []
						}
					/>

					<DefaultStageSetting apiKey={existingApiKey} disabled={!isEditing} />

					<ManagementPermissions
						apiKey={existingApiKey}
						disabled={!isEditing}
						initialPermissions={
							existingApiKey ? existingApiKey.managementPermissions : []
						}
					/>

					{/* <Regenerate
						apiKey={existingApiKey}
						disabled={!isEditing}
					/> */}

					{activeProject && activeProject.ecommerceEnabled && (
						<EcommercePermissions
							apiKey={existingApiKey}
							disabled={!isEditing}
						/>
					)}

					<IntrospectionSetting apiKey={existingApiKey} disabled={!isEditing} />

					<DeleteSetting apiKey={existingApiKey} disabled={!isEditing} />
				</div>
			</div>
		</>
	);
};

ApiKeyFormComponent.defaultProps = {
	existingApiKey: null,
};

export const ApiKeyForm = withErrorBoundary(ApiKeyFormComponent);
