import React, { useContext } from 'react';
import { Button, formatCurrency, TextField } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { TeamContext } from '../context/TeamContext';
import { SCOPE_BILLING_UPDATE } from '../constants';
import { P } from '../common/Text';
import { useFormik } from 'formik';
import { extractGraphqlError } from '../util';

export const FormSubmissionsAddOn = () => {
	const { canDo } = useContext(TeamContext);
	const form = useFormik({
		initialValues: {
			quantity: 1,
		},
	});
	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Form submissions add-on</p>
				<P className="mb-4">
					Determines how many form submissions you can accept. Increasing this
					number will give you extra blocks of 500 submissions (£5 per block per
					month).
				</P>
				<div className="w-full md:w-96">
					<TextField
						disabled={form.isSubmitting}
						value={form.values.quantity}
						name="quantity"
						onChange={form.handleChange}
						error={extractGraphqlError('quantity', form.errors)}
						type="number"
						suffix={`${(
							form.values.quantity * 500
						).toLocaleString()} submissions (${formatCurrency(
							(form.values.quantity - 1) * 5,
							'gbp'
						)} per month)`}
						min="1"
						max="20"
					/>
				</div>
			</FieldsetContent>
			<FieldsetFooter end={false}>
				<div className="flex items-center">
					<P className="flex-grow">
						Your plan gives you 200 free submissions per month.
					</P>
					{canDo(SCOPE_BILLING_UPDATE) && (
						<Button
							disabled={form.isSubmitting || !form.dirty}
							submit
							width="large"
						>
							Save
						</Button>
					)}
				</div>
			</FieldsetFooter>
		</Fieldset>
	);
};
