import React from 'react';
import Helpers from '../Helpers';
// import moment from 'moment';
// import { Message } from '@platformapp/ui';
import VisaImage from '../images/visa.png';
import AmexImage from '../images/american-express.png';
import MastercardImage from '../images/mastercard.png';
import { P } from '../common/Text';

const BrandIcon = ({ brand }) => {
	switch (brand) {
		case 'Visa':
		case 'visa':
			return <img className="inline-block mr-2 h-6" src={VisaImage} />;
		case 'MasterCard':
		case 'mastercard':
			return <img className="inline-block mr-2 h-6" src={MastercardImage} />;
		case 'American Express':
		case 'amex':
			return <img className="inline-block mr-2 h-6" src={AmexImage} />;
	}
	// diners, discover, jcb, unionpay, or unknown
	return null;
};

// const ExpirationWarning = ({ paymentMethodExpires }) => {
// 	if (!paymentMethodExpires) {
// 		return null;
// 	}

// 	// if (paymentMethodExpires.isBefore(moment())) {
// 	// 	return (
// 	// 		<Message className="mb-4" danger>
// 	// 			<P>
// 	// 				Your current payment method has expired. Please update your payment
// 	// 				method to avoid interruption to your team and projects.
// 	// 			</P>
// 	// 		</Message>
// 	// 	);
// 	// } else if (
// 	// 	paymentMethodExpires.clone().subtract(30, 'days').isBefore(moment())
// 	// ) {
// 	// 	return (
// 	// 		<Message className="mb-4" warning>
// 	// 			<P>
// 	// 				Your current payment method expires this month. Please update your
// 	// 				payment method to avoid interruption.
// 	// 			</P>
// 	// 		</Message>
// 	// 	);
// 	// }

// 	return null;
// };

export const PaymentMethodOverview = ({ subscription }) => {
	if (subscription.paymentMethod === null) {
		return <P>No payment method on file.</P>;
	}

	// const paymentMethodExpires = moment.utc(
	// 	subscription.paymentMethod.expiresAt,
	// 	moment.ISO_8601
	// );

	return (
		<>
			{/* <ExpirationWarning paymentMethodExpires={paymentMethodExpires} /> */}
			<p className="flex items-center">
				<BrandIcon brand={subscription.paymentMethod.brand} />
				<span>
					{Helpers.t(
						`card_types.${subscription.paymentMethod.brand.toLowerCase()}`
					)}{' '}
					**** **** **** {subscription.paymentMethod.lastFour} (expires{' '}
					{/* {paymentMethodExpires.format('MM/YYYY')}) */}
				</span>
			</p>
		</>
	);
};
