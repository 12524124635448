import React from 'react';
import { DataList, DataListItem, Image, TextStyle } from '@platformapp/ui';
import { nameFromMimeType, formatBytes, assetTypeFromMime } from '../util';
import Helpers from '../Helpers';
import { ASSET_FILTER_IMAGE } from '../constants';
import { PublishStatusLabel } from '../common/PublishStatusLabel';
import { statusFromStages } from '../util/publishing';
import { B } from '../common/Text';
import { ApiIdLabel } from '../common/ApiIdLabel';

export const AssetDetails = ({ assets }) => (
	<div className="sticky top-28 mt-6">
		<div className="bg-gray-200 rounded py-4">
			{assets.length > 0 && (
				<div className="flex items-center flex-col">
					<div className="w-40 h-40">
						{assets.length === 1 &&
							assetTypeFromMime(assets[0].node.mimeType) ===
								ASSET_FILTER_IMAGE && (
								<Image src={assets[0].node.mediumThumbUrl} />
							)}
					</div>
					<p
						className="mt-4 truncate w-full text-center px-3"
						title={assets.length === 1 ? assets[0].node.title : null}
					>
						{assets.length === 1 && <B>{assets[0].node.title}</B>}
						{assets.length > 1 && <B>{assets.length} assets</B>}
					</p>
				</div>
			)}
			{assets.length === 0 && (
				<div className="py-16 px-2">
					<p className="text-sm text-center">
						<TextStyle subdued>Select an asset to see more details</TextStyle>
					</p>
				</div>
			)}
		</div>
		{assets.length === 1 && (
			<DataList className="mt-5">
				<DataListItem stacked title="Size">
					<p>{formatBytes(assets[0].node.size)}</p>
				</DataListItem>
				{assets[0].node.width && (
					<DataListItem stacked title="Dimensions">
						<p>
							{assets[0].node.width} x {assets[0].node.height}
						</p>
					</DataListItem>
				)}
				<DataListItem stacked title="Type">
					<AssetType asset={assets[0].node} />
				</DataListItem>
				<DataListItem stacked title="Status">
					<PublishStatusLabel status={statusFromStages(assets[0].node)} />
				</DataListItem>
				<DataListItem stacked title="Item ID">
					<ApiIdLabel textToCopy={assets[0].node.id}>
						{assets[0].node.id}
					</ApiIdLabel>
				</DataListItem>
			</DataList>
		)}
		{assets.length > 1 && (
			<DataList className="mt-5">
				<DataListItem stacked title="Total size">
					<p>
						{formatBytes(
							assets.reduce((total, item) => total + item.node.size, 0)
						)}
					</p>
				</DataListItem>
			</DataList>
		)}
	</div>
);

const AssetType = ({ asset }) => {
	const friendlyMimeType = nameFromMimeType(asset.mimeType);
	return (
		<p className="whitespace-nowrap">
			{Helpers.t(`asset.type.${assetTypeFromMime(asset.mimeType)}`)}
			{friendlyMimeType && <> ({friendlyMimeType})</>}
		</p>
	);
};
