import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { ActionList } from '@platformapp/ui';
import { UserAvatar } from './UserAvatar';

export const UserMenu = () => {
	const userCtx = useContext(UserContext);

	const trigger = (
		<UserAvatar className="cursor-pointer flex justify-center" user={userCtx} />
	);

	return (
		<ActionList
			trigger={trigger}
			items={[
				{
					label: userCtx.name,
				},
				{
					label: 'Account settings',
					to: '/account',
				},
				{
					to: '/sign-out',
					label: 'Sign out',
				},
			]}
		/>
	);
};
