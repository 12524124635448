import React from 'react';

import { Badge } from '@platformapp/ui';

import { STATUS_PUBLISHED, STATUS_CHANGED } from '../constants';

import Helpers from '../Helpers';

export const PublishStatusLabel = ({ status, ...props }) => (
	<Badge
		text={Helpers.t(`publishing.status.${status}`)}
		attention={status === STATUS_CHANGED}
		positive={status === STATUS_PUBLISHED}
		{...props}
	/>
);

PublishStatusLabel.defaultProps = {
	className: '',
};
