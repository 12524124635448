import React, { useState, useContext } from 'react';
import { Button } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { B, P } from '../common/Text';
import DELETE_TEAM_MUTATION from './delete.gql';
import { graphql } from '../api';
import { ConfirmDialog } from '@platformapp/ui';
import { useHistory } from 'react-router-dom';
import { extractGraphqlError } from '../util';
import { TeamsContext } from '../context/TeamsContext';
import { TeamContext } from '../context/TeamContext';
import toast from 'react-hot-toast';

export const DeleteTeamSetting = ({ team }) => {
	const { removeTeam } = useContext(TeamsContext);
	const { activeTeam } = useContext(TeamContext);
	const history = useHistory();
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = () => {
		setIsDeleting(true);
		graphql({
			query: DELETE_TEAM_MUTATION,
			variables: {
				input: {
					teamId: activeTeam.id,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteTeam;

				if (payload.userErrors.length > 0) {
					const teamErrs = extractGraphqlError('teamId', payload.userErrors);

					if (teamErrs.length > 0) {
						toast.error(teamErrs[0]);
					} else {
						toast.error('An error occurred deleting this team');
					}

					setIsDeleting(false);
					setIsConfirmingDelete(false);
				} else if (payload.errors) {
					setIsDeleting(false);
					setIsConfirmingDelete(false);
					toast.error('An error occurred deleting this team');
				} else {
					toast.success('Team deleted');
					removeTeam(activeTeam.id);
					history.replace('/');
				}
			})
			.catch((err) => {
				console.error(err);
				setIsDeleting(false);
				setIsConfirmingDelete(false);
				toast.error('An error occurred deleting this team');
			});
	};

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					dangerous
					title="Delete team"
					body={
						<>
							<p className="mb-3">
								Are you sure you want to permanently delete{' '}
								<B>{team.node.name}</B>?
							</p>
							<p className="mb-3">
								<B>This will remove all team data, and cannot be undone.</B>
							</p>
							<p>
								Please ensure you download all required invoices before
								proceeding, as these will be deleted. You will be invoiced
								immediately for any remaining usage.
							</p>
						</>
					}
					onConfirm={handleDelete}
					onCancel={() => setIsConfirmingDelete(false)}
					isWorking={isDeleting}
					confirmText="Delete team"
				/>
			)}
			<Fieldset danger>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Delete team</p>
					<P>
						Once a team has been deleted, it cannot be recovered - the
						subscription will be cancelled and all data will be permanently
						removed. Please be certain.
					</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						destructive
						disabled={team.isLoading}
						width="large"
						onClick={() => setIsConfirmingDelete(true)}
					>
						Delete
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
