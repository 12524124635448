import { isEmpty, reject, trim } from 'ramda';
import { initialVariantValues } from '../util/AttributeHelpers';

const cartesian = (...a) =>
	a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));

/**
 *
 * @param {*} optionValues An array containing up to 3 string arrays for all options,
 * e.g., `[['Small', 'Medium], ['Red', 'Yellow', 'Blue']]`.
 * @returns
 */
export const generateVariants = (optionValues) => {
	// Filter out any empty option values
	const variantVals = optionValues.map((option) =>
		reject(isEmpty, option.map(trim))
	);

	if (variantVals.length === 0) {
		return [];
	} else if (variantVals.length === 1) {
		// [["Small"], ["Medium"], ["Large"]]
		return variantVals[0].map((v) => [v]);
	}

	// [["Small", "Red"], ["Medium", "Red"], ["Large", "Red"]]
	return cartesian(...(variantVals.length === 1 ? [variantVals] : variantVals));
};

/**
 *
 * @param {*} optionValues An array containing up to 3 string arrays for all options,
 * e.g., `[['Small', 'Medium], ['Red', 'Yellow', 'Blue']]`.
 * @param {*} baseCurrency Base currency object containing `code`.
 * @param {*} variantBlueprint The product varint blueprint.
 * @param {*} existingVariants Array of existing variants to match with.
 * @returns
 */
export const regenerateVariants = (
	optionValues,
	baseCurrency,
	variantBlueprint,
	existingVariants = []
) => {
	console.log('Regenerating variants for option values', optionValues);
	console.log('Existing variants', existingVariants);

	const basePrice =
		existingVariants.length === 1 ? existingVariants[0].price.amount : 0;

	const newVariants = generateVariants(optionValues).map((options) => ({
		...initialVariantValues(variantBlueprint, baseCurrency),
		options,
		price: {
			amount: basePrice,
			currency: baseCurrency.code,
		},
	}));

	if (newVariants.length === 0) {
		return [initialVariantValues(variantBlueprint, baseCurrency)];
	}

	return newVariants;
};
