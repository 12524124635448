import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { graphql } from '../api';
import CONFIRM_STRIPE from './ConfirmStripe.gql';
import { Loader } from '@platformapp/ui';
import { pathOr } from 'ramda';
import toast from 'react-hot-toast';

const getReturnPath = (env) => {
	if (env.isDefault) {
		return [
			env.project.team.slug,
			env.project.id,
			'settings',
			'payment-providers',
		].join('/');
	}
	return [
		env.project.team.slug,
		env.project.id,
		'env',
		env.slug,
		'settings',
		'payment-providers',
	].join('/');
};

export const StripeCallback = () => {
	const history = useHistory();
	const qs = new URLSearchParams(window.location.search);

	useEffect(() => {
		graphql({
			query: CONFIRM_STRIPE,
			variables: {
				code: qs.get('code'),
				state: qs.get('state'),
			},
		})
			.then((res) => {
				const env = pathOr(
					null,
					[
						'data',
						'data',
						'confirmStripeIntegration',
						'integration',
						'environment',
					],
					res
				);
				if (env) {
					toast.success('Stripe is now connected');
					history.replace(`/${getReturnPath(env)}`);
				} else {
					toast.error('Failed to connect to Stripe');
					history.replace('/');
				}
			})
			.catch((err) => {
				console.warn(err);
				toast.error('Failed to connect to Stripe');
			});
	}, []);

	return <Loader />;
};
