import React from 'react';

export const FileThumbnail = ({
    asset,
    className,
    fixedWidth,
    small
}) => {
    const mimeParts = asset.mimeType.split('/');
    return (
        <div
            className="w-full h-full flex items-center justify-center"
        >
            <div
                className={`${className ? `${className} ` : ''}${fixedWidth ? '' : 'w-2/5 h-3/5 '}select-none flex border-b border-gray-400 bg-gray-100 rounded items-center px-1`}
            >
                <p
                    className={`${small ? 'text-3xs ' : 'text-xs'} uppercase font-medium truncate w-full text-gray-500 text-center`}
                >
                    {mimeParts.length === 2 ? mimeParts[1] : '...'}
                </p>
            </div>
        </div>
    )
}

FileThumbnail.defaultProps = {
    fixedWidth: false
}