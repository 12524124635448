import React, { useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import GET_HOOK_QUERY from './getHook.gql';
import { Overview } from './Overview';
import { Settings } from './Settings';
import { NotFound } from '../NotFound';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const Webhook = withErrorBoundary(() => {
	const { webhookId } = useParams();
	const [hook, setHook] = useState({
		isLoading: true,
		node: {},
	});

	useEnvironmentTitle(hook.isLoading ? 'Webhooks' : hook.node.name);

	useAsyncEffect(async (isActive) => {
		const res = await graphql({
			query: GET_HOOK_QUERY,
			variables: {
				id: webhookId,
			},
		});
		if (!isActive()) {
			return;
		}
		setHook({
			isLoading: false,
			node: res.data.data.webhook,
		});
	}, []);

	if (!hook.isLoading && !hook.node) {
		return (
			<NotFound
				header="Webhook not found"
				body={<p>No such webhook: {webhookId}</p>}
			/>
		);
	}

	return (
		<div className="h-screen flex-grow min-w-0">
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/webhooks/:webhookId',
					'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId',
				]}
				render={() => <Overview hook={hook} />}
			/>
			<Route
				path={[
					'/:teamSlug/:projectId/webhooks/:webhookId/settings',
					'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId/settings',
				]}
				render={() => (
					<Settings
						hook={hook}
						onChange={(newWebhook) =>
							setHook({
								isLoading: false,
								node: {
									...hook.node,
									...newWebhook,
								},
							})
						}
					/>
				)}
			/>
		</div>
	);
});
