import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateWebhook } from './mutations';
import toast from 'react-hot-toast';

export const UrlSetting = ({ hook, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			url: pathOr('', ['node', 'url'], hook),
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			updateWebhook(hook.node.id, values)
				.then((updatedHook) => {
					onChange(updatedHook);
				})
				.catch((err) => {
					if (err.isUserError) {
						setErrors(err.userErrors);
					} else {
						toast.error('Failed to update webhook');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Webhook URL</p>
					<p className="mb-4">
						An HTTP POST request will be made to this URL each time one of the
						webhook events are triggered.
					</p>
					<TextField
						disabled={hook.isLoading || form.isSubmitting}
						value={form.values.url}
						name="url"
						onChange={form.handleChange}
						error={extractGraphqlError('url', form.errors)}
					/>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
