import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RichEditor } from './RichEditor';
import { RawEditor } from './RawEditor';
import { withErrorBoundary } from './ErrorBoundary';
import { ErrorMessageElement, Field } from '@platformapp/ui';
import { isEmpty } from 'ramda';

export const MarkdownEditorComponent = ({
	autoFocus,
	initialMode,
	onChange,
	readOnly,
	value,
	error,
	helpText,
}) => {
	const [mode, setMode] = useState(initialMode);
	const isError = error && !isEmpty(error);

	return (
		<Field helpText={helpText}>
			<div
				className={`border rounded bg-white ${isError ? 'border-red-700' : ''}`}
			>
				{mode === 'raw' && (
					<RawEditor
						mode={mode}
						onChange={onChange}
						readOnly={readOnly}
						setMode={setMode}
						value={value}
					/>
				)}
				{mode === 'rich' && (
					<RichEditor
						autoFocus={autoFocus}
						mode={mode}
						onChange={onChange}
						readOnly={readOnly}
						setMode={setMode}
						value={value}
					/>
				)}
			</div>
			<ErrorMessageElement message={error} />
		</Field>
	);
};

MarkdownEditorComponent.defaultProps = {
	initialMode: 'rich',
	readOnly: false,
	value: '',
};

MarkdownEditorComponent.propTypes = {
	initialMode: PropTypes.oneOf(['raw', 'rich']),
	readOnly: PropTypes.bool,
	value: PropTypes.string,
};

export const MarkdownEditor = withErrorBoundary(MarkdownEditorComponent);
