import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { TeamContext } from '../context/TeamContext';
import TEAM_QUERY from './getTeam.gql';
import { Heading } from '../common/Heading';
import { SCOPE_DELETE_TEAM } from '../constants';
import { TeamNameSetting } from './TeamNameSetting';
import { TeamSlugSetting } from './TeamSlugSetting';
import { DeleteTeamSetting } from './DeleteTeamSetting';
import { useTeamTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const Settings = withErrorBoundary(() => {
	useTeamTitle('Team settings');
	const { canDo } = useContext(TeamContext);
	const { teamSlug } = useParams();

	const [result, setResult] = useState({
		isLoading: true,
		data: {},
	});

	useAsyncEffect(
		async (isActive) => {
			const { data } = await graphql({
				query: TEAM_QUERY,
				variables: {
					slug: teamSlug,
				},
			});
			if (!isActive()) {
				return;
			}
			setResult({
				isLoading: false,
				node: data.data.team,
			});
		},
		[teamSlug]
	);

	const handleChange = (updatedTeam) =>
		setResult({
			isLoading: false,
			node: {
				...result.node,
				...updatedTeam,
			},
		});

	return (
		<div className="lg:mr-48 mt-24 pt-6 mx-5 md:mx-10">
			<Heading className="mb-6">Team settings</Heading>
			<div>
				<TeamNameSetting team={result} onChange={handleChange} />
				<TeamSlugSetting team={result} onChange={handleChange} />
				{canDo(SCOPE_DELETE_TEAM) && <DeleteTeamSetting team={result} />}
			</div>
		</div>
	);
});
