import {
	TYPE_ASSET,
	TYPE_TEXT,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_BIREF,
} from '../constants';
import { pick } from 'ramda';
import Helpers from '../Helpers';

export const getDataToSave = (attributeData, isNew) => {
	const base = {
		...pick(
			['attributeId', 'modelId', 'name', 'apiId', 'apiIdPlural', 'isRequired'],
			attributeData
		),
		...(isNew ? pick(['isList', 'type'], attributeData) : {}),
	};

	switch (attributeData.__typename) {
		case ATTRIBUTE_TYPENAME_COMPONENT:
			return {
				...base,
				...pick(['allowedComponents'], attributeData),
			};
		case ATTRIBUTE_TYPENAME_UNIREF:
			if (isNew) {
				return {
					...base,
					...pick(['referencedCollection'], attributeData),
				};
			}
			break;
		case ATTRIBUTE_TYPENAME_BIREF:
			if (isNew) {
				return {
					...base,
					...pick(['referencedCollection', 'inverseAttribute'], attributeData),
				};
			}
			break;
		case ATTRIBUTE_TYPENAME_ENUM:
			if (isNew) {
				return {
					...base,
					...pick(['enumerationId'], attributeData),
				};
			}
			break;
	}

	switch (attributeData.type) {
		case TYPE_TEXT:
			return {
				...base,
				...pick(['isUnique'], attributeData),
			};
		case TYPE_ASSET:
			return {
				...base,
				validations: attributeData.validations,
			};
	}
	return base;
};

export const getAttributeLabel = (attribute) => {
	if (attribute.__typename === ATTRIBUTE_TYPENAME_BASIC) {
		return Helpers.t(`attr_types.${attribute.type}`);
	}
	return Helpers.t(`attr_typenames.${attribute.__typename}`);
};
