import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import UPDATE_USER_MUTATION from './UpdateUser.gql';
import { graphql } from '../api';
import toast from 'react-hot-toast';

export const EmailSetting = ({ user, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: pathOr('', ['email'], user),
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await graphql(
					{
						query: UPDATE_USER_MUTATION,
						variables: {
							input: values,
						},
					},
					'users'
				);
				const payload = res.data.data.updateUser;

				if (payload.userInputErrors.length > 0) {
					setErrors(payload.userInputErrors);
				} else {
					onChange(payload.user);
					toast.success('Email saved');
				}
			} catch (err) {
				console.error(err);
				toast.error('Failed to change email.');
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Your email</p>
					<p className="mb-4">
						Enter the email that you will use to login to Platform.
					</p>
					<div className="w-full md:w-96">
						<TextField
							disabled={!user || form.isSubmitting}
							value={form.values.email}
							name="email"
							onChange={form.handleChange}
							error={extractGraphqlError('email', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
