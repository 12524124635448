import React, { useState } from 'react';
import { Button, RadioFields, TextField } from '@platformapp/ui';
import Helpers from '../Helpers';
import { set, lensProp } from 'ramda';
import { FilterOperators } from './Operators';

const OPERATORS = Object.freeze([
	FilterOperators.Equal,
	FilterOperators.NotEqual,
]);

export const StringFilter = ({ predicate, onChange, onRequestClose }) => {
	const [value, setValue] = useState(predicate.value);
	const handleOperatorChange = (value) =>
		onChange(set(lensProp('operator'), value, predicate));
	return (
		<div className="px-4 py-3" role="menu">
			<RadioFields
				onChange={handleOperatorChange}
				options={OPERATORS.map((value) => ({
					label: Helpers.t(`filters.string.${value}`),
					value,
				}))}
				value={predicate.operator}
			/>
			<div className="mb-3 mt-3">
				<TextField value={value} onChange={(e) => setValue(e.target.value)} />
			</div>
			<Button
				disabled={predicate.value === value}
				primary
				onClick={() => {
					onChange(set(lensProp('value'), value, predicate));
					onRequestClose();
				}}
			>
				Apply
			</Button>
		</div>
	);
};
