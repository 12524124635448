import { useState } from 'react';
// @ts-ignore
import { CheckboxField, Popover, Button, Icon } from '@platformapp/ui';
// @ts-ignore
import PinIcon from '../icons/pin.svg';
// @ts-ignore
import TriangleDownIcon from '../icons/triangle-down.svg';
import { toggleListItem } from '../util';

type Column = {
	key: string;
	label: string;
}

type ColumnPinMenuProps = {
	onChange: (apiIds: string[]) => void;
	pinnableColumns: Column[];
	pinnedColumns: string[];
}

type ColumnListProps = {
	onChange: (apiIds: string[]) => void;
	pinnableColumns: Column[];
	pinnedColumns: string[];
}

const ColumnList = ({
	onChange,
	pinnableColumns,
	pinnedColumns
}: ColumnListProps) => (
	<div className="px-3 py-2">
		{pinnableColumns.map((col) => (
			<div
				className="mb-2 last:mb-0 flex items-center gap-1"
				key={col.key}
			>
				<CheckboxField
					label={col.label}
					checked={pinnedColumns.includes(col.key)}
					onChange={() => onChange(toggleListItem(col.key, pinnedColumns))}
				/>
			</div>
		))}
	</div>
)

export const ColumnPinMenu = ({
	onChange,
	pinnableColumns,
	pinnedColumns
}: ColumnPinMenuProps) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<Popover
			active={isActive}
			onClose={() => setIsActive(false)}
			trigger={
				<Button
					styleOnHover
					className="text-gray-700 flex items-center"
					tabIndex="0"
					width="small"
					onClick={() => setIsActive(!isActive)}
				>
					<Icon source={PinIcon} />
					<Icon source={TriangleDownIcon} className="ml-1" />
				</Button>
			}
		>
			<ColumnList
				onChange={onChange}
				pinnableColumns={pinnableColumns}
				pinnedColumns={pinnedColumns}
			/>
		</Popover>
	);
};
