import { Transforms, Element, Node, Editor, Text } from 'slate'

export const ensureChildrenAreElements = (editor, path) => {
    for (const [child, childPath] of Node.children(editor, path)) {
        if (!Element.isElement(child)) {
            Transforms.wrapNodes(
                editor,
                { type: 'paragraph', children: [] },
                { at: childPath }
            )
            return;
        }
    }
}

export const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    })
    return !!match
}

/**
 * Transform the selected node into the provided block type, or lift the node up. This
 * works for nodes like block quotes, which contain a set of nested elements (paragraphs,
 * headings, etc).
 * @param {Editor} editor Slate editor root
 * @param {String} format Block type
 */
export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)

    if (isActive) {
        Transforms.liftNodes(editor);
    } else {
        Transforms.setNodes(editor, {
            type: format,
        })
    }
}

export const isMarkActive = (editor, mark) => {
    const [match] = Editor.nodes(editor, {
        match: n => n[mark] === true,
        universal: true,
    })
    return !!match
}

export const toggleMark = (editor, mark) => {
    const isActive = isMarkActive(editor, mark);
    const props = {};
    props[mark] = isActive ? null : true;
    Transforms.setNodes(
        editor,
        props,
        { match: n => Text.isText(n), split: true }
    )
}