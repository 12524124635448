import React from 'react';
import { Card } from '@platformapp/ui';
import { Link } from 'react-router-dom';

export const ProjectCard = ({ project, to }) => (
	<Link className="block w-full sm:w-1/2 lg:w-1/3 p-3" to={to}>
		<Card
			key={project.id}
			hoverable
			className="transition-colors duration-150 border-gray-400"
		>
			<div className="p-4">
				<span className="flex items-center">
					<span className="block flex-grow truncate font-medium text-base">
						{project.name}
					</span>
				</span>
			</div>
		</Card>
	</Link>
);
