import React from 'react';
import { DevelopersSidebar } from './DevelopersSidebar';
import { Route, Switch } from 'react-router-dom';
import { DevelopersLanding } from './DevelopersLanding';
import { WebhookList } from '../webhooks/WebhookList';
import { ApiKeys } from '../api-keys/ApiKeys';
import { ApiKeyForm } from '../api-key/ApiKeyForm';
import { EditApiKey } from '../api-key/EditApiKey';
import { Webhook } from '../webhooks/Webhook';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';

export const Developers = () => (
	<MainContentContainer>
		<MainContentNav>
			<DevelopersSidebar />
		</MainContentNav>
		<Switch>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/webhooks/:webhookId',
					'/:teamSlug/:projectId/webhooks/:webhookId/settings',
					'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId',
					'/:teamSlug/:projectId/env/:envSlug/webhooks/:webhookId/settings',
				]}
				component={Webhook}
			/>
			<Route>
				<MainContent>
					<Switch>
						<Route
							exact
							path="/:teamSlug/:projectId/developers"
							component={DevelopersLanding}
						/>
						<Route
							exact
							path="/:teamSlug/:projectId/webhooks"
							component={WebhookList}
						/>
						<Route path="/:teamSlug/:projectId/api-keys">
							<Switch>
								<Route
									exact
									path="/:teamSlug/:projectId/api-keys"
									component={ApiKeys}
								/>
								<Route
									exact
									path="/:teamSlug/:projectId/api-keys/new"
									component={ApiKeyForm}
								/>
								<Route
									exact
									path="/:teamSlug/:projectId/api-keys/:apiKeyId"
									component={EditApiKey}
								/>
							</Switch>
						</Route>
						<Route
							exact
							path="/:teamSlug/:projectId/env/:envSlug/developers"
							component={DevelopersLanding}
						/>
						<Route
							exact
							path="/:teamSlug/:projectId/env/:envSlug/webhooks"
							component={WebhookList}
						/>
						<Route path="/:teamSlug/:projectId/env/:envSlug/api-keys">
							<Switch>
								<Route
									exact
									path="/:teamSlug/:projectId/env/:envSlug/api-keys"
									component={ApiKeys}
								/>
								<Route
									exact
									path="/:teamSlug/:projectId/env/:envSlug/api-keys/new"
									component={ApiKeyForm}
								/>
								<Route
									exact
									path="/:teamSlug/:projectId/env/:envSlug/api-keys/:apiKeyId"
									component={EditApiKey}
								/>
							</Switch>
						</Route>
					</Switch>
				</MainContent>
			</Route>
		</Switch>
	</MainContentContainer>
);
