import ReactModal from 'react-modal';

import React, { useEffect } from 'react';

export const ModalStackLayer = ({ onRequestClose, children }) => {
	// Ensure body has stacked class
	useEffect(() => {
		document.body.classList.add('stacked');
		return () => {
			// If this is the last layer in stack, remove stacked class from body
			if (document.querySelectorAll('.stack-portal').length === 0) {
				document.body.classList.remove('stacked');
			}
		};
	}, []);

	return (
		<ReactModal
			isOpen={true}
			overlayClassName="fixed inset-0 bg-black/30 fade-in z-20"
			className="ReactModal__Content ReactModal__Content--after-open top-0 right-0 md:ml-32 h-full focus:outline-none overflow-y-scroll bg-white drop-shadow-2xl rounded-tl-xl rounded-bl-xl"
			onRequestClose={() => onRequestClose()}
			portalClassName="stack-portal"
			bodyOpenClassName="overflow-hidden"
		>
			{children}
		</ReactModal>
	);
};
