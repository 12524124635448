import React from 'react';
import { P } from './Text';

export const EmptyMessage = ({
    title,
    message,
    children,
    className,
    ...props
}) => (
    <div
        className={`${className} flex items-center flex-col border-2 border-dashed rounded-md py-16 mt-16 mx-6 md:mx-10 px-10 lg:mx-32`}
        {...props}
    >
        <h1 className="text-xl text-center">{title}</h1>
        {message && (
            <P light centered className="mt-4">{message}</P>
        )}
        {children && (
            <div className="mt-6">
                {children}
            </div>
        )}
    </div>
)