import mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';
import { useEffect, useRef } from 'react';

export const useMousetrap = (handlerKey, handlerCallback, evtType) => {
	let actionRef = useRef(null);
	actionRef.current = handlerCallback;

	useEffect(() => {
		mousetrap.bindGlobal(
			handlerKey,
			(evt, combo) => {
				return (
					typeof actionRef.current === 'function' &&
					actionRef.current(evt, combo)
				);
			},
			evtType
		);
		return () => {
			mousetrap.unbind(handlerKey);
		};
	}, [handlerKey]);
};
