import React from 'react';
import { formatShortDate } from '../util';
import { Message } from '@platformapp/ui';
import { B } from '../common/Text';

export const SubscriptionDelinquent = ({ subscription }) => {
	if (!subscription.is_delinquent) {
		return null;
	}

	return (
		<Message
			className="mb-6"
			header="We were unable to collect payment for your subscription"
			danger
		>
			<p className="mb-3">
				Please ensure that your card details are correct and that sufficient
				funds are available.
			</p>
			<p>
				Payment will be retried on{' '}
				<B>{formatShortDate(subscription.payment_retry)}</B>
			</p>
		</Message>
	);
};
