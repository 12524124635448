import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateSettings } from './mutations';
import toast from 'react-hot-toast';

export const StoreNameSetting = ({ settings, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			storeName: pathOr('', ['storeName'], settings.data.ecommerceSettings),
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await updateSettings(settings.data.id, values);
				toast.success('Store name updated');
				onChange(res);
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to update store name.');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Store name</p>
					<p className="mb-4">
						This will be displayed to customers at checkout and in emails.
					</p>
					<div className="w-full md:w-96">
						<TextField
							disabled={settings.isLoading || form.isSubmitting}
							value={form.values.storeName}
							name="storeName"
							onChange={form.handleChange}
							error={extractGraphqlError('storeName', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
