import React, { useState } from 'react';
import { EditableVariantsTable } from './EditableVariantsTable';

export const NewVariantsTable = ({ form, readOnly, ...props }) => {
	const [selection, setSelection] = useState([]);
	return (
		<EditableVariantsTable
			data={form.values.variants}
			onChange={(data) => form.setFieldValue('variants', data)}
			onDelete={() => {
				if (selection.length === form.values.variants.length) {
					// User is trying to remove all variants. Just remove all options and leave
					// the first variant.
					form.setValues({
						options: [],
						variants: [
							{
								...form.values.variants[0],
								options: [],
							},
						],
					});
				} else {
					form.setFieldValue(
						'variants',
						form.values.variants.filter(
							(_, index) => !selection.includes(index)
						)
					);
				}
				setSelection([]);
			}}
			options={form.values.options}
			readOnly={readOnly}
			selection={selection}
			setSelection={setSelection}
			{...props}
		/>
	);
};
