import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TeamListContainer } from '../team-members/TeamListContainer';
import { Billing } from '../billing/Billing';
import { Usage } from '../usage/Usage';
import { Settings } from './Settings';
import { TeamProjects } from './TeamProjects';
import { Project } from '../Project';
import { Chrome } from '../common/Chrome';

export const Team = () => (
	<Switch>
		<Route
			exact
			path={[
				'/:teamSlug/:projectId/blueprints/:blueprintId',
				'/:teamSlug/:projectId/forms/:formId',
				'/:teamSlug/:projectId/forms/:formId/settings',

				'/:teamSlug/:projectId/env/:envSlug/blueprints/:blueprintId',
				'/:teamSlug/:projectId/env/:envSlug/forms/:formId',
				'/:teamSlug/:projectId/env/:envSlug/forms/:formId/settings',
			]}
			render={() => (
				<Chrome>
					<Project />
				</Chrome>
			)}
		/>
		<Route
			exact
			path="/:teamSlug"
			render={() => (
				<Chrome>
					<TeamProjects />
				</Chrome>
			)}
		/>
		<Route
			exact
			path="/:teamSlug/members"
			render={() => (
				<Chrome>
					<TeamListContainer />
				</Chrome>
			)}
		/>
		<Route
			exact
			path="/:teamSlug/billing"
			render={() => (
				<Chrome>
					<Billing />
				</Chrome>
			)}
		/>
		<Route
			exact
			path="/:teamSlug/usage"
			render={() => (
				<Chrome>
					<Usage />
				</Chrome>
			)}
		/>
		<Route
			exact
			path="/:teamSlug/settings"
			render={() => (
				<Chrome>
					<Settings />
				</Chrome>
			)}
		/>
		<Route
			path="/:teamSlug/:projectId"
			render={() => (
				<Chrome>
					<Project />
				</Chrome>
			)}
		/>
	</Switch>
);
