import React, { useState } from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from 'react-stripe-elements';
import { Field } from '@platformapp/ui';

const stripeClasses =
	'stripe-element rounded border border-gray-400 hover:border-blue-700 bg-white cursor-text px-3 py-2';
const focusedClasses = 'border-blue-700 shadow-outline';

const ElementField = ({ children }) => {
	const [isFocused, setIsFocused] = useState(false);
	return children({
		isFocused,
		setIsFocused,
		className: `${stripeClasses} ${isFocused ? focusedClasses : ''}`,
	});
};

export const UpdatePaymentForm = () => (
	<div className="grid grid-cols-12 gap-4">
		<div className="col-span-6">
			<Field label="Card number">
				<ElementField>
					{({ setIsFocused, className }) => (
						<CardNumberElement
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							className={className}
						/>
					)}
				</ElementField>
			</Field>
		</div>
		<div className="col-span-3">
			<Field label="Expiry date">
				<ElementField>
					{({ setIsFocused, className }) => (
						<CardExpiryElement
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							className={className}
						/>
					)}
				</ElementField>
			</Field>
		</div>
		<div className="col-span-3">
			<Field label="CVC">
				<ElementField>
					{({ setIsFocused, className }) => (
						<CardCvcElement
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							className={className}
						/>
					)}
				</ElementField>
			</Field>
		</div>
	</div>
);
