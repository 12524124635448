import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { graphql } from '../api';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Modal,
	ModalContent,
	ModalFooter,
	TextField,
} from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { withErrorBoundary } from '../common/ErrorBoundary';
import CREATE_HOOK from './createHook.gql';
import UPDATE_HOOK from './updateHook.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { EventsChoiceList } from './EventsChoiceList';
import toast from 'react-hot-toast';

const EditWebhookModalComponent = ({
	onCancel,
	onConfirm,
	existingHook,
	initialValues,
}) => {
	const { environment } = useContext(EnvironmentContext);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: initialValues,
		onSubmit: (values, { setErrors, setSubmitting }) => {
			const input = {
				...values,
			};

			if (existingHook) {
				input.webhookId = existingHook.id;
			} else {
				input.environmentId = environment.node.id;
			}

			graphql({
				query: existingHook ? UPDATE_HOOK : CREATE_HOOK,
				variables: {
					input: input,
				},
			})
				.then((res) => {
					const key = existingHook ? 'updateWebhook' : 'createWebhook';

					if (res.data.data[key].userErrors.length > 0) {
						setErrors(res.data.data[key].userErrors);
						setSubmitting(false);
					} else if (res.data.data[key].errors) {
						toast.error(
							`Webhook ${existingHook ? 'update' : 'creation'} failed`
						);
						setSubmitting(false);
					} else {
						onConfirm(res.data.data[key].webhook);
					}
				})
				.catch((err) => {
					console.error(err);
					toast.error(`Webhook ${existingHook ? 'update' : 'creation'} failed`);
					setSubmitting(false);
				});
		},
	});

	return (
		<Modal
			title={
				existingHook ? `Editing ${existingHook.name}` : 'Create a new webhook'
			}
			tiny
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					{extractGraphqlError('environmentId', form.errors).length > 0 && (
						<ErrorMessageElement
							message={extractGraphqlError('environmentId', form.errors)}
						/>
					)}

					<TextField
						name="name"
						label="Name"
						onBlur={form.handleBlur}
						onChange={form.handleChange}
						value={form.values.name}
						error={extractGraphqlError('name', form.errors)}
						autoFocus
						showCharacterCount={true}
						maxChars={40}
					/>

					<TextField
						name="url"
						label="URL"
						onBlur={form.handleBlur}
						onChange={form.handleChange}
						value={form.values.url}
						error={extractGraphqlError('url', form.errors)}
						placeholder="https://..."
					/>

					<EventsChoiceList
						error={extractGraphqlError('events', form.errors)}
						onChange={(values) => form.setFieldValue('events', values)}
						value={form.values.events}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							{existingHook ? 'Save' : 'Create webhook'}
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};

EditWebhookModalComponent.defaultProps = {
	existingId: null,
	initialValues: {
		name: '',
		url: '',
		events: [],
	},
};

export const EditWebhookModal = withErrorBoundary(EditWebhookModalComponent);
