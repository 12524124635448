import React, { useState } from 'react';
import { graphql } from '../api';
import { BlueprintEditorInner } from './BlueprintEditorInner';
import { withErrorBoundary } from '../common/ErrorBoundary';
import DELETE_ATTRIBUTE_MUTATION from './deleteAttributeMutation.gql';
import MOVE_ATTRIBUTE_MUTATION from './moveAttributeMutation.gql';
import toast from 'react-hot-toast';

const BlueprintEditorContainerComponent = ({ blueprint, onChange }) => {
	const [isDeletingAttribute, setIsDeletingAttribute] = useState(false);

	const moveAttribute = async (id, destInd) => {
		const promise = graphql({
			query: MOVE_ATTRIBUTE_MUTATION,
			variables: {
				input: {
					attributeId: id,
					position: destInd,
				},
			},
		});

		toast.promise(promise, {
			loading: 'Saving order',
			success: 'Order saved',
			error: 'Failed to save order',
		});

		const { data } = await promise;
		onChange(data.data.moveAttribute.attribute.model);
	};

	const handleDeleteAttribute = async (id) => {
		setIsDeletingAttribute(true);
		try {
			const { data } = await graphql({
				query: DELETE_ATTRIBUTE_MUTATION,
				variables: {
					input: {
						attributeId: id,
					},
				},
			});
			onChange(data.data.deleteAttribute.attribute.model);
		} catch (err) {
			console.error(err);
		} finally {
			setIsDeletingAttribute(false);
		}
	};

	return (
		<BlueprintEditorInner
			result={blueprint}
			moveAttribute={moveAttribute}
			updateAll={onChange}
			deleteAttribute={handleDeleteAttribute}
			blueprint={blueprint.data}
			isDeletingAttribute={isDeletingAttribute}
		/>
	);
};

export const BlueprintEditorContainer = withErrorBoundary(
	BlueprintEditorContainerComponent
);
