import React, { useState } from 'react';
import { PublishStatusLabel } from '../common/PublishStatusLabel';
import { ErrorMessageElement, Icon } from '@platformapp/ui';
import { isNil, mergeDeepRight, move, remove } from 'ramda';
import { DragHandle } from '../common/DragHandle';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { extractGraphqlError, makeId } from '../util';
import DeleteIcon from '../icons/x.svg';
import { statusFromStages } from '../util/publishing';
import { Thumbnail } from './Thumbnail';
import { AssetDetailsLayer } from '../assets/AssetDetailsLayer';

const AssetSlat = ({
	canReorder,
	dragHandleProps,
	onViewDetails,
	onRemove,
	readOnly,
	value,
}) => (
	<div className="flex rounded px-4 py-3 border border-gray-300 items-center bg-white">
		<div className="flex-grow flex items-center">
			{canReorder && <DragHandle {...dragHandleProps} />}
			<div className="w-10 h-10 flex-shrink-0 mr-3">
				<Thumbnail disabled value={value} />
			</div>
			<button
				type="button"
				className="truncate inline-block text-left text-blue-700 hover:text-blue-800 text-base focus:outline-none focus:shadow-outline"
				onClick={() => onViewDetails()}
				title={value.title || value.filename}
			>
				{value.title || value.filename}
			</button>
		</div>
		<div className="flex-shrink-0 flex items-center">
			<PublishStatusLabel status={statusFromStages(value)} />
			{onRemove && (
				<button
					disabled={readOnly}
					className={`${
						readOnly ? 'cursor-auto' : ''
					} text-gray-600 hover:text-gray-700 ml-3`}
					title="Remove item from list"
					onClick={() => onRemove()}
				>
					<Icon source={DeleteIcon} />
				</button>
			)}
		</div>
	</div>
);

AssetSlat.defaultProps = {
	canReorder: false,
};

const AssetSlatList = ({ onChange, onViewDetails, readOnly, value }) => {
	if (isNil(value)) {
		return null;
	}
	return (
		<DragDropContext
			onDragEnd={(res) => {
				if (!res.destination || res.source.index === res.destination.index) {
					return;
				}
				onChange({
					...value,
					edges: move(res.source.index, res.destination.index, value.edges),
				});
			}}
		>
			<Droppable droppableId={makeId()} isDropDisabled={readOnly}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{value.edges.map(({ node }, i) => (
							<Draggable
								key={node.id}
								draggableId={node.id}
								index={i}
								isDragDisabled={readOnly}
							>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										style={provided.draggableProps.style}
										className="mb-3"
									>
										<AssetSlat
											canReorder
											dragHandleProps={provided.dragHandleProps}
											onViewDetails={() => onViewDetails(node.id)}
											onRemove={() =>
												onChange({
													...value,
													edges: remove(i, 1, value.edges),
												})
											}
											readOnly={readOnly}
											value={node}
										/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export const MultiAssetList = ({
	onChange,
	pathPrefix,
	readOnly,
	validationErrors,
	value,
}) => {
	const [viewingDetails, setViewingDetails] = useState(null);
	return (
		<>
			{viewingDetails && (
				<AssetDetailsLayer
					onCancel={() => setViewingDetails(null)}
					onSave={(val) => {
						setViewingDetails(null);

						onChange({
							...value,
							edges: value.edges.map((edge) => {
								if (edge.node.id === val.id) {
									return {
										node: mergeDeepRight(edge.node, val),
									};
								}

								return edge;
							}),
						});
					}}
					assetId={viewingDetails}
				/>
			)}
			<AssetSlatList
				onChange={onChange}
				onViewDetails={setViewingDetails}
				readOnly={readOnly}
				value={value}
			/>
			<ErrorMessageElement
				message={extractGraphqlError(pathPrefix, validationErrors)}
			/>
		</>
	);
};
