import React, { useContext } from 'react';
import { ProjectContext } from '../context/ProjectContext';
import { BLUEPRINT_VIEW_METADATA_KEY, VIEW_INBOX } from '../constants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { getMetadataValue } from '../util/metadata';
import { Sidebar, SidebarLink } from '../common/Sidebar';
import { hasAttributes } from '../util';

export const FormsSidebar = () => {
	const { generateEnvPath, environment, collections } = useContext(
		EnvironmentContext
	);
	const { isLoading } = useContext(ProjectContext);
	const formBps = environment.isLoading
		? []
		: collections
				.filter(
					(node) =>
						getMetadataValue(node.metadata, BLUEPRINT_VIEW_METADATA_KEY) ===
						VIEW_INBOX
				)
				.filter(hasAttributes);
	return (
		<Sidebar title="Forms">
			{!isLoading && !environment.isLoading && (
				<>
					{formBps.map((node) => (
						<SidebarLink
							key={node.id}
							to={generateEnvPath(`forms/${node.apiId}`)}
						>
							{node.name}
						</SidebarLink>
					))}
				</>
			)}
		</Sidebar>
	);
};
