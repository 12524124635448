import React from 'react';
import { ErrorLandingMessage } from './ErrorLandingMessage';
import { TextStyle } from '@platformapp/ui';

export const DataLoadErrorMessage = () => (
	<ErrorLandingMessage
		title="Unable to load this data"
		body={
			<p className="leading-normal">
				<TextStyle subdued>
					Make sure your device is connected to the internet and try again.
					<br />
					Contact support if the issue persists.
				</TextStyle>
			</p>
		}
	/>
);
