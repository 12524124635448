import { useContext, useState } from 'react';
import { propOr } from 'ramda';
import { VariantsTable } from './VariantsTable';
import { AdjustInventoryModal } from '../inventory/AdjustInventoryModal';
import { VariantLayer } from '../product-variants/VariantLayer';
import { DeleteItemModal } from '../publishable/DeleteItemModal';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Product } from './ProductEditReducer';

type ExistingVariantTableProps = {
	onDelete: (ids: string[]) => void;
	onInventoryUpdate: (variants: any[]) => void;
	onUpdate: (variants: any[]) => void;
	product: Product;
	readOnly: boolean;
}

export const ExistingVariantTable = ({
	onDelete,
	onInventoryUpdate,
	onUpdate,
	product,
	readOnly,
}: ExistingVariantTableProps) => {
	const { collectionByApiId } = useContext(EnvironmentContext);
	const [selection, setSelection] = useState([]);
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isAdjustingQty, setIsAdjustingQty] = useState(false);
	const [editingVariant, setEditingVariant] = useState(null);

	return (
		<>
			{isConfirmingDelete && (
				<DeleteItemModal
					collection={collectionByApiId('ProductVariant').node}
					items={product.variants
						.filter((variant: any) => selection.includes(variant.id))
						.map((variant: any) => ({
							...variant,
							__title: variant.options.join(' / '),
						}))}
					onCancel={() => setIsConfirmingDelete(false)}
					onDelete={(ids) => {
						setSelection([]);
						onDelete(ids);
						setIsConfirmingDelete(false);
					}}
				/>
			)}

			{isAdjustingQty && (
				<AdjustInventoryModal
					inventoryItems={product.variants
						.filter(v => selection.includes(v.id))
						.map((v) => ({
							id: v.inventoryItem.id,
							available: v.inventoryItem.available,
							productName: product.name,
							variantOptions: v.options,
						}))}
					onCancel={() => setIsAdjustingQty(false)}
					onUpdate={(updatedItems: any) => {
						setIsAdjustingQty(false);
						onInventoryUpdate(updatedItems);
					}}
				/>
			)}

			{editingVariant && (
				<VariantLayer
					onCancel={() => setEditingVariant(null)}
					onSave={(variant: any) => {
						setEditingVariant(null);
						onUpdate([variant]);
					}}
					product={product}
					productId={product.id}
					productOptions={product.options}
					item={product.variants.find((v: any) => v.id === editingVariant)}
					variantId={editingVariant}
				/>
			)}

			{propOr<string[], any, any>([], 'options', product).length > 0 && (
				<VariantsTable
					readOnly={readOnly}
					className="mt-6"
					options={product.options}
					data={product.variants}
					onAdjustQuantitiesClick={() => setIsAdjustingQty(true)}
					onDeleteClick={() => setIsConfirmingDelete(true)}
					onEditClick={(id: string) => setEditingVariant(id)}
					onSelectionChange={setSelection}
					selectable
					selection={selection}
				/>
			)}
		</>
	);
};
