import React, { useContext, useEffect, useState } from 'react';
import { B } from './common/Text';
import { UserContext } from './context/UserContext';

const LS_KEY = 'pl:verificationHiddenUntil';

const getHiddenUntilFromLs = () => {
	const lsData = localStorage.getItem(LS_KEY);
	if (!lsData) {
		return null;
	}
	return new Date(lsData);
};

export const VerificationWarning = () => {
	const { user } = useContext(UserContext);
	const [hiddenUntil, setHiddenUntil] = useState(getHiddenUntilFromLs());

	useEffect(() => {
		if (hiddenUntil) {
			localStorage.setItem(LS_KEY, hiddenUntil.toISOString());
		}
	}, [hiddenUntil]);

	if (user.isVerified || (hiddenUntil !== null && hiddenUntil > new Date())) {
		return null;
	}

	return (
		<div className="fixed top-0 left-0 w-full px-5 py-2 z-20 bg-orange-600 flex items-center justify-center">
			<p className="text-white text-center leading-normal">
				Please verify your account by following the link sent to{' '}
				<B>{user.email}</B>
			</p>
			<button
				className="text-white text-xs ml-4 font-medium"
				onClick={() => {
					const d = new Date();
					d.setDate(d.getDate() + 1);
					setHiddenUntil(d);
				}}
			>
				Hide for 24 hours
			</button>
		</div>
	);
};
