import React from 'react';
import { AttributeRow } from './AttributeRow';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { makeId } from '../util';

export const AttributesList = ({
	blueprint,
	attributes,
	deleteAttribute,
	editAttribute,
	onSortEnd,
	isDeletingAttribute,
}) => {
	const onDragEnd = (res) => {
		if (!res.destination || res.source.index === res.destination.index) {
			return;
		}
		onSortEnd(res.draggableId, res.destination.index);
	};

	return (
		<DragDropContext onDragEnd={(res) => onDragEnd(res)}>
			<Droppable droppableId={makeId()}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{attributes.map((attr, i) => (
							<Draggable key={attr.id} draggableId={attr.id} index={i}>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										style={provided.draggableProps.style}
										className="mb-4 last:mb-0"
									>
										<AttributeRow
											attribute={attr}
											deleteAttribute={deleteAttribute}
											editAttribute={editAttribute}
											index={i}
											dragHandleProps={provided.dragHandleProps}
											blueprint={blueprint}
											isDeletingAttribute={isDeletingAttribute}
										/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
