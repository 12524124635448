import React from 'react';
import { STAGE_DRAFT, STAGE_PUBLISHED } from '../constants';
import { RadioField } from '@platformapp/ui';

export const DefaultStageRadioGroup = ({ disabled, onChange, value }) => (
	<div className="mt-2 flex-shrink-0 flex">
		<div className="mr-6">
			<RadioField
				disabled={disabled}
				label="Draft"
				checked={value === STAGE_DRAFT}
				onChange={() => onChange(STAGE_DRAFT)}
			/>
		</div>
		<div className="mr-6">
			<RadioField
				disabled={disabled}
				label="Published"
				checked={value === STAGE_PUBLISHED}
				onChange={() => onChange(STAGE_PUBLISHED)}
			/>
		</div>
	</div>
);
