import React, { useState, useContext } from 'react';
import {
	EDITOR_MODE_NEW,
	EDITOR_MODE_COPY,
	EDITOR_MODE_EDIT,
} from '../constants';
import { useParams } from 'react-router-dom';
import {
	createAttributeValues,
	initialAttributeValues,
} from '../util/AttributeHelpers';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { EditContentForm } from './EditContentForm';
import { NotFound } from '../NotFound';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { ContentSidebar } from '../content-list/ContentSidebar';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { HeadingLoader, TableSkeleton } from '../common/Skeleton';
import { ContentApiContext } from '../context/ContentApiContext';
import { useAsyncEffect } from 'use-async-effect';

export const EditContentContainer = ({ mode: initialMode }) => {
	const { environment, collectionById } = useContext(EnvironmentContext);
	const { blueprintId, id: contentId } = useParams();
	const blueprint = collectionById(blueprintId);
	const [content, setContent] = useState({
		isLoading: initialMode !== EDITOR_MODE_NEW,
		data: {},
	});
	const { getItem } = useContext(ContentApiContext);

	useEnvironmentTitle(
		initialMode === EDITOR_MODE_NEW
			? blueprint.isLoading
				? null
				: `New ${blueprint.node.name}`
			: content.isLoading
			? null
			: content.data.__title || 'Untitled'
	);

	const [mode, setMode] = useState(initialMode);

	useAsyncEffect(
		async (isActive) => {
			const res = await getItem(blueprint.node.apiId, contentId);
			if (isActive()) {
				setContent({
					isLoading: false,
					data: res,
				});
			}
		},
		[environment]
	);

	return (
		<MainContentContainer>
			<MainContentNav>
				<ContentSidebar />
			</MainContentNav>
			<MainContent>
				{(content.isLoading || blueprint.isLoading) && (
					<>
						<div className="mb-10 w-full md:w-40">
							<HeadingLoader />
						</div>
						<TableSkeleton rows={5} />
					</>
				)}

				{!content.data && (
					<NotFound
						header="Item not found"
						body={<p>No such item: {contentId}</p>}
					/>
				)}

				{!content.isLoading && !blueprint.isLoading && content.data && (
					<EditContentForm
						blueprint={blueprint}
						content={content}
						mode={mode}
						setContent={setContent}
						setMode={setMode}
						values={
							mode === EDITOR_MODE_EDIT || mode === EDITOR_MODE_COPY
								? createAttributeValues(content.data, blueprint.node)
								: initialAttributeValues(blueprint.node)
						}
					/>
				)}
			</MainContent>
		</MainContentContainer>
	);
};
