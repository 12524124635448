import React from 'react';
import { Link } from '../common/Link';
import Helpers from '../Helpers';

export const Address = ({ address, showMapLink }) => (
	<div>
		<p className="mb-2 last:mb-0">{address.name}</p>
		<p className="mb-2 last:mb-0">{address.line1}</p>
		{address.line2 && <p className="mb-2 last:mb-0">{address.line2}</p>}
		<p className="mb-2 last:mb-0">{address.city}</p>
		<p className="mb-2 last:mb-0">
			{Helpers.t(`countries.${address.country}`)} ({address.country})
		</p>
		<p className="mb-2 last:mb-0">{address.postalCode}</p>
		{address.phone && <p className="mb-2 last:mb-0">{address.phone}</p>}

		{showMapLink && (
			<p className="mt-3">
				<Link
					to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
						address.postalCode
					)}`}
					external
					newWindow
				>
					View on map
				</Link>
			</p>
		)}
	</div>
);

Address.defaultProps = {
	showMapLink: false,
};
