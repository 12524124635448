import CREATE_API_KEY from './CreateApiKey.gql';
import DELETE_API_KEY from './DeleteApiKey.gql';
import UPDATE_API_KEY from './UpdateApiKey.gql';
import DELETE_API_KEY_PERMISSION from './DeletePermission.gql';
import { graphql } from '../api';

export const updateApiKey = (apiKeyId, data) =>
	new Promise((resolve, reject) =>
		graphql({
			query: UPDATE_API_KEY,
			variables: {
				input: {
					apiKeyId,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateApiKey;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.apiKey);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);

export const createApiKey = (data) =>
	new Promise((resolve, reject) =>
		graphql({
			query: CREATE_API_KEY,
			variables: {
				input: data,
			},
		})
			.then((res) => {
				const payload = res.data.data.createApiKey;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);

export const deleteApiKey = (apiKeyId) =>
	new Promise((resolve, reject) =>
		graphql({
			query: DELETE_API_KEY,
			variables: {
				input: {
					apiKeyId,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteApiKey;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve();
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);

export const deleteApiKeyPermission = (permissionId) =>
	new Promise((resolve, reject) =>
		graphql({
			query: DELETE_API_KEY_PERMISSION,
			variables: {
				input: {
					permissionId,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteApiKeyPermission;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve();
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);
