import React, { useContext, useState } from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import Helpers from '../Helpers';
import DELETE_API_KEY_MUTATION from '../api-key/DeleteApiKey.gql';
import { ConfirmDialog } from '@platformapp/ui';
import { graphql } from '../api';
import { pathOr } from 'ramda';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { B } from '../common/Text';
import toast from 'react-hot-toast';

export const ApiKeyContextMenu = ({ apiKey, onDelete }) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const { generateEnvPath } = useContext(EnvironmentContext);

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					title="Delete API key"
					body={
						<>
							<p className="leading-normal mb-2">
								Are you sure you want to delete <B>{apiKey.name}</B>?
							</p>
							<p className="leading-normal">
								This API key will no longer permit access to any API resources,
								and will be permanently removed.
							</p>
						</>
					}
					onConfirm={() => {
						setIsDeleting(true);

						graphql({
							query: DELETE_API_KEY_MUTATION,
							variables: {
								input: {
									apiKeyId: apiKey.id,
								},
							},
						})
							.then((res) => {
								const userInputErrs = pathOr(
									[],
									['data', 'deleteApiKey', 'userErrors'],
									res.data
								);
								const errs = pathOr(
									[],
									['data', 'deleteApiKey', 'errors'],
									res.data
								);
								if (userInputErrs.length > 0 || errs.length > 0) {
									toast.error('Failed to delete API key');
									setIsDeleting(false);
								} else {
									onDelete();
								}
							})
							.catch((err) => {
								console.error(err);
								toast.error(`Failed to delete API key`);
								setIsDeleting(false);
							})
							.then(() => setIsConfirmingDelete(false));
					}}
					onCancel={() => setIsConfirmingDelete(false)}
					dangerous
					isWorking={isDeleting}
					confirmText="Delete API key"
				/>
			)}
			<NewContextMenu
				styleOnHover
				smallTrigger
				sections={[
					{
						items: [
							{
								label: Helpers.t('general.edit'),
								to: generateEnvPath(`api-keys/${apiKey.id}`),
							},
						],
					},
					{
						items: [
							{
								label: Helpers.t('general.delete'),
								onAction: () => setIsConfirmingDelete(true),
								destructive: true,
							},
						],
					},
				]}
			/>
		</>
	);
};
