import UPDATE_TEAM_MUTATION from './update.gql';
import { graphql } from '../api';

export const updateTeam = (teamId, data) =>
	new Promise((resolve, reject) => {
		graphql({
			query: UPDATE_TEAM_MUTATION,
			variables: {
				input: {
					teamId,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateTeam;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.team);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			});
	});
