import UPDATE_SETTING_MUTATION from './updateSettings.gql';
import { graphql } from '../api';

export const updateSettings = (environmentId, data) =>
	new Promise((resolve, reject) =>
		graphql({
			query: UPDATE_SETTING_MUTATION,
			variables: {
				input: {
					environmentId,
					...data,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.updateEcommerceSettings;
				if (payload.userErrors.length > 0) {
					reject({
						isUserError: true,
						userErrors: payload.userErrors,
					});
				} else {
					resolve(payload.environment);
				}
			})
			.catch((err) => {
				console.error(err);
				reject({
					isUserError: false,
				});
			})
	);
