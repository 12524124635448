import React from 'react';
import { DragHandle } from '../common/DragHandle';
import { Draggable } from 'react-beautiful-dnd';
import { find, propEq, concat } from 'ramda';
import { Badge, ErrorMessageElement } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { ComponentAttribute } from './ComponentAttribute';
import { ComponentListItemActions } from './ComponentListItemActions';

export const ComponentListItem = ({
	attribute,
	index,
	initialValue,
	item,
	onAddAfter,
	onAddBefore,
	onChange,
	onDelete,
	pathPrefix,
	readOnly,
	validationErrors,
}) => {
	const bp = find(
		propEq('apiId', item.__typename),
		attribute.allowedComponents
	);
	const generalError = extractGraphqlError(pathPrefix, validationErrors);
	const typeError = extractGraphqlError(
		concat(pathPrefix, ['type']),
		validationErrors
	);
	const valueError = extractGraphqlError(
		concat(pathPrefix, [item.__typename]),
		validationErrors
	);

	const classNames = ['pb-4'];

	if (generalError.length > 0 || typeError.length > 0) {
		classNames.push('border-red-700');
	}

	return (
		<Draggable
			key={item._draggableId}
			draggableId={item._draggableId}
			index={index}
			isDragDisabled={readOnly}
		>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					style={provided.draggableProps.style}
					className={classNames.join(' ')}
				>
					<div className="bg-white rounded border">
						<div className="flex items-center px-4 py-2 border-b">
							<DragHandle {...provided.dragHandleProps} />
							<Badge text={bp.name} />
							<div className="flex-grow text-right">
								<ComponentListItemActions
									attribute={attribute}
									disabled={readOnly}
									onAddAfter={onAddAfter}
									onAddBefore={onAddBefore}
									onRemove={onDelete}
								/>
							</div>
						</div>
						<div className="p-4">
							<ComponentAttribute
								attribute={attribute}
								initialValue={initialValue}
								onChange={(val) => onChange(val)}
								pathPrefix={concat(pathPrefix, [item.__typename])}
								readOnly={readOnly}
								showClear={false}
								validationErrors={validationErrors}
								value={item}
							/>
							<ErrorMessageElement message={generalError} />
							<ErrorMessageElement message={typeError} />
							<ErrorMessageElement message={valueError} />
						</div>
					</div>
				</div>
			)}
		</Draggable>
	);
};
