import { times } from 'ramda';

export const SkeletonBox = ({ className, ...props }: any) => (
	<div
		className={`${className} bg-gray-200 animate-pulse rounded-md`}
		{...props}
	/>
);

export const CardLoader = () => <SkeletonBox className="h-20" />;

export const CardsLoader = ({ cardCount }: any) => (
	<div className="flex flex-wrap fade-in -mx-3">
		{[...Array(cardCount)].map((e, i) => (
			<div className="w-full sm:w-1/2 lg:w-1/3 p-3" key={i}>
				<CardLoader />
			</div>
		))}
	</div>
);

CardsLoader.defaultProps = {
	cardCount: 4,
};

export const SlatsLoader = ({ count }: any) => (
	<div className="fade-in">
		{[...Array(count)].map((_, i) => (
			<div className="mb-6 last:mb-0" key={i}>
				<CardLoader />
			</div>
		))}
	</div>
);

SlatsLoader.defaultProps = {
	count: 3,
};

export const HeadingLoader = ({ className, ...props }: any) => (
	<SkeletonBox className={`${className} h-8`} {...props} />
);

interface TableSkeletonProps {
	className?: string;
	rows?: number;
}

export const TableSkeleton = ({ rows, ...props }: TableSkeletonProps) => (
	<div {...props}>
		{times(
			(i) => (
				<SkeletonBox key={i} className="h-6 mb-6 last:mb-0" />
			),
			rows
		)}
	</div>
);

TableSkeleton.defaultProps = {
	rows: 5,
};
