import React from 'react';
import {
	Button,
	ButtonGroup,
	Message,
	Modal,
	ModalContent,
	ModalFooter,
	TextField,
} from '@platformapp/ui';
import Helpers from '../Helpers';
import { graphql } from '../api';
import { extractGraphqlError } from '../util';
import { useFormik } from 'formik';
import CREATE_TEAM_MUTATION from './create.gql';

export const CreateTeam = ({ onCancel, onCreate }) => {
	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			name: '',
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			graphql({
				query: CREATE_TEAM_MUTATION,
				variables: {
					input: values,
				},
			})
				.then((res) => {
					const payload = res.data.data.createTeam;
					if (payload.errors) {
						setSubmitting(false);
					} else if (payload.userErrors.length > 0) {
						setErrors(payload.userErrors);
						setSubmitting(false);
					} else {
						onCreate(payload.team);
					}
				})
				.catch((err) => {
					console.error(err);
					setSubmitting(false);
				});
		},
	});

	return (
		<Modal
			title="Create a new team"
			small
			isOpen={true}
			onRequestClose={() => onCancel()}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					<Message info className="mb-4">
						<p>All teams are given a 10 day free trial.</p>
					</Message>
					<TextField
						name="name"
						label="Team name"
						value={form.values.name}
						onChange={form.handleChange}
						error={extractGraphqlError('name', form.errors)}
						autoFocus
						disabled={form.isSubmitting}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button disabled={form.isSubmitting} onClick={() => onCancel()}>
							{Helpers.t('general.cancel')}
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							Create team
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};
