import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Message } from '@platformapp/ui';
import { DeleteSetting } from './DeleteSetting';
// import { StatusSetting } from './StatusSetting';
import { NameSetting } from './NameSetting';
import { ApiIdsSetting } from './ApiIdsSetting';
import { MetadataSetting } from './MetadataSetting';
import { DashboardViewSettings } from './DashboardViewSettings';

export const Settings = withErrorBoundary(({ blueprint, onChange }) => {
	const isEditable = !blueprint.isLoading && !blueprint.data.isSystem;
	const isSystem = !blueprint.isLoading && blueprint.data.isSystem;

	return (
		<>
			<div className="mt-5">
				<div className="fade-in">
					{isSystem && (
						<Message className="mb-4">
							<p>Settings for system blueprints cannot be edited.</p>
						</Message>
					)}

					<NameSetting blueprint={blueprint} onChange={onChange} />
					<ApiIdsSetting blueprint={blueprint} onChange={onChange} />

					{!blueprint.isLoading &&
						blueprint.data.__typename === 'Collection' && (
							<DashboardViewSettings
								collection={blueprint.data}
								onChange={onChange}
							/>
						)}

					{/* {isEditable && (
						<StatusSetting blueprint={blueprint} onChange={onChange} />
					)} */}

					{!blueprint.isLoading && (
						<MetadataSetting blueprint={blueprint} onChange={onChange} />
					)}

					{isEditable && <DeleteSetting blueprint={blueprint} />}
				</div>
			</div>
		</>
	);
});
