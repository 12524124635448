import { CollectionChoiceList } from "./CollectionChoiceList";
// @ts-ignore
import { ChoiceList, TextField } from '@platformapp/ui';
import { Module } from "../common/Module";
import { mergeDeepRight } from "ramda";
import { attributeNameToApiId, extractGraphqlError } from "../util";
import { FormikProps } from "formik";
import { AttributeFormValues } from "./BasicAttributeLayer";
import { Collection } from "../types/Collection";
import { useContext } from "react";
import { EnvironmentContext } from "../context/EnvironmentContext";

enum ReferenceType {
    OneToOne,
    OneToMany,
    ManyToOne,
    ManyToMany
}

type BiRefSettingsProps = {
    collection: Collection;
    form: FormikProps<AttributeFormValues>;
    isNew: boolean;
    userErrors: any[];
}

const getReferenceType = (formValues: any) => {
    if (formValues.isList) {
        return formValues.inverseAttribute.isList
            ? ReferenceType.ManyToMany
            : ReferenceType.OneToMany;
    } else if (formValues.inverseAttribute.isList) {
        return ReferenceType.ManyToOne;
    }
    return ReferenceType.OneToOne;
}

export const BiRefSettings = ({
    collection,
    form,
    isNew,
    userErrors
}: BiRefSettingsProps) => {
    const {collectionById} = useContext(EnvironmentContext);
    const refdColl = form.values.referencedCollection
        ? collectionById(form.values.referencedCollection)
        : null;
    return (<>
        <CollectionChoiceList
            disabled={form.isSubmitting || !isNew}
            label="Referenced collection"
            onChange={id => form.setFieldValue('referencedCollection', id[0])}
            value={form.values.referencedCollection}
        />
        <ChoiceList
            disabled={form.isSubmitting || !isNew}
            label="Reference type"
            value={getReferenceType(form.values).toString()}
            choices={[
                {
                    value: ReferenceType.OneToOne.toString(),
                    label: 'One-to-one'
                },
                {
                    value: ReferenceType.OneToMany.toString(),
                    label: 'One-to-many'
                },
                {
                    value: ReferenceType.ManyToOne.toString(),
                    label: 'Many-to-one'
                },
                {
                    value: ReferenceType.ManyToMany.toString(),
                    label: 'Many-to-many'
                },
            ]}
            onChange={(val: any) => {
                form.setValues(mergeDeepRight(form.values, {
                    isList: [ReferenceType.OneToMany.toString(), ReferenceType.ManyToMany.toString()].includes(val[0]),
                    inverseAttribute: {
                        isList: [ReferenceType.ManyToOne.toString(), ReferenceType.ManyToMany.toString()].includes(val[0]),
                    }
                }))
            }}
        />

        <Module
            bottomMargin
            heading="Attribute"
            subheading={`These settings apply to the attribute created on the ${collection.name} collection.`}
        >
			<TextField
				name="name"
				label="Name"
				helpText="The label that will be shown above this attribute in the Dashboard."
				value={form.values.name}
				onChange={(e: any) => {
                    if (form.status.autoGenerateApiId) {
                        form.setValues(mergeDeepRight(form.values, {
                            apiId: attributeNameToApiId(e.target.value),
                            name: e.target.value
                        }))
                    } else {
                        form.setFieldValue('name', e.target.value);
                    }
				}}
				error={extractGraphqlError('name', userErrors)}
				disabled={form.isSubmitting}
			/>
			<TextField
				name="apiId"
				label="API ID"
				helpText="This will be used in the Content API."
				value={form.values.apiId}
				onChange={(e: any) => {
                    form.setFieldValue('apiId', e.target.value);

                    if (form.status.autoGenerateApiId) {
                        form.setStatus({
                            ...form.status,
                            autoGenerateApiId: false
                        })
                    }
				}}
				className="font-mono"
				error={extractGraphqlError('apiId', userErrors)}
				disabled={form.isSubmitting}
			/>
			<TextField
				name="helpText"
				label="Help text"
				helpText="Appears below the attribute form field to guide team members."
				value={form.values.helpText}
				onChange={form.handleChange}
				error={extractGraphqlError('helpText', userErrors)}
				disabled={form.isSubmitting}
			/>
        </Module>

        <Module
            bottomMargin
            heading="Inverse attribute"
            subheading={`These settings apply to the inverse attribute created on the ${refdColl ? refdColl.node.name : 'referenced'} collection.`}
        >
			<TextField
				name="name"
				label="Name"
				helpText="The label that will be shown above this attribute in the Dashboard."
				value={form.values.inverseAttribute.name}
				onChange={(e: any) => {
                    if (form.status.autoGenerateInverseApiId) {
                        form.setValues(mergeDeepRight(form.values, {
                            inverseAttribute: {
                                apiId: attributeNameToApiId(e.target.value),
                                name: e.target.value
                            }
                        }))
                    } else {
                        form.setFieldValue('inverseAttribute.name', e.target.value);
                    }
				}}
				error={extractGraphqlError(['inverseAttribute', 'name'], userErrors)}
				disabled={form.isSubmitting || !isNew}
			/>
			<TextField
				name="apiId"
				label="API ID"
				helpText="This will be used in the Content API."
				value={form.values.inverseAttribute.apiId}
				onChange={(e: any) => {
                    form.setFieldValue('inverseAttribute.apiId', e.target.value);

                    if (form.status.autoGenerateInverseApiId) {
                        form.setStatus({
                            ...form.status,
                            autoGenerateInverseApiId: false
                        })
                    }
				}}
				className="font-mono"
                error={extractGraphqlError(['inverseAttribute', 'apiId'], userErrors)}
				disabled={form.isSubmitting || !isNew}
			/>
			<TextField
				name="helpText"
				label="Help text"
				helpText="Appears below the attribute form field to guide team members."
				value={form.values.inverseAttribute.helpText}
				onChange={(e: any) => form.setFieldValue('inverseAttribute.helpText', e.target.value)}
                error={extractGraphqlError(['inverseAttribute', 'helpText'], userErrors)}
				disabled={form.isSubmitting || !isNew}
			/>
        </Module>
    </>);
}