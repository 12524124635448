import React from 'react';
import { StickyHeader } from '../common/StickyHeader';
import { Heading } from './Heading';

export const ListPageHeader = ({
	title,
	right,
	bottom,
	top,
	sticky,
	hasTabs,
	bordered,
	...props
}) => (
	<StickyHeader
		bordered={bordered}
		hasTabs={hasTabs}
		sticky={sticky}
		hasPx={false}
		hasPy={false}
		{...props}
	>
		{top && <div>{top}</div>}
		<div
			className={`flex items-center ${
				hasTabs ? 'md:items-end' : 'md:items-start'
			} ${bottom ? ' mb-3' : ''}`}
		>
			<div className={`${hasTabs ? 'mb-4 mt-1' : ''} flex-grow`}>
				{title && <Heading className="truncate">{title}</Heading>}
			</div>
			{right}
		</div>
		{bottom && <div className="flex items-center">{bottom}</div>}
	</StickyHeader>
);

ListPageHeader.defaultProps = {
	bordered: false,
	bottom: null,
	hasTabs: false,
	right: null,
	sticky: false,
	top: null,
};
