import {
	TYPE_DATE,
	TYPE_LONG_TEXT,
	TYPE_MARKDOWN,
	TYPE_NUMBER,
	PINNABLE_BASIC_ATTRIBUTE_TYPES,
	TYPE_TEXT,
	TYPE_ASSET,
	TYPE_BOOLEAN,
	TYPE_DATETIME,
	TYPE_MONEY,
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_ENUM,
	PINNABLE_TYPENAMES,
} from '../constants';
import { prop, has, isEmpty, isNil, find, propEq, assoc, omit } from 'ramda';
import { makeId } from '.';
import Helpers from '../Helpers';
import { Attribute, BasicAttributeType } from '../types/Attribute';
import { Collection } from '../types/Collection';

export const isReferenceAttribute = (attribute: Attribute) =>
	[ATTRIBUTE_TYPENAME_BIREF, ATTRIBUTE_TYPENAME_UNIREF].includes(
		attribute.__typename
	);

export const isComponentAttribute = (attribute: Attribute) => attribute.__typename === 'ComponentAttribute';

export const getTitle = (item: any) =>
	!item.__title || isEmpty(item.__title)
		? Helpers.t('general.untitled')
		: item.__title;

// Process a single value of the given type for saving to API
const getValueForSave = (attribute: Attribute, rawValue: any, isList: boolean, extra: any) => {
	switch (attribute.__typename) {
		case ATTRIBUTE_TYPENAME_BASIC: {
			switch (attribute.type) {
				case TYPE_TEXT:
				case TYPE_LONG_TEXT:
				case TYPE_MARKDOWN:
				case TYPE_BOOLEAN:
				case TYPE_MONEY:
				case TYPE_ASSET:
					return rawValue;
				case TYPE_NUMBER:
					return isNil(rawValue) ? null : parseInt(rawValue, 10);
				case TYPE_DATE:
					if (isNil(rawValue)) {
						return null;
					}
					return rawValue.toISOString().split('T')[0];
				case TYPE_DATETIME:
					if (isNil(rawValue)) {
						return null;
					}
					return rawValue.toISOString();
			}
			break;
		}
		case ATTRIBUTE_TYPENAME_ENUM:
			return isNil(rawValue) || isEmpty(rawValue) ? null : rawValue;
		case ATTRIBUTE_TYPENAME_UNIREF:
		case ATTRIBUTE_TYPENAME_BIREF:
			if (isNil(rawValue)) {
				return null;
			}
			return rawValue;
		case ATTRIBUTE_TYPENAME_COMPONENT: {
			if (isList) {
				if (!rawValue) {
					return [];
				}
				return rawValue.map((item: any) => {
					const values = omit(['__typename', '_draggableId'], item);
					const itemBp: any = find(
						propEq('apiId', item.__typename),
						extra.attr.allowedComponents
					);
					return {
						[item.__typename]: getValuesForSave(values, itemBp),
					};
				});
			}

			if (!rawValue) {
				return null;
			}

			const values = omit(['__typename'], rawValue);

			const itemBp: any = find(
				propEq('apiId', rawValue.__typename),
				extra.attr.allowedComponents
			);
			return {
				[rawValue.__typename]: getValuesForSave(values, itemBp),
			};
		}
	}

	return null;
};

export const getValuesForSave = (values: any, blueprint: Collection) => {
	if (!blueprint) {
		console.debug('No blueprint provided for values', values);
		throw new Error('No blueprint has been provided');
	}

	// Loop over each attribute and prepare the value for sending to API
	const valuesToSave: Record<string, any> = {};

	// Don't save non-editable attributes
	const validAttributes = blueprint.attributes.filter((a) => a.isEditable);

	for (const attr of validAttributes) {
		if (has(attr.apiId, values)) {
			valuesToSave[attr.apiId] = getValueForSave(
				attr,
				values[attr.apiId],
				attr.isList,
				{
					attr,
					blueprint,
				}
			);
		} else if (!isReferenceAttribute(attr) && (attr.__typename === 'BasicAttribute' && attr.type !== TYPE_ASSET)) {
			valuesToSave[attr.apiId] = null;
		}
	}

	return valuesToSave;
};

/**
 * Create attribute values from an existing item.
 * @param {*} values
 * @param {*} blueprint
 * @returns
 */
export const createAttributeValues = (
	values: any,
	blueprint: Collection,
	includeReferences = false
) =>
	blueprint.attributes.reduce((finalValues, attr) => {
		if (
			isReferenceAttribute(attr) ||
			// isComponentAttribute(attr) ||
			// @ts-ignore
			(attr.type === TYPE_ASSET && !includeReferences) ||
			!attr.isEditable
		) {
			return finalValues;
		}

		let val = prop(attr.apiId, values);

		// If the value is null/undefined, get a default value for the attribute.
		if (isNil(val)) {
			return assoc(attr.apiId, getAttributeDefaultValue(attr), finalValues);
		}

		switch (attr.__typename) {
			case ATTRIBUTE_TYPENAME_BASIC: {
				switch (attr.type) {
					case TYPE_DATE:
					case TYPE_DATETIME:
						// If this is a date, instantiate a date object
						val = val === null ? null : new Date(val);
						break;
					case TYPE_ASSET:
						val = attr.isList ? val.edges.map((edge: any) => edge.node.id) : val.id;
						break;
				}
				break;
			}
			case ATTRIBUTE_TYPENAME_COMPONENT: {
				if (attr.isList) {
					val = val.map((item: any) => {
						const component: any = attr.allowedComponents.find(
							(c) => c.apiId === item.__typename
						);

						return {
							_draggableId: makeId(),
							__typename: item.__typename,
							...createAttributeValues(item, component, true),
						};
					});
				} else {
					const component: any = attr.allowedComponents.find(
						(c) => c.apiId === val.__typename
					);
					val = {
						__typename: val.__typename,
						...createAttributeValues(val, component, true),
					}
				}
				break;
			}
		}

		return assoc(attr.apiId, val, finalValues);
	}, {});

const getScalarDefault = (type: BasicAttributeType) => {
	switch (type) {
		case TYPE_TEXT:
		case TYPE_LONG_TEXT:
		case TYPE_MARKDOWN:
			return '';
		case TYPE_BOOLEAN:
			return false;
	}
	return null;
};

export const getAttributeDefaultValue = (attribute: Attribute): any =>
	// @ts-ignore
	attribute.isList ? [] : getScalarDefault(attribute.type);

export const initialAttributeValues = (blueprint: Collection) =>
	blueprint.attributes.reduce((attrVals, attr) => {
		// Don't set an initial value for non-editable or reference attributes
		if (
			!attr.isEditable ||
			isReferenceAttribute(attr) ||
			// @ts-ignore
			attr.type === TYPE_ASSET
		) {
			return attrVals;
		}

		return assoc(attr.apiId, getAttributeDefaultValue(attr), attrVals);
	}, {});

export const initialVariantValues = (blueprint: Collection, baseCurrency: any) => ({
	...initialAttributeValues(blueprint),
	inventoryItem: {
		available: 0,
		isTracked: false,
	},
	options: [] as string[],
	price: {
		amount: 0,
		currency: baseCurrency.code,
	},
	requiresShipping: false,
	salePrice: null as any,
});

export const getPinnableAttributes = (attributes: Attribute[]) =>
	attributes.filter(
		(attr) =>
			(PINNABLE_TYPENAMES.includes(attr.__typename) ||
				// @ts-ignore
				PINNABLE_BASIC_ATTRIBUTE_TYPES.includes(attr.type)) &&
			!attr.isList
	);

export const getReferenceAttributeApiIds = (attributes: Attribute[]) =>
	attributes.reduce((apiIds, attr) => {
		if (isReferenceAttribute(attr)) {
			return [...apiIds, attr.apiId];
		}
		return apiIds;
	}, []);
