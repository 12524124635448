// @ts-ignore
import { Button, SelectField } from '@platformapp/ui';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateModel } from './mutations';
import { Collection } from '../types/Collection';
import { CollectionMetadataKeys, TITLE_ATTRIBUTE_TYPES } from '../constants';
import { getMetadataCsv, getMetadataValue, MetadataEntry } from '../util/metadata';
import toast from 'react-hot-toast';
import { TagField, TagFieldItem } from '../common/TagField';
import { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { canBePinned } from '../util/attributes';

type DashboardViewSettings = {
	collection: Collection;
	onChange: (data: any) => void;
}

export const DashboardViewSettings = ({ collection, onChange }: DashboardViewSettings) => {
	const {refresh} = useContext(EnvironmentContext);
	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			[CollectionMetadataKeys.PinnedAttributes]: getMetadataCsv(collection.metadata, CollectionMetadataKeys.PinnedAttributes).reduce((vals: TagFieldItem[], apiId) => {
				const attr = collection.attributes.find(a => a.apiId === apiId);
		
				if (!attr) {
					return vals;
				}
		
				return [
					...vals,
					{
						label: attr.name,
						value: attr.apiId
					}
				]
			}, []),
			[CollectionMetadataKeys.Title]: getMetadataValue(collection.metadata, CollectionMetadataKeys.Title, 'id'),
		},
		onSubmit: async (values, {resetForm}) => {
			const metadata: MetadataEntry[] = [
				{
					key: CollectionMetadataKeys.Title,
					value: values[CollectionMetadataKeys.Title] === 'id'
						? null
						: values[CollectionMetadataKeys.Title]
				},
				{
					key: CollectionMetadataKeys.PinnedAttributes,
					value: values[CollectionMetadataKeys.PinnedAttributes].length > 0
						? values[CollectionMetadataKeys.PinnedAttributes].map(v => v.value).join(',')
						: null,
				},
			];

			try {
				const res = await updateModel(collection.id, {
					metadata
				});
				onChange(res);
			} catch (err) {
				console.error(err);
				toast.error('Failed to update collection');
				return;
			}

			toast.success('Collection updated');
			resetForm({
				values
			});
			refresh();
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Dashboard view</p>
					<p className="mb-4 leading-relaxed">
						These settings control how this collection appears in the Dashboard.
					</p>

					<div className="w-1/2 mb-8">
						<SelectField
							name={CollectionMetadataKeys.Title}
							label="Title"
							helpText="This attribute will be used when displaying the item in reference fields."
							onChange={form.handleChange}
							disabled={form.isSubmitting}
							options={[
								{
									label: 'ID',
									value: 'id'
								},
								...collection.attributes
									.filter(a => a.__typename === 'BasicAttribute' && TITLE_ATTRIBUTE_TYPES.includes(a.type) && !a.isList)
									.map(a => ({
										label: `${a.name} (${a.apiId})`,
										value: a.apiId
									}))
							]}
							value={form.values[CollectionMetadataKeys.Title]}
						/>
					</div>

					<TagField
						availableValues={collection.attributes.filter(canBePinned).map(a => ({
							label: a.name,
							value: a.apiId
						}))}
						disabled={form.isSubmitting}
						helpText="These attributes will be shown on collection list pages."
						label="Pinned attributes"
						onChange={val => form.setFieldValue(CollectionMetadataKeys.PinnedAttributes, val)}
						value={form.values[CollectionMetadataKeys.PinnedAttributes]}
					/>
				</FieldsetContent>
				{/* @ts-ignore */}
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
