import { useContext, useEffect, useState } from "react";
// @ts-ignore
import { Loader } from '@platformapp/ui';
import { ExtensionsContext } from "./ExtensionsContext";
import { ExtensionInstallation } from "../types/Extension";
import { toast } from "react-hot-toast";
import { EnvironmentContext } from "../context/EnvironmentContext";

type FrameProps = {
    installation: ExtensionInstallation;
    url: string;
}

export const Frame = ({
    installation,
    url
}: FrameProps) => {
    const { getToken } = useContext(ExtensionsContext);
    const { environment } = useContext(EnvironmentContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
		const handler = async (e: any) => {
			if (e.origin !== url) {
				console.debug(`Origin '${e.origin}' invalid.`);
				return;
			}

			// Handle the request
			switch (e.data.type) {
				case 'platform.jwt.request': {
					const token = await getToken(installation.id);

					if (token) {
						e.ports[0].postMessage({
							type: 'platform.jwt.response',
							data: {
								token,
							},
						});
					} else {
						console.error('An error occurred getting a JWT');
						toast.error(
							'Something went wrong and this extension may not work correctly.'
						);
					}
					break;
				}
				case 'platform.environment.get.request': {
					e.ports[0].postMessage({
						type: 'platform.environment.get.response',
						data: {
							id: environment.node.id
						},
					});
					break;
				}
				case 'platform.toast.show': {
					toast.success(e.data.data.message);
					break;
				}
			}
		};
		window.addEventListener('message', handler);
		return () => window.removeEventListener('message', handler);
	}, []);

    return (
        <>
            {isLoading && (
                <div className="bg-white absolute h-full w-full">
                    <Loader />
                </div>
            )}
            <iframe
                className="w-full h-full"
                onLoad={() => setIsLoading(false)}
                referrerPolicy="no-referrer"
                sandbox="allow-forms allow-scripts allow-same-origin"
                src={url}
            />
        </>
    );
}