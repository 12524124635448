import React from 'react';
import { Button, TextField } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr, pick } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateModel } from './mutations';
import toast from 'react-hot-toast';

export const NameSetting = ({ blueprint, onChange }) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: pathOr('', ['data', 'name'], blueprint),
		},
		onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
			updateModel(blueprint.data.id, values)
				.then((updatedBp) => {
					resetForm({ values: pick(['name'], updatedBp) });
					onChange(updatedBp);
					toast.success('Blueprint name updated');
				})
				.catch((error) => {
					if (error.isUserError) {
						setErrors(error.userErrors);
					} else {
						console.error(error);
						toast.error('Failed to update blueprint name');
					}
				})
				.finally(() => setSubmitting(false));
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">
						{blueprint.data.__typename} name
					</p>
					{blueprint.data.__typename === 'collection' && (
						<p className="mb-4 leading-relaxed">
							This is the blueprint&apos;s visible name on the Dashboard. This
							is usually the name of the type of content you&apos;re modelling,
							like Blog Post or Product.
						</p>
					)}
					{blueprint.data.__typename === 'component' && (
						<p className="mb-4 leading-relaxed">
							This is the component&apos;s visible name on the Dashboard. This
							is usually the name of the type of content you&apos;re modelling,
							like Gallery Item or Testimonial.
						</p>
					)}
					<div className="w-full md:w-96">
						<TextField
							disabled={
								blueprint.isLoading ||
								form.isSubmitting ||
								pathOr(false, ['data', 'isSystem'], blueprint)
							}
							value={form.values.name}
							name="name"
							onChange={form.handleChange}
							error={extractGraphqlError('name', form.errors)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
