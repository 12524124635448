import { useContext, useState } from 'react';
// @ts-ignore
import { CheckboxField, TextField } from '@platformapp/ui';
import { Module } from '../common/Module';
import { assoc, isNil } from 'ramda';
import { extractGraphqlError } from '../util';
import { EDITOR_MODE_EDIT, EDITOR_MODE_NEW } from '../constants';
import { InventoryQuantityInput } from '../common/InventoryQuantityInput';
import { AssetSelector } from '../common/AssetSelector';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ProductVariant } from '../types/Item';
import { ProductDispatch } from '../products-edit/ProductEditReducer';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { Attribute } from '../types/Attribute';
import { CurrencyField } from '../common/CurrencyField';

type SingleVariantProductFormProps = {
	form: any;
	mode: 'edit' | 'new';
	productDispatch: ProductDispatch;
	readOnly: boolean;
	variant: ProductVariant;
}

export const SingleVariantProductForm = ({
	form,
	mode,
	productDispatch,
	readOnly,
	variant,
}: SingleVariantProductFormProps) => {
	const { baseCurrency, collectionByApiId } = useContext(EnvironmentContext);
	const variantCollection = collectionByApiId('ProductVariant');
	const onVariantPropChange = (prop: string, value: any) =>
		form.setFieldValue('variants', [
			assoc(prop, value, form.values.variants[0]),
		]);
	const [images, setImages] = useState(variant ? variant.images : {edges:[]});
	return (
		<>
			<Module bottomMargin>
				<AttributeEditor
					attributes={variantCollection.node.attributes.filter((a: Attribute) => a.isEditable && !a.isSystem)}
					initialValues={variant}
					isNew={mode === EDITOR_MODE_NEW}
					onAttributeChange={(apiId: string, val: any) =>
						form.setFieldValue(`variants[0].${apiId}`, val)
					}
					validationErrors={form.errors}
					pathPrefix={['input', 'variants', '0']}
					readOnly={readOnly}
					values={form.values.variants[0]}
				/>
			</Module>
			<Module bottomMargin heading="Images">
				{/* @ts-ignore */}
				<AssetSelector
					error={extractGraphqlError(
						['variants', '0', 'images'],
						form.errors
					)}
					multiple
					onChange={(val: any) => {
						setImages(val);
						form.setFieldValue('variants[0].images', val.edges.map((edge: any) => edge.node.id));
					}}
					readOnly={readOnly}
					value={images}
				/>
			</Module>
			<Module bottomMargin heading="Pricing">
				<div className="flex gap-6">
					<div className="w-1/2 mb-8">
						<CurrencyField
							currency={form.values.variants[0].price.currency}
							error={extractGraphqlError(['variants', '0', 'price'], form.errors, true)}
							label="Price"
							onChange={(amount: any) => {
								form.setFieldValue('variants[0].price.amount', amount)
							}}
							readOnly={readOnly}
							value={form.values.variants[0].price.amount}
						/>
					</div>
					<div className="w-1/2">
						<CurrencyField
							currency={baseCurrency.code}
							error={extractGraphqlError(['variants', '0', 'salePrice'], form.errors, true)}
							label="Sale price"
							onChange={(amount: any) => {
								if (isNil(amount)) {
									form.setFieldValue('variants[0].salePrice', null);
								} else {
									form.setFieldValue('variants[0].salePrice', {
										currency: baseCurrency.code,
										amount
									});
								}
							}}
							readOnly={readOnly}
							value={form.values.variants[0].salePrice?.amount}
						/>
					</div>
				</div>
				<CheckboxField
					name="onSale"
					label="On sale"
					helpText="Enable to use the sale price when displaying the product and calculating cart totals."
					checked={form.values.variants[0].onSale}
					onChange={(checked: boolean) =>
						form.setFieldValue('variants[0].onSale', checked)
					}
					error={extractGraphqlError(
						['variants', '0', 'onSale'],
						form.errors
					)}
					readOnly={readOnly}
				/>
			</Module>
			<Module bottomMargin heading="Inventory">
				<TextField
					label="SKU"
					name="sku"
					value={form.values.variants[0].sku || ''}
					onChange={(e: any) => onVariantPropChange('sku', e.target.value)}
					error={extractGraphqlError(
						['variants', '0', 'sku'],
						form.errors
					)}
					readOnly={readOnly}
				/>
				<CheckboxField
					disabled={readOnly}
					name="trackInventory"
					label="Track inventory"
					checked={form.values.variants[0].inventoryItem.isTracked}
					onChange={(checked: boolean) =>
						form.setFieldValue('variants[0].inventoryItem.isTracked', checked)
					}
					error={extractGraphqlError(
						['variants', '0', 'trackInventory'],
						form.errors
					)}
					readOnly={readOnly}
				/>
				{form.values.variants[0].inventoryItem.isTracked && (
					<>
						{mode !== EDITOR_MODE_EDIT && (
							<TextField
								label="Quantity available"
								name="available"
								value={form.values.variants[0].inventoryItem.available}
								onChange={(e: any) =>
									form.setFieldValue(
										'variants[0].inventoryItem.available',
										parseInt(e.target.value, 10)
									)
								}
								readOnly={readOnly}
								error={extractGraphqlError(
									['variants', '0', 'inventoryItem', 'available'],
									form.errors
								)}
								type="number"
								max="5000"
								min="0"
							/>
						)}
						{mode === EDITOR_MODE_EDIT && (
							<InventoryQuantityInput
								error={extractGraphqlError(
									['variants', '0', 'inventoryItem', 'available'],
									form.errors
								)}
								inventoryItem={{
									...variant.inventoryItem,
									productName: form.values.name,
									variantOptions: [],
								}}
								onUpdate={(ii: any) => {
									productDispatch({
										type: 'inventory_update',
										data: [ii]
									})
								}}
								readOnly={readOnly}
							/>
						)}
					</>
				)}
			</Module>
			<Module bottomMargin heading="Shipping">
				<CheckboxField
					disabled={readOnly}
					name="requiresShipping"
					label="This product requires shipping."
					helpText="Customers will be required to enter a shipping address and choose a shipping rate at checkout."
					checked={form.values.variants[0].requiresShipping}
					onChange={(checked: boolean) =>
						onVariantPropChange('requiresShipping', checked)
					}
					error={extractGraphqlError(
						['variants', '0', 'requiresShipping'],
						form.errors
					)}
				/>
			</Module>
		</>
	);
};
