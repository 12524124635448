const MDAST_PARAGRAPH = 'paragraph';
const MDAST_HEADING = 'heading';
const MDAST_STRONG = 'strong';
const MDAST_EMPHASIS = 'emphasis';
const MDAST_BLOCKQUOTE = 'blockquote';
const MDAST_LIST = 'list';
const MDAST_LIST_ITEM = 'listItem';
const MDAST_CODE = 'code';  // block code
const MDAST_INLINE_CODE = 'inlineCode';
const MDAST_LINK = 'link';
const MDAST_IMAGE = 'image';
const MDAST_HORIZONTAL_RULE = 'thematicBreak';
const MDAST_BREAK = 'break'; // single line break, like in an address
const MDAST_TEXT = 'text';
const MDAST_ROOT = 'root';

const SLATE_TO_MDAST_MAP = Object.freeze({
    'break': MDAST_BREAK,
    'paragraph': MDAST_PARAGRAPH,
    'block-quote': MDAST_BLOCKQUOTE,
    'list-item': MDAST_LIST_ITEM,
})

const HEADING_DEPTHS = Object.freeze({
    'heading-one': 1,
    'heading-two': 2,
    'heading-three': 3,
    'heading-four': 4,
    'heading-five': 5,
    'heading-six': 6,
})

const makeNode = (type, options = {}) => ({
    type,
    ...options
})

const makeTextNode = text => makeNode(MDAST_TEXT, {
    value: text
})

const makeParentNode = (type, children, options = {}) => makeNode(type, {
    children,
    ...options
})

const processTextNode = node => {

    if (node.bold && node.italic) {
        return makeParentNode(MDAST_STRONG, [
            makeParentNode(MDAST_EMPHASIS, [
                makeTextNode(node.text)
            ])
        ])
    }

    if (node.bold) {
        return makeParentNode(MDAST_STRONG, [
            makeTextNode(node.text)
        ])
    }

    if (node.italic) {
        return makeParentNode(MDAST_EMPHASIS, [
            makeTextNode(node.text)
        ])
    }

    if (node.code) {
        return makeNode(MDAST_INLINE_CODE, {
            value: node.text
        })
    }

    return makeTextNode(node.text);

}

const serialiseNode = node => {
    const isLeaf = typeof node.text === 'string';

    if (isLeaf) {
        return processTextNode(node);
    }

    // Process all child nodes, if this node has a `children` property and
    // it is an array.
    const children = node.children && Array.isArray(node.children)
        ? node.children.map(n => serialiseNode(n))
        : [];

    // Headings are returned as a single `heading` type, with a depth
    // (1=largest, 6=smallest).
    if (Object.keys(HEADING_DEPTHS).includes(node.type)) {
        return makeParentNode(MDAST_HEADING, children, {
            depth: HEADING_DEPTHS[node.type]
        })
    }

    switch (node.type) {
        case 'code-block':
            return makeNode(MDAST_CODE, {
                value: node.value
            })
        case 'image':
            return makeNode(MDAST_IMAGE, {
                alt: node.alt,
                title: node.title,
                url: node.url
            })
        case 'link':
            return makeParentNode(MDAST_LINK, children, {
                title: node.title,
                url: node.url,
            })
        case 'ordered-list':
        case 'unordered-list':
            return makeParentNode(MDAST_LIST, children, {
                ordered: node.type === 'ordered-list'
            })
        case 'thematic-break':
            return makeNode(MDAST_HORIZONTAL_RULE)
    }

    return makeParentNode(SLATE_TO_MDAST_MAP[node.type], children);
}

// All MDASTs start with a `root` node
export const serialise = slateAst => makeParentNode(MDAST_ROOT, slateAst.map(node => serialiseNode(node)))