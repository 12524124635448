import React from 'react';
import { Image } from '@platformapp/ui';
import { isNil } from 'ramda';
import { assetTypeFromMime } from '../util';
import { FileThumbnail } from '../assets-list/FileThumbnail';
import { ASSET_FILTER_IMAGE } from '../constants';

export const Thumbnail = ({ disabled, setDetailsLayerVisible, value }) => {
	const imageClasses = 'block h-full w-full border rounded overflow-hidden';
	if (isNil(value)) {
		return <div className={`${imageClasses} hatched`} />;
	}

	const type = assetTypeFromMime(value.mimeType);

	return (
		<button
			className={`${imageClasses} ${
				disabled ? 'cursor-auto' : ''
			} focus:outline-none focus:shadow-outline`}
			disabled={disabled}
			onClick={(e) => {
				e.preventDefault();
				if (setDetailsLayerVisible) {
					setDetailsLayerVisible(true);
				}
			}}
		>
			{type === ASSET_FILTER_IMAGE && (
				<Image className="w-full h-full" src={value.thumbnailUrl} />
			)}
			{type !== ASSET_FILTER_IMAGE && (
				<FileThumbnail asset={value} className="w-8 h-10" fixedWidth small />
			)}
		</button>
	);
};

Thumbnail.defaultProps = {
	disabled: false,
};
