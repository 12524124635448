import React, { useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '../common/Link';
import { DataTable } from '../table/DataTable';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { NewComponent } from './NewComponent';
import { BlueprintIcon, Button, LandingMessage } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ListPageHeader } from '../common/ListPageHeader';
import Helpers from '../Helpers';
import { TableSkeleton } from '../common/Skeleton';

export const ComponentList = ({ result }) => {
	const history = useHistory();
	const { generateEnvPath } = useContext(EnvironmentContext);

	const tableDef = useMemo(() => [
		{
			accessor: 'node.name',
			Header: 'Name',
			Cell: (m) => (
				<Link to={generateEnvPath(`blueprints/${m.row.original.node.id}`)}>
					{m.cell.value}
				</Link>
			),
		},
		{
			accessor: 'node.apiId',
			Header: 'API ID',
			Cell: (m) => <ApiIdLabel>{m.cell.value}</ApiIdLabel>,
		},
	]);

	const [addingBlueprint, setAddingBlueprint] = useState(false);

	return (
		<>
			{addingBlueprint && (
				<NewComponent
					onCancel={() => setAddingBlueprint(false)}
					onConfirm={(bp) =>
						history.replace(generateEnvPath(`blueprints/${bp.id}`))
					}
				/>
			)}

			<ListPageHeader
				hasPx={false}
				title={Helpers.t('components.components')}
				right={
					<Button primary onClick={() => setAddingBlueprint(true)}>
						{Helpers.t('components.create')}
					</Button>
				}
			/>

			<div className="mt-5">
				{result.isLoading && <TableSkeleton />}
				{!result.isLoading && result.edges.length === 0 && (
					<div className="fade-in">
						<LandingMessage
							icon={<BlueprintIcon />}
							header={Helpers.t('components.empty_message')}
						/>
					</div>
				)}
				{!result.isLoading && result.edges.length > 0 && (
					<DataTable
						rowIdPath={['node', 'id']}
						columns={tableDef}
						data={result.edges}
					/>
				)}
			</div>
		</>
	);
};
