import React, { useState, useContext } from 'react';
import { CollectionList } from './CollectionList';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import COLLECTIONS_QUERY from './ListCollections.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useEnvironmentTitle } from '../hooks/useTitle';
import Helpers from '../Helpers';
import { useAsyncEffect } from 'use-async-effect';

export const CollectionListContainerComponent = () => {
	useEnvironmentTitle(Helpers.t('collections.collections'));
	const { environment } = useContext(EnvironmentContext);

	const [result, setResult] = useState({
		isLoading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			setResult({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const res = await graphql({
					query: COLLECTIONS_QUERY,
					variables: {
						envId: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setResult({
					isLoading: false,
					...res.data.data.environment.collections,
				});
			}
		},
		[environment.isLoading]
	);

	return <CollectionList result={result} />;
};

export const CollectionListContainer = withErrorBoundary(
	CollectionListContainerComponent
);
