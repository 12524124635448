import { hasPath } from 'ramda';
import React, { useState } from 'react';
import { Button } from '@platformapp/ui';
import { graphql } from '../api';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import RESET_MUTATION from './ResetPassword.gql';
import toast from 'react-hot-toast';

export const ResetPasswordSetting = ({ user }) => {
	const [isResettingPassword, setIsResettingPassword] = useState(false);

	const resetPassword = async () => {
		setIsResettingPassword(true);
		try {
			const res = await graphql(
				{
					query: RESET_MUTATION,
					variables: {
						input: {
							email: user.email,
						},
					},
				},
				'users'
			);
			if (
				!hasPath(
					['data', 'requestPasswordReset', 'userInputErrors'],
					res.data
				) ||
				res.data.data.requestPasswordReset.userInputErrors.length > 0
			) {
				toast.error('Failed to send password reset');
			} else {
				toast.success('Check your email inbox for instructions');
			}
		} catch (err) {
			console.error(err);
			toast.error('Failed to request password reset');
		} finally {
			setIsResettingPassword(false);
		}
	};
	return (
		<Fieldset>
			<FieldsetContent>
				<p className="font-medium text-lg mb-4">Reset password</p>
				<p className="mt-6">
					If you need to reset your password we can send you an email with
					further instructions.
				</p>
			</FieldsetContent>
			<FieldsetFooter>
				<Button
					disabled={!user || isResettingPassword}
					onClick={resetPassword}
					width="large"
				>
					Reset my password by email
				</Button>
			</FieldsetFooter>
		</Fieldset>
	);
};
