// @ts-ignore
import DragIcon from '../icons/grabber.svg';
// @ts-ignore
import { Icon } from '@platformapp/ui';

type DragHandleProps = {
	className?: string;
	disabled?: boolean;
}

export const DragHandle = ({
	className,
	disabled,
	...props
}: DragHandleProps) => (
	<span
		{...props}
		className={`mr-1 ${disabled ? '' : 'cursor-grab active:cursor-grabbing'} ${className}`}
	>
		<Icon source={DragIcon} />
	</span>
);

DragHandle.defaultProps = {
	className: '',
	disabled: false,
};
