import { isEmpty } from "ramda";
import { encodeFiltersForApi } from "../util";

export const filtersToApiParams = (filters, legacyEqualsHandling = false) => {
    const apiParams = {};
    if (!isEmpty(filters)) {
        const nonEmptyFilters = filters.filter(f => !isEmpty(f.value));
        for (const [ key, val ] of Object.entries(encodeFiltersForApi(nonEmptyFilters, legacyEqualsHandling))) {
            if (!isEmpty(val)) {
                apiParams[key] = val;
            }
        }
    }
    return apiParams;
}