import { Filters } from '../filters/Filters';
import Helpers from '../Helpers';
import { FILTER_ENUM } from '../constants';
import * as PropTypes from 'prop-types';
import { filtersForAttributes, sortByLabel } from '../util';
import { Collection } from '../types/Collection';

type ContentFiltersProps = {
	[key: string]: any;
	blueprint: Collection;
};

/**
 * Component for displaying content filters as dropdowns, and allowing the user to change values.
 * @component
 */
export const ItemFilters = ({
	appliedFilters,
	blueprint,
	blueprints,
	includeBlueprintFilter,
	onChange,
}: ContentFiltersProps) => {
	let filterDefs = sortByLabel(filtersForAttributes(blueprint.attributes));

	if (includeBlueprintFilter) {
		filterDefs = [
			{
				key: 'blueprint',
				type: FILTER_ENUM,
				label: Helpers.t('blueprints.blueprint'),
				persistent: true,
				values: blueprints.map((bp: any) => ({
					label: bp.name,
					value: bp.id,
				})),
			},
			...filterDefs,
		];
	}

	return (
		<Filters
			definitions={filterDefs}
			filters={appliedFilters}
			onChange={(filters: any) =>
				onChange(filters.filter((filter: any) => filter.value !== null))
			}
		/>
	);
};

ItemFilters.defaultProps = {
	blueprints: [],
	includeBlueprintFilter: false,
};

ItemFilters.propTypes = {
	appliedFilters: PropTypes.array,
	blueprints: PropTypes.array,
	includeBlueprintFilter: PropTypes.bool,
};
