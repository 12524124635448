// @ts-ignore
import { Icon } from '@platformapp/ui';
import { ModalStackLayer } from '../stacks/ModalStackLayer';
import { Frame } from './Frame';
// @ts-ignore
import CloseIcon from '../icons/x.svg';
import { ExtensionInstallation, PagePoint } from '../types/Extension';
import { AppLogo } from './AppLogo';

type ExtensionLayerProps = {
    installation: ExtensionInstallation;
    onCancel: () => void;
}

export const ExtensionLayer = ({
    installation,
    onCancel
}: ExtensionLayerProps) => {
    const point: PagePoint = installation.extension.points.find(p => p.__typename === 'PagePoint') as PagePoint;
	return (
		<ModalStackLayer
            onRequestClose={onCancel}
        >
			<div className="flex flex-col h-full">
                <div className="flex items-center py-2 md:py-4 px-4 md:px-7 sticky top-0 z-10 bg-white border-gray-300 border-b">
                    <div className="w-full">
                        <div className="flex items-center">
                            <div className="flex flex-grow mr-6 gap-3 items-center">
                                <AppLogo app={installation.extension} size="small" />
                                <h1 className="text-xl font-medium leading-relaxed truncate">
                                    {installation.extension.name}
                                </h1>
                            </div>
                            <div>
                                <button
                                    onClick={() => onCancel()}
                                >
                                    <Icon className="w-5 h-5" source={CloseIcon} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="flex-grow overflow-scroll md:flex">
                    <Frame
                        installation={installation}
                        url={point.url}
                    />
				</div>
			</div>
		</ModalStackLayer>
	);
};