import Poly from "./Poly";

export const t = (key: string, args: any = null) => Poly.t(key, args);
export const hasT = (key: string) => Poly.has(key);

const Helpers = {
	t,
	hasT
}

export default Helpers;