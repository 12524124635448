import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

type ApiIdLabelProps = {
    className?: string;
    textToCopy?: string;
    children: React.ReactNode;
}

export const ApiIdLabel = ({
    className,
    textToCopy,
    ...props
}: ApiIdLabelProps) => {
    let classes = ['rounded text-xs px-2 py-1 font-mono api-id'];

    if (textToCopy) {
        classes.push('cursor-pointer');
    }

    useEffect(() => {
        if (textToCopy) {
            ReactTooltip.rebuild();
        }
    })

    return (
        <span
            className={`${className} ${classes.join(' ')}`}
            onClick={() => {
                if (textToCopy) {
                    navigator.clipboard.writeText(textToCopy);
                }
            }}
            data-tip="Copy"
            {...props}
        />
    )
}

ApiIdLabel.defaultProps = {
    className: '',
    textToCopy: null,
}