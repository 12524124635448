import React, { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Sidebar, SidebarLink } from '../common/Sidebar';

export const SchemaSidebar = () => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<Sidebar title="Schema">
			<SidebarLink to={generateEnvPath('schema/collections')}>
				Collections
			</SidebarLink>
			<SidebarLink to={generateEnvPath('schema/components')}>
				Components
			</SidebarLink>
			<SidebarLink to={generateEnvPath('schema/enumerations')}>
				Enumerations
			</SidebarLink>
		</Sidebar>
	);
};
