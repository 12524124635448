import { useContext, useState } from 'react';
//@ts-ignore
import { ConfirmDialog } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';
import Helpers from '../Helpers';
import { buildBulkDeleteMutation } from '../util/query-builder';
import { B } from '../common/Text';
import { getTitle } from '../util/AttributeHelpers';
import toast from 'react-hot-toast';

interface DeleteItemModalProps {
	collection: any;
	items: any[];
	onCancel: (() => void);
	onDelete: ((ids: string[]) => void);
}

export const DeleteItemModal = ({
	collection,
	items,
	onCancel,
	onDelete,
}: DeleteItemModalProps) => {
	const { contentApiRequest2 } = useContext<any>(EnvironmentContext);
	const [isDeleting, setIsDeleting] = useState(false);

	const itemIds = items.map(i => i.id);

	const handleDelete = async () => {
		setIsDeleting(true);

		try {
			const { data }: any = await contentApiRequest2({
				query: buildBulkDeleteMutation(collection, itemIds),
				variables: itemIds.reduce(
					(all, id, index) => ({
						...all,
						[`input${index}`]: {
							id,
						},
					}),
					{}
				),
			});
			const allIds: any = Object.values(data).reduce(
				(ids: string[], val: any) => [...ids, ...val.deletedNodes],
				[]
			);
			onDelete(allIds);
		} catch (err) {
			console.log(err);
			toast.error('An error occurred deleting this item');
		} finally {
			setIsDeleting(false);
		}
	};

	return (
		<ConfirmDialog
			title={Helpers.t(items.length === 1 ? 'items.delete_confirm_title_single' : 'items.delete_confirm_title_many')}
			body={<>
				{items.length === 1 && (<>
					<p className="leading-normal mb-3">
						Are you sure you want to delete <B>{getTitle(items[0])}</B>?
					</p>
					<p className="leading-normal">
						This item and any associated data will be permanently deleted
						- this cannot be undone.
					</p>
				</>)}
				{items.length > 1 && (<>
					<p className="leading-normal mb-3">
						Are you sure you want to delete {items.length} items?
					</p>
					<p className="leading-normal">
						These items and any associated data will be permanently deleted
						- this cannot be undone.
					</p>
				</>)}
			</>}
			onConfirm={handleDelete}
			onCancel={onCancel}
			isWorking={isDeleting}
			dangerous
			confirmText={items.length === 1 ? 'Delete item' : 'Delete items'}
		/>
	);
};