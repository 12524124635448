import { useState, useContext, useMemo } from 'react';
// @ts-ignore
import { Button, ButtonGroup, Icon } from '@platformapp/ui';
import { StickyHeader } from '../common/StickyHeader';
import { ModalStackLayer } from '../stacks/ModalStackLayer';
import { BasicPaginator } from '../common/Pagination';
import { assoc, find, omit, pathEq, pick } from 'ramda';
import { Heading } from '../common/Heading';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ItemFilters } from '../content-list/ItemFilters';
import { NoResults } from '../common/NoResults';
import { filtersToApiParams } from '../filters/util';
import { plural } from 'pluralize';
import { ContentResourceTable } from '../content-list/ContentResourceTable';
import { SORT_ASC } from '../common/ResourceTable';
import { TableSkeleton } from '../common/Skeleton';
import { ASSET_DEFAULT_PINNED, CollectionMetadataKeys, DEFAULT_PER_PAGE } from '../constants';
// @ts-ignore
import ArrowLeftIcon from '../icons/arrow-left.svg';
import { useAsyncEffect } from 'use-async-effect';
import { ContentApiContext } from '../context/ContentApiContext';
import { getMetadataCsv } from '../util/metadata';
import { getDefaultPinned } from '../util/attributes';

type TableParams = {
	filters: any;
	order: any;
	pagination: any;
}

type ItemChooserLayerProps = {
	allowedBlueprints: string[];
	disabledItems?: string[];
	onCancel?: () => void;
	multiple?: boolean;
	onSelect: (value: any) => void;
}

export const ItemChooserLayer = ({
	allowedBlueprints,
	disabledItems,
	onCancel,
	onSelect,
	multiple,
}: ItemChooserLayerProps) => {
	const { collections } = useContext(EnvironmentContext);
	const { findItems } = useContext(ContentApiContext);
	const [selection, setSelection] = useState([]);

	const [content, setContent] = useState<any>({
		isLoading: true,
	});

	const allowedBpObjects = collections.filter((bp) =>
		allowedBlueprints.includes(bp.id)
	);

	const pinnedCols = useMemo(() => {
		const defaultPinned = allowedBpObjects[0].apiId === 'Asset'
			? ASSET_DEFAULT_PINNED
			: getDefaultPinned(allowedBpObjects[0].attributes);
		return getMetadataCsv(allowedBpObjects[0].metadata, CollectionMetadataKeys.PinnedAttributes, defaultPinned);
	}, []);

	const [tableParams, setTableParams] = useState<TableParams>({
		filters: [
			{
				key: 'blueprint',
				operator: 'eq',
				value: allowedBlueprints[0],
			},
		],
		order: {
			field: 'createdAt',
			dir: SORT_ASC,
		},
		pagination: {
			first: DEFAULT_PER_PAGE,
		},
	});

	useAsyncEffect(
		async (isActive) => {
			setContent({
				...content,
				isLoading: true,
			});

			const apiFilters: any = filtersToApiParams(tableParams.filters);

			if (disabledItems.length > 0) {
				apiFilters.id = {
					nin: disabledItems,
				};
			}

			const res = await findItems(allowedBpObjects[0].apiId, {
				filter: omit(['blueprint'], apiFilters),
				order: {
					[tableParams.order.field]:
						tableParams.order.dir === SORT_ASC ? 'ASC' : 'DESC',
				},
				...tableParams.pagination,
			});

			if (!isActive()) {
				return;
			}

			setContent({
				...res,
				isLoading: false,
			});
		},
		[tableParams]
	);

	return (
		<ModalStackLayer onRequestClose={onCancel}>
			{/* @ts-ignore */}
			<StickyHeader bordered hasPx hasPy sticky>
				<div className="md:flex items-center mb-3">
					<div className="flex-grow flex items-center">
						<Button className="mr-4" onClick={() => onCancel()} width="small">
							<Icon source={ArrowLeftIcon} />
						</Button>
						{/* @ts-ignore */}
						<Heading className="flex-grow">
							{allowedBpObjects.length === 1 &&
								`Select ${
									multiple
										? plural(allowedBpObjects[0].name)
										: allowedBpObjects[0].name
								}`}
							{allowedBlueprints.length !== 1 && 'Select content'}
						</Heading>
					</div>
					<ButtonGroup>
						{multiple && (
							<Button
								primary
								onClick={() =>
									onSelect({
										edges: selection.map((id) =>
											find(pathEq(['node', 'id'], id), content.edges)
										),
									})
								}
								disabled={content.isLoading}
							>
								Select items
							</Button>
						)}
						<Button onClick={() => onCancel()}>Cancel</Button>
					</ButtonGroup>
				</div>
				<ItemFilters
					appliedFilters={tableParams.filters}
					blueprint={allowedBpObjects[0]}
					blueprints={allowedBpObjects}
					includeBlueprintFilter={allowedBpObjects.length > 1}
					onChange={(f: any) => setTableParams(assoc('filters', f, tableParams))}
				/>
			</StickyHeader>
			{!content.isLoading && (
				<div className="fade-in">
					<div className="py-4 px-5 md:px-12">
						{content.edges.length === 0 && <NoResults />}
						{content.edges.length > 0 && (
							<ContentResourceTable
								collection={allowedBpObjects[0]}
								data={content.edges}
								showCheckBoxes={multiple}
								rowClass={multiple ? '' : 'cursor-pointer'}
								selectable
								selection={selection}
								linkRows={false}
								selectOnRowClick
								pinnableColumns={allowedBpObjects[0].attributes.filter(a => pinnedCols.includes(a.apiId)).map(pc => ({
									key: pc.apiId,
									label: pc.name
								}))}
								pinnedColumns={pinnedCols}
								onSelectionChange={(value: any) => {
									if (multiple) {
										setSelection(value);
									} else {
										const edge = find(
											pathEq(['node', 'id'], value[0]),
											content.edges
										);
										onSelect(edge.node);
									}
								}}
								onItemDoubleClick={(edge: any) => {
									if (multiple) {
										onSelect({
											edges: [edge],
										});
									}
								}}
								sortKey={tableParams.order.field}
								sortDirection={tableParams.order.dir}
								onSort={(sortKey: string, direction: string) =>
									setTableParams({
										...tableParams,
										order: {
											dir: direction,
											field: sortKey,
										},
									})
								}
							/>
						)}
					</div>
					<div className="px-6 md:px-10">
						{content.edges.length > 0 && (
							// @ts-ignore
							<BasicPaginator
								{...pick(['totalCount', 'pageInfo'], content)}
								onNext={() =>
									setTableParams({
										...tableParams,
										pagination: {
											first: DEFAULT_PER_PAGE,
											after: content.pageInfo.endCursor,
										},
									})
								}
								onPrevious={() =>
									setTableParams({
										...tableParams,
										pagination: {
											last: DEFAULT_PER_PAGE,
											before: content.pageInfo.startCursor,
										},
									})
								}
								{...tableParams.pagination}
							/>
						)}
					</div>
				</div>
			)}
			{content.isLoading && <TableSkeleton className="px-10 py-8" />}
		</ModalStackLayer>
	);
};

ItemChooserLayer.defaultProps = {
	allowedBlueprints: [],
	disabledItems: [],
	onCancel: null,
};
