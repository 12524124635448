import { extractGraphqlError } from '../util';
import { CollectionChoiceList } from './CollectionChoiceList';

type ReferenceAttributeSettingsProps = {
	isNew: boolean;
	onChange: (value: any) => void;
	attribute: any;
	userErrors: any[];
}

export const ReferenceAttributeSettings = ({
	isNew,
	onChange,
	attribute,
	userErrors,
}: ReferenceAttributeSettingsProps) =>(
	<CollectionChoiceList
		disabled={!isNew}
		error={extractGraphqlError('referencedCollection', userErrors)}
		label="Referenced collection"
		onChange={(values) =>
			onChange({
				referencedCollection: values[0],
			})
		}
		value={attribute.referencedCollection}
	/>
	);