import React, { Fragment, useState } from 'react';
import { Details } from './Details';
import { Module } from '../common/Module';
import Helpers from '../Helpers';
import { Heading } from '../common/Heading';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { TextPayload } from './TextPayload';
import { WEBHOOK_STATUS_FAILED, WEBHOOK_STATUS_SUCCESS } from '../constants';
import { format, isToday, isYesterday } from 'date-fns';
import { groupBy } from 'ramda';
import { HttpStatusBadge } from './HttpStatusBadge';
import { WebhookHeader } from './WebhookHeader';

const DateRow = ({ date }) => (
	<li className="border-b border-gray-400 py-3 px-3 bg-white sticky top-0">
		<div className="border-l-3 border-transparent">
			<p className="text-gray-700 tracking-wide uppercase text-xs font-medium">
				{date}
			</p>
		</div>
	</li>
);

const groupByDate = groupBy(({ node }) => {
	const date = new Date(node.createdAt);
	if (isToday(date)) {
		return 'Today';
	} else if (isYesterday(date)) {
		return 'Yesterday';
	}
	return format(date, 'do MMM y');
});

const AttemptRow = ({ attempt, selected, onSelect }) => (
	<li
		key={attempt.id}
		className={`cursor-pointer border-b border-gray-400 hover:bg-gray-100 ${
			selected ? 'bg-gray-100' : ''
		}`}
		onClick={onSelect}
	>
		<div
			className={`flex items-center py-3 px-3 border-l-3 ${
				selected ? 'border-blue-700' : 'border-transparent'
			}`}
		>
			<div className="mr-3">
				{attempt.status === WEBHOOK_STATUS_SUCCESS && <>✅</>}
				{attempt.status === WEBHOOK_STATUS_FAILED && <>❌</>}
			</div>
			<p className="flex-grow">{Helpers.t(`events.${attempt.event.type}`)}</p>
			<p className="text-gray-600 flex-shrink-0">
				{format(new Date(attempt.createdAt), 'HH:mm:ss')}
			</p>
		</div>
	</li>
);

const AttemptList = ({ attempts, selected, onSelectedChange }) => (
	<ul>
		{Object.entries(groupByDate(attempts)).map(([day, attempts]) => (
			<Fragment key={day}>
				<DateRow date={day} />
				{attempts.map(({ node }) => (
					<AttemptRow
						key={node.id}
						attempt={node}
						selected={node.id === selected}
						onSelect={() => onSelectedChange(node.id)}
					/>
				))}
			</Fragment>
		))}
	</ul>
);

const AttemptDetails = ({ attempt }) => (
	<>
		<div className="mb-6 flex">
			<div className="flex-grow">
				<Heading>{Helpers.t(`events.${attempt.event.type}`)}</Heading>
			</div>
			<div className="flex-shrink-0">
				<ApiIdLabel>{attempt.event.id}</ApiIdLabel>
			</div>
		</div>
		<Module bottomMargin heading="Response">
			<div className="mb-3">
				<HttpStatusBadge status={attempt.httpStatus} />
			</div>
			<TextPayload payload={attempt.responseBody || attempt.failDetail} />
		</Module>
		<Module bottomMargin heading="Request">
			<TextPayload payload={attempt.requestBody} />
		</Module>
	</>
);

const AttemptViewer = ({ attempts }) => {
	const [selected, setSelected] = useState(
		attempts.length > 0 ? attempts[0].node.id : null
	);

	if (attempts.length === 0) {
		return (
			<p className="text-center text-gray-600 mt-16">
				No events have been sent to this webhook recently
			</p>
		);
	}

	return (
		<div className="md:overflow-auto md:flex">
			<div className="flex-shrink-0 md:w-96 md:border-r border-gray-400 overflow-y-auto">
				<AttemptList
					attempts={attempts}
					onSelectedChange={setSelected}
					selected={selected}
				/>
			</div>
			<div className="flex-grow py-6 px-8 overflow-y-auto hidden md:block">
				{selected && (
					<AttemptDetails
						attempt={attempts.find(({ node }) => node.id === selected).node}
					/>
				)}
			</div>
		</div>
	);
};

export const Overview = ({ hook }) => (
	<div className="h-full flex flex-col outline-none pt-8 md:pt-30">
		<WebhookHeader hook={hook} />
		{!hook.isLoading && (
			<>
				<Details hook={hook.node} />
				<AttemptViewer attempts={hook.node.attempts.edges} />
			</>
		)}
	</div>
);
