// @ts-ignore
import { Button, ButtonGroup } from '@platformapp/ui';
import { StickyHeader } from '../common/StickyHeader';
import { Heading } from '../common/Heading';
import * as PropTypes from 'prop-types';
import { EditPublishableDropdown } from './EditPublishableDropdown';

type EditItemHeaderProps = {
	canPublish: boolean;
	collectionName: string;
	isDirty: boolean;
	isPublishable: boolean;
	isSaving: boolean;
	onDeleteClick?: () => void;
	onPublishClick?: () => void;
	onSaveClick?: () => void;
	onUnpublishClick?: () => void;
	status: string;
	title: string;
}

export const EditItemHeader = ({
	canPublish,
	isDirty,
	isPublishable,
	isSaving,
	onDeleteClick,
	onPublishClick,
	onSaveClick,
	onUnpublishClick,
	title,
	status,
	collectionName,
}: EditItemHeaderProps) => (
	// @ts-ignore
	<StickyHeader>
		<div className="md:flex items-end">
			<div className="md:flex-grow min-w-0 mb-2 md:mb-0">
				<div className="flex">
					<p className="text-gray-600 mb-1">{collectionName}</p>
				</div>
				<div className="flex items-center">
					{/* @ts-ignore */}
					<Heading truncate>{title}</Heading>
				</div>
			</div>

			<ButtonGroup className="flex-shrink-0">
				{isPublishable && (
					<Button
						primary
						width="large"
						// TODO: or if the draft / published versions are different
						disabled={isSaving || !canPublish}
						loading={isSaving}
						onClick={() => onPublishClick()}
					>
						Publish
					</Button>
				)}

				<Button
					primary={!isPublishable}
					width="large"
					disabled={isSaving || !isDirty}
					loading={isSaving}
					onClick={() => onSaveClick()}
				>
					Save
				</Button>

				{onDeleteClick && (
					<EditPublishableDropdown
						status={status}
						onDeleteClick={onDeleteClick}
						onUnpublishClick={onUnpublishClick}
					/>
				)}
			</ButtonGroup>
		</div>
	</StickyHeader>
);

EditItemHeader.defaultProps = {
	canPublish: true,
	isDirty: true,
	isPublishable: true,
	isSubLayer: false,
	status: null,
};

EditItemHeader.propTypes = {
	isDirty: PropTypes.bool.isRequired,
	isPublishable: PropTypes.bool,
	isSaving: PropTypes.bool.isRequired,
	onPublishClick: PropTypes.func.isRequired,
	onSaveClick: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	status: PropTypes.string,
};
