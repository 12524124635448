import React from 'react';

import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
} from '@platformapp/ui';

export const InfoDialog = ({ buttonText, onConfirm, title, children }) => (
	<Modal
		title={title}
		tiny
		isOpen
		onRequestClose={() => onConfirm()}
		shouldCloseOnEsc={true}
		shouldCloseOnOverlayClick={true}
	>
		<ModalContent>{children}</ModalContent>
		<ModalFooter>
			<ButtonGroup>
				<Button primary onClick={() => onConfirm()}>
					{buttonText}
				</Button>
			</ButtonGroup>
		</ModalFooter>
	</Modal>
);

InfoDialog.defaultProps = {
	buttonText: 'OK',
};
