import React, { useContext, useState } from 'react';
import { ListPageHeader } from '../common/ListPageHeader';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { InventoryItemsTable } from './InventoryItemsTable';
import { EnvironmentContext } from '../context/EnvironmentContext';
import LIST_INVENTORY_ITEMS from './ListInventoryItems.gql';
import { QueryStringPaginator } from '../common/Pagination';
import { compose, lensPath, pick, set } from 'ramda';
import { AdjustInventoryModal } from './AdjustInventoryModal';
import { StringParam, useQueryParams } from 'use-query-params';
import { DEFAULT_PER_PAGE } from '../constants';
import { ProductsSidebar } from '../products-list/ProductsSidebar';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { TableSkeleton } from '../common/Skeleton';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const ListInventory = withErrorBoundary(() => {
	useEnvironmentTitle('Inventory');
	const { contentApiRequest, envId } = useContext(EnvironmentContext);
	const [query] = useQueryParams({
		after: StringParam,
		before: StringParam,
	});
	const [adjusting, setAdjusting] = useState(false);
	const [selection, setSelection] = useState([]);
	const [result, setResult] = useState({
		loading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			if (selection.length > 0) {
				setSelection([]);
			}
			setResult({
				loading: true,
			});
			const variables = {};
			if (query.after) {
				variables.after = query.after;
				variables.first = DEFAULT_PER_PAGE;
			} else if (query.before) {
				variables.before = query.before;
				variables.last = DEFAULT_PER_PAGE;
			} else {
				variables.first = DEFAULT_PER_PAGE;
			}
			const res = await contentApiRequest({
				query: LIST_INVENTORY_ITEMS,
				variables,
			});
			if (!isActive()) {
				return;
			}
			setResult({
				loading: false,
				...res.data.data.allInventoryItems,
			});
		},
		[query, envId]
	);

	return (
		<>
			{adjusting && (
				<AdjustInventoryModal
					inventoryItems={result.edges
						.filter((invItem) => selection.includes(invItem.node.id))
						.map((ii) => ({
							id: ii.node.id,
							available: ii.node.available,
							productName: ii.node.variant.product.name,
							variantOptions: ii.node.variant.options,
						}))}
					onCancel={() => setAdjusting(false)}
					onUpdate={(invItems) => {
						setAdjusting(false);
						const updates = invItems.map((ii) => {
							const index = result.edges.findIndex(
								({ node }) => node.id === ii.id
							);
							const lens = lensPath(['edges', index, 'node', 'available']);
							return set(lens, ii.available);
						});
						setResult(compose(...updates)(result));
					}}
				/>
			)}

			<MainContentContainer>
				<MainContentNav>
					<ProductsSidebar />
				</MainContentNav>
				<MainContent>
					<ListPageHeader title="Inventory" sticky={false} />

					<div className="mt-5">
						{result.loading && <TableSkeleton />}

						{!result.loading && (
							<div className="fade-in">
								<InventoryItemsTable
									data={result.edges}
									onAdjustQuantitiesClick={() => setAdjusting(true)}
									onSelectionChange={setSelection}
									selection={selection}
								/>
								<QueryStringPaginator
									{...pick(['pageInfo', 'totalCount'], result)}
								/>
							</div>
						)}
					</div>
				</MainContent>
			</MainContentContainer>
		</>
	);
});
