import React, { useContext } from 'react';
import { Button, TextField, TextStyle } from '@platformapp/ui';
import {
	comparator,
	equals,
	lensPath,
	map,
	pipe,
	pluck,
	prop,
	remove,
	set,
	sortWith,
	transpose,
	uniqBy,
	update,
	uniq,
} from 'ramda';
import { TextList } from '../common/TextList';
import { extractGraphqlError } from '../util';
import { generateVariants } from './variants';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { initialVariantValues } from '../util/AttributeHelpers';

const getExistingOptions = pipe(pluck('options'), transpose, map(uniq));

export const VariantOptionsForm = ({ readOnly, form }) => {
	const { baseCurrency, collectionByApiId } = useContext(EnvironmentContext);
	const remainingOptions = 3 - form.values.options.length;
	const variantBlueprint = collectionByApiId('ProductVariant').node;
	const allOptionValues = getExistingOptions(form.values.variants);

	return (
		<>
			{form.values.options.length === 0 && (
				<p className="mb-5 leading-relaxed">
					No options have been defined. To generate variants for this product,
					add an option.
				</p>
			)}
			{form.values.options.map((option, i) => (
				<div
					className={`${
						i === form.values.length - 1 ? '' : 'border-b border-gray-300'
					} pb-5 mb-5`}
					key={i}
				>
					<div className="flex mb-3 items-center">
						<p className="flex-grow font-medium">Option {i + 1}</p>
						<button
							className={`flex-shrink-0 text-base ${
								readOnly
									? 'text-gray-500 cursor-auto'
									: 'text-blue-700 hover:text-blue-800'
							}`}
							disabled={readOnly}
							onClick={() => {
								// When an option is removed completely, we just need to delete all entries
								// from all option arrays at it's index, then remove any duplicate variants.
								const newVariants = uniqBy(
									prop('options'),
									form.values.variants.map((v) => ({
										...v,
										options: remove(i, 1, v.options),
									}))
								);

								form.setValues({
									...form.values,
									options: remove(i, 1, form.values.options),
									variants: newVariants,
								});
							}}
							type="button"
						>
							Remove
						</button>
					</div>
					<div className="flex">
						<div className="w-1/4 flex-shrink-0">
							<TextField
								error={extractGraphqlError(['options', i, 'name'], form.errors)}
								onChange={(e) => {
									form.setFieldValue(`options[${i}]`, e.target.value);
								}}
								placeholder="Option name"
								readOnly={readOnly}
								value={option}
							/>
						</div>
						<div className="flex-grow ml-5">
							<TextList
								readOnly={readOnly}
								error={extractGraphqlError(
									['options', i, 'values'],
									form.errors
								)}
								onAddItem={(item, optionValues) => {
									const allOptVals =
										allOptionValues.length === i
											? [...allOptionValues, []]
											: allOptionValues;

									const newAllOptions = set(
										lensPath([i]),
										optionValues,
										allOptVals
									);

									// If this is the first value being added for this option, just append the
									// value to all existing variants
									let newVariants;
									if (allOptVals[i].length === 0) {
										newVariants = form.values.variants.map((v) =>
											set(lensPath(['options', i]), item, v)
										);
									} else {
										// Generate all possible combinations for this option
										const optionCombinations = generateVariants(
											update(i, optionValues, allOptVals)
										);

										// Merge the existing variants and the newly generated ones
										newVariants = optionCombinations.reduce(
											(variants, options) => {
												// Determine if a variant for this option combination already exists
												const existingVariant = form.values.variants.find((v) =>
													equals(v.options, options)
												);

												if (existingVariant) {
													// Variant already exists - just return it to preserve existing price, SKU, etc.
													return [...variants, existingVariant];
												}

												// A new variant needs to be created
												const newVariant = {
													...initialVariantValues(
														variantBlueprint,
														baseCurrency
													),
													options,
												};

												// Copy some values from the default variant
												newVariant.price.amount =
													form.values.variants[0].price.amount;
												newVariant.requiresShipping =
													form.values.variants[0].requiresShipping;
												newVariant.sku = form.values.variants[0].sku;
												newVariant.inventoryItem.isTracked =
													form.values.variants[0].inventoryItem.isTracked;

												return [...variants, newVariant];
											},
											[]
										);
									}

									// Order the variants based on the option values
									const makeOptionComparator = (index) =>
										comparator((a, b) => {
											const indexA = newAllOptions[index].indexOf(
												a.options[index]
											);
											const indexB = newAllOptions[index].indexOf(
												b.options[index]
											);
											return indexA < indexB;
										});
									const sortedVariants = sortWith(
										[makeOptionComparator(0)],
										newVariants
									);

									form.setFieldValue('variants', sortedVariants);
								}}
								onRemoveItem={(item, allOptions) => {
									let newVariants;

									// If all option values have been removed, that's the equivalent of removing
									// the option completely.
									if (allOptions.length === 0) {
										newVariants = uniqBy(
											prop('options'),
											form.values.variants.map((v) => ({
												...v,
												options: remove(i, 1, v.options),
											}))
										);
									} else {
										// Filter out any variants that contain the given option
										newVariants = form.values.variants.filter(
											(v) => !v.options[i].includes(item)
										);
									}

									// Remove all variants containing this option
									form.setFieldValue('variants', newVariants);
								}}
								unique
								value={allOptionValues[i] || []}
							/>
						</div>
					</div>
				</div>
			))}
			<div className="flex items-center">
				<div className="flex-grow">
					<Button
						disabled={readOnly || form.values.options.length === 3}
						onClick={() => {
							form.setValues({
								...form.values,
								options: [...form.values.options, ''],
							});
						}}
					>
						{form.values.options.length === 0
							? 'Add an option'
							: 'Add another option'}
					</Button>
				</div>
				<p className="flex-shrink-0">
					<TextStyle subdued>
						{remainingOptions === 0 ? 'No' : remainingOptions} options remaining
					</TextStyle>
				</p>
			</div>
		</>
	);
};
