import React from 'react';
import { Module } from '../common/Module';
import { longDateTime } from '../util';
import { DataList, DataListItem } from '@platformapp/ui';

const ShipmentRow = ({ shipment }) => (
	<div className="py-5">
		<p className="font-medium mb-5">{longDateTime(shipment.createdAt)}</p>

		<div className="grid grid-cols-2 mb-5">
			<div>
				<DataList>
					<DataListItem stacked title="Tracking reference">
						<p>{shipment.trackingReference || '-'}</p>
					</DataListItem>
				</DataList>
			</div>
			<div>
				<DataList>
					<DataListItem stacked title="Carrier">
						<p>{shipment.carrierName || '-'}</p>
					</DataListItem>
				</DataList>
			</div>
		</div>
		<DataList className="mb-5">
			<DataListItem stacked title="Tracking URL">
				<p className="truncate">{shipment.trackingUrl || '-'}</p>
			</DataListItem>
		</DataList>
		<DataList>
			<DataListItem stacked title="Customer notified">
				<p>{shipment.customerNotified ? 'Yes' : 'No'}</p>
			</DataListItem>
		</DataList>
	</div>
);

export const ViewOrderShipments = ({ order }) => (
	<Module heading="Shipments">
		{order.shipments.edges.map(({ node }) => (
			<ShipmentRow shipment={node} key={node.id} />
		))}
	</Module>
);
