import React, { useContext, useState } from 'react';
import { Button } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { TeamContext } from '../context/TeamContext';
import { SCOPE_BILLING_UPDATE } from '../constants';
import { PaymentMethodOverview } from './PaymentMethodOverview';
import { ChangePaymentMethod } from './ChangePaymentMethod';

export const PaymentMethodSetting = ({
	subscription,
	paymentMethodUpdated,
}) => {
	const { canDo } = useContext(TeamContext);
	const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
	const hasPaymentMethod = subscription.paymentMethod !== null;

	return (
		<>
			{isAddingPaymentMethod && (
				<ChangePaymentMethod
					paymentMethodUpdated={(sub) => {
						paymentMethodUpdated(sub);
						setIsAddingPaymentMethod(false);
					}}
					onCancel={() => setIsAddingPaymentMethod(false)}
				/>
			)}
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Payment method</p>
					<p className="mb-4">
						Payment will be taken automatically using this payment method.
					</p>
					<PaymentMethodOverview subscription={subscription} />
				</FieldsetContent>
				<FieldsetFooter>
					{canDo(SCOPE_BILLING_UPDATE) && (
						<Button
							onClick={() => setIsAddingPaymentMethod(true)}
							width="large"
						>
							{hasPaymentMethod ? 'Update' : 'Add'} payment method
						</Button>
					)}
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
