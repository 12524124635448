import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { graphql } from '../api';
import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
	TextField,
	CheckboxField,
} from '@platformapp/ui';
import { pascalCase, extractGraphqlError } from '../util';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { plural, singular } from 'pluralize';
import CREATE_COLLECTION from './CreateCollection.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import toast from 'react-hot-toast';

const NewCollectionComponent = ({ onCancel, onConfirm }) => {
	const { environment } = useContext(EnvironmentContext);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			name: '',
			apiId: '',
			apiIdPlural: '',
			isPublishable: true,
		},
		initialStatus: {
			autoGenerateApiId: true,
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await graphql({
					query: CREATE_COLLECTION,
					variables: {
						input: {
							environmentId: environment.node.id,
							...values,
						},
					},
				});
				if (res.data.data.createCollection.userErrors.length > 0) {
					setErrors(res.data.data.createCollection.userErrors);
				} else if (res.data.data.createCollection.errors) {
					toast.error('Collection creation failed');
				} else {
					onConfirm(res.data.data.createCollection.collection);
				}
			} catch (err) {
				console.error(err);
				toast.error('Collection creation failed');
			}
		},
	});

	return (
		<Modal
			title="Create a new collection"
			small
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					<TextField
						disabled={form.isSubmitting}
						name="name"
						label="Name"
						onBlur={form.handleBlur}
						onChange={(e) => {
							form.setFieldValue('name', e.target.value);
							if (form.status.autoGenerateApiId) {
								form.setFieldValue(
									'apiId',
									pascalCase(singular(e.target.value))
								);
								form.setFieldValue(
									'apiIdPlural',
									pascalCase(plural(e.target.value))
								);
							}
						}}
						value={form.values.name}
						error={extractGraphqlError('name', form.errors)}
						autoFocus
					/>

					<div className="flex mb-8">
						<div className="w-1/2 pr-3">
							<TextField
								disabled={form.isSubmitting}
								name="apiId"
								label="Singular identifier"
								helpText="How single items are referred to"
								onBlur={form.handleBlur}
								onChange={(e) => {
									form.setFieldValue('apiId', e.target.value);
									form.setStatus({
										autoGenerateApiId: false,
									});
								}}
								value={form.values.apiId}
								error={extractGraphqlError('apiId', form.errors)}
								inputClassName="font-mono"
							/>
						</div>
						<div className="w-1/2 pl-3">
							<TextField
								disabled={form.isSubmitting}
								name="apiIdPlural"
								label="Plural identifier"
								helpText="How multiple items are referred to"
								onBlur={form.handleBlur}
								onChange={(e) => {
									form.setFieldValue('apiIdPlural', e.target.value);
									form.setStatus({
										autoGenerateApiId: false,
									});
								}}
								value={form.values.apiIdPlural}
								error={extractGraphqlError('apiIdPlural', form.errors)}
								inputClassName="font-mono"
							/>
						</div>
					</div>

					<CheckboxField
						disabled={form.isSubmitting}
						label="Enable publishing workflow"
						helpText="Publishing workflow allows items to exist in draft and published stages. This cannot be changed after a collection has been created."
						checked={form.values.isPublishable}
						onChange={(checked) => form.setFieldValue('isPublishable', checked)}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							Create collection
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export const NewCollection = withErrorBoundary(NewCollectionComponent);
