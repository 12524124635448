import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TeamContext } from '../context/TeamContext';
import { TeamsContext } from '../context/TeamsContext';
import { ActionList, Icon } from '@platformapp/ui';
import { CreateTeam } from '../team/CreateTeam';
// import ChevronDownIcon from '../icons/chevron-down.svg';
import ChevronDownIcon from '../icons/code-2.svg';

export const TeamMenu = () => {
	const { addTeam, teams } = useContext(TeamsContext);
	const { activeTeam } = useContext(TeamContext);
	const history = useHistory();
	const [isCreatingTeam, setIsCreatingTeam] = useState(false);
	return (
		<>
			{isCreatingTeam && (
				<CreateTeam
					onCancel={() => setIsCreatingTeam(false)}
					onCreate={(team) => {
						addTeam(team);
						setIsCreatingTeam(false);
						history.push(`/${team.slug}`);
					}}
				/>
			)}
			{activeTeam && (
				<>
					<Link
						className="text-base text-gray-900 mr-2"
						to={`/${activeTeam.slug}`}
					>
						{activeTeam.name}
					</Link>
					<ActionList
						trigger={
							<button className="text-base text-gray-900 truncate">
								<Icon
									source={ChevronDownIcon}
									className="text-gray-600 transform rotate-90 scale-75"
								/>
							</button>
						}
						sections={[
							{
								items: teams.map((team) => ({
									label: team.node.name,
									to: `/${team.node.slug}`,
								})),
							},
							{
								items: [
									{
										label: 'Create team',
										onAction: () => setIsCreatingTeam(true),
									},
								],
							},
						]}
					/>
				</>
			)}
		</>
	);
};
