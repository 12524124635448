// @ts-ignore
import { TextStyle } from '@platformapp/ui';
import { isNil, isEmpty } from 'ramda';
import { B } from '../common/Text';
import { Attribute, BasicAttributeType } from '../types/Attribute';

const EXCLUDED_ATTRIBUTES = Object.freeze([
	'isSpam',
	'isArchived',
	'ipAddress',
	'userAgent'
]);

const processValue = (value: any, attribute: Attribute) => {
	if (attribute.__typename === 'BasicAttribute') {
		switch (attribute.type) {
			case BasicAttributeType.Boolean:
				return value ? 'Yes' : 'No';
		}
	}
	return value;
}

type SubmissionFieldsProps = {
	attributes: Attribute[];
	submission: any;
}

export const SubmissionFields = ({ attributes, submission }: SubmissionFieldsProps) => (
	<div className="bg-gray-100 rounded p-5 mb-6">
		{attributes
			.filter((a) => !a.isSystem && !EXCLUDED_ATTRIBUTES.includes(a.apiId))
			.map((attr) => {
				const isBlank =
					isNil(submission[attr.apiId]) || isEmpty(submission[attr.apiId]);

				return (
					<div className="mb-6 last:mb-0" key={attr.apiId}>
						<p className="leading-normal mb-2">
							<B>{attr.name}</B>
						</p>
						{!isBlank && (
							<p className="leading-normal">{processValue(submission[attr.apiId], attr)}</p>
						)}
						{isBlank && (
							<p className="leading-normal">
								<TextStyle subdued>No value</TextStyle>
							</p>
						)}
					</div>
				);
			})}
	</div>
);
