import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TeamMemberList } from './TeamMemberList';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { prepend, reject } from 'ramda';
import TEAM_MEMBERS_QUERY from './listMembers.gql';
import { update } from 'ramda';
import { useTeamTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

export const TeamListContainer = withErrorBoundary(() => {
	useTeamTitle('Members');
	const { teamSlug } = useParams();

	const [result, setResult] = useState({
		isLoading: true,
		data: [],
		totalCount: 0,
		edges: [],
		pageInfo: {},
	});

	useAsyncEffect(async (isActive) => {
		const { data } = await graphql({
			query: TEAM_MEMBERS_QUERY,
			variables: {
				slug: teamSlug,
			},
		});
		if (!isActive()) {
			return;
		}
		setResult({
			isLoading: false,
			...data.data.team.members,
		});
	}, []);

	const handleDelete = (id) => {
		setResult({
			...result,
			edges: reject((x) => x.node.id === id, result.edges),
		});
	};

	const handleAdd = (member) => {
		setResult({
			...result,
			edges: prepend({ node: member }, result.edges),
		});
	};

	const handleUpdate = (index, member) => {
		setResult({
			...result,
			edges: update(index, { node: member }, result.edges),
		});
	};

	return (
		<TeamMemberList
			result={result}
			onDelete={handleDelete}
			onAdd={handleAdd}
			onUpdate={handleUpdate}
		/>
	);
});
