import React from 'react';
import { ActionList, Button, ChevronDownIcon } from '@platformapp/ui';

export const AddComponentListItemDropdown = ({
	attribute,
	onAddItem,
	readOnly,
}) => {
	// If there is only 1 allowed item type, show a button rather than a dropdown
	if (attribute.allowedComponents.length === 1) {
		return (
			<Button
				disabled={readOnly}
				onClick={() => onAddItem(attribute.allowedComponents[0].apiId)}
			>
				Add {attribute.allowedComponents[0].name}
			</Button>
		);
	}

	const items = attribute.allowedComponents.map((type) => ({
		onAction: () => onAddItem(type.apiId),
		label: type.name,
	}));

	return (
		<ActionList
			trigger={
				<Button disabled={readOnly}>
					Add item <ChevronDownIcon className="h-4" />
				</Button>
			}
			items={items}
		/>
	);
};
