import {oneOf} from 'prop-types';

const P_BASE_CLASSNAME = 'text-base leading-relaxed mb-1 last:mb-0';

type PProps = {
	centered?: boolean;
	className?: string;
	light?: boolean;
	weight?: 'normal' | 'medium';
	[key: string]: any;
}

export const P = ({ centered, className, light, weight, ...props }: PProps) => {
	return (
		<p
			className={`${className} ${centered ? 'text-center' : ''} ${
				light ? 'text-gray-600' : 'text-gray-800'
			} ${weight === 'medium' && 'font-medium'} ${P_BASE_CLASSNAME}`}
			{...props}
		/>
	);
};

P.defaultProps = {
	centered: false,
	className: '',
	weight: 'normal',
};

P.propTypes = {
	weight: oneOf(['normal', 'medium']),
};

interface BProps {
	className?: string;
}

export const B = ({ className, ...props }: BProps) => (
	<b className={`font-medium ${className}`} {...props} />
);

B.defaultProps = {
	className: '',
};
