import { append, without } from 'ramda';
//@ts-ignore
import { CheckboxField, Image } from '@platformapp/ui';
import { ASSET_FILTER_IMAGE } from '../constants';
import { FileThumbnail } from './FileThumbnail';
import { assetTypeFromMime } from '../util';
import { PublishableContextMenu } from '../publishable/EditPublishableDropdown';
import { ItemStage } from '../types/Item';
import { BulkAction, BulkActionBar } from '../common/BulkActionBar';
import { getTitle } from '../util/AttributeHelpers';

interface Data {
	node: any;
}

interface AssetGridProps {
	bulkActions: BulkAction[];
	data: Data[];
	multiple: boolean;
	onChange: any;
	onDelete: any;
	onDoubleClick: any;
	onSelectionChange: any;
	selection: string[];
	setViewingDetails: any;
	showContextMenu: boolean;
	updateContentStatus: any;
}

export const AssetGrid = ({
	bulkActions,
	data,
	multiple,
	onDelete,
	onDoubleClick,
	onSelectionChange,
	selection,
	setViewingDetails,
	showContextMenu,
	updateContentStatus
}: AssetGridProps) => (
	<div className="border-b border-gray-300 pb-6">
		<div className="border-b border-gray-300 mb-4">
			{multiple && (<>
				{selection.length > 0 && bulkActions.length > 0 && (
					<BulkActionBar
						bulkActions={bulkActions}
						onSelectAll={() => onSelectionChange([])}
						selection={selection}
					/>
				)}
				<div className="flex-grow py-3 px-2">
					<CheckboxField
						checked={selection.length === data.length}
						onChange={(checked: boolean) =>
							onSelectionChange(
								checked ? data.map((asset) => asset.node.id) : []
							)
						}
					/>
				</div>
			</>)}
		</div>
		<div className="flex flex-wrap md:-m-3">
			{data.map(({ node }) => {
				const type = assetTypeFromMime(node.mimeType);
				return (
					<div className="group w-1/3 md:w-1/4 lg:w-1/5 p-3" key={node.id}>
						<div className="relative square">
							<div className="w-full h-full absolute">
								<div
									className={`w-full h-full hover:bg-gray-200 border-t-2 border-transparent relative ${
										multiple ? '' : 'cursor-pointer'
									} ${
										selection.includes(node.id)
											? 'bg-gray-200 border-blue-700'
											: ''
									}`}
									onClick={(e) => {
										if (e.metaKey) {
											if (selection.includes(node.id)) {
												onSelectionChange(without([node.id], selection));
											} else {
												onSelectionChange(append(node.id, selection));
											}
										} else {
											onSelectionChange([node.id])
										}
									}}
									onDoubleClick={() => {
										if (setViewingDetails) {
											setViewingDetails(node.id);
										} else if (onDoubleClick) {
											onDoubleClick(node);
										}
									}}
								>
									{multiple && (
										<div
											className={`${
												selection.length === 0
													? 'lg:hidden group-hover:block'
													: ''
											} absolute top-0 left-0 z-10 pl-3 pt-3`}
										>
											<CheckboxField
												checked={selection.includes(node.id)}
												onChange={(checked: boolean) =>
													onSelectionChange(
														checked
															? append(node.id, selection)
															: selection.filter((s) => s !== node.id)
													)
												}
											/>
										</div>
									)}
									{showContextMenu && (
										<div className="absolute top-0 right-0 pr-3 pt-3 z-10">
											<PublishableContextMenu
												isPublishable
												onDeleteClick={() => onDelete([node.id])}
												publishable={node}
												smallTrigger
												styleOnHover={false}
												togglePublish={(status: ItemStage) => updateContentStatus(status, node)}
											/>
										</div>
									)}
									{type === ASSET_FILTER_IMAGE && (
										<Image
											className={`rounded overflow-hidden transition-transform duration-150 ${
												selection.includes(node.id) ? 'transform scale-90' : ''
											}`}
											src={node.largeThumbUrl}
										/>
									)}
									{type !== ASSET_FILTER_IMAGE && (<>
										{/* @ts-ignore */}
										<FileThumbnail asset={node} />
									</>)}
								</div>
							</div>
						</div>
						<button
							className="block w-full truncate text-center mt-3 text-sm hover:text-blue-700"
							onClick={() => {
								if (setViewingDetails) {
									setViewingDetails(node.id);
								} else {
									onSelectionChange([node.id]);
								}
							}}
						>
							{getTitle(node)}
						</button>
					</div>
				);
			})}
		</div>
	</div>
);

AssetGrid.defaultProps = {
	bulkActions: [],
	multiple: false,
	selection: [],
	showContextMenu: false,
};
