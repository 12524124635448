import React, { useContext } from 'react';
import { Paginator } from '../common/Pagination';
import { OrderTable } from './OrderTable';
import { OrderFilters } from './OrderFilters';
import { ListPageHeader } from '../common/ListPageHeader';
import { ErrorIcon, LandingMessage } from '@platformapp/ui';
import { useQuery } from '../hooks/useQuery';
import { pick } from 'ramda';
import { EmptyMessage } from '../common/EmptyMessage';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { TableSkeleton } from '../common/Skeleton';

export const OrderList = ({ orders }) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	const { filters } = useQuery();

	return (
		<div className="mt-24 pt-6 mx-5 md:mx-10 md:pr-10">
			<ListPageHeader
				title="Orders"
				bottom={<OrderFilters appliedFilters={filters} />}
			/>

			<div className="mt-5">
				{orders.isLoading && <TableSkeleton />}

				{!orders.isLoading && !orders.isError && orders.edges.length > 0 && (
					<>
						<OrderTable orders={orders.edges} />
						<Paginator
							{...pick(['totalCount', 'pageInfo'], orders)}
							baseUrl={generateEnvPath('orders')}
							filters={filters}
						/>
					</>
				)}

				{!orders.isLoading && !orders.isError && orders.edges.length === 0 && (
					<EmptyMessage
						title="No orders found"
						message="Try changing filter options above."
						className="fade-in"
					/>
				)}

				{orders.isError && (
					<div className="fade-in">
						<LandingMessage
							icon={<ErrorIcon />}
							header="An error occurred fetching orders"
							body="Please try clearing all filters, or try this query again later."
						/>
					</div>
				)}
			</div>
		</div>
	);
};
