import React, { useState } from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import { graphql } from '../api';
import { ConfirmDialog } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import DELETE_TEAM_MEMBER_QUERY from './deleteMember.gql';
import { AddTeamMember } from './AddTeamMember';
import { B } from '../common/Text';
import toast from 'react-hot-toast';

export const TeamMemberContextMenu = ({ onDelete, onUpdate, node }) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleDelete = () => {
		setIsDeleting(true);

		graphql({
			query: DELETE_TEAM_MEMBER_QUERY,
			variables: {
				input: {
					teamMemberId: node.id,
				},
			},
		})
			.then((res) => {
				const payload = res.data.data.deleteTeamMember;

				if (payload.userErrors.length > 0) {
					const err = extractGraphqlError('teamMemberId', payload.userErrors);

					if (err.length > 0) {
						toast.error('A team must have at least 1 owner');
					}

					setIsConfirmingDelete(false);
				} else if (payload.errors) {
					toast.error('An error occurred deleting the team member');
					setIsDeleting(false);
				} else {
					onDelete();
				}
			})
			.catch((err) => {
				console.error(err);
				toast.error('An error occurred deleting the team member');
				setIsDeleting(false);
			});
	};

	const handleUpdate = (member) => {
		onUpdate(member);
		setIsEditing(false);
	};

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					dangerous
					title="Remove team member"
					body={
						<>
							<p className="leading-normal">
								Are you sure you want to remove <B>{node.email}</B> as a team
								member?
							</p>
							<p className="leading-normal">
								They will no longer be able to access this project and this
								action cannot be undone.
							</p>
						</>
					}
					onConfirm={handleDelete}
					onCancel={() => setIsConfirmingDelete(false)}
					isWorking={isDeleting}
					confirmText="Delete team member"
				/>
			)}

			{isEditing && (
				<AddTeamMember
					existingItem={node}
					initialValues={{
						email: node.email,
						role: node.role,
					}}
					onCancel={() => setIsEditing(false)}
					onSave={handleUpdate}
				/>
			)}

			<NewContextMenu
				styleOnHover
				sections={[
					{
						items: [
							{
								label: 'Edit',
								onAction: () => setIsEditing(true),
							},
						],
					},
					{
						items: [
							{
								label: node.isAccepted ? 'Delete' : 'Revoke invite',
								onAction: () => setIsConfirmingDelete(true),
								destructive: true,
							},
						],
					},
				]}
			/>
		</>
	);
};
