import React, { useMemo, useState } from 'react';
import { Toolbar } from './Toolbar';
import { TextStyle } from '@platformapp/ui';
import { createEditor, Node } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import debounce from 'lodash.debounce';

export const RawEditor = ({
	autoFocus,
	mode,
	onChange,
	readOnly,
	setMode,
	value,
}) => {
	const [internalValue, setInternalValue] = useState(
		value.split('\n').map((node) => ({
			children: [{ text: node }],
		}))
	);

	const editor = useMemo(() => withHistory(withReact(createEditor())), []);

	const handleRawChanged = debounce((value) => {
		onChange(value.map((n) => Node.string(n)).join('\n'));
	}, 150);

	return (
		<>
			<Slate
				editor={editor}
				value={internalValue}
				onChange={(value) => {
					setInternalValue(value);
					handleRawChanged(value);
				}}
			>
				<Toolbar
					mode={mode}
					disabled
					setMode={setMode}
					toggleDisabled={readOnly}
				/>
				<Editable
					autoFocus={autoFocus}
					className="font-mono p-4"
					placeholder="Enter some Markdown..."
					readOnly={readOnly}
				/>
			</Slate>
			<div className="py-3 px-4 bg-gray-200">
				<p>
					<TextStyle subdued>
						<a
							href="https://commonmark.org/help/"
							target="_blank"
							className="hover:text-blue-700"
							rel="noreferrer"
						>
							Styling with CommonMark is supported
						</a>
					</TextStyle>
				</p>
			</div>
		</>
	);
};
