import axios from 'axios';
import { PlatformConfig } from './PlatformConfig';
import { stringify } from 'qs';
import debug from 'debug';

let isRefreshing = false;
let refreshSubscribers = [];
let refreshFailedCallback;

const d = debug('api');

d(`Configuring API with base "${PlatformConfig.api}"`);

const instance = axios.create({
	baseURL: PlatformConfig.api,
	paramsSerializer: function (params) {
		return stringify(params);
	},
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
	},
	timeout: 30000,
	withCredentials: true,
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const {
			config,
			response: { status },
		} = error;
		const originalRequest = config;

		d('Error response received: ' + status);

		if (error.request.responseURL.endsWith('/auth/refresh-token')) {
			d('Token refresh failed.');
			refreshFailedCallback();
		}

		if (status == 401) {
			if (!isRefreshing) {
				d('Triggering refreshing token...');
				isRefreshing = true;
				refreshAccessToken().then((newToken) => {
					d('Token refreshed.');
					isRefreshing = false;
					onRefreshed(newToken);
				});
			} else {
				d('Already refreshing');
			}

			const retryOrigReq = new Promise((resolve) => {
				subscribeTokenRefresh(() => {
					// Retry original request
					d(
						`Retrying ${originalRequest.method.toUpperCase()} ${
							originalRequest.url
						}`
					);
					resolve(axios(originalRequest));
				});
			});
			return retryOrigReq;
		} else {
			return Promise.reject(error);
		}
	}
);

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
	refreshSubscribers.map((cb) => cb(token));
	refreshSubscribers = [];
};

const refreshAccessToken = function () {
	d('Refreshing access token...');
	return instance.post('auth/refresh-token');
};

export const init = (failedCb) => {
	d('Initialising API...');
	refreshFailedCallback = failedCb;
};

export default instance;

/**
 *
 * @param {Object} args GraphQL arguments
 * @param {string} args.query GraphQL query or mutation
 * @param {Object} [args.variables] Variables object
 * @param {string} [args.operationName] Operation name
 * @param {string} [path=graphql] Path of API
 */
export const graphql = (args, path = 'graphql') => instance.post(path, args);

export const api = instance;
