import React from 'react';
import { Button, formatCurrency } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { P } from '../common/Text';
import { useParams } from 'react-router-dom';
import { formatShortDate } from '../util';
import { differenceInCalendarDays, parseISO } from 'date-fns';

export const PlanSetting = ({ subscription }) => {
	const { teamSlug } = useParams();
	const subscriptionTotal = subscription.items.reduce(
		(total, item) => total + item.totalPrice,
		0
	);
	return (
		<>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Plan</p>
					{subscription.isTrialing && (
						<>
							<P>
								There are{' '}
								{differenceInCalendarDays(
									parseISO(subscription.nextPaymentAt),
									new Date()
								)}{' '}
								days left on your Basic trial. The first payment of{' '}
								{formatCurrency(subscriptionTotal, 'gbp')} will occur on{' '}
								{formatShortDate(subscription.nextPaymentAt)}.
							</P>
						</>
					)}
					<PlanTable subscription={subscription} total={subscriptionTotal} />
				</FieldsetContent>
				<FieldsetFooter>
					<Button to={`/${teamSlug}/change-plan`} width="large">
						Change plan
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};

const Th = ({ className, rightAlign, ...props }) => (
	<th
		className={`${className ? className : ''} py-3 text-gray-700 ${
			rightAlign ? 'text-right' : 'text-left'
		}`}
		{...props}
	/>
);

Th.defaultProps = {
	className: null,
	rightAlign: false,
};

const Td = ({ className, rightAlign, ...props }) => (
	<td
		className={`${className ? className : ''} py-4 ${
			rightAlign ? 'text-right' : 'text-left'
		}`}
		{...props}
	/>
);

Td.defaultProps = {
	className: null,
	rightAlign: false,
};

const BASE_PLAN_PRODUCTS = Object.freeze([
	'prod_ImsQlgbfClJhbn',
	'prod_InQCoW8mUTJu4t',
	'prod_InQDNMVcVxnxuP',
]);

const PlanTable = ({ subscription, total }) => (
	<table className="w-full mt-3">
		<thead>
			<tr className="border-b border-gray-300">
				<Th>Item</Th>
				<Th rightAlign>Quantity</Th>
				<Th rightAlign>Unit price</Th>
				<Th rightAlign>Total</Th>
			</tr>
		</thead>
		<tbody>
			{subscription.items.map((item) => (
				<tr className="border-b border-gray-300" key={item.id}>
					<Td colSpan={BASE_PLAN_PRODUCTS.includes(item.id) ? 3 : 1}>
						{item.name}
					</Td>
					{!BASE_PLAN_PRODUCTS.includes(item.id) && (
						<>
							<Td rightAlign>{item.quantity}</Td>
							<Td rightAlign>{formatCurrency(item.unitPrice, 'gbp')}</Td>
						</>
					)}
					<Td rightAlign>{formatCurrency(item.totalPrice, 'gbp')}</Td>
				</tr>
			))}
		</tbody>
		<tfoot>
			<tr>
				<Td colSpan="3" className="font-medium">
					Total
				</Td>
				<Td rightAlign className="font-medium">
					{formatCurrency(total, 'gbp')}
				</Td>
			</tr>
		</tfoot>
	</table>
);
