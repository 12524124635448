import { isEmpty, isNil, propOr, concat, equals } from 'ramda';
import {
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_COMPONENT,
	TYPE_MONEY,
} from '../constants';
import { Collection } from '../types/Collection';
import { Component } from '../types/Component';
import { ProductVariant } from '../types/Item';

type ErrorMessage = {
	path: string[];
	message: string;
}

export const validateVariant = (variant: ProductVariant, pathPrefix = ['input']): ErrorMessage[] => {
	let errors: ErrorMessage[] = [];

	if (variant.onSale && isNil(variant.salePrice)) {
		errors.push({
			message: 'A sale price must be set to mark a product as on sale.',
			path: concat(pathPrefix, ['onSale'])
		});
	}

	if (!isNil(variant.salePrice) && variant.price.amount < variant.salePrice.amount) {
		errors.push({
			message: 'Sale price cannot be more than the price.',
			path: concat(pathPrefix, ['salePrice'])
		});
	}

	return errors;
}

export const validateAttributeValues = (
	values: any,
	blueprint: Collection | Component,
	pathPrefix = ['input']
): ErrorMessage[] =>
	blueprint.attributes
		.filter((attr) => attr.isEditable)
		.reduce((errors, attr) => {
			const value: any = propOr(null, attr.apiId, values);
			const isNullValue = isNil(value) || isEmpty(value);

			// Ensure required fields have a non-null, non-empty value. Temporarily
			// skip validating bidirectional references
			if (
				attr.isRequired &&
				isNullValue &&
				attr.__typename !== ATTRIBUTE_TYPENAME_BIREF
			) {
				errors.push({
					path: concat(pathPrefix, [attr.apiId]),
					message: 'Required',
				});
			}

			switch (attr.__typename) {
				case ATTRIBUTE_TYPENAME_BASIC: {
					switch (attr.type) {
						case TYPE_MONEY:
							if (attr.isList) {
								for (let i = 0; i < value.length; i++) {
									const price = value[i];
									if (price.amount < 0) {
										errors.push({
											path: concat(pathPrefix, [
												attr.apiId,
												i.toString(),
												'amount',
											]),
											message: 'Cannot be a negative value',
										});
									}
								}
							}
							break;
					}
					break;
				}
				case ATTRIBUTE_TYPENAME_COMPONENT: {
					if (attr.isList) {
						const componentErrors = value.reduce((prev: any[], item: any, i: number) => {
							const componentApiId = Object.keys(item)[0];
							const component = attr.allowedComponents.find(
								(a) => a.apiId === componentApiId
							);

							return [
								...prev,
								...validateAttributeValues(
									item[componentApiId],
									component,
									concat(pathPrefix, [attr.apiId, `${i}`, componentApiId])
								)
							];
						}, []);

						errors = concat(errors, componentErrors);
						break;
					}

					if (!value) {
						break;
					}

					const componentApiId = Object.keys(value)[0];
					const component = attr.allowedComponents.find(
						(a) => a.apiId === componentApiId
					);
					const res = validateAttributeValues(
						value[componentApiId],
						component,
						concat(pathPrefix, [attr.apiId, componentApiId])
					);
					errors = concat(errors, res);
					break;
				}
			}

			console.debug('Validation errors', errors);

			return errors;
		}, []);

export const getPriceErrors = (errors: ErrorMessage[]) =>
	errors.reduce((errors, err) => {
		if (
			err.path.length < 4 ||
			!equals(err.path.slice(0, 4), ['input', 'variants', '0', 'prices'])
		) {
			return errors;
		}

		return [
			...errors,
			{
				path: err.path.slice(4),
				message: err.message,
			},
		];
	}, []);

/**
 * Retrieve all errors starting with the given path.
 * @param {*} errors
 * @param {*} path
 * @returns
 */
export const getErrorsMatchingPath = (errors: ErrorMessage[], path: string[], stripPrefix = true) =>
	errors.reduce((errors, err) => {
		if (
			err.path.length < path.length ||
			!equals(err.path.slice(0, path.length), path)
		) {
			return errors;
		}

		return [
			...errors,
			{
				path: stripPrefix ? err.path.slice(path.length) : err.path,
				message: err.message,
			},
		];
	}, []);
