import { TopNavLink } from '../common/PrimaryNav';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SCOPE_UPDATE_TEAM } from '../constants';
import { TeamContext } from '../context/TeamContext';

export const TeamNav = () => {
	const { canDo } = useContext(TeamContext);
	const { teamSlug } = useParams();
	const base = `/${teamSlug}`;
	return (
		<>
			<TopNavLink to={base} exact>
				Projects
			</TopNavLink>
			<TopNavLink to={`${base}/members`} exact>
				Members
			</TopNavLink>
			{/* <TopNavLink to={`${base}/billing`} exact>
				Billing
			</TopNavLink> */}
			{canDo(SCOPE_UPDATE_TEAM) && (
				<>
					<TopNavLink to={`${base}/usage`} exact>
						Usage
					</TopNavLink>
					<TopNavLink to={`${base}/settings`} exact>
						Settings
					</TopNavLink>
				</>
			)}
		</>
	);
};
