import React, { useContext } from 'react';
import { ResourceTable } from '../common/ResourceTable';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Badge, TextStyle } from '@platformapp/ui';
import { Link } from '../common/Link';

const ProductCell = ({ node }) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<>
			<div>
				<Link to={generateEnvPath(`products/${node.variant.product.id}`)}>
					{node.variant.product.name}
				</Link>
			</div>
			{node.variant.options.length > 0 && (
				<div>
					<Link
						className="text-sm"
						to={generateEnvPath(
							`products/${node.variant.product.id}/variants/${node.variant.id}`
						)}
					>
						{node.variant.options.join(' / ')}
					</Link>
				</div>
			)}
		</>
	);
};

const SkuCell = ({ node }) => {
	if (node.variant.sku) {
		return node.variant.sku;
	}
	return <TextStyle subdued>No SKU</TextStyle>;
};

export const InventoryItemsTable = ({ onAdjustQuantitiesClick, ...props }) => {
	const tableDef = [
		{
			title: 'Product',
			render: ({ node }) => <ProductCell node={node} />,
		},
		{
			title: 'SKU',
			render: ({ node }) => <SkuCell node={node} />,
		},
		{
			title: 'Tracking',
			render: ({ node }) => (
				<Badge
					text={node.isTracked ? 'Tracked' : 'Not tracked'}
					positive={node.isTracked}
				/>
			),
		},
		{
			title: 'Available',
			render: ({ node }) => <p>{node.available}</p>,
		},
	];

	return (
		<ResourceTable
			{...props}
			columns={tableDef}
			idSource={['node', 'id']}
			bulkActions={[
				{
					label: 'Adjust quantities',
					onAction: () => onAdjustQuantitiesClick(),
				},
			]}
			selectable
			selectOnRowClick
		/>
	);
};
