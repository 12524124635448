import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { ProjectContext } from '../context/ProjectContext';
import { CrudScopeRow } from './CrudScopeRow';
import { Button } from '@platformapp/ui';
import { updateApiKey } from './mutations';
import toast from 'react-hot-toast';

const RESOURCES = Object.freeze([
	{
		name: 'Attributes',
		prefix: 'ATTRIBUTE',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	{
		name: 'Collections',
		prefix: 'COLLECTION',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	{
		name: 'Components',
		prefix: 'COMPONENT',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	{
		name: 'CORS origins',
		prefix: 'CORS_ORIGIN',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	{
		name: 'Enumerations',
		prefix: 'ENUM',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	{
		name: 'Environments',
		prefix: 'ENVIRONMENT',
		create: false,
		read: true,
		update: true,
		delete: false,
	},
	{
		name: 'Webhooks',
		prefix: 'WEBHOOK',
		create: true,
		read: true,
		update: true,
		delete: true,
	},
]);

export const ManagementPermissions = ({
	apiKey,
	disabled,
	initialPermissions,
}) => {
	const { activeProject } = useContext(ProjectContext);
	const form = useFormik({
		initialValues: {
			permissions: initialPermissions,
		},
		onSubmit: async (values, { resetForm }) => {
			await updateApiKey(apiKey.id, {
				managementPermissions: values.permissions,
			});
			resetForm({
				values,
			});
			toast.success('Permissions saved');
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Management permissions</p>
					<p className="mb-8">
						These permissions allow API keys to access and manage resources
						within an environment, like collections and webhooks.
					</p>
					<div className="border border-gray-300 rounded">
						{RESOURCES.map((r, index) => (
							<CrudScopeRow
								disabled={form.isSubmitting}
								key={index}
								resourceName={r.name}
								prefix={r.prefix}
								createScope={r.create}
								readScope={r.read}
								updateScope={r.update}
								deleteScope={r.delete}
								selectedScope={form.values.permissions}
								onChange={(val) => form.setFieldValue('permissions', val)}
							/>
						))}
						{activeProject && activeProject.ecommerceEnabled && (
							<CrudScopeRow
								disabled={form.isSubmitting}
								resourceName="Orders"
								prefix="ORDER"
								createScope={false}
								readScope={true}
								updateScope={false}
								deleteScope={false}
								selectedScope={form.values.permissions}
								onChange={(val) => form.setFieldValue('permissions', val)}
							/>
						)}
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
