import { Node, Editor, Transforms } from "slate";

/**
 * Ensure that the final node at the top level of a Slate editor is editable (not void).
 * @param {Slate} editor 
 */
export const withLastEditable = editor => {
    const { normalizeNode } = editor
    editor.normalizeNode = entry => {
        // If the last node in the top level of the document is a code block, add a paragraph below.
        const lastNode = Node.child(editor, editor.children.length - 1);

        if (Editor.isVoid(editor, lastNode)) {
            console.debug('Last node is void - adding trailing paragraph');
            Transforms.insertNodes(editor, {
                type: 'paragraph',
                children: [
                    { text: '' }
                ]
            }, {
                at: Editor.end(editor, [])
            })
        }

        normalizeNode(entry);
    }
    return editor
}