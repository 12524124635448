import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const LINK_CLASSES = 'text-blue-700 leading-relaxed';
const LINK_HOVER_CLASSES = 'hover:text-blue-800';

export const Link = ({ className, to, external, newWindow, ...props }) => {
	const classNames = `${LINK_CLASSES} ${LINK_HOVER_CLASSES} ${className}`;

	if (external) {
		const aProps = {
			href: to,
			className: classNames,
		};

		if (newWindow) {
			aProps.target = '_blank';
			aProps.rel = 'noreferrer';
		}

		return <a {...aProps} {...props} />;
	}

	return <RouterLink to={to} className={classNames} {...props} />;
};

Link.defaultProps = {
	className: '',
	external: false,
	newWindow: false,
};

Link.propTypes = {
	// to: PropTypes.isRequired,
	external: PropTypes.bool,
	newWindow: PropTypes.bool,
};
