import { useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { createAttributeValues } from '../util/AttributeHelpers';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { EditProductForm } from './EditProductForm';
import { NotFound } from '../NotFound';
import { pick } from 'ramda';
import { ProjectContext } from '../context/ProjectContext';
import { withErrorBoundary } from '../common/ErrorBoundary';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { ProductsSidebar } from '../products-list/ProductsSidebar';
import { useEnvironmentTitle } from '../hooks/useTitle';
import {useAsyncEffect} from 'use-async-effect';
import { reducer } from './ProductEditReducer';
import { HeadingLoader, TableSkeleton } from '../common/Skeleton';
import { ContentApiContext } from '../context/ContentApiContext';

type UrlParams = {
	id: string;
}

export const EditProductContainer = withErrorBoundary(() => {
	const {
		environment,
		collectionByApiId,
	} = useContext(EnvironmentContext);
	const { getItem } = useContext(ContentApiContext);
	// @ts-ignore
	const { isLoading: projectLoading } = useContext(ProjectContext);
	const { id } = useParams<UrlParams>();
	const productCollection = collectionByApiId('Product');
	const variantCollection = collectionByApiId('ProductVariant');

	const [product, productDispatch] = useReducer(reducer, {
		isLoading: true,
		isError: false
	});

	useEnvironmentTitle(product.isLoading ? 'Products' : product.data.name);

	useAsyncEffect(async isActive => {
		if (!environment.isLoading) {
			const res = await getItem(productCollection.node.apiId, id, {
				resolveReferences: ['variants']
			});

			if (!isActive()) {
				return;
			}

			productDispatch({
				type: 'set',
				data: {
					data: {
						...res,
						variants: res.variants.edges.map((edge: any) => edge.node)
					},
					isLoading: false,
					isError: false
				}
			});
		}
	}, [environment]);

	return (
		<MainContentContainer>
			<MainContentNav>
				<ProductsSidebar />
			</MainContentNav>
			<MainContent>
				{(product.isLoading || productCollection.isLoading) && (<>
					<div className="mb-10 w-full md:w-40">
						<HeadingLoader />
					</div>
					<TableSkeleton rows={5} />
				</>)}

				{!product.isLoading &&
					!productCollection.isLoading &&
					!projectLoading &&
					!product.data && (
						<NotFound
							header="Product not found"
							body={<p>No such product: {id}</p>}
						/>
					)}

				{!product.isLoading &&
					!productCollection.isLoading &&
					!projectLoading &&
					product.data && (
						<EditProductForm
							collection={productCollection}
							initialValues={{
								id,
								...createAttributeValues(product.data, productCollection.node),
								variants: product.data.variants.map(v => ({
									...createAttributeValues(v, variantCollection.node),
									...pick(['id', 'inventoryItem'], v)
								}))
							}}
							product={product.data}
							productDispatch={productDispatch}
						/>
					)}
			</MainContent>
		</MainContentContainer>
	);
});
