import { ExtensionsContext } from '../extensions/ExtensionsContext';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { withExtensionAttributeErrorBoundary } from './ExtensionAttributeErrorBoundary';

export const ExtensionAttributeEditor = withExtensionAttributeErrorBoundary(
	({ installation, onChange, readOnly, value, helpText = { helpText } }) => {
		const { getToken, getZoidComponent, installations } = useContext(
			ExtensionsContext
		);

		if (installations.isLoading) {
			return null;
		}

		const zoidComponent = getZoidComponent(installation.extension, 'attribute');
		const EmbeddedComponent = zoidComponent.driver('react', {
			React: React,
			ReactDOM: ReactDOM,
		});
		return (
			<div className="relative">
				{readOnly && (
					<div className="absolute top-0 left-0 w-full h-full bg-white z-10 opacity-50" />
				)}
				<EmbeddedComponent
					getToken={() => getToken(installation.id)}
					initialValue={value}
					onChange={onChange}
				/>
				{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
			</div>
		);
	}
);
