import React from 'react';
import { Badge } from '@platformapp/ui';
import Helpers from '../Helpers';

export const HttpStatusBadge = ({ status }) => (
	<Badge
		positive={status >= 200 && status <= 299}
		text={
			Helpers.hasT(`http_status.${status}`)
				? `${status} ${Helpers.t(`http_status.${status}`)}`
				: status
		}
	/>
);
