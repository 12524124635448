import React, { useContext, useState } from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { graphql } from '../api';
import { useFormik } from 'formik';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Field,
	Icon,
	Modal,
	ModalContent,
	ModalFooter,
	TextField,
} from '@platformapp/ui';
import { pascalCase, extractGraphqlError } from '../util';
import { constantCase } from 'constant-case';
import DeleteIcon from '../icons/x.svg';
import { isNil, append, isEmpty, remove, length } from 'ramda';

import CREATE_ENUM_MUTATION from './CreateEnum.gql';
import UPDATE_ENUM_MUTATION from './UpdateEnum.gql';
import toast from 'react-hot-toast';

const EnumValue = ({ children, onDelete }) => (
	<div className="inline-flex items-center mr-2 mb-2 bg-gray-300 rounded pl-3 py-2 whitespace-nowrap">
		<p>{children}</p>
		<button
			className="flex items-center cursor-pointer px-2 text-gray-600 hover:text-gray-700"
			onClick={(e) => {
				e.preventDefault();
				onDelete();
			}}
		>
			<Icon source={DeleteIcon} />
		</button>
	</div>
);

const EditEnumModalComponent = ({ onCancel, onSave, existingEnum }) => {
	const { environment } = useContext(EnvironmentContext);

	const [addingValue, setAddingValue] = useState('');
	const [addingValueError, setAddingValueError] = useState(null);

	const isNew = isNil(existingEnum);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			name: isNew ? '' : existingEnum.name,
			apiId: isNew ? '' : existingEnum.apiId,
			values: isNew ? [] : existingEnum.values.edges.map((edge) => edge.node),
		},
		initialStatus: {
			autoGenerateApiId: isNew,
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			const input = {
				...values,
			};

			if (isNew) {
				input.environmentId = environment.node.id;
			} else {
				input.enumerationId = existingEnum.id;
			}

			graphql({
				query: isNew ? CREATE_ENUM_MUTATION : UPDATE_ENUM_MUTATION,
				variables: {
					input: input,
				},
			})
				.then((res) => {
					const key = isNew ? 'createEnumeration' : 'updateEnumeration';

					if (res.data.data[key].userErrors.length) {
						setErrors(res.data.data[key].userErrors);
					} else {
						console.log('Saved enum value', res.data.data[key].enumeration);
						onSave(res.data.data[key].enumeration);
					}
				})
				.catch((error) => {
					console.error(error);
					toast.error('Error saving enum');
				})
				.then(() => setSubmitting(false));
		},
	});

	const addEnumValue = () => {
		if (!isEmpty(addingValue)) {
			// Ensure item does not already exist in list
			const apiId = constantCase(addingValue);
			const alreadyExists = length(
				form.values.values.filter((x) => x.apiId === apiId)
			);

			if (alreadyExists) {
				setAddingValueError('This value already exists');
			} else {
				setAddingValueError(null);
				form.setFieldValue(
					'values',
					append(
						{
							name: addingValue,
							apiId: apiId,
						},
						form.values.values
					)
				);
				setAddingValue('');
			}
		}
	};

	return (
		<Modal
			title={
				isNew ? 'Create a new enumeration' : `Editing ${existingEnum.name}`
			}
			small
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					<TextField
						name="name"
						label="Name"
						onChange={(e) => {
							form.setFieldValue('name', e.target.value);
							if (form.status.autoGenerateApiId) {
								form.setFieldValue('apiId', pascalCase(e.target.value));
							}
						}}
						value={form.values.name}
						error={extractGraphqlError(['name'], form.errors)}
						autoFocus
						helpText="This is usually singular, e.g., Category or Author"
					/>

					<TextField
						name="apiId"
						label="API ID"
						onChange={(e) => {
							form.setFieldValue('apiId', e.target.value);
							form.setStatus({
								autoGenerateApiId: false,
							});
						}}
						value={form.values.apiId}
						error={extractGraphqlError(['apiId'], form.errors)}
						className="font-mono"
					/>

					<Field label="Values">
						<div className="flex items-center">
							<div className="flex-grow">
								<TextField
									name="value"
									value={addingValue}
									onChange={(e) => setAddingValue(e.target.value)}
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											e.preventDefault();
											addEnumValue();
										}
									}}
								/>
							</div>
							<Button
								primary
								disabled={isEmpty(addingValue)}
								className="flex-shrink-0 ml-3"
								onClick={() => addEnumValue()}
							>
								Add value
							</Button>
						</div>
						<ErrorMessageElement message={addingValueError} />
						<div className="mt-4">
							{form.values.values.map((val, i) => (
								<EnumValue
									key={i}
									onDelete={() =>
										form.setFieldValue(
											'values',
											remove(i, 1, form.values.values)
										)
									}
								>
									{val.name}
								</EnumValue>
							))}
						</div>
						<ErrorMessageElement
							message={extractGraphqlError(['values'], form.errors, true)}
						/>
					</Field>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							{isNew ? 'Create enumeration' : 'Save'}
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};

EditEnumModalComponent.defaultProps = {
	existingEnum: null,
};

export const EditEnumModal = withErrorBoundary(EditEnumModalComponent);
