import React from 'react';
import { AttributesList } from './AttributesList';
import { EmptyMessage } from '../common/EmptyMessage';

export const CategoryTab = ({
	blueprint,
	deleteAttribute,
	editAttribute,
	moveAttribute,
	isDeletingAttribute,
}) => {
	if (blueprint.attributes.length === 0) {
		return (
			<EmptyMessage
				title="You haven't added any attributes to this blueprint"
				message="Select an attribute type from the right to add it to this blueprint."
			/>
		);
	}

	return (
		<AttributesList
			attributes={blueprint.attributes}
			deleteAttribute={deleteAttribute}
			editAttribute={editAttribute}
			onSortEnd={moveAttribute}
			blueprint={blueprint}
			isDeletingAttribute={isDeletingAttribute}
		/>
	);
};
