import React, { useContext, useState } from 'react';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Modal,
	ModalContent,
	ModalFooter,
} from '@platformapp/ui';
import { graphql } from '../api';
import Helpers from '../Helpers';
import { PlatformConfig } from '../PlatformConfig';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { UpdatePaymentForm } from './UpdatePaymentForm';
import UPDATE_QUERY from './updatePayment.gql';
import { TeamContext } from '../context/TeamContext';
import toast from 'react-hot-toast';

const ChangePaymentMethodInner = ({
	elements,
	stripe,
	onCancel,
	paymentMethodUpdated,
}) => {
	const cardElement = elements.getElement('cardNumber');
	const { activeTeam } = useContext(TeamContext);

	const [isWorking, setIsWorking] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const onConfirm = () => {
		setErrorMessage(null);
		setIsWorking(true);

		stripe
			.createPaymentMethod({
				type: 'card',
				card: cardElement,
			})
			.then(function (result) {
				if (result.error) {
					setIsWorking(false);
					setErrorMessage(result.error.message);
				} else {
					graphql(
						{
							query: UPDATE_QUERY,
							variables: {
								input: {
									subscriptionId: activeTeam.subscriptionId,
									paymentMethodId: result.paymentMethod.id,
								},
							},
						},
						'billing'
					)
						.then(({ data }) => {
							if (
								data.errors ||
								data.data.updatePaymentMethod.userErrors.length > 0
							) {
								toast.error('Failed to update payment method');
								setIsWorking(false);
							} else {
								paymentMethodUpdated(
									data.data.updatePaymentMethod.subscription
								);
							}
						})
						.catch((err) => {
							console.error(err);
							toast.error('Failed to update payment method');
							setIsWorking(false);
						});
				}
			});
	};

	return (
		<Modal
			title="Add a payment method"
			small
			isOpen={true}
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!isWorking}
			shouldCloseOnOverlayClick={!isWorking}
		>
			<ModalContent>
				{errorMessage && (
					<ErrorMessageElement className="mb-3" message={errorMessage} />
				)}
				<UpdatePaymentForm />
			</ModalContent>
			<ModalFooter>
				<ButtonGroup>
					<Button onClick={() => onCancel()} disabled={isWorking}>
						{Helpers.t('general.cancel')}
					</Button>
					<Button
						primary
						loading={isWorking}
						disabled={isWorking}
						onClick={() => onConfirm()}
					>
						{Helpers.t('general.save')}
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);
};

const InnerInjected = injectStripe(ChangePaymentMethodInner);

export const ChangePaymentMethod = (props) => (
	<StripeProvider apiKey={PlatformConfig.stripe_live}>
		<Elements>
			<InnerInjected {...props} />
		</Elements>
	</StripeProvider>
);
