//@ts-ignore
import { ActionList, Button, Icon } from '@platformapp/ui';
//@ts-ignore
import ListIcon from '../icons/rows.svg';
//@ts-ignore
import GridIcon from '../icons/grid.svg';
//@ts-ignore
import TriangleDownIcon from '../icons/triangle-down.svg';

export enum AssetView {
	Grid = 'grid',
	List = 'list'
}

interface AssetViewToggleProps {
	onChange: ((view: AssetView) => void);
	view: AssetView;
}

export const AssetViewToggle = ({ onChange, view }: AssetViewToggleProps) => {
	return (
		<ActionList
			trigger={
				<Button
					styleOnHover
					className="text-gray-700 flex items-center"
					tabIndex="0"
					width="small"
				>
					<Icon source={view === AssetView.List ? ListIcon : GridIcon} />
					<Icon source={TriangleDownIcon} className="ml-1" />
				</Button>
			}
			items={[
				{
					label: 'Grid',
					onAction: () => onChange(AssetView.Grid),
				},
				{
					label: 'List',
					onAction: () => onChange(AssetView.List),
				},
			]}
		/>
	);
};
