import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Heading } from '../common/Heading';
import { ApiKeysList } from './ApiKeysList';

export const ApiKeys = withErrorBoundary(() => (
	<>
		<Heading className="mb-6">API keys</Heading>
		<ApiKeysList />
	</>
));
