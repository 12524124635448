import { useContext } from 'react';
import { useFormik } from 'formik';
import { EditProductLayer } from './EditProductLayer';
import { Prompt } from 'react-router-dom';
import {
	getReferenceAttributeApiIds,
	getValuesForSave,
} from '../util/AttributeHelpers';
import { generateUpdateContentMutation } from '../content/Mutations';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { validateAttributeValues, validateVariant } from '../util/validation';
// @ts-ignore
import UPDATE_INVENTORY_ITEM from '../product-variants/UpdateInventoryItem.gql';
// @ts-ignore
import UPDATE_VARIANTS from '../product-variants/UpdateVariant.gql';
import { omit, pathOr, without } from 'ramda';
import { useMousetrap } from '../hooks/useMousetrap';
import toast from 'react-hot-toast';
import Helpers from '../Helpers';
import { ProductDispatch } from './ProductEditReducer';
import { ContentApiContext } from '../context/ContentApiContext';

type EditProductFormProps = {
	collection: any;
	initialValues: any;
	product: any;
	productDispatch: ProductDispatch;
}

export const EditProductForm = ({
	collection,
	initialValues,
	product,
	productDispatch
}: EditProductFormProps) => {
	const {
		collectionByApiId,
		contentApiRequest,
	} = useContext(EnvironmentContext);
	const variantBp = collectionByApiId('ProductVariant');
	const {publishItem, unpublishItem} = useContext(ContentApiContext);
	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		// @ts-ignore
		initialErrors: [],
		initialValues,
		validate: (values) => {
			const productErrors = validateAttributeValues(
				getValuesForSave(values, collection.node),
				collection.node
			);

			const variantErrors = values.variants
				? values.variants.reduce(
						(variantErrors: any, variant: any, index: number) => [
							...variantErrors,
							...validateAttributeValues(
								getValuesForSave(variant, variantBp.node),
								variantBp.node,
								['input', 'variants', index.toString()]
							),
							...validateVariant(variant, ['input', 'variants', index.toString()]),
						],
						[]
				  )
				: [];

			const errors = [...productErrors, ...variantErrors];

			if (errors.length !== 0) {
				toast.error('Some fields are invalid; correct these and save again.');
			}

			return errors;
		},
		onSubmit: async (formValues, { setErrors, resetForm }) => {
			const isSingleVariant = formValues.variants && formValues.variants.length === 1;
			let payload;

			if (form.dirty) {
				const input = {
					...omit(['variants'], getValuesForSave(formValues, collection.node)),
					id: form.values.id,
				};

				const res = await contentApiRequest({
					query: generateUpdateContentMutation(collection.node),
					variables: {
						input,
					},
				});

				payload = pathOr(null, ['data', 'result'], res.data);

				// If no `data` key is returned or it has no `node`/`userErrors` properties,
				// then something went wrong server side.
				if (!payload || (!payload.node && !payload.userErrors)) {
					console.error(res.data);
					toast.error('Something went wrong saving this item.');
					return;
				}

				if (payload.userErrors.length > 0) {
					setErrors(payload.userErrors);
					toast.error('Some fields are invalid; correct these and save again.');
					return;
				}

				// The form will only have a variants array if the product has no options.
				if (isSingleVariant) {
					// Update any existing variants
					const variantsToUpdate = formValues.variants
						.map((variant: any) => ({
							...getValuesForSave(variant, variantBp.node),
							id: variant.id,
						}));

					const variantUpdateRes = await contentApiRequest({
						query: UPDATE_VARIANTS,
						variables: {
							input: variantsToUpdate[0],
						},
					});
					const data = variantUpdateRes.data.data;
					if (!data.variantMutation.node) {
						setErrors(data.variantMutation.userErrors);
						toast.error(
							'Some variant fields are invalid; correct these and save again.'
						);
						return;
					}
				}
			}

			// If this is a single variant product and the inventory item `isTracked` property
			// has changed, update the inventory item.
			if (
				isSingleVariant &&
				formValues.variants[0].inventoryItem.isTracked !==
				initialValues.variants[0].inventoryItem.isTracked
			) {
				await contentApiRequest({
					query: UPDATE_INVENTORY_ITEM,
					variables: {
						id: formValues.variants[0].inventoryItem.id,
						isTracked: formValues.variants[0].inventoryItem.isTracked,
					},
				});
			}

			// Check if the resource needs to be published/unpublished
			if (form.status && form.status.action === 'publish') {
				const res = await publishItem(form.values.id);
				toast.success(`Product published`);
				productDispatch({
					type: 'publish',
					data: res
				});
			} else {
				toast.success(`Product saved`);
				productDispatch({
					type: 'update_product',
					data: payload.node
				});
			}

			resetForm({
				values: omit(
					without(['variants'], getReferenceAttributeApiIds(collection.node.attributes)),
					formValues
				),
				status: null,
			});
		},
	});

	const onUnpublish = async () => {
		const promise = unpublishItem(form.values.id);

		toast.promise(promise, {
			loading: Helpers.t('publishing.unpublishing_inprogress', 1),
			success: Helpers.t('publishing.unpublishing_success', 1),
			error: Helpers.t('publishing.unpublishing_failed', 1)
		});

		const res = await promise;
		const productNode = res.find(r => r.id === form.values.id);

		productDispatch({
			type: 'update_product',
			data: productNode
		});
	};

	useMousetrap('mod+s', () => {
		if (form.dirty && !form.isSubmitting) {
			form.submitForm();
		}
		return false;
	});

	return (
		<>
			<EditProductLayer
				collection={collection}
				form={form}
				onUnpublish={onUnpublish}
				product={product}
				productDispatch={productDispatch}
			/>
			<Prompt
				when={form.dirty}
				message="You have unsaved changes. Are you sure you want to leave this page?"
			/>
		</>
	);
};
