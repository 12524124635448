import React, { createContext, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TeamsContext } from "./TeamsContext";
import { find, pathEq, propOr } from 'ramda';
import { PageNotFound } from "../common/PageNotFound";
import { LS_LAST_TEAM_KEY } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const TeamContext = createContext({});

export const TeamConsumer = TeamContext.Consumer;

export const TeamProvider = ({children}) => {
    const {teamSlug} = useParams();
    const {isLoading, teams} = useContext(TeamsContext);
	const activeTeam = isLoading ? null : propOr(null, 'node', find(pathEq(['node', 'slug'], teamSlug), teams))
	const {1: setLastTeam} = useLocalStorage(LS_LAST_TEAM_KEY);

	useEffect(() => {
		if (!isLoading && activeTeam) {
			setLastTeam(teamSlug);
		}
	}, [teamSlug])

	if (!isLoading && (teamSlug && !activeTeam)) {
        return <PageNotFound />
	}

	return (
		<TeamContext.Provider value={{
            activeTeam,
            canDo: action => {
				if (!activeTeam) {
					return false;
				}
				return activeTeam.viewerCapabilities.includes(action);
			},
        }}>
			{children}
		</TeamContext.Provider>
	)
}