import { withErrorBoundary } from '../common/ErrorBoundary';
import { Heading } from '../common/Heading';
import { CorsSettings } from './CorsSettings';
import { ContentApiEndpoint } from './ContentApiEndpoint';
import { useEnvironmentTitle } from '../hooks/useTitle';

export const DevelopersLanding = withErrorBoundary(() => {
	useEnvironmentTitle('API access');
	return (
		<>
			{/* @ts-ignore */}
			<Heading className="mb-6">API access</Heading>
			<ContentApiEndpoint />
			<CorsSettings />
		</>
	);
});
