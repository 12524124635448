import { useContext, useState } from 'react';
import { Fieldset, FieldsetContent, FieldsetHeader } from '../common/Fieldset';
// @ts-ignore
import { Button, TextStyle } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ExtensionsContext } from './ExtensionsContext';
import { Heading } from '../common/Heading';
import { TableSkeleton } from '../common/Skeleton';
import { useEnvironmentTitle } from '../hooks/useTitle';
import toast from 'react-hot-toast';
import { AsyncConfirmDialog } from '../common/AsyncConfirmDialog';
import { P } from '../common/Text';
import { Extension, ExtensionInstallation } from '../types/Extension';
import { ExtensionLayer } from './ExtensionLayer';
import { AppLogo } from './AppLogo';

type ExtensionHeaderProps = {
	installation: ExtensionInstallation;
}

const ExtensionHeader = ({ installation }: ExtensionHeaderProps) => (
	<div className="flex items-center mb-5 last:mb-0 gap-4">
		<AppLogo
			app={installation.extension}
			size="large"
		/>
		<div className="flex-grow">
			<p className="text-base font-medium mb-2">{installation.extension.name}</p>
			<p className="text-base text-gray-600">{installation.extension.strapline}</p>
		</div>
	</div>
);

type ExtensionItemProps = {
	installation: ExtensionInstallation;
}

const ExtensionItem = ({ installation }: ExtensionItemProps) => {
	const [uninstalling, setUninstalling] = useState(false);
	const [open, setOpen] = useState<ExtensionInstallation>(null);
	const { uninstallExtension } = useContext(EnvironmentContext);
	return (
		<>
			{open && (
				<ExtensionLayer
					installation={open}
					onCancel={() => setOpen(null)}
				/>
			)}
			{uninstalling && (
				<AsyncConfirmDialog
					body={
						<>
							<P>
								Are you sure you want to uninstall{' '}
								<b className="font-medium">{installation.extension.name}</b>?
							</P>
							<P>
								The app will no longer be available in this environment.
								The app provider may delete any associated data.
							</P>
						</>
					}
					confirmText="Uninstall app"
					dangerous
					onCancel={() => setUninstalling(false)}
					onComplete={() => setUninstalling(false)}
					onConfirm={async () => {
						const res = await uninstallExtension(installation.extension.id);
						if (res) {
							return true;
						}
						toast.error('Could not uninstall app.');
						return false;
					}}
					title="Uninstall app?"
				/>
			)}
			<Row>
				<div className="flex-grow">
					<ExtensionHeader installation={installation} />
				</div>
				<div className="flex-shrink-0 flex gap-3">
					<Button destructive onClick={async () => setUninstalling(true)}>
						Uninstall
					</Button>
				</div>
			</Row>
		</>
	);
};

const Row = ({ children }: any) => (
	<div className="flex items-center pb-4 mb-4 last:mb-0 border-b last:border-b-0 border-gray-300">
		{children}
	</div>
);

type AvailableExtensionProps = {
	extension: Extension;
}

const AvailableExtension = ({ extension }: AvailableExtensionProps) => {
	const { installExtension } = useContext(EnvironmentContext);
	const [installing, setInstalling] = useState(false);
	return (
		<Row>
			<div className="flex flex-grow gap-4 items-center">
				<AppLogo
					app={extension}
					size="large"
				/>
				<div>
					<p className="text-base font-medium mb-2">{extension.name}</p>
					<p className="text-base text-gray-600">{extension.strapline}</p>
				</div>
			</div>
			<div className="flex-shrink-0">
				<Button
					disabled={installing}
					onClick={async () => {
						setInstalling(true);
						const res = await installExtension(extension.id);
						if (res) {
							toast.success(`${extension.name} installed`);
						} else {
							setInstalling(false);
							toast.error('Could not install extension.');
						}
					}}
				>
					Install
				</Button>
			</div>
		</Row>
	);
};

export const InstalledExtensions = () => {
	useEnvironmentTitle('Manage apps');
	const { allExtensions, installations } = useContext(ExtensionsContext);
	const loading = allExtensions.isLoading || installations.isLoading;

	const installedIds = installations.isLoading
		? []
		: installations.data.map((e) => e.extension.id);
	const availableExtensions = allExtensions.isLoading
		? []
		: allExtensions.data.edges.filter((e: any) => !installedIds.includes(e.node.id));

	return (
		<>
			{/* @ts-ignore */}
			<Heading className="mb-6">Manage apps</Heading>
			{loading && <TableSkeleton rows={3} />}
			{!loading && (
				<Fieldset>
					<FieldsetContent>
						<FieldsetHeader title="Installed apps" subtitle="Managed the apps installed in this environment." />
						<div className="mt-8">
							{installations.data.length === 0 && (
								<p>
									<TextStyle subdued>
										No apps installed in this environment
									</TextStyle>
								</p>
							)}

							{installations.data.filter(e => e.extension.name !== 'Ecommerce').map((node) => (
								<ExtensionItem key={node.id} installation={node} />
							))}
						</div>
					</FieldsetContent>
				</Fieldset>
			)}

			{!loading && availableExtensions.length > 0 && (
				<Fieldset>
					<FieldsetContent>
						<FieldsetHeader title="Available apps" subtitle="Choose from apps to access new features." />
						<div className="mt-8">
							{availableExtensions.map(({ node }: any) => (
								<AvailableExtension key={node.id} extension={node} />
							))}
						</div>
					</FieldsetContent>
				</Fieldset>
			)}
		</>
	);
};