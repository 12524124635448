import React from 'react';

import { RadioField } from '@platformapp/ui';

export const BooleanFilter = ({ definition, predicate, onChange }) => {
	const handleChange = (value) =>
		onChange({
			...predicate,
			operator: 'eq',
			value,
		});
	return (
		<div className="px-4 py-3" role="menu">
			<div>
				<RadioField
					name={definition.key}
					value={true}
					checked={predicate.operator === 'eq' && predicate.value === true}
					onChange={() => handleChange(true)}
					label="Is true"
				/>
			</div>
			<div>
				<RadioField
					name={definition.key}
					value={false}
					checked={predicate.operator === 'eq' && predicate.value === false}
					onChange={() => handleChange(false)}
					label="Is false"
				/>
			</div>
		</div>
	);
};
