import { hasPath } from 'ramda';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ConfirmDialog } from '@platformapp/ui';
import { graphql } from '../api';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { P } from '../common/Text';
import DELETE_USER_QUERY from './DeleteUser.gql';
import toast from 'react-hot-toast';

export const DeleteSetting = ({ user }) => {
	const history = useHistory();
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = () => {
		setIsDeleting(true);
		graphql(
			{
				query: DELETE_USER_QUERY,
			},
			'users'
		)
			.then((res) => {
				if (!hasPath(['data', 'deleteUser', 'userInputErrors'], res.data)) {
					toast.error('Failed to delete user account.');
					setIsDeleting(false);
				} else if (res.data.data.deleteUser.userInputErrors.length > 0) {
					toast.error(res.data.data.deleteUser.userInputErrors[0].message);
					setIsDeleting(false);
					setIsConfirmingDelete(false);
				} else {
					history.replace('/sign-out');
				}
			})
			.catch((err) => {
				console.error(err);
				toast.error('Failed to delete user account.');
				setIsDeleting(false);
			});
	};

	return (
		<>
			{isConfirmingDelete && (
				<ConfirmDialog
					title="Delete user account"
					body={
						<p className="leading-relaxed">
							Your account and any associated data will be permanently deleted -
							this cannot be undone.
						</p>
					}
					onConfirm={handleDelete}
					onCancel={() => setIsConfirmingDelete(false)}
					isWorking={isDeleting}
					dangerous
					confirmText="Delete my account"
				/>
			)}
			<Fieldset danger>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Delete account</p>
					<P>
						Once an account has been deleted, it cannot be recovered - all data
						will be permanently removed.
					</P>
					<P className="mt-1 mb-4">Please be certain.</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!user}
						onClick={() => setIsConfirmingDelete(true)}
						submit
						width="large"
						destructive
					>
						Delete my account
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
