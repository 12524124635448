import React from 'react';
import { ActionList, Icon } from '@platformapp/ui';
import PlusIcon from '../icons/plus.svg';

export const AddFilterDropdown = ({ onSelect, definitions }) => (
	<ActionList
		trigger={
			<button className="font-medium py-2 cursor-pointer text-blue-700 text-sm focus:outline-none whitespace-nowrap">
				<Icon source={PlusIcon} /> Add filter
			</button>
		}
		items={definitions.map((filter) => ({
			onAction: () => onSelect(filter.key),
			label: filter.label,
		}))}
	/>
);
