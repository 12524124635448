import { ContentListContainer } from '../content-list/ContentListContainer';
import { useContext } from 'react';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { ContentSidebar } from './ContentSidebar';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useParams } from 'react-router-dom';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { TableSkeleton } from '../common/Skeleton';

type UrlParams = {
	blueprintId: string;
}

export const ContentList = () => {
	const { blueprintId } = useParams<UrlParams>();
	const { collectionById } = useContext(EnvironmentContext);
	const blueprint = collectionById(blueprintId);
	useEnvironmentTitle(blueprint.isLoading ? null : blueprint.node.name);
	return (
		<MainContentContainer>
			<MainContentNav>
				<ContentSidebar />
			</MainContentNav>
			<MainContent>
				{blueprint.isLoading && <TableSkeleton className="mt-5" />}
				{!blueprint.isLoading && <ContentListContainer blueprint={blueprint} />}
			</MainContent>
		</MainContentContainer>
	);
};
