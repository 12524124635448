import { Attribute, BasicAttributeType } from "../types/Attribute";

export const canBePinned = (attr: Attribute) => {
	if (attr.isList) {
		return false;
	} else if (attr.__typename === 'BidirectionalReferenceAttribute' || attr.__typename === 'EnumerationAttribute' || attr.__typename === 'UnidirectionalReferenceAttribute') {
		return true;
	} else if (attr.__typename === 'BasicAttribute') {
		return [BasicAttributeType.Asset, BasicAttributeType.Boolean, BasicAttributeType.Date, BasicAttributeType.Datetime, BasicAttributeType.Number, BasicAttributeType.Text].includes(attr.type);
	}
	return false;
}

export const getDefaultPinned = (attributes: Attribute[]): string[] => [
    ...attributes
        .filter(canBePinned)
        .filter(a => !['createdAt', 'publishedAt', 'updatedAt'].includes(a.apiId))
        .map(a => a.apiId)
        .slice(0, 4),
    'updatedAt'
];