import { MainContent } from "../common/MainContent"
import { EndpointSetting } from "./EndpointSetting"
import { Nav } from "./Nav"
import { NotificationSetting } from "./NotificationSetting"

type SettingsProps = {
    collection: any;
}

export const Settings = ({
    collection
}: SettingsProps) => {
    return (
        <MainContent>
            <Nav collection={collection} />
            <div className="mt-8">
                <EndpointSetting collection={collection} />
                <NotificationSetting collection={collection} onChange={() => {}} />
            </div>
        </MainContent>
    )
}