import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ConfirmDialog } from '@platformapp/ui';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { B, P } from '../common/Text';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { deleteWebhook } from './mutations';
import toast from 'react-hot-toast';

export const DeleteSetting = ({ hook }) => {
	const history = useHistory();
	const { generateEnvPath } = useContext(EnvironmentContext);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDoingDelete, setIsDoingDelete] = useState(false);
	return (
		<>
			{isDeleting && (
				<ConfirmDialog
					title="Delete webhook"
					body={
						<>
							<p className="leading-loose">
								Are you sure you want to delete <B>{hook.node.name}</B>?
							</p>
							<p className="leading-loose">
								Events will no longer be delivered to this webhook.
							</p>
						</>
					}
					onConfirm={() => {
						setIsDoingDelete(true);
						deleteWebhook(hook.node.id)
							.then(() => {
								toast.success('Webhook deleted');
								history.push(generateEnvPath('webhooks'));
							})
							.catch(() => {
								toast.error('Error deleting webhook');
							});
					}}
					onCancel={() => setIsDeleting(false)}
					dangerous
					isWorking={isDoingDelete}
					confirmText="Delete webhook"
				/>
			)}
			<Fieldset danger>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Delete webhook</p>
					<P>
						This webhook will be permanently deleted, and events will no longer
						trigger the URL.
					</P>
					<P className="mt-1 mb-4">
						All delivery attempts and log data associated with this webhook will
						also be deleted.
					</P>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						onClick={() => setIsDeleting(true)}
						submit
						width="large"
						destructive
					>
						Delete
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</>
	);
};
