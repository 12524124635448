import React, { useContext } from 'react';
import { ComponentListItem } from './ComponentListItem';
import { AddComponentListItemDropdown } from './AddComponentListItemDropdown';
import {
	set,
	lensPath,
	remove,
	append,
	move,
	concat,
	isEmpty,
	isNil,
	insert,
} from 'ramda';
import { makeId, extractGraphqlError } from '../util';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ErrorMessageElement, TextStyle } from '@platformapp/ui';
import { initialAttributeValues } from '../util/AttributeHelpers';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { AttributeEditorContext } from '../attribute-editor/AttributeEditorContext';

export const ComponentListAttribute = ({
	attribute,
	onChange,
	value,
	readOnly,
	validationErrors,
	pathPrefix,
	helpText,
}) => {
	const { componentByApiId } = useContext(EnvironmentContext);
	const { initialValues } = useContext(AttributeEditorContext);

	const addItem = (componentApiId, index = null) => {
		console.log(`Add component of type '${componentApiId}' at index ${index}`);

		// Get the initial values for this blueprint type
		const component = componentByApiId(componentApiId);

		const newItem = {
			__typename: componentApiId,
			_draggableId: makeId(),
			...initialAttributeValues(component.node),
		};

		const newValue = isNil(index)
			? append(newItem, value)
			: insert(index, newItem, value);

		onChange(newValue);
	};

	const onItemChange = (index, itemValue) => {
		const valueLens = lensPath([index]);
		onChange(set(valueLens, itemValue, value));
	};

	const onItemDelete = (index) => onChange(remove(index, 1, value));

	const onDragEnd = (res) => {
		if (res.source.index === res.destination.index) {
			return;
		}
		onChange(move(res.source.index, res.destination.index, value));
	};

	const error = extractGraphqlError(pathPrefix, validationErrors);

	return (
		<>
			<div className={error && !isEmpty(error) ? 'border-red-700' : ''}>
				{value.length === 0 && (
					<div className="py-8 border-dashed border-2 rounded border-gray-300">
						<p className="text-center select-none mb-5">
							<TextStyle subdued>
								No items have been added to this list
							</TextStyle>
						</p>
						<div className="flex justify-center">
							<AddComponentListItemDropdown
								attribute={attribute}
								onAddItem={addItem}
								readOnly={readOnly}
							/>
						</div>
					</div>
				)}

				{value.length > 0 && (
					<>
						<DragDropContext onDragEnd={(res) => onDragEnd(res)}>
							<Droppable droppableId={makeId()}>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										className="pt-2"
									>
										{value &&
											value.map((item, i) => (
												<ComponentListItem
													attribute={attribute}
													initialValue={initialValues[attribute.apiId]?.[i]}
													index={i}
													item={item}
													key={i}
													onAddAfter={(type) => addItem(type, i + 1)}
													onAddBefore={(type) => addItem(type, i)}
													onChange={(value) => onItemChange(i, value)}
													onDelete={() => onItemDelete(i)}
													pathPrefix={concat(pathPrefix, [`${i}`])}
													readOnly={readOnly}
													validationErrors={validationErrors}
												/>
											))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<AddComponentListItemDropdown
							attribute={attribute}
							onAddItem={addItem}
							readOnly={readOnly}
						/>
					</>
				)}
			</div>
			<ErrorMessageElement message={error} />
			{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
		</>
	);
};
