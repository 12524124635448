import React, { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ListPageHeader } from '../common/ListPageHeader';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { HeadingLoader } from '../common/Skeleton';

export const WebhookHeader = ({ hook }) => {
	const { webhookId } = useParams();
	const { generateEnvPath } = useContext(EnvironmentContext);

	return (
		<ListPageHeader
			bordered
			title={
				hook.isLoading ? <HeadingLoader className="w-40" /> : hook.node.name
			}
			right={
				<div className="flex self-end">
					<NavLink
						className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
						activeClassName="text-gray-800 border-b border-gray-800"
						to={generateEnvPath(`webhooks/${webhookId}`)}
						exact
					>
						Overview
					</NavLink>
					<NavLink
						className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
						activeClassName="text-gray-800 border-b border-gray-800"
						to={generateEnvPath(`webhooks/${webhookId}/settings`)}
					>
						Settings
					</NavLink>
				</div>
			}
		/>
	);
};
