import React from 'react';
import { RadioField } from '@platformapp/ui';
import { append, isNil, without } from 'ramda';
import { SCOPE_COMMERCE_API_WRITE_CHECKOUT } from '../constants';

const COMMERCE_SCOPES = Object.freeze([SCOPE_COMMERCE_API_WRITE_CHECKOUT]);

export const CheckoutScopeRow = ({ selectedScope, onChange }) => {
	const handleChange = (scope) => {
		if (isNil(scope)) {
			onChange(without(COMMERCE_SCOPES, selectedScope));
		} else {
			onChange(append(scope, without(COMMERCE_SCOPES, selectedScope)));
		}
	};
	return (
		<div className="flex items-center border-b border-gray-300 last:border-b-0 px-5 py-4">
			<div className="flex-grow">
				<p className="text-gray-900">Cart</p>
			</div>
			<div className="flex-shrink-0 flex">
				<div className="mr-6">
					<RadioField
						label="None"
						checked={!selectedScope.some((s) => COMMERCE_SCOPES.includes(s))}
						onChange={() => handleChange(null)}
					/>
				</div>
				<div className="mr-6">
					<RadioField
						label="Write"
						checked={selectedScope.includes(SCOPE_COMMERCE_API_WRITE_CHECKOUT)}
						onChange={() => handleChange(SCOPE_COMMERCE_API_WRITE_CHECKOUT)}
					/>
				</div>
			</div>
		</div>
	);
};
