import React from 'react';
import { AttributeInput } from './AttributeInput';
import { Field } from '@platformapp/ui';
import { TYPE_BOOLEAN } from '../constants';
import { AttributeEditorProvider } from './AttributeEditorContext';

export const AttributeEditor = ({
	attributes,
	initialValues,
	isNew,
	onAttributeChange,
	pathPrefix,
	preventAutofocus,
	readOnly,
	validationErrors,
	values,
}) => (
	<AttributeEditorProvider
		initialValues={initialValues}
		isNew={isNew}
		readOnly={readOnly}
	>
		<div>
			{attributes.map((attr, i) => (
				<Field
					key={attr.id}
					label={attr.type !== TYPE_BOOLEAN ? attr.name : null}
				>
					<AttributeInput
						readOnly={readOnly}
						attribute={attr}
						value={values[attr.apiId]}
						onChange={(val) => onAttributeChange(attr.apiId, val)}
						validationErrors={validationErrors}
						isFirst={i === 0 && !preventAutofocus}
						pathPrefix={pathPrefix}
					/>
				</Field>
			))}
		</div>
	</AttributeEditorProvider>
);

AttributeEditor.defaultProps = {
	initialValues: {},
	preventAutofocus: false,
	pathPrefix: [],
};
