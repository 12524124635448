import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import GET_SETTINGS_QUERY from './GetSettings.gql';
import { Heading } from '../common/Heading';
import { ProjectContext } from '../context/ProjectContext';
import { mergeDeepRight } from 'ramda';
import { useTitle } from '../hooks/useTitle';
import { StoreNameSetting } from './StoreNameSetting';
import { SuccessUrlSetting } from './SuccessUrlSetting';
import { SupportEmailSetting } from './SupportEmailSetting';
import { CurrencySetting } from './CurrencySetting';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useAsyncEffect } from 'use-async-effect';
import { OrderNotifcationsSetting } from './OrderNotifcationsSetting';

export const EcommerceSettings = withErrorBoundary(() => {
	const { activeProject } = useContext(ProjectContext);
	const { environment } = useContext(EnvironmentContext);
	const [settings, setSettings] = useState({
		isLoading: true,
		data: {},
	});
	useTitle(
		activeProject && environment.node
			? ['Ecommerce settings', activeProject.name, environment.node.name]
			: null
	);

	useAsyncEffect(
		async (isActive) => {
			if (environment.node) {
				const { data } = await graphql({
					query: GET_SETTINGS_QUERY,
					variables: {
						id: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setSettings({
					isLoading: false,
					data: data.data.env,
				});
			}
		},
		[environment]
	);

	const onChange = (data) => {
		setSettings({
			isLoading: false,
			data: mergeDeepRight(settings.data, data),
		});
	};

	return (
		<>
			<Heading className="mb-6">Ecommerce settings</Heading>

			<div className="lg:mr-48">
				<StoreNameSetting onChange={onChange} settings={settings} />
				<SupportEmailSetting onChange={onChange} settings={settings} />
				<SuccessUrlSetting onChange={onChange} settings={settings} />
				<OrderNotifcationsSetting onChange={onChange} settings={settings} />
				<CurrencySetting onChange={onChange} settings={settings} />
			</div>
		</>
	);
});
