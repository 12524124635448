import React, { useContext, useState } from 'react';
import StripeLogo from '../images/stripe-logo.svg';
import { Badge, Button, TextStyle } from '@platformapp/ui';
import { graphql } from '../api';
import CONNECT_STRIPE from './GetStripeAuthzUri.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { pathOr } from 'ramda';
import toast from 'react-hot-toast';

export const PaymentProvidersList = ({ providers }) => {
	const { environment } = useContext(EnvironmentContext);
	const stripeProvider = providers.find(
		(p) => p.__typename === 'StripeIntegration'
	);
	const [isRedirecting, setIsRedirecting] = useState(false);
	return (
		<div className="border rounded-md md:flex items-center">
			<div className="w-32 flex-shrink-0 mx-6 py-5">
				<StripeLogo />
			</div>
			<div className="flex-grow">
				<p className="font-medium mb-3">Stripe payments</p>
				<p>
					<TextStyle subdued>
						Connect your Stripe Standard account to accept payments through
						Stripe.
					</TextStyle>
				</p>
			</div>
			<div className="flex-shrink-0 px-6">
				{!stripeProvider && (
					<Button
						disabled={isRedirecting}
						onClick={async () => {
							setIsRedirecting(true);
							const res = await graphql({
								query: CONNECT_STRIPE,
								variables: {
									envId: environment.node.id,
								},
							});
							const uri = pathOr(
								null,
								[
									'data',
									'data',
									'generateStripeAuthorizationUri',
									'redirectUri',
								],
								res
							);
							if (uri) {
								window.location.href = uri;
							} else {
								setIsRedirecting(false);
								toast.error('Could not connect to Stripe');
							}
						}}
					>
						Connect
					</Button>
				)}
				{stripeProvider && <Badge positive text="Connected" />}
			</div>
		</div>
	);
};
