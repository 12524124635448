import React from 'react';
import Helpers from '../Helpers';
import { Badge } from '@platformapp/ui';
import { ORDER_FULFILLED } from '../constants';

export const StatusBadge = ({ status }) => (
	<Badge
		text={Helpers.t(`orders.status.${status}`)}
		positive={status === ORDER_FULFILLED}
	/>
);
