import React, { useContext } from 'react';
import { Heading } from '../common/Heading';
import { ProjectContext } from '../context/ProjectContext';
import { Message, TextStyle } from '@platformapp/ui';
import { Link } from 'react-router-dom';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const Landing = () => {
	const { activeProject } = useContext(ProjectContext);
	const { generateEnvPath, environment } = useContext(EnvironmentContext);
	return (
		<div className="px-10 mt-24 pt-6">
			<div className="mx-auto">
				<Heading className="mb-6">
					{activeProject ? activeProject.name : '...'}
				</Heading>
				{activeProject && (
					<p className="text-md">
						<TextStyle subdued>
							{activeProject.ecommerceEnabled
								? 'Use this project to create and publish content and manage your ecommerce business.'
								: 'Use this project to create and publish content.'}
						</TextStyle>
					</p>
				)}

				{!environment.isLoading && !environment.node.isDefault && (
					<Message className="mt-5">
						<p>
							This is the test environment for this project. Any changes made
							here will not be reflected on any lives sites or apps.
						</p>
					</Message>
				)}

				<div className="mt-8">
					{activeProject && activeProject.ecommerceEnabled && (
						<Link
							className="block bg-white text-md p-6 rounded border-gray border"
							to={generateEnvPath('/orders')}
						>
							<p className="font-medium">View your recent orders</p>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};
