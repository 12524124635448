import React, { useState } from 'react';
import { Transforms } from 'slate'
import { useEditor, ReactEditor, useSelected } from 'slate-react';

const CodeBlockEditorTextarea = ({ onChange, value }) => {
    const [ internalValue, setInternalValue ] = useState(value);
    return (
        <textarea
            className="bg-gray-100 border rounded p-4 w-full resize-none font-mono"
            onChange={e => {
                setInternalValue(e.target.value);
                onChange(e.target.value);
            }}
            value={internalValue}
            onClick={e => e.stopPropagation()}
            rows={10}
        />
    )
}

const CodeBlockEditor = ({ attributes, children, element }) => {
    const editor = useEditor();
    return (
        <div {...attributes} contentEditable={false}>
            <CodeBlockEditorTextarea
                value={element.value}
                onChange={value => {
                    const path = ReactEditor.findPath(editor, element)
                    Transforms.setNodes(editor, { value }, { at: path });
                }}
            />
            {children}
        </div>
    )
}

const ImageElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    return (
        <span {...attributes} contentEditable={false}>
            <img
                src={element.url}
                className={`max-h-64 inline ${selected ? 'opacity-75' : ''}`}
            />
            {children}
        </span>
    )
}

const BreakElement = ({ attributes, children }) => (
    <span {...attributes} contentEditable={false}>
        <br />
        {children}
    </span>
)

export const SlateElement = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes} className="border-l-3 pl-3 mb-4">{children}</blockquote>
        case 'break':
            return <BreakElement {...props} />
        case 'unordered-list':
            return <ul {...attributes} className="pl-6 list-disc mb-4">{children}</ul>
        case 'code-block':
            return <CodeBlockEditor {...props} className="mb-4" />
        case 'heading-one':
            return <h1 {...attributes} className="mb-4 leading-normal font-medium text-5xl">{children}</h1>
        case 'heading-two':
            return <h2 {...attributes} className="mb-4 leading-normal font-medium text-4xl">{children}</h2>
        case 'heading-three':
            return <h3 {...attributes} className="mb-4 leading-normal font-medium text-3xl">{children}</h3>
        case 'heading-four':
            return <h4 {...attributes} className="mb-4 leading-normal font-medium text-2xl">{children}</h4>
        case 'heading-five':
            return <h5 {...attributes} className="mb-4 leading-normal font-medium text-xl">{children}</h5>
        case 'heading-six':
            return <h6 {...attributes} className="mb-4 leading-normal font-medium text-lg">{children}</h6>
        case 'list-item':
            return <li {...attributes} className="leading-normal text-base -mb-4 last:mb-0">{children}</li>
        case 'ordered-list':
            return <ol {...attributes} className="pl-6 list-decimal mb-4">{children}</ol>
        case 'image':
            return <ImageElement {...props} />
        case 'link':
            return <a {...attributes} className="text-blue-700">{children}</a>
        default:
            return <p {...attributes} className="leading-normal mb-4">{children}</p>
    }
}

export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <span className="font-bold">{children}</span>
    }

    if (leaf.code) {
      children = <code className="border rounded bg-gray-100">{children}</code>
    }

    if (leaf.italic) {
      children = <span className="italic">{children}</span>
    }

    return <span {...attributes}>{children}</span>
}