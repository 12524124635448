import { useState, useCallback } from 'react';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	TextStyle,
// @ts-ignore
} from '@platformapp/ui';
import { AssetDetailsLayer } from '../assets/AssetDetailsLayer';
import { PublishStatusLabel } from './PublishStatusLabel';
import { isEmpty, mergeDeepRight } from 'ramda';
import { statusFromStages } from '../util/publishing';
import { MultiAssetList } from './MultiAssetList';
import { Thumbnail } from './Thumbnail';
import { ItemChooserLayer } from '../content-chooser/ItemChooserLayer';
import { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';

const SmallSelector = ({ readOnly, setIsChooserVisible, value }: any) => {
	return (
		<button
			className={`flex-shrink-0 h-10 w-10 ${
				readOnly ? 'cursor-auto' : 'cursor-pointer'
			}`}
			disabled={readOnly}
			onClick={setIsChooserVisible}
		>
			{/* @ts-ignore */}
			<Thumbnail value={value} />
		</button>
	);
};

export const AssetSelector = ({
	allowRemove,
	error,
	multiple,
	onChange,
	readOnly,
	small,
	value,
	helpText,
}: any) => {
	const {collectionByApiId} = useContext(EnvironmentContext);
	const [isCreating, setIsCreating] = useState(false);
	const [isChooserVisible, setIsChooserVisible] = useState(false);
	const [detailsLayerVisible, setDetailsLayerVisible] = useState(false);

	// @ts-ignore
	const handleImageSelect = useCallback((image) => {
		onChange(image);
		setIsChooserVisible(false);
	});

	const handleSaveDetails = (asset: any) => {
		onChange(mergeDeepRight(value, asset));
		setDetailsLayerVisible(false);
	};

	const isError = error && !isEmpty(error);

	return (
		<>
			{isCreating && (
				<AssetDetailsLayer
					onCancel={() => setIsCreating(false)}
					onSave={(newItem: any) => {
						if (multiple) {
							onChange({
								...value,
								edges: [
									...value.edges,
									{
										node: newItem,
									},
								],
							});
						} else {
							onChange(newItem);
						}
						setIsCreating(false);
					}}
					defaultAction="publish"
				/>
			)}

			{detailsLayerVisible && (
				// @ts-ignore
				<AssetDetailsLayer
					onCancel={() => setDetailsLayerVisible(false)}
					onSave={handleSaveDetails}
					assetId={value.id}
				/>
			)}

			{isChooserVisible && (
				<ItemChooserLayer
					allowedBlueprints={[
						collectionByApiId('Asset').node.id
					]}
					multiple={multiple}
					onCancel={() => setIsChooserVisible(false)}
					onSelect={(selection: any) => {
						if (multiple) {
							const originalValue = value || { edges: [] };
							const newSel = [
								// Remove any items from the existing list that are in the new one
								...originalValue.edges.filter(
									(existingEdge: any) =>
										!selection.edges.some(
											(newEdge: any) => newEdge.node.id === existingEdge.node.id
										)
								),
								...selection.edges,
							];
							handleImageSelect({
								edges: newSel,
							});
						} else {
							handleImageSelect(selection);
						}
					}}
				/>
			)}

			{small && (
				<SmallSelector
					readOnly={readOnly}
					setIsChooserVisible={setIsChooserVisible}
					value={value}
				/>
			)}

			{!small && !multiple && (
				<div className="flex items-center">
					<div className="flex-shrink-0 h-24 w-24">
						<Thumbnail
							setDetailsLayerVisible={setDetailsLayerVisible}
							value={value}
						/>
					</div>
					<div className="flex-grow ml-5 overflow-auto p-2">
						<div className="mb-3 flex">
							{value === null && (
								<p>
									<TextStyle subdued>No asset selected</TextStyle>
								</p>
							)}
							{value !== null && (
								<>
									<button
										className="rounded truncate inline-block text-left text-blue-700 hover:text-blue-800 text-base focus:outline-none focus:shadow-outline"
										onClick={() => setDetailsLayerVisible(true)}
										title={value.title || value.filename}
									>
										{value.title || value.filename}
									</button>
									<div className="ml-4">
										<PublishStatusLabel status={statusFromStages(value)} />
									</div>
								</>
							)}
						</div>
						<ButtonGroup>
							<Button
								disabled={readOnly}
								onClick={() => setIsChooserVisible(true)}
							>
								Select existing asset
							</Button>
							<Button disabled={readOnly} onClick={() => setIsCreating(true)}>
								Create asset
							</Button>
							{allowRemove && (
								<Button
									disabled={readOnly || value === null}
									onClick={() => onChange(null)}
								>
									Clear
								</Button>
							)}
						</ButtonGroup>
					</div>
				</div>
			)}

			{multiple && (
				<>
					{/* @ts-ignore */}
					<MultiAssetList
						onChange={onChange}
						readOnly={readOnly}
						validationErrors={error}
						value={value}
					/>
					<ButtonGroup>
						<Button
							disabled={readOnly}
							onClick={() => setIsChooserVisible(true)}
						>
							Select assets
						</Button>
						<Button disabled={readOnly} onClick={() => setIsCreating(true)}>
							Create asset
						</Button>
						{allowRemove && (
							<Button
								disabled={readOnly || value === null}
								onClick={() => onChange({ edges: [] })}
							>
								Clear all
							</Button>
						)}
					</ButtonGroup>
				</>
			)}

			{isError && <ErrorMessageElement message={error} />}

			{helpText && <p className="mt-2 text-sm text-gray-600">{helpText}</p>}
		</>
	);
};

AssetSelector.defaultProps = {
	allowRemove: true,
	multiple: false,
	onChange: null,
	readOnly: false,
	small: false,
	value: null,
};
