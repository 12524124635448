import {
	append,
	ascend,
	assoc,
	chain,
	curry,
	filter,
	findIndex,
	ifElse,
	includes,
	is,
	isEmpty,
	isNil,
	map,
	path,
	pathEq,
	prop,
	propEq,
	propOr,
	sort,
	startsWith,
	update,
	when,
	without,
	zipObj,
} from 'ramda';
// @ts-ignore
import Dinero from 'dinero.js/build/esm/dinero';
import {
	FRIENDLY_MIMES,
	ASSET_FILTER_MIMES,
	FILTERABLE_ATTRIBUTE_TYPES,
	TYPE_NUMBER,
	FILTER_STRING,
	FILTER_INTEGER,
	FILTER_ENUM,
	TYPE_DATE,
	TYPE_DATETIME,
	FILTER_DATE,
	FILTER_DATETIME,
	TYPE_BOOLEAN,
	FILTER_BOOLEAN,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_BASIC,
} from '../constants';
import { format } from 'date-fns';
import { camelCase } from 'camel-case';
import { Attribute } from '../types/Attribute';

export const makeId = () => Math.random().toString(11).replace('0.', '');

export const pluckPath = (pathArr: string[], list: any[]) => map(path(pathArr), list);

export const pascalCase = (value: string) => {
	if (isNil(value) || value === '') {
		return '';
	}

	const match = value.match(/[a-z]+/gi);

	if (isNil(match)) {
		return '';
	}

	return match
		.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
		.join('');
};

export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const slugify = (value: string, allowDash: boolean) => {
	let nonWordChars = /[^\w-]+/g;

	if (allowDash) {
		nonWordChars = /[^\w\-/]+/g;
	}

	return value
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(nonWordChars, '') // Remove all non-word chars
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

export const encodeFiltersForApi = (filters: any, legacyEqualsHandling = true) => {
	// [
	// 	"key": "created_at",
	// 	"operator": "gt",
	// 	"value": "2018-10-10"
	// ]

	// to

	// {
	// 	"created_at": {
	// 		"gt": "2018-10-10"
	// 	}
	// }

	var filterMap: any = {};

	filters.forEach(function (filter: any) {
		if (legacyEqualsHandling && filter.operator === 'eq') {
			filterMap[filter.key] = filter.value;
		} else {
			if (!Object.prototype.hasOwnProperty.call(filterMap, filter.key)) {
				filterMap[filter.key] = {};
			}
			filterMap[filter.key][filter.operator] = filter.value;
		}
	});

	return filterMap;
};

export const decodeFiltersFromQs = (filters: any) => {
	if (isNil(filters)) {
		return [];
	}
	try {
		return JSON.parse(atob(filters));
	} catch (errror) {
		console.warn("Couldn't decode filters.");
		return [];
	}
};

export const encodeFiltersForQs = (filters: any) => btoa(JSON.stringify(filters));

export const formatCurrency = (value: number, currency: any, includeSymbol = true) => {
	const dineroVal = Dinero({
		amount: value,
		currency: currency,
	});

	if (includeSymbol) {
		return dineroVal.toFormat('$0,0.00');
	}

	return dineroVal.toFormat('0,0.00');
};

export const formatMoney = (money: any) =>
	formatCurrency(money.amount, money.currency);

// Oct 15, 2019
const SHORT_DATE_FORMAT = 'MMM d, yyyy';

// March 15, 2019
const LONG_DATE_FORMAT = 'MMMM d, yyyy';

// 9:42 pm
const TIME_FORMAT = 'h:mm a';

const prepareDate = (value: string | Date) =>
	typeof value === 'string' ? new Date(value) : value;

// Oct 15, 2019
export const formatShortDate = (value: any) =>
	format(prepareDate(value), SHORT_DATE_FORMAT);

// March 15, 2019 at 9:42 pm
export const longDateTime = (value: any) =>
	format(prepareDate(value), `${LONG_DATE_FORMAT} 'at' ${TIME_FORMAT}`);

export const objectFromListWith = curry((fn, list) =>
	chain(zipObj, map(fn))(list)
);

// Formats a JS date object as an ISO date string (without time component)
export const dateToIsoDateString = (value: Date) =>
	value === null ? null : value.toISOString().split('T')[0];

export const mergeRefs = (refs: any) => (value: any) => {
	refs.forEach((ref: any) => {
		if (typeof ref === 'function') {
			ref(value);
		} else if (ref != null) {
			ref.current = value;
		}
	});
};

/**
 *
 * @param {array} path The path of the error.
 * @param {array} errors Object containing an array of object with keys `path` and `message`.
 * @param {*} startComparison
 * @returns {array}
 */
export const extractGraphqlError = (path: string | string[], errors: any[], startComparison = false): string[] => {
	// Path might just be a single string if the field is only 1 level deep
	let pathArray = is(String, path) ? [path] : path || [];

	if (pathArray.length > 0 && pathArray[0] !== 'input') {
		pathArray = ['input', ...pathArray];
	}

	if (isNil(errors) || isEmpty(errors)) {
		return [];
	}

	const comparator = startComparison
		? (x: any) => startsWith(pathArray, x.path)
		: propEq('path', pathArray);

	return filter(comparator, errors).map((x) => x.message);
};

export const nameFromMimeType = (mime: string) => propOr(null, mime, FRIENDLY_MIMES);

// Uppercase, convert spaces to underscores, remove all non alpha-numeric characters
export const toEnumCase = (value: string) =>
	value
		.replace(/ /g, '_')
		.replace(/[\W_]+/g, '')
		.toUpperCase();

export const stripExtension = (name: string) => name.replace(/\.[^/.]+$/, '');

export const assetTypeFromMime = (mime: string) => {
	for (const t in ASSET_FILTER_MIMES) {
		if (ASSET_FILTER_MIMES[t].includes(mime)) {
			return t;
		}
	}
	return 'other';
};

const isFiltertable = (attribute: Attribute) => {
	if (attribute.isList) {
		return false;
	}
	if (attribute.__typename === ATTRIBUTE_TYPENAME_ENUM) {
		return true;
	}
	return (
		attribute.__typename === ATTRIBUTE_TYPENAME_BASIC &&
		FILTERABLE_ATTRIBUTE_TYPES.includes(attribute.type)
	);
};

export const filtersForAttributes = (attributes: Attribute[]) =>
	attributes.filter(isFiltertable).map((attr) => {
		const f: any = {
			key: attr.apiId,
			type: FILTER_STRING,
			label: attr.name,
			persistent: false,
		};
		switch (attr.__typename) {
			case ATTRIBUTE_TYPENAME_ENUM: {
				f.type = FILTER_ENUM;
				f.values = attr.enumeration.values.edges.map(({ node }) => ({
					label: node.name,
					value: node.apiId,
				}));
				break;
			}
			case ATTRIBUTE_TYPENAME_BASIC: {
				switch (attr.type) {
					case TYPE_BOOLEAN:
						f.type = FILTER_BOOLEAN;
						break;
					case TYPE_NUMBER:
						f.type = FILTER_INTEGER;
						break;
					case TYPE_DATE:
						f.type = FILTER_DATE;
						f.default = {
							value: new Date().toISOString().split('T')[0],
						};
						break;
					case TYPE_DATETIME:
						f.type = FILTER_DATETIME;
						f.default = {
							value: new Date().toISOString(),
						};
						break;
				}
				break;
			}
		}
		return f;
	});

export const updateListItemById = (newItem: any, id: string, list: any) => {
	const itemIndex = findIndex(pathEq(['node', 'id'], id), list);
	return update(itemIndex, newItem, list);
};

export const alter = curry((checked, key, items) =>
	map(when(propEq('key', key), assoc('good', checked)), items)
);

export const sortByLabel = (list: any[]) => sort(ascend(prop('label')), list);

export const toggleListItem = curry((value, list) =>
	ifElse(includes(value), without([value]), append(value))(list)
);

export const FilterParam = Object.freeze({
	encode: (val: string) => encodeFiltersForQs(val),
	decode: (val: string) => decodeFiltersFromQs(val),
});

export const isJsonString = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const SYSTEM_ATTRIBUTES = Object.freeze([
	'createdAt',
	'updatedAt',
	'publishedAt',
]);

export const hasAttributes = (node: any) =>
	node.attributes.filter((attr: Attribute) => !SYSTEM_ATTRIBUTES.includes(attr.apiId))
		.length;

export const attributeNameToApiId = (name: string) =>
	camelCase(name)
		.replace(/_/g, '')
		.replace(/^[0-9]+/g, '');
