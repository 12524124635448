import { useState, useEffect, useContext } from 'react';
import { AssetList } from './AssetList';
import { useQuery } from '../hooks/useQuery';
import { withErrorBoundary } from '../common/ErrorBoundary';
import {
	reject,
	update,
	prepend,
	mergeDeepRight,
	pathEq,
	findIndex,
	values,
	flatten,
	isNil,
	omit,
	isEmpty,
	hasPath,
} from 'ramda';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
	ASSET_FILTER_MIMES,
	ASSET_FILTER_OTHER,
	DEFAULT_PER_PAGE,
} from '../constants';
import { SORT_ASC, SORT_DESC } from '../common/ResourceTable';
import { useQs } from '../hooks/useQs';
import { stringify } from 'qs';
import { makeId } from '../util';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { ContentApiContext } from '../context/ContentApiContext';
import { useAsyncEffect } from 'use-async-effect';
import { TableSkeleton } from '../common/Skeleton';

type UrlParams = {
	projectId: string;
}

const ASSET_ORDER_LS_KEY = 'pl:asset_order';
const ASSET_ORDER_VERSION = 3;
const ASSET_ORDER_DEFAULT = Object.freeze({
	dir: SORT_DESC,
	field: 'updatedAt',
	v: ASSET_ORDER_VERSION,
});

const getOrderFromLs = () => {
	const lsData = JSON.parse(localStorage.getItem(ASSET_ORDER_LS_KEY));
	if (!isNil(lsData) && lsData.v === ASSET_ORDER_VERSION) {
		return lsData;
	}
	return ASSET_ORDER_DEFAULT;
};

const AssetListContainerComponent = () => {
	useEnvironmentTitle('Assets');
	const { generateEnvPath, collectionByApiId, environment } = useContext(
		EnvironmentContext
	);
	const { findItems } = useContext(ContentApiContext);
	const qs = useQs();
	const history = useHistory();
	const { projectId } = useParams<UrlParams>();
	const { search } = useLocation();
	const [selection, setSelection] = useState([]);
	const { filters, pagination, apiParams }: any = useQuery({
		legacyEqualsHandling: false,
	});
	const [order, setOrder] = useState(getOrderFromLs());
	const [orderNonce, setOrderNonce] = useState(null);
	const assetBlueprint = collectionByApiId('Asset');
	const [assets, setAssets] = useState<any>({
		isLoading: true,
		isError: false,
		edges: [],
	});

	useEffect(() => {
		// TODO: this is a bit hacky - there might be a better solution, like a single unified object
		// for managing table state (filters, pagination, ordering) that could be a dependency of
		// the useEffect call.
		const lsOrder = JSON.parse(localStorage.getItem(ASSET_ORDER_LS_KEY));
		const isChanged =
			isNil(lsOrder) ||
			lsOrder.dir !== order.dir ||
			lsOrder.field !== order.field;
		if (isChanged) {
			localStorage.setItem(ASSET_ORDER_LS_KEY, JSON.stringify(order));
			if (
				Object.keys(qs).includes('after') ||
				Object.keys(qs).includes('before')
			) {
				// Reset pagination by removing `after` and `before` from the query string
				const newQs = omit(['after', 'before'], qs);
				const newUrl = generateEnvPath(
					`assets${isEmpty(newQs) ? '' : `?${stringify(newQs)}`}`
				);
				history.replace(newUrl);
				// If the URL has been updated, we don't need to trigger an update
			} else {
				setOrderNonce(makeId());
			}
		}
	}, [order]);

	const onUpload = (resource: any) =>
		setAssets({
			...assets,
			edges: prepend(
				{
					node: resource,
				},
				assets.edges
			),
			totalCount: assets.totalCount + 1,
		});

	const onDelete = (ids: string[]) =>
		setAssets({
			...assets,
			edges: reject((x: any) => ids.includes(x.node.id), assets.edges),
			totalCount: assets.totalCount - ids.length,
		});

	// Fetch assets from API initially, and then whenever
	// the querystring changes (filtering or pagination).
	useAsyncEffect(
		async (isActive) => {
			if (!environment.isLoading) {
				setAssets({
					...assets,
					isLoading: true,
				});
				setSelection([]);

				const vars: any = {
					filter: omit(['status', 'type', 'before', 'after'], apiParams),
					thumbSize: 48,
					order: {
						[order.field]: order.dir === SORT_ASC ? 'ASC' : 'DESC',
					},
				};

				if (hasPath(['type', 'eq'], apiParams)) {
					if (apiParams.type.eq === ASSET_FILTER_OTHER) {
						vars.filter.mimeType = {
							nin: flatten(values(ASSET_FILTER_MIMES)),
						};
					} else if (apiParams.type.eq in ASSET_FILTER_MIMES) {
						vars.filter.mimeType = {
							in: ASSET_FILTER_MIMES[apiParams.type.eq],
						};
					}
				}

				if (pagination.after) {
					vars.after = pagination.after;
				} else if (pagination.before) {
					vars.before = pagination.before;
				}

				if (pagination.before) {
					vars.last = DEFAULT_PER_PAGE;
				} else {
					vars.first = DEFAULT_PER_PAGE;
				}

				try {
					const res = await findItems('Asset', vars);
					if (!isActive()) {
						return;
					}
					setAssets({
						...res,
						isLoading: false,
					});
				} catch (err) {
					if (!isActive()) {
						return;
					}
					console.error(err);
					setAssets({
						...assets,
						isError: true,
					});
				}
			}
		},
		[search, projectId, orderNonce, environment]
	);

	const handleChange = (id: string, data: any) => {
		const index = findIndex(pathEq(['node', 'id'], id), assets.edges);
		const newNode = mergeDeepRight(assets.edges[index].node, data);
		setAssets({
			...assets,
			edges: update(index, { node: newNode }, assets.edges),
		});
	};

	const handleChangeBulk = (items: any) => {
		setAssets({
			...assets,
			edges: assets.edges.map((edge: any) => {
				// Check if this item needs to be updated
				const updatedNode = items.find((i: any) => i.id === edge.node.id);

				if (!updatedNode) {
					return edge;
				}

				return {
					...edge,
					node: mergeDeepRight(edge.node, updatedNode),
				};
			}),
		});
	};

	if (assetBlueprint.isLoading) {
		return <TableSkeleton />;
	}

	return (
		<AssetList
			assets={assets}
			filters={filters}
			onUpload={onUpload}
			onDelete={onDelete}
			onChange={(res: any, data: any) =>
				Array.isArray(res) ? handleChangeBulk(res) : handleChange(res, data)
			}
			onSelectionChange={(indices: number[]) => setSelection(indices)}
			selection={selection}
			order={order}
			setOrder={setOrder}
			assetBlueprint={assetBlueprint}
		/>
	);
};

export const AssetListContainer = withErrorBoundary(
	AssetListContainerComponent
);
