import React, { Component } from 'react';

export const withExtensionAttributeErrorBoundary = (BaseComponent) => (
	props
) => (
	<ExtensionAttributeErrorBoundary>
		<BaseComponent {...props} />
	</ExtensionAttributeErrorBoundary>
);

class ExtensionAttributeErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="rounded border border-red-400 p-4">
					<p className="text-red-700 font-medium">
						This attribute extension could not be loaded.
					</p>
				</div>
			);
		}
		return this.props.children;
	}
}
