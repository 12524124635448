import React, { useState, useEffect, useContext } from 'react';
import { OrderList } from './OrderList';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { graphql } from '../api';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useQuery } from '../hooks/useQuery';
import LIST_ORDERS from './ListOrders.gql';
import { DEFAULT_PER_PAGE } from '../constants';
import { useEnvironmentTitle } from '../hooks/useTitle';

const OPERATOR_MAP = Object.freeze({
	eq: 'EQUAL',
	gt: 'GREATER_THAN',
	gte: 'GREATER_THAN_EQUAL',
	lt: 'LESS_THAN',
	lte: 'LESS_THAN_EQUAL',
	ne: 'NOT_EQUAL',
});

const OrderListContainerComponent = () => {
	useEnvironmentTitle('Orders');
	const { environment } = useContext(EnvironmentContext);

	const [orders, setOrders] = useState({
		isLoading: true,
		edges: [],
	});

	const query = useQuery();

	useEffect(() => {
		let active = true;
		setOrders({
			isLoading: true,
			edges: [],
		});
		if (!environment.isLoading) {
			const variables = {
				envId: environment.node.id,
				after: query.pagination.after,
				before: query.pagination.before,
				filter: {},
			};

			if (query.pagination.before) {
				variables.last = DEFAULT_PER_PAGE;
			} else {
				variables.first = DEFAULT_PER_PAGE;
			}

			for (const f of query.filters) {
				if (f.key === 'created_at') {
					variables.filter.createdAt = {
						operator: OPERATOR_MAP[f.operator],
						value: f.value,
					};
				}
			}

			graphql({
				query: LIST_ORDERS,
				variables: variables,
			}).then(({ data }) => {
				if (active) {
					setOrders({
						isLoading: false,
						...data.data.environment.orders,
					});
				}
			});
		}
		return () => (active = false);
	}, [query.hash, environment.isLoading]);

	return <OrderList orders={orders} />;
};

export const OrderListContainer = withErrorBoundary(
	OrderListContainerComponent
);
