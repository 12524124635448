import { parse } from 'qs';
import { decodeFiltersFromQs } from '../util';

import { propOr, has } from 'ramda';
import { filtersToApiParams } from '../filters/util';

// Retrieves and parses pagination and filtering parameters from the URL query string
export const useQuery = ({ legacyEqualsHandling = true } = {}) => {
	// Get query string parameters
	const query = parse(window.location.search.slice(1));

	// Extract the pagination parameters for this component
	let pagination = {
		after: propOr(null, 'after', query),
		before: propOr(null, 'before', query),
	};

	// Process the filters, if passed in
	let filters = [];

	if (has('filters', query)) {
		const decodedFilters = decodeFiltersFromQs(query.filters);
		if (decodedFilters !== null) {
			filters = decodedFilters;
		}
	}

	// Create an object representing all parameters that need to be sent to the API
	const apiParams = filtersToApiParams(filters, legacyEqualsHandling);

	if (pagination.after !== null) {
		apiParams.after = pagination.after;
	} else if (pagination.before !== null) {
		apiParams.before = pagination.before;
	}

	return {
		filters: filters,
		pagination: pagination,
		apiParams: apiParams,
		hash: btoa(window.location.search),
	};
};
