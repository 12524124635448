import React from 'react';
import { Button, CheckboxField } from '@platformapp/ui';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateApiKey } from './mutations';
import { Link } from '../common/Link';
import toast from 'react-hot-toast';

export const IntrospectionSetting = ({ apiKey, disabled }) => {
	const form = useFormik({
		initialValues: {
			allowIntrospection: pathOr('', ['allowIntrospection'], apiKey),
		},
		onSubmit: async (values, { setErrors, resetForm }) => {
			try {
				await updateApiKey(apiKey.id, values);
				toast.success('Introspection settings saved');
				resetForm({
					values,
				});
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to save settings');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Schema introspection</p>
					<p className="mb-2">
						<Link
							external
							newWindow
							to="https://graphql.org/learn/introspection/"
						>
							Introspection
						</Link>{' '}
						allows GraphQL queries that describe the schema. This is useful for
						tools like GraphiQL or Gatsby.
					</p>
					<p className="mb-6">
						This can be disabled for security purposes to prevent unauthorised
						users learning about the API schemas.
					</p>
					<div className="w-full md:w-96">
						<CheckboxField
							label="Enable GraphQL schema introspection"
							checked={form.values.allowIntrospection}
							onChange={(checked) =>
								form.setFieldValue('allowIntrospection', checked)
							}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
