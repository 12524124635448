import React from 'react';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { Module } from '../common/Module';
import { SingleVariantProductForm } from '../product-variants/SingleVariantProductForm';
import { EDITOR_MODE_NEW } from '../constants';
import { VariantOptionsForm } from '../product-variants/VariantOptionsForm';
import { NewVariantsTable } from './NewVariantsTable';
import {
	MainContent,
	MainContentContainer,
	MainContentNav,
} from '../common/MainContent';
import { ProductsSidebar } from '../products-list/ProductsSidebar';
import { EditItemHeader } from '../publishable/EditItemHeader';

export const CreateProductLayer = ({ blueprint, form }) => (
	<MainContentContainer>
		<MainContentNav>
			<ProductsSidebar />
		</MainContentNav>
		<MainContent>
			<EditItemHeader
				isSaving={form.isSubmitting}
				isDirty={form.dirty}
				blueprint={blueprint.node}
				onSaveClick={() => form.submitForm()}
				onPublishClick={() => {
					form.setStatus({ action: 'publish' });
					form.submitForm();
				}}
				title={form.values.name || 'New product'}
				collectionName={blueprint.node.name}
			/>

			<div className="fade-in my-8 md:mr-96">
				<Module bottomMargin heading="Product information">
					<AttributeEditor
						attributes={blueprint.node.attributes.filter(
							(a) => a.isEditable && !['options', 'variants'].includes(a.apiId)
						)}
						onAttributeChange={(apiId, val) => form.setFieldValue(apiId, val)}
						pathPrefix={['input']}
						validationErrors={form.errors}
						values={form.values}
						readOnly={form.isSubmitting}
						isNew
					/>
				</Module>

				{form.values.options.length === 0 && (
					<SingleVariantProductForm
						form={form}
						mode={EDITOR_MODE_NEW}
						readOnly={form.isSubmitting}
					/>
				)}

				<Module
					bottomMargin
					heading="Variants"
					subheading="Sell different variants of the same product using options, like size and style for clothes. Variants will be generated and listed below."
				>
					<VariantOptionsForm readOnly={form.isSubmitting} form={form} />
					{form.values.options.length > 0 && (
						<div className="mt-12">
							<NewVariantsTable form={form} readOnly={form.isSubmitting} />
						</div>
					)}
				</Module>
			</div>
		</MainContent>
	</MainContentContainer>
);
