import { TextList } from "./TextList";

type EmailListProps = {
    error?: any;
    helpText?: string;
    label?: string;
    onChange?: (value: any) => void;
    readOnly?: boolean;
    value: any;
}

export const EmailList = ({
    error,
    helpText,
    label,
    onChange,
    readOnly,
    value
}: EmailListProps) => <TextList
    addLabel="Add email"
    error={error}
    helpText={helpText}
    label={label}
    maxLength={256}
    onChange={onChange}
    readOnly={readOnly}
    unique
    uniqueCaseInsensitive
    validate={value => {
        if (value.length < 3) {
            return 'Must be at least 3 characters';
        }

        const validEmail = value.toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        
        if (validEmail) {
            return null;
        }

        return 'Must be a valid email';
    }}
    value={value}
/>