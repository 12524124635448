import React from 'react';
import { NavLink } from 'react-router-dom';

export const TopNavLink = (props) => (
	<NavLink
		activeClassName="border-blue-700"
		className="pt-1 pb-2.5 mr-3 inline-block hover:text-blue-700 p-1 text-gray-800 font-medium text-base border-b-2 border-transparent"
		{...props}
	/>
);
