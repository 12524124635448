import { useContext, useState } from "react";
import { AttributeEditorContext } from "../attribute-editor/AttributeEditorContext";
import { AssetSelector } from "../common/AssetSelector"

const processChanged = (isList: boolean, value: any) => {
    if (!value) {
        return value;
    } else if (isList) {
        return value.edges.map((edge: any) => edge.node.id);
    }
    return value.id;
}

export const AssetAttribute = ({
    attribute,
    error,
    helpText,
    onChange
}: any) => {
    const { initialValues, readOnly } = useContext(AttributeEditorContext);
    const [fullValue, setFullValue] = useState(initialValues[attribute.apiId]);
    return(
        <AssetSelector
            allowRemove={!attribute.isRequired}
            error={error}
            multiple={attribute.isList}
            onChange={(value: any) => {
                setFullValue(value);
                onChange(processChanged(attribute.isList, value));
            }}
            readOnly={readOnly}
            value={fullValue}
            helpText={helpText}
        />
    )
}