import React, { useState } from 'react';
import { Button, Card, TextStyle } from '@platformapp/ui';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { EditEnumModal } from './EditEnumModal';
import { EnumContextMenu } from './EnumContextMenu';
import { EmptyMessage } from '../common/EmptyMessage';
import { ListPageHeader } from '../common/ListPageHeader';
import { TableSkeleton } from '../common/Skeleton';

const EnumValue = ({ children }) => (
	<p className="inline-block mr-2 mb-2 bg-gray-300 rounded px-3 py-2 whitespace-nowrap">
		{children}
	</p>
);

export const EnumList = ({ enums, onDelete, onAdd, onUpdate }) => {
	const [addingEnum, setAddingEnum] = useState(false);

	return (
		<>
			{addingEnum && (
				<EditEnumModal
					onCancel={() => setAddingEnum(false)}
					onSave={(item) => {
						onAdd(item);
						setAddingEnum(false);
					}}
				/>
			)}

			<ListPageHeader
				hasPx={false}
				title="Enumerations"
				right={
					<Button primary onClick={() => setAddingEnum(true)}>
						Create enumeration
					</Button>
				}
			/>

			<div className="mt-5">
				{enums.isLoading && <TableSkeleton />}
				<div className="fade-in">
					{!enums.isLoading && (
						<>
							{enums.edges.map(({ node }) => (
								<Card className="p-5 mb-6 last:mb-0" key={node.id}>
									<div className="flex items-top mb-6">
										<p className="font-medium flex-grow">
											{node.name}
											<ApiIdLabel className="ml-3">{node.apiId}</ApiIdLabel>
										</p>
										<EnumContextMenu
											enumItem={node}
											onDelete={onDelete}
											onUpdate={onUpdate}
										/>
									</div>
									<div
										className={node.values.edges.length > 0 ? '-mb-2' : null}
									>
										{node.values.edges.map(({ node }) => (
											<EnumValue key={node.id}>{node.name}</EnumValue>
										))}

										{node.values.edges.length === 0 && (
											<p>
												<TextStyle subdued>No values have been added</TextStyle>
											</p>
										)}
									</div>
								</Card>
							))}

							{enums.edges.length === 0 && (
								<EmptyMessage title="You don't have any enumerations">
									<Button onClick={() => setAddingEnum(true)}>
										Create enumeration
									</Button>
								</EmptyMessage>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
