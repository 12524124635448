import React, { useContext } from 'react';
import { FILTER_DATE } from '../constants';
import Helpers from '../Helpers';
import { encodeFiltersForQs } from '../util';
import { Filters } from '../filters/Filters';
import { stringify } from 'qs';
import { useHistory } from 'react-router-dom';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const OrderFilters = ({ appliedFilters }) => {
	const history = useHistory();
	const { generateEnvPath } = useContext(EnvironmentContext);

	const filterChanged = (filters) => {
		// Find only filters with non-null values
		const actualFilters = filters.filter((filter) => {
			return filter.value !== null;
		});

		// Update QS to match state
		let newUrl = generateEnvPath('orders');

		if (actualFilters.length) {
			const encodedFilters = encodeFiltersForQs(actualFilters);
			newUrl +=
				'?' +
				stringify({
					filters: encodedFilters,
				});
		}

		history.push(newUrl);
	};

	const filterDefs = [
		{
			key: 'created_at',
			type: FILTER_DATE,
			label: Helpers.t('orders.filters.created_at'),
			default: {
				value: new Date().toISOString().split('T')[0],
			},
			persistent: false,
		},
	];

	return (
		<Filters
			definitions={filterDefs}
			filters={appliedFilters}
			onChange={(filters) => filterChanged(filters)}
		/>
	);
};
