import React from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import {
	Button,
	ButtonGroup,
	ErrorMessageElement,
	Modal,
	ModalContent,
	ModalFooter,
	SelectField,
	TextField,
} from '@platformapp/ui';
import { graphql } from '../api';
import { useFormik } from 'formik';
import { extractGraphqlError } from '../util';
import ADD_TEAM_MEMBER_MUTATION from './addTeamMember.gql';
import UPDATE_TEAM_MEMBER_MUTATION from './updateTeamMember.gql';
import { ROLE_COLLABORATOR, ROLE_OWNER } from '../constants';
import toast from 'react-hot-toast';

const AddTeamMemberComponent = ({
	onCancel,
	onSave,
	existingItem,
	initialValues,
	teamId,
}) => {
	const isNew = !existingItem;

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: initialValues,
		validate: (values) => {
			const errors = [];
			if (!values.email) {
				errors.push({
					path: ['input', 'email'],
					message: 'Required',
				});
			}
			if (!values.role) {
				errors.push({
					path: ['input', 'role'],
					message: 'Required',
				});
			}
			return errors;
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			const inputVars = isNew
				? {
						...values,
						teamId,
				  }
				: {
						teamMemberId: existingItem.id,
						role: values.role,
				  };

			graphql({
				query: isNew ? ADD_TEAM_MEMBER_MUTATION : UPDATE_TEAM_MEMBER_MUTATION,
				variables: {
					input: inputVars,
				},
			})
				.then((res) => {
					const payload =
						res.data.data[isNew ? 'addTeamMember' : 'updateTeamMember'];

					if (payload.userErrors.length > 0) {
						setErrors(payload.userErrors);
						setSubmitting(false);
					} else if (payload.errors) {
						setSubmitting(false);
						toast.error('Failed to save team member');
					} else {
						onSave(payload.teamMember);
					}
				})
				.catch((err) => {
					console.error(err);
					setSubmitting(false);
					toast.error('Failed to save team member');
				});
		},
	});

	return (
		<Modal
			title={isNew ? 'Add team member' : `Edit ${existingItem.email}`}
			small
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					{extractGraphqlError('teamId', form.errors).length > 0 && (
						<ErrorMessageElement
							message={extractGraphqlError('teamId', form.errors)}
						/>
					)}

					{isNew && (
						<TextField
							name="email"
							type="email"
							label="Email"
							onChange={form.handleChange}
							value={form.values.email}
							error={extractGraphqlError('email', form.errors)}
							autoFocus
							helpText="An email will be sent inviting the user to this team."
							disabled={form.isSubmitting}
						/>
					)}

					<SelectField
						name="role"
						label="Role"
						onChange={form.handleChange}
						value={form.values.role}
						disabled={form.isSubmitting}
						options={[
							{
								label: 'Collaborator',
								value: ROLE_COLLABORATOR,
							},
							{
								label: 'Owner',
								value: ROLE_OWNER,
							},
						]}
						error={extractGraphqlError('role', form.errors)}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty || form.isSubmitting}
						>
							{isNew ? 'Send invite' : 'Save'}
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};

AddTeamMemberComponent.defaultProps = {
	existingItem: null,
	initialValues: {
		email: '',
		role: '',
	},
};

export const AddTeamMember = withErrorBoundary(AddTeamMemberComponent);
