export const Module = ({
	heading,
	subheading,
	className,
	actions,
	children,
	bottomMargin,
	...remainder
}: any) => (
	<div className={`mb-12 last:mb-0 last:pb-0 ${className}`} {...remainder}>
		{(heading || subheading) && (
			<div
				className={`pt-2 flex items-center border-b border-gray-300 ${
					bottomMargin ? 'mb-5' : ''
				}`}
			>
				<div className="flex-grow pb-3">
					<h2 className="font-medium text-lg">{heading}</h2>
					{subheading && <p className="mt-2 text-gray-600">{subheading}</p>}
				</div>
				{actions}
			</div>
		)}
		<div>{children}</div>
	</div>
);

Module.defaultProps = {
	title: null,
	subtitle: null,
	className: '',
	actions: null,
};
