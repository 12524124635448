import React, { useContext } from 'react';
import { Loader } from '@platformapp/ui';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ProjectContext } from '../context/ProjectContext';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { CreateProductForm } from './CreateProductForm';

export const CreateProductContainer = () => {
	useEnvironmentTitle('New product');
	const { collectionByApiId } = useContext(EnvironmentContext);
	const { isLoading: projectLoading } = useContext(ProjectContext);
	const blueprint = collectionByApiId('Product');

	if (blueprint.isLoading || projectLoading) {
		return <Loader />;
	}

	return <CreateProductForm blueprint={blueprint} />;
};
