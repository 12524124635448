import { useContext } from "react"
import { NavLink, useParams } from "react-router-dom"
import { ListPageHeader } from "../common/ListPageHeader"
import { EnvironmentContext } from "../context/EnvironmentContext"

type NavProps = {
    collection: any;
}

type Params = {
    formId: string;
}

export const Nav = ({collection}: NavProps) => {
    const {generateEnvPath} = useContext(EnvironmentContext);
    const { formId } = useParams<Params>();
    return (
        // @ts-ignore
        <ListPageHeader
            hasPx={false}
            title={collection.isLoading ? '...' : collection.node.name}
            bordered
            right={
                <div className="flex self-end">
                    <NavLink
                        className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
                        activeClassName="text-gray-800 border-b border-gray-800"
                        to={generateEnvPath(`forms/${formId}`)}
                        exact
                    >
                        Submissions
                    </NavLink>
                    <NavLink
                        className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
                        activeClassName="text-gray-800 border-b border-gray-800"
                        to={generateEnvPath(`forms/${formId}/settings`)}
                    >
                        Settings
                    </NavLink>
                </div>
            }
        />
    )
}