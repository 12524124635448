import React, { useEffect } from 'react';
import { Heading } from '../common/Heading';
import { BasicLoader, TextStyle } from '@platformapp/ui';
import api from '../api';

export const SignOut = () => {
	useEffect(() => {
		// Destroy ls data
		localStorage.clear();

		// Destroy cookies/session
		api.post('/auth/logout').then(() => {
			window.location.replace('https://login.platformapp.net/logout');
		});
	}, []);

	return (
		<div className="absolute h-screen w-full flex items-center justify-center">
			<div className="max-w-sm bg-white px-8 py-8 rounded md:shadow-lg">
				<Heading className="text-center mb-6">Signing out</Heading>
				<BasicLoader className="block h-8 w-8 mx-auto mb-6 text-blue-700 fill-current" />
				<p className="text-center">
					<TextStyle subdued>
						You are being signed out of the Dashboard...
					</TextStyle>
				</p>
			</div>
		</div>
	);
};
