import React from 'react';
import { ResourceTable } from '../common/ResourceTable';
import { CurrencyField, TextField } from '@platformapp/ui';
import { lensPath, pathOr, set } from 'ramda';
import { AssetSelector } from '../common/AssetSelector';
import { EmptyTextValue } from '../common/EmptyTextValue';

export const EditableVariantsTable = ({
	data,
	onChange,
	onDelete,
	readOnly,
	selection,
	setSelection,
	...props
}) => (
	<ResourceTable
		columns={[
			{
				className: 'w-16',
				title: 'Image',
				render: (variant, { index }) => (
					<AssetSelector
						onChange={(val) => {
							const newImages = {
								edges: [
									{
										node: val,
									},
								],
							};
							onChange(set(lensPath([index, 'images']), newImages, data));
						}}
						readOnly={readOnly}
						small
						value={pathOr(null, ['images', 'edges', 0, 'node'], variant)}
					/>
				),
			},
			{
				title: 'Variant options',
				render: (variant) => variant.options.join(', '),
			},
			{
				title: 'Price',
				render: (variant, { index }) => (
					<CurrencyField
						className="mr-6"
						readOnly={readOnly}
						onChange={(e) => {
							const newVariants = set(
								lensPath([index, 'price', 'amount']),
								e,
								data
							);
							onChange(newVariants);
						}}
						value={variant.price.amount}
					/>
				),
			},
			{
				title: 'Quantity',
				render: (variant, { index }) =>
					variant.inventoryItem.isTracked ? (
						<TextField
							className="mr-6"
							readOnly={readOnly}
							onChange={(e) => {
								const newVariants = set(
									lensPath([index, 'inventoryItem', 'available']),
									parseInt(e.target.value, 10),
									data
								);
								onChange(newVariants);
							}}
							type="number"
							value={variant.inventoryItem.available}
						/>
					) : (
						<EmptyTextValue />
					),
			},
			{
				title: 'SKU',
				render: (variant, { index }) => (
					<TextField
						onChange={(e) => {
							const newVariants = set(
								lensPath([index, 'sku']),
								e.target.value,
								data
							);
							onChange(newVariants);
						}}
						readOnly={readOnly}
						value={variant.sku}
					/>
				),
			},
		]}
		disabled={readOnly}
		data={data}
		idSource={null}
		selectable
		selection={selection}
		onSelectionChange={setSelection}
		bulkActions={[
			{
				destructive: true,
				label: 'Delete',
				onAction: () => onDelete(),
			},
		]}
		{...props}
	/>
);
