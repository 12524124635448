import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import GET_INTEGRATIONS from './GetIntegrations.gql';
import { Heading } from '../common/Heading';
import { ProjectContext } from '../context/ProjectContext';
import { useTitle } from '../hooks/useTitle';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { SlatsLoader } from '../common/Skeleton';
import { PaymentProvidersList } from './PaymentProvidersList';
import { useAsyncEffect } from 'use-async-effect';

export const PaymentProviders = withErrorBoundary(() => {
	const { activeProject } = useContext(ProjectContext);
	const { environment } = useContext(EnvironmentContext);
	const [providers, setProviders] = useState({
		isLoading: true,
		data: {},
	});
	useTitle(
		activeProject && environment.node
			? ['Payment providers', activeProject.name, environment.node.name]
			: null
	);

	useAsyncEffect(
		async (isActive) => {
			if (environment.node) {
				const { data } = await graphql({
					query: GET_INTEGRATIONS,
					variables: {
						id: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setProviders({
					isLoading: false,
					data: data.data.env.integrations,
				});
			}
		},
		[environment]
	);

	return (
		<>
			<Heading className="mb-6">Payment providers</Heading>

			<div className="lg:mr-48 mb-12">
				{providers.isLoading && <SlatsLoader count={3} />}

				{!providers.isLoading && (
					<PaymentProvidersList providers={providers.data} />
				)}
			</div>
		</>
	);
});
