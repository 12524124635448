import React, { useContext, useEffect, useState } from 'react';
import api from '../api';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { trigger } from '../util/events';
import { useQs } from '../hooks/useQs';
import { Link } from '../common/Link';
import { Loader, LandingMessage, WarningTriangleIcon } from '@platformapp/ui';

export const AuthCallback = () => {
	const { code, state } = useQs();
	const [isError, setIsError] = useState(false);

	const { setUser, is_logged_in } = useContext(UserContext);
	const history = useHistory();

	useEffect(() => {
		console.info('Exchange code for token...');
		api
			.post('/auth/callback', {
				code,
				state,
			})
			.then((res) => {
				console.info('Code exchange successful');

				setUser({
					is_logged_in: true,
					is_initialised: true,
					...res.data,
				});
			})
			.catch((err) => {
				console.error(err);
				setIsError(true);
			});
	}, []);

	useEffect(() => {
		if (is_logged_in) {
			console.info('Redirect to home...');
			trigger('Login');
			history.replace('/');
		}
	}, [is_logged_in]);

	return (
		<>
			{!isError && <Loader />}
			{isError && (
				<LandingMessage
					header="An error occurred logging in"
					body={
						<p>
							Please <Link to="/">try again</Link>
						</p>
					}
					icon={<WarningTriangleIcon />}
				/>
			)}
		</>
	);
};
