import React, { useState, useMemo, useContext } from 'react';
import { AddTeamMember } from './AddTeamMember';
import { DataTable } from '../table/DataTable';
import { Badge, Button } from '@platformapp/ui';
import { ListPageHeader } from '../common/ListPageHeader';
import { Paginator } from '../common/Pagination';
import {
	SCOPE_CREATE_TEAM_MEMBER,
	SCOPE_UPDATE_TEAM_MEMBER,
} from '../constants';
import Helpers from '../Helpers';
import { pick } from 'ramda';
import { TeamMemberContextMenu } from './TeamMemberContextMenu';
import { TeamContext } from '../context/TeamContext';
import { UserAvatar } from '../user/UserAvatar';
import { TableSkeleton } from '../common/Skeleton';
import toast from 'react-hot-toast';

export const TeamMemberList = ({ result, onDelete, onAdd, onUpdate }) => {
	const { activeTeam, canDo } = useContext(TeamContext);

	const tableDef = useMemo(() => [
		{
			accessor: 'node.name',
			Header: 'Team member',
			Cell: (m) => (
				<div className="flex items-center">
					<span className="mr-6">
						<UserAvatar user={m.row.original.node} />
					</span>

					<div className="flex-grow">
						{m.row.original.node.isAccepted && (
							<>
								<p className="font-medium">{m.row.original.node.name}</p>
								<p className="mt-1">{m.row.original.node.email}</p>
							</>
						)}

						{!m.row.original.node.isAccepted && (
							<p>{m.row.original.node.email}</p>
						)}
					</div>
				</div>
			),
		},
		{
			accessor: 'node.role',
			Header: 'Role',
			Cell: (m) => <Badge text={Helpers.t(`teams.roles.${m.cell.value}`)} />,
		},
		{
			accessor: 'node.isAccepted',
			Header: 'Status',
			Cell: (m) => (
				<Badge
					positive={m.cell.value}
					text={m.cell.value ? 'Accepted' : 'Pending'}
				/>
			),
		},
		{
			id: 'contextmenu',
			Cell: (m) =>
				canDo(SCOPE_UPDATE_TEAM_MEMBER) && (
					<TeamMemberContextMenu
						onDelete={() => onDelete(m.row.original.node.id)}
						onUpdate={(member) => onUpdate(m.row.index, member)}
						node={m.row.original.node}
					/>
				),
		},
	]);

	const [isInviting, setIsInviting] = useState(false);

	const handleAddTeamMember = (member) => {
		toast.success(`Invite sent to ${member.email}`);
		setIsInviting(false);
		onAdd(member);
	};

	return (
		<>
			{isInviting && (
				<AddTeamMember
					onCancel={() => setIsInviting(false)}
					onSave={handleAddTeamMember}
					teamId={activeTeam.id}
				/>
			)}

			<div className="fade-in mt-24 pt-6 mx-5 md:mx-10">
				<ListPageHeader
					title="Team members"
					sticky={false}
					right={
						canDo(SCOPE_CREATE_TEAM_MEMBER) && (
							<Button primary onClick={() => setIsInviting(true)}>
								Add team member
							</Button>
						)
					}
				/>

				<div className="mt-5">
					{result.isLoading && <TableSkeleton />}
					{!result.isLoading && (
						<>
							<DataTable
								columns={tableDef}
								data={result.edges}
								rowIdPath={['node', 'id']}
							/>
							<Paginator {...pick(['totalCount', 'pageInfo'], result)} />
						</>
					)}
				</div>
			</div>
		</>
	);
};
