import { BasicAttributeType } from "./types/Attribute";

// App wide default for number of items per page
export const DEFAULT_PER_PAGE = 20;

// The default refresh interval for datetime components that refresh.
export const DATE_REFRESH = 30000;

// Team member roles
export const ROLE_OWNER = 'OWNER';
export const ROLE_COLLABORATOR = 'COLLABORATOR';

// Publish statuses
export const STATUS_PUBLISHED = 'PUBLISHED';
export const STATUS_DRAFT = 'DRAFT';
export const STATUS_CHANGED = 'CHANGED';

// Stages
export const STAGE_DRAFT = 'DRAFT';
export const STAGE_PUBLISHED = 'PUBLISHED';

// Edit modes
export const EDITOR_MODE_NEW = 'new';
export const EDITOR_MODE_COPY = 'copy';
export const EDITOR_MODE_EDIT = 'edit';

// Attribute typenames
export const ATTRIBUTE_TYPENAME_BASIC = 'BasicAttribute';
export const ATTRIBUTE_TYPENAME_ENUM = 'EnumerationAttribute';
export const ATTRIBUTE_TYPENAME_UNIREF = 'UnidirectionalReferenceAttribute';
export const ATTRIBUTE_TYPENAME_BIREF = 'BidirectionalReferenceAttribute';
export const ATTRIBUTE_TYPENAME_COMPONENT = 'ComponentAttribute';

// Basic attribute types
export const TYPE_TEXT = 'TEXT';
export const TYPE_NUMBER = 'NUMBER';
export const TYPE_MARKDOWN = 'MARKDOWN';
export const TYPE_LONG_TEXT = 'LONG_TEXT';
export const TYPE_DATE = 'DATE';
export const TYPE_DATETIME = 'DATETIME';
export const TYPE_BOOLEAN = 'BOOLEAN';
export const TYPE_MONEY = 'MONEY';
export const TYPE_ASSET = 'ASSET';

// Types that can represent the content title
export const TITLE_ATTRIBUTE_TYPES = [BasicAttributeType.Text];

// Types that can be unique
export const UNIQUE_ATTRIBUTE_TYPES = [BasicAttributeType.Text];

// Types that can be a list
export const LIST_ATTRIBUTE_TYPES = [BasicAttributeType.Text, TYPE_ASSET];

export const LIST_TYPENAMES = [
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_BIREF,
];

// MIME type to friendly name mapping
export const FRIENDLY_MIMES = {
	'image/jpeg': 'JPEG',
	'image/jpg': 'JPEG',
	'image/png': 'PNG',
	'text/plain': 'Text',
	'image/gif': 'GIF',
	'image/tiff': 'TIFF',
	'application/pdf': 'PDF',
	'application/msword': 'Word Document',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		'Word document',
	'application/zip': 'ZIP archive',
	'video/mp4': 'MP4',
};

// Events
export const EVENT_ITEM_PUBLISHED = 'ITEM_PUBLISHED';
export const EVENT_ITEM_UNPUBLISHED = 'ITEM_UNPUBLISHED';
export const EVENT_ITEM_DELETED = 'ITEM_DELETED';
export const EVENT_ITEM_CREATED = 'ITEM_CREATED';
export const EVENT_ITEM_UPDATED = 'ITEM_UPDATED';

export const EVENT_ORDER_CREATED = 'ORDER_CREATED';

// Order statuses
export const ORDER_FULFILLED = 'FULFILLED';
export const ORDER_UNFULFILLED = 'UNFULFILLED';

// Order item statuses
export const ORDER_ITEM_FULFILLED = 'FULFILLED';
export const ORDER_ITEM_UNFULFILLED = 'UNFULFILLED';

// Asset filters
export const ASSET_FILTER_IMAGE = 'image';
export const ASSET_FILTER_VIDEO = 'video';
export const ASSET_FILTER_AUDIO = 'audio';
export const ASSET_FILTER_DOCUMENT = 'document';
export const ASSET_FILTER_OTHER = 'other';

export const ASSET_FILTER_MIMES: {
	[key: string]: readonly string[]
} = Object.freeze({
	[ASSET_FILTER_IMAGE]: Object.freeze([
		'image/bmp',
		'image/vnd.microsoft.icon',
		'image/png',
		'image/jpeg',
		'image/jpg',
		'image/gif',
		'image/tiff',
		'image/webp',
		'image/svg+xml',
	]),
	[ASSET_FILTER_VIDEO]: Object.freeze([
		'application/vnd.apple.mpegurl',
		'application/x-mpegurl',
		'video/3gpp',
		'video/3gpp2',
		'video/mpeg',
		'video/ogg',
		'video/quicktime',
		'video/webm',
		'video/x-m4v',
		'video/ms-asf',
		'video/x-ms-wmv',
		'video/x-msvideo',
		'video/mp2t',
		'video/avi',
		'video/mp4',
	]),
	[ASSET_FILTER_AUDIO]: Object.freeze([
		'audio/aac',
		'audio/midi',
		'audio/x-midi',
		'audio/mpeg',
		'audio/mp3',
		'audio/mp4',
		'audio/ogg',
		'audio/opus',
		'audio/wav',
		'audio/webm',
	]),
	[ASSET_FILTER_DOCUMENT]: Object.freeze([
		'application/x-abiword',
		'application/pdf',
		'application/msword',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'text/plain',
		'text/markdown',
		'application/vnd.oasis.opendocument.presentation',
		'application/vnd.oasis.opendocument.spreadsheet',
		'application/vnd.oasis.opendocument.text',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.visio',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/rtf',
		// 'application/x-mobipocket-ebook',
		// 'application/epub+zip',
		// 'application/zip',
	]),
});

export const FILTERABLE_ATTRIBUTE_TYPES = Object.freeze([
	TYPE_TEXT,
	TYPE_NUMBER,
	// TYPE_DATETIME,
	TYPE_DATE,
	TYPE_BOOLEAN,
]);

export const PINNABLE_TYPENAMES = Object.freeze([
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_UNIREF,
]);

export const PINNABLE_BASIC_ATTRIBUTE_TYPES = Object.freeze([
	TYPE_TEXT,
	TYPE_NUMBER,
	TYPE_DATE,
	TYPE_DATETIME,
	TYPE_BOOLEAN,
	TYPE_ASSET,
]);

/**
 * The default pinned attributes for an asset collection.
 */
export const ASSET_DEFAULT_PINNED: string[] = [
	'title',
	'size',
	'updatedAt'
];

// Scopes
export const SCOPE_CREATE_ATTRIBUTE = 'attribute:create';
export const SCOPE_DELETE_ATTRIBUTE = 'attribute:delete';
export const SCOPE_UPDATE_ATTRIBUTE = 'attribute:update';
export const SCOPE_READ_ATTRIBUTE = 'attribute:read';

export const SCOPE_BILLING_UPDATE = 'billing:update';

export const SCOPE_READ_BLUEPRINT = 'blueprint:read';
export const SCOPE_CREATE_BLUEPRINT = 'blueprint:create';
export const SCOPE_UPDATE_BLUEPRINT = 'blueprint:update';
export const SCOPE_DELETE_BLUEPRINT = 'blueprint:delete';

export const SCOPE_READ_CORS_ORIGIN = 'cors-origin:read';
export const SCOPE_CREATE_CORS_ORIGIN = 'cors-origin:create';
export const SCOPE_DELETE_CORS_ORIGIN = 'cors-origin:delete';

export const SCOPE_READ_ENUM = 'enum:read';
export const SCOPE_CREATE_ENUM = 'enum:create';
export const SCOPE_UPDATE_ENUM = 'enum:update';
export const SCOPE_DELETE_ENUM = 'enum:delete';

export const SCOPE_READ_ENVIRONMENT = 'environment:read';
export const SCOPE_UPDATE_ENVIRONMENT = 'environment:update';

export const SCOPE_READ_FORM_SUBMISSION = 'form-submission:read';
export const SCOPE_UPDATE_FORM_SUBMISSION = 'form-submission:update';
export const SCOPE_DELETE_FORM_SUBMISSION = 'form-submission:delete';

export const SCOPE_READ_ORDER = 'order:read';

export const SCOPE_READ_PROJECT = 'project:read';
export const SCOPE_CREATE_PROJECT = 'project:create';
export const SCOPE_UPDATE_PROJECT = 'project:update';
export const SCOPE_DELETE_PROJECT = 'project:delete';

export const SCOPE_DELETE_TEAM = 'team:delete';
export const SCOPE_UPDATE_TEAM = 'team:update';

export const SCOPE_CREATE_TEAM_MEMBER = 'team-member:create';
export const SCOPE_UPDATE_TEAM_MEMBER = 'team-member:update';

export const SCOPE_CREATE_WEBHOOK = 'webhook:create';
export const SCOPE_READ_WEBHOOK = 'webhook:read';
export const SCOPE_UPDATE_WEBHOOK = 'webhook:update';
export const SCOPE_DELETE_WEBHOOK = 'webhook:delete';

// Content API scopes
export const SCOPE_CONTENT_API_READ_INVENTORY = 'inventory:read';
export const SCOPE_CONTENT_API_WRITE_INVENTORY = 'inventory:write';

// Commerce API scopes
export const SCOPE_COMMERCE_API_WRITE_CHECKOUT = 'checkout:write';

export const VALID_API_KEY_SCOPES = Object.freeze([
	SCOPE_CREATE_ATTRIBUTE,
	SCOPE_DELETE_ATTRIBUTE,
	SCOPE_UPDATE_ATTRIBUTE,
	SCOPE_READ_ATTRIBUTE,
	SCOPE_READ_BLUEPRINT,
	SCOPE_CREATE_BLUEPRINT,
	SCOPE_UPDATE_BLUEPRINT,
	SCOPE_DELETE_BLUEPRINT,
	SCOPE_READ_CORS_ORIGIN,
	SCOPE_CREATE_CORS_ORIGIN,
	SCOPE_DELETE_CORS_ORIGIN,
	SCOPE_READ_ENUM,
	SCOPE_CREATE_ENUM,
	SCOPE_UPDATE_ENUM,
	SCOPE_DELETE_ENUM,
	SCOPE_READ_ENVIRONMENT,
	SCOPE_UPDATE_ENVIRONMENT,
	SCOPE_READ_FORM_SUBMISSION,
	SCOPE_UPDATE_FORM_SUBMISSION,
	SCOPE_DELETE_FORM_SUBMISSION,
	SCOPE_READ_ORDER,
	SCOPE_READ_PROJECT,
	SCOPE_UPDATE_PROJECT,
	SCOPE_DELETE_PROJECT,
	// SCOPE_CREATE_SHIPMENT,
	// SCOPE_CREATE_UPLOAD,
	SCOPE_CREATE_WEBHOOK,
	SCOPE_READ_WEBHOOK,
	SCOPE_UPDATE_WEBHOOK,
	SCOPE_DELETE_WEBHOOK,
]);

export const FILTER_BOOLEAN = 'boolean';
export const FILTER_DATE = 'date';
export const FILTER_DATETIME = 'datetime';
export const FILTER_INTEGER = 'integer';
export const FILTER_ENUM = 'enum';
export const FILTER_STRING = 'string';

export const LS_LAST_TEAM_KEY = 'pl:lastteam';

export const INVENTORY_DELTA = 'DELTA';
export const INVENTORY_SET = 'SET';

// Errors codes
export const ERROR_RESOURCE_UNAVAILABLE = 'RESOURCE_UNAVAILABLE';

// Metadata key used on attributes to define the extension ID
export const ATTR_EXTENSION_KEY = 'pl_extension';
export const BLUEPRINT_VIEW_METADATA_KEY = 'pl_view';
export const METADATA_ATTR_HELP_TEXT = 'pl_helptext';

export enum CollectionMetadataKeys {
	Title = 'pl_title',
	PinnedAttributes = 'pl_pinned',
	FormNotifications = 'pl_form_notify'
}

export enum AttributeMetadataKeys {
	HelpText = 'pl_helptext',
}

export const VIEW_INBOX = 'inbox';

export const WEBHOOK_STATUS_SUCCESS = 'SUCCESS';
export const WEBHOOK_STATUS_FAILED = 'FAILED';

export const DELETE_MODE_PREVENT = 'PREVENT';
export const DELETE_MODE_CASCADE = 'CASCADE';
