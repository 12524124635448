import { useFormik } from 'formik';
import React from 'react';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { Button } from '@platformapp/ui';
import { updateApiKey } from './mutations';
import { CheckoutScopeRow } from './CheckoutScopeRow';
import { InventoryScopeRow } from './InventoryScopeRow';
import toast from 'react-hot-toast';

export const EcommercePermissions = ({ apiKey, disabled }) => {
	const form = useFormik({
		initialValues: {
			scope: apiKey ? apiKey.scope : [],
		},
		onSubmit: async (values, { resetForm }) => {
			await updateApiKey(apiKey.id, {
				scope: values.scope,
			});
			resetForm({
				values,
			});
			toast.success('Permissions saved');
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset disabled={disabled}>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Ecommerce permissions</p>
					<p className="mb-8">
						{/* These permissions allow API keys to access and manage resources within an environment, like collections and webhooks. */}
					</p>
					<div className="border border-gray-300 rounded">
						<CheckoutScopeRow
							selectedScope={form.values.scope}
							onChange={(val) => form.setFieldValue('scope', val)}
						/>
						<InventoryScopeRow
							selectedScope={form.values.scope}
							onChange={(val) => form.setFieldValue('scope', val)}
						/>
					</div>
				</FieldsetContent>
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
