import React, { useState, useContext } from 'react';
import { ComponentList } from './ComponentList';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import COMPONENTS_QUERY from './ListComponents.gql';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { useEnvironmentTitle } from '../hooks/useTitle';
import Helpers from '../Helpers';
import { useAsyncEffect } from 'use-async-effect';

export const ComponentListContainerComponent = () => {
	useEnvironmentTitle(Helpers.t('components.components'));
	const { environment } = useContext(EnvironmentContext);

	const [result, setResult] = useState({
		isLoading: true,
	});

	useAsyncEffect(
		async (isActive) => {
			setResult({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const res = await graphql({
					query: COMPONENTS_QUERY,
					variables: {
						envId: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setResult({
					isLoading: false,
					...res.data.data.environment.components,
				});
			}
		},
		[environment.isLoading]
	);

	return <ComponentList result={result} />;
};

export const ComponentListContainer = withErrorBoundary(
	ComponentListContainerComponent
);
