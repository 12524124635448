import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { withErrorBoundary } from '../common/ErrorBoundary';
import GET_USER_QUERY from './GetUser.gql';
import { Heading } from '../common/Heading';
import { UserContext } from '../context/UserContext';
import { NameSetting } from './NameSetting';
import { EmailSetting } from './EmailSetting';
import { ResetPasswordSetting } from './ResetPasswordSetting';
import { DeleteSetting } from './DeleteSetting';
import { useTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';

const GeneralSettingsComponent = () => {
	useTitle(['General settings', 'Account']);
	const { setUser } = useContext(UserContext);

	const [result, setResult] = useState({
		isLoading: true,
	});

	const handleUserUpdate = (data) => {
		setUser(data);
		setResult({
			isLoading: false,
			viewer: data,
		});
	};

	// Fetch user
	useAsyncEffect(async (isActive) => {
		const { data } = await graphql({
			query: GET_USER_QUERY,
		});
		if (!isActive()) {
			return;
		}
		setResult({
			isLoading: false,
			...data.data,
		});
	}, []);

	return (
		<div className="lg:mr-48">
			<Heading className="mb-6">General settings</Heading>

			<NameSetting onChange={handleUserUpdate} user={result.viewer} />
			<EmailSetting onChange={handleUserUpdate} user={result.viewer} />
			<ResetPasswordSetting user={result.viewer} />
			<DeleteSetting user={result.viewer} />
		</div>
	);
};

export const GeneralSettings = withErrorBoundary(GeneralSettingsComponent);
