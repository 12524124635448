import React, { useContext } from 'react';
import {
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_COMPONENT,
} from '../constants';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
import { getAttributeDefaultValue } from '../util/AttributeHelpers';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { withErrorBoundary } from '../common/ErrorBoundary';

export const AttributePreview = withErrorBoundary(({ attribute, values }) => {
	const { components, enumerationById, collectionById } = useContext(
		EnvironmentContext
	);

	const baseAttribute = {
		id: 'fake_id',
		...values,
		...attribute,
	};
	const defaultValue = getAttributeDefaultValue(baseAttribute);

	switch (attribute.__typename) {
		case ATTRIBUTE_TYPENAME_COMPONENT: {
			if (values.allowedComponents.length === 0) {
				return <p>Select one or more components for the preview to show.</p>;
			}
			baseAttribute.allowedComponents = components.filter((c) =>
				values.allowedComponents.includes(c.id)
			);
			break;
		}
		case ATTRIBUTE_TYPENAME_ENUM: {
			if (!values.enumerationId) {
				return <p>Select an enumeration for the preview to show.</p>;
			}
			const enumNode = enumerationById(values.enumerationId);
			baseAttribute.enumeration = enumNode.node;
			break;
		}
		case ATTRIBUTE_TYPENAME_BIREF:
		case ATTRIBUTE_TYPENAME_UNIREF: {
			if (!values.referencedCollection) {
				return <p>Select a collection for the preview to show.</p>;
			}
			const coll = collectionById(values.referencedCollection);
			baseAttribute.referencedCollection = coll.node;
			break;
		}
	}

	return (
		<AttributeEditor
			attributes={[baseAttribute]}
			values={{
				[values.apiId]: defaultValue,
			}}
			preventAutofocus
			isNew
		/>
	);
});
