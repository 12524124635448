// @ts-ignore
import { Button } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import { Fieldset, FieldsetContent, FieldsetFooter } from '../common/Fieldset';
import { updateSettings } from './mutations';
import toast from 'react-hot-toast';
import { EmailList } from '../common/EmailList';

type OrderNotifcationsSettingProps = {
	settings: any;
	onChange: (data: any) => void;
}

export const OrderNotifcationsSetting = ({ settings, onChange }: OrderNotifcationsSettingProps) => {
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			orderNotificationEmails: pathOr([], ['orderNotificationEmails'], settings.data.ecommerceSettings),
		},
		onSubmit: async (values, { setErrors }) => {
			try {
				const res = await updateSettings(settings.data.id, values);
				toast.success('Order notifications updated');
				onChange(res);
			} catch (err) {
				if (err.isUserError) {
					setErrors(err.userErrors);
				} else {
					console.error(err);
					toast.error('Failed to update order notifications.');
				}
			}
		},
	});
	return (
		<form onSubmit={form.handleSubmit}>
			<Fieldset>
				<FieldsetContent>
					<p className="font-medium text-lg mb-4">Order notifications</p>
					<p className="mb-4">
						These email addresses will receive notifications every time a new order is placed.
					</p>
					<EmailList
						readOnly={settings.isLoading || form.isSubmitting}
						// @ts-ignore
						error={extractGraphqlError(['orderNotificationEmails'], form.errors, true)}
						onChange={val => form.setFieldValue('orderNotificationEmails', val)}
						value={form.values.orderNotificationEmails}
					/>
				</FieldsetContent>
				{/* @ts-ignore */}
				<FieldsetFooter>
					<Button
						disabled={!form.dirty}
						loading={form.isSubmitting}
						submit
						width="large"
					>
						Save
					</Button>
				</FieldsetFooter>
			</Fieldset>
		</form>
	);
};
