import React from 'react';
import { NewContextMenu } from '../dropdown/ContextMenu';
import Helpers from '../Helpers';

export const SubmissionContextMenu = ({
	deleteSubmission,
	archiveSubmission,
	submission,
	unarchiveSubmission,
	flagSpam,
	flagNotSpam,
}) => (
	<NewContextMenu
		sections={[
			{
				items: [
					{
						label: submission.isSpam ? 'Mark as not spam' : 'Mark as spam',
						onAction: () => (submission.isSpam ? flagNotSpam() : flagSpam()),
					},
				],
			},
			{
				items: [
					{
						label: submission.isArchived
							? Helpers.t('general.unarchive')
							: Helpers.t('general.archive'),
						onAction: () =>
							submission.isArchived
								? unarchiveSubmission()
								: archiveSubmission(),
						destructive: !submission.isArchived,
					},
					{
						label: Helpers.t('general.delete'),
						onAction: () => deleteSubmission(),
						destructive: true,
					},
				],
			},
		]}
	/>
);
