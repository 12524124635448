import { useContext } from 'react';
import { FormSubmissionsList } from './FormSubmissionsList';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Route, useParams } from 'react-router-dom';
import { NotFound } from '../NotFound';
import { FormsSidebar } from './FormsSidebar';
import { MainContentContainer, MainContentNav } from '../common/MainContent';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { Settings } from './Settings';

type Params = {
	formId: string;
}

export const Form = () => {
	useEnvironmentTitle('Forms');
	const { formId } = useParams<Params>();
	const { collectionByApiId } = useContext(EnvironmentContext);
	const blueprint = collectionByApiId(formId);

	if (!blueprint.isLoading && !blueprint.node) {
		return (
			<NotFound
				header="Form not found"
				body={<p>No such form blueprint: {formId}</p>}
			/>
		);
	}

	return (
		<MainContentContainer>
			<MainContentNav>
				<FormsSidebar />
			</MainContentNav>
			<div className="flex-grow h-screen">
				<Route
					path={[
						"/:teamSlug/:projectId/forms/:formId",
						"/:teamSlug/:projectId/env/:envSlug/forms/:formId",
					]}
					exact
				>
					<FormSubmissionsList blueprint={blueprint} />
				</Route>
				<Route
					path={[
						"/:teamSlug/:projectId/forms/:formId/settings",
						"/:teamSlug/:projectId/env/:envSlug/forms/:formId/settings",
					]}
					exact
				>
					<Settings collection={blueprint} />
				</Route>
			</div>
		</MainContentContainer>
	);
};
