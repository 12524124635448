import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Button, SelectField, TextField } from '@platformapp/ui';
import { graphql } from '../api';
import { Heading } from '../common/Heading';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ProjectContext } from '../context/ProjectContext';
import { extractGraphqlError } from '../util';
import ACTIVATE_ECOMMERCE from './ActivateEcommerce.gql';

export const EcommerceOnboard = () => {
	const { activeProject, updateProject, generateProjectPath } = useContext(
		ProjectContext
	);
	const { refresh } = useContext(EnvironmentContext);
	const history = useHistory();
	const form = useFormik({
		initialValues: {
			baseCurrency: null,
			storeName: '',
			supportEmail: '',
		},
		validateOnBlur: false,
		validateOnChange: false,
		validate: (values) => {
			let errors = [];

			if (isEmpty(values.storeName)) {
				errors.push({
					path: ['input', 'storeName'],
					message: 'Store name is required',
				});
			}

			if (isEmpty(values.supportEmail)) {
				errors.push({
					path: ['input', 'supportEmail'],
					message: 'Support email is required',
				});
			}

			if (!values.baseCurrency) {
				errors.push({
					path: ['input', 'baseCurrency'],
					message: 'Base currency is required',
				});
			}

			return errors;
		},
		onSubmit: async (values, { setErrors }) => {
			const res = await graphql({
				query: ACTIVATE_ECOMMERCE,
				variables: {
					input: {
						...values,
						projectId: activeProject.id,
					},
				},
			});
			const payload = res.data.data.activateEcommerce;

			await refresh();

			if (payload.userErrors.length > 0) {
				setErrors(payload.userErrors);
			} else {
				updateProject(payload.project);
				history.replace(generateProjectPath('products'));
			}
		},
	});

	if (activeProject && activeProject.ecommerceEnabled) {
		return <Redirect to={generateProjectPath('products')} />;
	}

	return (
		<div className="mx-5 md:mx-10 mt-32 pb-10">
			<Heading className="mb-6">Ecommerce setup</Heading>
			<form onSubmit={form.handleSubmit}>
				<TextField
					autoFocus
					className="w-96"
					helpText="This will be shown on checkout pages and in emails to customers."
					label="Store name"
					name="storeName"
					onChange={form.handleChange}
					value={form.values.storeName}
					disabled={form.isSubmitting || !activeProject}
					error={extractGraphqlError('storeName', form.errors)}
				/>
				<TextField
					className="w-96"
					helpText="An email address where customers can get support for their orders."
					label="Support email"
					name="supportEmail"
					onChange={form.handleChange}
					value={form.values.supportEmail}
					disabled={form.isSubmitting || !activeProject}
					error={extractGraphqlError('supportEmail', form.errors)}
				/>
				<SelectField
					className="md:w-32"
					name="baseCurrency"
					label="Base currency"
					onChange={form.handleChange}
					disabled={form.isSubmitting || !activeProject}
					error={extractGraphqlError('baseCurrency', form.errors)}
					options={[
						{
							label: '',
							value: '',
						},
						{
							label: 'GBP',
							value: 'GBP',
						},
						{
							label: 'USD',
							value: 'USD',
						},
					]}
					value={form.values.baseCurrency}
				/>
				<Button
					disabled={form.isSubmitting || !activeProject}
					primary
					type="submit"
				>
					Activate ecommerce
				</Button>
			</form>
		</div>
	);
};
