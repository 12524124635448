import React, { useState, useEffect, useContext } from 'react';
import { Route, NavLink, useParams } from 'react-router-dom';
import { BackArrow } from '../common/BackArrow';
import { graphql } from '../api';
import GET_MODEL from './GetModel.gql';
import { BlueprintEditorContainer } from './BlueprintEditorContainer';
import { Settings } from '../blueprint-settings/Settings';
import { mergeDeepRight } from 'ramda';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { NotFound } from '../NotFound';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Heading } from '../common/Heading';
import { Badge } from '@platformapp/ui';
import { StickyHeader } from '../common/StickyHeader';
import { HeadingLoader } from '../common/Skeleton';

export const Blueprint = withErrorBoundary(() => {
	const { blueprintId } = useParams();
	const { generateEnvPath, refresh } = useContext(EnvironmentContext);

	const [model, setModel] = useState({
		isLoading: true,
		data: {},
	});

	useEffect(() => {
		graphql({
			query: GET_MODEL,
			variables: {
				id: blueprintId,
			},
		}).then((res) => {
			setModel({
				isLoading: false,
				data: res.data.data.model,
			});
		});
	}, []);

	const handleChange = (data) => {
		setModel({
			...model,
			data: mergeDeepRight(model.data, data),
		});
		refresh();
	};

	if (!model.isLoading && !model.data) {
		return (
			<NotFound
				header="Blueprint not found"
				body={<p>No such blueprint: {blueprintId}</p>}
			/>
		);
	}

	const isComponent = model.data.__typename === 'Component';

	return (
		<div className="flex flex-col h-screen pt-34 mx-5 md:mx-10 md:pr-10">
			<StickyHeader bordered={true}>
				<div>
					<BackArrow
						to={generateEnvPath(
							`schema/${isComponent ? 'components' : 'collections'}`
						)}
						label={isComponent ? 'Components' : 'Collections'}
					/>
				</div>
				<div className="md:flex items-bottom">
					<div className="flex flex-grow items-center mb-4 mt-1">
						<Heading truncate>
							{model.isLoading ? (
								<HeadingLoader className="w-40" />
							) : (
								model.data.name
							)}
						</Heading>
						{!model.isLoading && (
							<Badge className="ml-4" text={model.data.__typename} />
						)}
					</div>
					<div className="flex self-end">
						<NavLink
							className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
							activeClassName="text-gray-800 border-b border-gray-800"
							to={generateEnvPath(`blueprints/${blueprintId}`)}
							exact
						>
							Attributes
						</NavLink>
						<NavLink
							className="block -m-px text-base font-medium text-gray-600 px-3 py-3 mr-2 last:mr-0 hover:bg-gray-100 hover:border-b border-gray-300"
							activeClassName="text-gray-800 border-b border-gray-800"
							to={generateEnvPath(`blueprints/${blueprintId}/settings`)}
						>
							Settings
						</NavLink>
					</div>
				</div>
			</StickyHeader>
			<Route
				exact
				path={[
					'/:teamSlug/:projectId/blueprints/:blueprintId',
					'/:teamSlug/:projectId/env/:env/blueprints/:blueprintId',
				]}
				render={() => (
					<BlueprintEditorContainer blueprint={model} onChange={handleChange} />
				)}
			/>
			<Route
				path={[
					'/:teamSlug/:projectId/blueprints/:blueprintId/settings',
					'/:teamSlug/:projectId/env/:envSlug/blueprints/:blueprintId/settings',
				]}
				render={() => <Settings blueprint={model} onChange={handleChange} />}
			/>
		</div>
	);
});
