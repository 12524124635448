import React from 'react';
import Helpers from '../Helpers';
import { set, lensProp } from 'ramda';
import { Calendar, RadioFields } from '@platformapp/ui';
import { dateToIsoDateString } from '../util';

const OPERATORS = Object.freeze(['eq', 'gte', 'gt', 'lte', 'lt']);

export const DateFilterEditor = ({ filter, onChange }) => {
	const handleOperatorChange = (value) =>
		onChange(set(lensProp('operator'), value, filter));

	const handleDateChange = (value) => {
		const formattedDate =
			value !== undefined ? dateToIsoDateString(value) : value;
		onChange(set(lensProp('value'), formattedDate, filter));
	};

	const selectedDate = filter.value == null ? '' : new Date(filter.value);

	return (
		<>
			<RadioFields
				onChange={handleOperatorChange}
				options={OPERATORS.map((value) => ({
					label: Helpers.t(`filters.date.${value}`),
					value,
				}))}
				value={filter.operator}
			/>
			<Calendar
				value={selectedDate}
				onChange={(value) => handleDateChange(value)}
				className="mt-2 p-2 border rounded"
			/>
		</>
	);
};
