import { append, isEmpty, isNil } from 'ramda';
import { useContext, useEffect } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { ProjectContext } from '../context/ProjectContext';
import { TeamContext } from '../context/TeamContext';

const DEFAULT_TITLE = 'Platform';

const makeTitle = (title) => {
	if (Array.isArray(title)) {
		return append(DEFAULT_TITLE, title).join(' - ');
	}
	return makeTitle([title]);
};

export const useTitle = (title) => {
	useEffect(() => {
		document.title =
			isNil(title) || isEmpty(title) ? DEFAULT_TITLE : makeTitle(title);
	}, [title]);
};

export const useEnvironmentTitle = (title) => {
	const { activeProject } = useContext(ProjectContext);
	const { environment } = useContext(EnvironmentContext);

	const titleParts =
		activeProject && environment.node && !isNil(title)
			? [title, activeProject.name, environment.node.name]
			: null;

	return useTitle(titleParts);
};

export const useTeamTitle = (title) => {
	const { activeTeam } = useContext(TeamContext);

	const titleParts = activeTeam ? [title, activeTeam.name] : null;

	return useTitle(titleParts);
};
