import { useContext, useState } from 'react';
import { AttributeEditor } from '../attribute-editor/AttributeEditor';
// @ts-ignore
import { Button } from '@platformapp/ui';
import { useHistory } from 'react-router-dom';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { Module } from '../common/Module';
import { SingleVariantProductForm } from '../product-variants/SingleVariantProductForm';
import { ExistingVariantTable } from './ExistingVariantTable';
import { EditVariantLayer } from '../product-variants/EditVariantLayer';
import { initialVariantValues } from '../util/AttributeHelpers';
import { EditItemHeader } from '../publishable/EditItemHeader';
import { EDITOR_MODE_EDIT, STATUS_CHANGED, STATUS_DRAFT } from '../constants';
import { getProductStatus } from '../util/publishing';
import { DeleteItemModal } from '../publishable/DeleteItemModal';
import { ItemSidebar } from '../publishable/ItemSidebar';
import { Attribute } from '../types/Attribute';
import { Product, ProductDispatch } from './ProductEditReducer';
import toast from 'react-hot-toast';

type EditProductLayer = {
	collection: any;
	form: any;
	productDispatch: ProductDispatch;
	onUnpublish: any;
	product: Product;
}

export const EditProductLayer = ({
	collection,
	form,
	onUnpublish,
	product,
	productDispatch
}: EditProductLayer) => {
	const { baseCurrency, collectionByApiId, generateEnvPath } = useContext(
		EnvironmentContext
	);
	const history = useHistory();
	const [isAddingVariant, setIsAddingVariant] = useState(false);
	const [confirmingDelete, setConfirmingDelete] = useState(false);

	const status = getProductStatus(
		product.inStages,
		product.variants.map((v: any) => v.inStages)
	);

	return (
		<>
			{isAddingVariant && (
				<EditVariantLayer
					initialValues={initialVariantValues(
						collectionByApiId('ProductVariant').node,
						baseCurrency
					)}
					isLoading={false}
					onCancel={() => setIsAddingVariant(false)}
					onSave={(variant: any) => {
						setIsAddingVariant(false);
						productDispatch({
							type: 'add_variant',
							data: variant
						})
					}}
					product={form.values}
					productId={form.values.id}
					productOptions={form.values.options}
				/>
			)}

			{confirmingDelete && (
				<DeleteItemModal
					collection={collection.node}
					items={[
						{
							...form.values,
							__title: form.values.name,
						},
					]}
					onCancel={() => setConfirmingDelete(false)}
					onDelete={() => {
						toast.success('Product deleted');
						history.push(generateEnvPath('products'));
					}}
				/>
			)}

			<EditItemHeader
				canPublish={
					status === STATUS_DRAFT || status === STATUS_CHANGED || form.dirty
				}
				collectionName={collection.node.name}
				isSaving={form.isSubmitting}
				isDirty={form.dirty}
				onSaveClick={() => form.submitForm()}
				onPublishClick={() => {
					form.setStatus({ action: 'publish' });
					form.submitForm();
				}}
				onUnpublishClick={() => onUnpublish()}
				onDeleteClick={() => setConfirmingDelete(true)}
				title={form.values.name}
				status={status}
			/>

			<div className="fade-in my-8 md:flex gap-14">
				<div className="flex-grow">
					<Module bottomMargin heading="Product information">
						<AttributeEditor
							attributes={collection.node.attributes.filter(
								(a: Attribute) => a.isEditable && !['options', 'variants'].includes(a.apiId)
							)}
							onAttributeChange={(apiId: string, val: any) => form.setFieldValue(apiId, val)}
							pathPrefix={['input']}
							validationErrors={form.errors}
							values={form.values}
							readOnly={form.isSubmitting}
							isNew={false}
							initialValues={product}
							preventAutofocus
						/>
					</Module>

					{form.values.options.length === 0 && (
						<SingleVariantProductForm
							form={form}
							mode={EDITOR_MODE_EDIT}
							productDispatch={productDispatch}
							readOnly={form.isSubmitting}
							variant={product.variants[0]}
						/>
					)}

					{form.values.options.length > 0 && (
						<Module
							bottomMargin
							heading="Variants"
							subheading="Sell different variants of the same product using options, like size and style for clothes."
							actions={
								<Button
									onClick={() => setIsAddingVariant(true)}
									disabled={form.isSubmitting}
								>
									Add variant
								</Button>
							}
						>
							<div className="overflow-x-scroll md:overflow-x-visible">
								<ExistingVariantTable
									product={product}
									readOnly={form.isSubmitting}
									onDelete={(ids: string[]) => productDispatch({
										type: 'delete_variant',
										ids
									})}
									onUpdate={variant => productDispatch({
										type: 'update_variant',
										data: variant
									})}
									onInventoryUpdate={items => productDispatch({
										type: 'inventory_update',
										data: items
									})}
								/>
							</div>
						</Module>
					)}
				</div>
				<div className="mt-6 pb-5 md:mt-0 md:w-80 md:sticky md:top-24 self-start flex-shrink-0">
					<ItemSidebar
						item={product}
					/>
				</div>
			</div>
		</>
	);
};