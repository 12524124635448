import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { graphql } from '../api';
import {
	Button,
	ButtonGroup,
	Modal,
	ModalContent,
	ModalFooter,
	SelectField,
} from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { PermissionSelector, TYPE_READ } from './PermissionSelector';
import ADD_PERMISSION_MUTATION from './AddApiKeyPermission.gql';
import toast from 'react-hot-toast';

const AddPermissionModalComponent = ({ apiKeyId, onCancel, onConfirm }) => {
	const { collections, collectionById } = useContext(EnvironmentContext);

	const form = useFormik({
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			collectionId: 'all',
			stages: [],
			type: '',
		},
		onSubmit: async (values, { setErrors, setSubmitting }) => {
			const res = await graphql({
				query: ADD_PERMISSION_MUTATION,
				variables: {
					input: {
						[values.type]: {
							apiKeyId,
							collectionId:
								values.collectionId === 'all' ? null : values.collectionId,
							...(values.type === TYPE_READ
								? {
										stages: values.stages,
								  }
								: {}),
						},
					},
				},
			});

			if (res.data.data.addApiKeyPermission.userErrors.length > 0) {
				setErrors(res.data.data.addApiKeyPermission.userErrors);
				setSubmitting(false);
			} else if (res.data.data.addApiKeyPermission.errors) {
				toast.error('Failed to add permission');
				setSubmitting(false);
			} else {
				onConfirm(res.data.data.addApiKeyPermission.permission);
			}
		},
	});

	const chosenCollection = form.values.collectionId
		? collectionById(form.values.collectionId).node
		: null;

	return (
		<Modal
			title="Add permission"
			tiny
			isOpen
			onRequestClose={() => onCancel()}
			shouldCloseOnEsc={!form.isSubmitting}
			shouldCloseOnOverlayClick={!form.isSubmitting}
		>
			<form onSubmit={form.handleSubmit}>
				<ModalContent>
					<SelectField
						name="collectionId"
						label="Collection"
						onChange={form.handleChange}
						disabled={form.isSubmitting}
						error={extractGraphqlError('collectionId', form.errors)}
						options={[
							{
								label: 'All collections',
								value: 'all',
							},
							...collections.map((coll) => ({
								label: coll.name,
								value: coll.id,
							})),
						]}
						value={form.values.collectionId}
					/>
					<PermissionSelector
						disabled={form.isSubmitting}
						isPublishable={
							chosenCollection ? chosenCollection.isPublishable : true
						}
						onChange={(val) =>
							form.setValues({
								...form.values,
								...val,
							})
						}
						value={form.values}
					/>
				</ModalContent>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} disabled={form.isSubmitting}>
							Cancel
						</Button>
						<Button
							submit
							primary
							loading={form.isSubmitting}
							disabled={!form.dirty}
						>
							Add permission
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export const AddPermissionModal = withErrorBoundary(
	AddPermissionModalComponent
);
