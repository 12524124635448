import { head } from 'ramda';
import React from 'react';
import { Badge, DateTime, TextStyle } from '@platformapp/ui';
import Helpers from '../Helpers';
import { DATE_REFRESH } from '../constants';

const Preview = ({ attributes, node }) => {
	const firstAttrKey = head(attributes.filter((a) => !a.isSystem)).apiId;
	const data = node[firstAttrKey];
	if (data) {
		return <p className="leading-normal truncate">{data}</p>;
	}
	return null;
};

const BASE_CLASSES =
	'block cursor-pointer hover:bg-gray-100 border-b border-gray-300 transition-colors duration-100';

export const FormSubmission = ({
	submission: sub,
	attributes,
	onClick,
	selected,
}) => (
	<div
		className={`${BASE_CLASSES} ${selected ? 'sm:bg-gray-100' : ''}`}
		role="menuitem"
		onClick={onClick}
	>
		<div
			className={`px-4 sm:px-3 py-3 border-l-3 ${
				selected ? 'border-blue-700' : 'border-transparent'
			}`}
		>
			<Preview attributes={attributes} node={sub.node} />
			{attributes.length === 0 && (
				<p>
					<TextStyle subdued>No data available</TextStyle>
				</p>
			)}
			<div className="flex items-center mt-2">
				<p className="leading-normal flex-grow text-sm">
					<TextStyle subdued>
						<DateTime value={sub.node.createdAt} refresh={DATE_REFRESH} />
					</TextStyle>
				</p>
				<div className="flex-shrink-0">
					{sub.node.isArchived && (
						<Badge className="mr-2" text={Helpers.t('publishing.archived')} />
					)}
					{sub.node.isSpam && <Badge className="mr-2" warning text="Spam" />}
				</div>
			</div>
		</div>
	</div>
);
