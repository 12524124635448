import React, { useState, useContext } from 'react';
import { graphql } from '../api';
import { prepend } from 'ramda';
import { Badge, Button, DateTime } from '@platformapp/ui';
import { Link } from '../common/Link';
import { EditWebhookModal } from './EditWebhookModal';
import { EmptyMessage } from '../common/EmptyMessage';
import LIST_WEBHOOKS_QUERY from './listHooks.gql';
import { ListPageHeader } from '../common/ListPageHeader';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { TableSkeleton } from '../common/Skeleton';
import { useEnvironmentTitle } from '../hooks/useTitle';
import { useAsyncEffect } from 'use-async-effect';
import { ResourceTable } from '../common/ResourceTable';

const Table = ({ webhooks }) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	return (
		<ResourceTable
			data={webhooks}
			idSource={['node', 'id']}
			rowLink={({ node }) => generateEnvPath(`webhooks/${node.id}`)}
			columns={[
				{
					title: 'Name',
					link: true,
					render: ({ node }) => (
						<Link to={generateEnvPath(`webhooks/${node.id}`)}>{node.name}</Link>
					),
				},
				{
					title: 'Created',
					link: true,
					render: ({ node }) => <DateTime value={node.createdAt} />,
				},
				{
					title: 'URL',
					link: true,
					render: ({ node }) => node.url,
				},
				{
					title: 'Status',
					link: true,
					render: ({ node }) => (
						<Badge
							positive={node.isEnabled}
							text={node.isEnabled ? 'Enabled' : 'Disabled'}
						/>
					),
				},
			]}
		/>
	);
};

export const WebhookList = () => {
	useEnvironmentTitle('Webhooks');
	const { environment } = useContext(EnvironmentContext);

	const [hooks, setHooks] = useState({
		isLoading: true,
		data: [],
	});
	const [isAddingHook, setIsAddingHook] = useState(false);

	const handleHookCreated = (hook) => {
		setHooks({
			...hooks,
			data: prepend({ node: hook }, hooks.data),
		});
		setIsAddingHook(false);
	};

	useAsyncEffect(
		async (isActive) => {
			setHooks({
				isLoading: true,
			});
			if (!environment.isLoading) {
				const res = await graphql({
					query: LIST_WEBHOOKS_QUERY,
					variables: {
						id: environment.node.id,
					},
				});
				if (!isActive()) {
					return;
				}
				setHooks({
					isLoading: false,
					data: res.data.data.environment.webhooks.edges,
				});
			}
		},
		[environment.isLoading]
	);

	return (
		<>
			{isAddingHook && (
				<EditWebhookModal
					onCancel={() => setIsAddingHook(false)}
					onConfirm={handleHookCreated}
				/>
			)}

			<ListPageHeader
				title="Webhooks"
				right={
					<Button
						disabled={hooks.isLoading}
						onClick={() => setIsAddingHook(true)}
						primary
					>
						Create webhook
					</Button>
				}
			/>

			<div className="mt-5">
				{hooks.isLoading && <TableSkeleton />}

				{!hooks.isLoading && hooks.data.length === 0 && (
					<EmptyMessage
						title="You don't have any webhooks"
						message="Webhooks allow external services to be triggered based on events within your project."
					/>
				)}

				{!hooks.isLoading && hooks.data.length > 0 && (
					<div className="fade-in">
						<Table webhooks={hooks.data} />
					</div>
				)}
			</div>
		</>
	);
};
