import React, { useContext } from 'react';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const EnvironmentWarning = () => {
	const ctx = useContext(EnvironmentContext);
	if (!ctx || ctx.environment.isLoading || ctx.environment.node.isDefault) {
		return null;
	}
	return (
		<div className="flex justify-center border-t-2 border-orange-500 absolute z-50 top-0 right-0 left-0 pointer-events-none -mt-px">
			<p className="text-3xs leading-snug select-none tracking-wider font-medium text-white uppercase bg-orange-500 rounded-b px-2">
				{ctx.environment.node.name} environment
			</p>
		</div>
	);
};
