import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserMenu } from '../user/UserMenu';
import { ProjectContext } from '../context/ProjectContext';
import { TeamMenu } from './TeamMenu';

const TOP_NAV_CLASSNAMES =
	'flex items-center h-11 bg-gray-100 border-b border-gray-300 px-4 md:px-10';

export const TopNav = () => {
	const { activeProject, generateProjectPath } = useContext(ProjectContext);
	return (
		<div className={TOP_NAV_CLASSNAMES}>
			<div className="flex-grow min-w-0">
				<div className="flex items-center">
					<TeamMenu />
					<p className="text-base truncate">
						{activeProject && (
							<>
								<span className="text-gray-400 mx-3 select-none">/</span>
								<Link
									className="text-gray-900"
									to={generateProjectPath('content')}
								>
									{activeProject.name}
								</Link>
							</>
						)}
					</p>
				</div>
			</div>
			<div className="flex-shrink-0">
				<UserMenu />
			</div>
		</div>
	);
};

export const TopNav2 = ({ children }) => (
	<div className={TOP_NAV_CLASSNAMES}>
		<div className="flex-grow min-w-0">{children}</div>
		<div className="flex-shrink-0">
			<UserMenu />
		</div>
	</div>
);
