import { createContext, useEffect, useState } from 'react';
import {
	generatePath,
	useHistory,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
// @ts-ignore
import ENV_QUERY from './GetEnvironment.gql';
// @ts-ignore
import INSTALL_EXTENSION from './InstallExtension.gql';
// @ts-ignore
import UNINSTALL_EXTENSION from './UninstallExtension.gql';
import { graphql } from '../api';
import { append, filter, find, lensPath, over, pathOr, propEq, propOr } from 'ramda';
import api from '../api';
import { PageNotFound } from '../common/PageNotFound';
import { Attribute, BasicAttributeType } from '../types/Attribute';
import { getMetadataValue } from '../util/metadata';
import { Collection } from '../types/Collection';
import { Enumeration } from '../types/Enumeration';
import { Component } from '../types/Component';
import { CollectionMetadataKeys } from '../constants';
import { Extension } from '../types/Extension';
import { DEFAULT_ROUTE, NAMED_ROUTES, SWAPPABLE_ROUTES } from './routes';

type Environment = {
	contentEndpoint: string;
	id: string;
	extensionInstallations: ExtensionInstallationsConnection;
}

type ExtensionInstallationsConnection = {
	edges: ExtensionInstallationEdge[];
}

type ExtensionInstallationEdge = {
	node: ExtensionInstallation;
}

type ExtensionInstallation = {
	id: string;
	extension: Extension;
}

type Data = {
	collections?: Collection[];
	components?: Component[];
	enumerations?: Enumeration[];
	environment?: Environment;
	isLoading: boolean;
}

type EnvironmentContextProps = {
	baseCurrency?: any;
	collections: Collection[];
	components: Component[];
	contentApiRequest: (params: any, config?: any) => Promise<any>;
	contentApiRequest2: (params: any, config?: any) => Promise<any>;
	currencies: any[];
	enumerations: Enumeration[];
	environment: {
		isLoading: boolean;
		node: Environment;
	}
	envId: string;
	generateEnvPath: (path: string) => string;
	collectionById: (id: string) => {
		isLoading: boolean;
		node?: Collection;
	}
	collectionByApiId: (apiId: string) => {
		isLoading: boolean;
		node?: Collection;
	}
	componentByApiId: (apiId: string) => {
		isLoading: boolean;
		node?: Component;
	}
	enumerationById: (id: string) => {
		isLoading: boolean;
		node?: Enumeration;
	}
	refresh: () => Promise<void>;
	switchEnvironment: (envSlug: string) => void;
	installExtension: (extensionId: string) => Promise<boolean>;
	uninstallExtension: (extensionId: string) => Promise<boolean>;
}

export const EnvironmentContext = createContext<EnvironmentContextProps>(null);
export const EnvironmentConsumer = EnvironmentContext.Consumer;

const DATA_DEFAULT = Object.freeze({
	isLoading: true,
	isRefreshing: false,
	environment: null,
	collections: [],
	components: [],
	enumerations: [],
});

type EnvironmentProviderProps = {
	children: React.ReactNode;
}

type UrlParams = {
	envSlug?: string;
	projectId: string;
	teamSlug: string;
}

const getTitleAttributeApiId = (collection: Collection): string => {
	const title = getMetadataValue(collection.metadata, CollectionMetadataKeys.Title);

	if (!title) {
		return null;
	}

	const attr = collection.attributes.find((a: Attribute) => a.apiId === title);

	if (!attr) {
		return null;
	}

	// Ensure attribute is a valid type to be the title. Only short text attribute are supported.
	if (!attr.isList && attr.__typename === 'BasicAttribute' && attr.type === BasicAttributeType.Text) {
		return attr.apiId;
	}

	return null;
}

export const EnvironmentProvider = ({ children }: EnvironmentProviderProps) => {
	const { projectId, envSlug, teamSlug } = useParams<UrlParams>();
	const [data, setData] = useState<Data>(DATA_DEFAULT);
	const history = useHistory();
	const match = useRouteMatch();
	const namedRoute: any = propOr(null, match.path, NAMED_ROUTES);
	const switchLink = namedRoute ? SWAPPABLE_ROUTES[namedRoute] : DEFAULT_ROUTE;

	const fetchEnvironment = async (isRefetch = false) => {
		if (projectId) {
			if (!isRefetch) {
				setData({
					isLoading: true,
				});
			}
			const { data } = await graphql({
				query: ENV_QUERY,
				variables: {
					envSlug: envSlug || 'main',
					projectId,
				},
			});

			if (!data.data.node.environment) {
				setData({
					...DATA_DEFAULT,
					isLoading: false,
				});
			} else {
				const collections: Collection[] = data.data.node.environment.collections.edges.map(
					({ node }: any) => ({
						...node,
						isActive: true,
						titleApiId: getTitleAttributeApiId(node)
					})
				);
				const enumerations: Enumeration[] = data.data.node.environment.enumerations.edges.map(
					({ node }: any) => node
				);
				const components: Component[] = data.data.node.environment.components.edges.map(
					({ node }: any) => ({
						...node,
						isActive: true,
					})
				);
				setData({
					isLoading: false,
					environment: data.data.node.environment,
					collections: collections.map((coll: Collection): Collection => ({
						...coll,
						attributes: coll.attributes.map(attr => {
							if (attr.__typename == 'BidirectionalReferenceAttribute' || attr.__typename === 'UnidirectionalReferenceAttribute') {
								attr.referencedCollection = collections.find(c => c.id === attr.referencedCollection.id);
							} else if (attr.__typename === 'ComponentAttribute') {
								const ids = attr.allowedComponents.map(i => i.id);
								attr.allowedComponents = components.filter(c => ids.includes(c.id));
							} else if (attr.__typename === 'EnumerationAttribute') {
								attr.enumeration = enumerations.find(e => e.id === attr.enumeration.id);
							}
							return attr;
						})
					})),
					components,
					enumerations,
				});
			}
		}
	};

	useEffect(() => {
		(async () => fetchEnvironment())();
	}, [projectId, envSlug]);

	if (!data.isLoading && envSlug && !data.environment) {
		return <PageNotFound />;
	}

	const currencies = pathOr(
		[],
		['ecommerceSettings', 'currencies'],
		data.environment
	);

	return (
		<EnvironmentContext.Provider
			value={{
				baseCurrency: currencies.find((c) => c.isBase),
				collections: data.collections,
				components: data.components,
				contentApiRequest: (params: any, config: any) =>
					api.post(`content/${projectId}/${envSlug || 'main'}`, params, config),
				contentApiRequest2: async (params: any, config: any) => {
					const res = await api.post(
						`content/${projectId}/${envSlug || 'main'}`,
						params,
						config
					);
					return res.data;
				},
				currencies,
				enumerations: data.enumerations,
				environment: {
					isLoading: data.isLoading,
					node: data.environment,
				},
				envId: envSlug || 'main',
				generateEnvPath: (path = '') =>
					`/${teamSlug}/${projectId}/${
						envSlug ? `env/${envSlug}/` : ''
					}${path}`,
				collectionById: (id: string) => {
					if (data.isLoading) {
						return {
							isLoading: true,
						};
					}
					return {
						isLoading: false,
						node: find<Collection>(propEq('id', id), data.collections),
					};
				},
				collectionByApiId: (apiId: string) => {
					if (data.isLoading) {
						return {
							isLoading: true,
						};
					}
					return {
						isLoading: false,
						node: find<Collection>(propEq('apiId', apiId), data.collections),
					};
				},
				componentByApiId: (apiId: string) => {
					if (data.isLoading) {
						return {
							isLoading: true,
						};
					}
					return {
						isLoading: false,
						node: find<Component>(propEq('apiId', apiId), data.components),
					};
				},
				enumerationById: (id: string) => {
					if (data.isLoading) {
						return {
							isLoading: true,
						};
					}
					return {
						isLoading: false,
						node: find<Enumeration>(propEq('id', id), data.enumerations),
					};
				},
				refresh: () => fetchEnvironment(true),
				switchEnvironment: (envSlug: string) => {
					const newEnvPath = generatePath(
						switchLink[envSlug === 'main' ? 'default' : 'env'],
						{
							teamSlug: teamSlug,
							projectId: projectId,
							envSlug: envSlug,
						}
					);
					history.push(newEnvPath);
				},
				installExtension: async (extensionId: string) => {
					const res = await graphql({
						query: INSTALL_EXTENSION,
						variables: {
							input: {
								extensionId,
								environmentId: data.environment.id
							}
						}
					});
					const newData = over(
						lensPath(['environment', 'extensionInstallations', 'edges']),
						append({
							node: res.data.data.installExtension.extensionInstallation
						}),
						data
					);
					setData(newData);
					return true;
				},
				uninstallExtension: async (extensionId: string) => {
					const res = await graphql({
						query: UNINSTALL_EXTENSION,
						variables: {
							input: {
								extensionId,
								environmentId: data.environment.id
							}
						}
					});
					const newData = over(
						lensPath(['environment', 'extensionInstallations', 'edges']),
						filter((x: {node: {extension: Extension}}) => x.node.extension.id !== extensionId),
						data
					);
					setData(newData);
					return true;
				}
			}}
		>
			{children}
		</EnvironmentContext.Provider>
	);
};
