import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectCard } from '../projects/ProjectCard';
import { CardsLoader } from '../common/Skeleton';
import { EmptyMessage } from '../common/EmptyMessage';
import { ERROR_RESOURCE_UNAVAILABLE } from '../constants';
import { graphql } from '../api';
import LIST_PROJECTS from './GetTeamProjects.gql';
import { pathOr, propOr } from 'ramda';
import { useAsyncEffect } from 'use-async-effect';
// import { TeamContext } from "../context/TeamContext";

export const ProjectList = () => {
	const [projects, setProjects] = useState({
		isLoading: true,
		edges: [],
		errors: [],
	});
	const { teamSlug } = useParams();
	// const {canDo} = useContext(TeamContext);

	useAsyncEffect(
		async (isActive) => {
			setProjects({
				isLoading: true,
				edges: [],
				errors: [],
			});
			const res = await graphql({
				query: LIST_PROJECTS,
				variables: {
					slug: teamSlug,
				},
			});
			if (!isActive()) {
				return;
			}
			setProjects({
				isLoading: false,
				...pathOr(
					{
						edges: [],
					},
					['data', 'data', 'team', 'projects'],
					res
				),
				errors: propOr([], 'errors', res.data),
			});
		},
		[teamSlug]
	);

	const teamUnavailable = projects.errors.some(
		(err) =>
			pathOr(null, ['extensions', 'code'], err) === ERROR_RESOURCE_UNAVAILABLE
	);

	return (
		<>
			{projects.isLoading && <CardsLoader />}

			{!projects.isLoading && !teamUnavailable && projects.edges.length > 0 && (
				<div className="fade-in flex flex-wrap -mx-3">
					{projects.edges.map(({ node: project }) => (
						<ProjectCard
							key={project.id}
							project={project}
							to={`/${teamSlug}/${project.id}/content`}
						/>
					))}
				</div>
			)}

			{!projects.isLoading && !teamUnavailable && projects.edges.length === 0 && (
				<EmptyMessage
					title="This team does not have any projects"
					className="fade-in"
				>
					{/* {canDo(SCOPE_CREATE_PROJECT) && (
                        <Button
                            className="mt-6"
                            primary
                            // onClick={() => setIsCreating(true)}
                        >
                            Create a project
                        </Button>
                    )} */}
				</EmptyMessage>
			)}

			{teamUnavailable && (
				<EmptyMessage
					title="This team is not currently available"
					className="fade-in"
				/>
			)}
		</>
	);
};
