import React from 'react';
import { Sidebar, SidebarLink } from '../common/Sidebar';

export const SettingsSidebar = () => (
	<Sidebar title="Settings">
		<SidebarLink exact to="/account">
			General
		</SidebarLink>
		<SidebarLink exact to="/account/security">
			Security
		</SidebarLink>
	</Sidebar>
);
