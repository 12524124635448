import { P } from './Text';

type FieldsetProps = {
	danger?: boolean;
	disabled?: boolean;
	children: any;
}

export const Fieldset = ({ danger, disabled, children }: FieldsetProps) => (
	<div
		className={`rounded-md ${
			danger ? 'border-red-600' : 'border-gray-300'
		} border mb-8 relative`}
	>
		{disabled && (
			<div className="absolute left-0 top-0 right-0 bottom-0 bg-white opacity-50 -mx-1 -my-1 z-10" />
		)}
		{children}
	</div>
);

Fieldset.defaultProps = {
	danger: false,
	disabled: false,
};

export const FieldsetContent = ({ children }: any) => (
	<div className="px-6 py-8">{children}</div>
);

type FieldsetFooterProps = {
	children: any;
	className?: string;
	end?: Boolean;
}

export const FieldsetFooter = ({ children, className, end }: FieldsetFooterProps) => (
	<div
		className={`${className ? `${className} ` : ''} rounded-b-md ${
			end ? 'flex justify-end' : ''
		} bg-gray-100 border-t border-gray-300 py-2 px-6`}
	>
		{children}
	</div>
);

FieldsetFooter.defaultProps = {
	end: true,
};

type FieldsetHeader = {
	subtitle?: string;
	title: string;
}

export const FieldsetHeader = ({ subtitle, title }: FieldsetHeader) => {
	return (
		<div className="mb-4">
			<p className="font-medium text-lg mb-4">{title}</p>
			{subtitle && <P>{subtitle}</P>}
		</div>
	);
};
