import React, { useState } from 'react';
import {
	UNIQUE_ATTRIBUTE_TYPES,
	LIST_ATTRIBUTE_TYPES,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_BASIC,
	LIST_TYPENAMES,
} from '../constants';
import { CheckboxField, TextField } from '@platformapp/ui';
import { attributeNameToApiId, extractGraphqlError } from '../util';
import { set, lensProp, mergeDeepRight } from 'ramda';
import { EnumSelect } from './EnumSelect';
import { ReferenceAttributeSettings } from './ReferenceAttributeSettings';
import { ComponentAttributeSettings } from './ComponentAttributeSettings';

export const AttributeSettings = ({
	attribute,
	onUpdate,
	isNew,
	userErrors,
	blueprint,
	form,
}) => {
	const updateAttribute = (param, value) =>
		onUpdate(set(lensProp(param), value, attribute));

	const [autoUpdateApiId, setAutoUpdateApiId] = useState(isNew);

	const showUnique =
		UNIQUE_ATTRIBUTE_TYPES.includes(attribute.type) &&
		blueprint.__typename === 'Collection';
	const showList =
		(attribute.__typename === ATTRIBUTE_TYPENAME_BASIC &&
			LIST_ATTRIBUTE_TYPES.includes(attribute.type)) ||
		LIST_TYPENAMES.includes(attribute.__typename);

	return (
		<>
			<TextField
				name="name"
				label="Name"
				helpText="The label that will be shown above this attribute in the Dashboard."
				value={attribute.name}
				onChange={(e) => {
					const newData = {
						name: e.target.value,
					};
					if (autoUpdateApiId) {
						newData.apiId = attributeNameToApiId(e.target.value);
					}
					onUpdate(mergeDeepRight(attribute, newData));
				}}
				autoFocus
				error={extractGraphqlError('name', userErrors)}
				disabled={form.isSubmitting}
			/>
			<TextField
				name="apiId"
				label="API ID"
				helpText="This will be used in the Content API."
				value={attribute.apiId}
				onChange={(e) => {
					setAutoUpdateApiId(false);
					onUpdate(set(lensProp('apiId'), e.target.value, attribute));
				}}
				className="font-mono"
				error={extractGraphqlError('apiId', userErrors)}
				disabled={form.isSubmitting}
			/>
			<TextField
				name="helpText"
				label="Help text"
				helpText="Appears below the attribute form field to guide team members."
				value={form.values.helpText}
				onChange={form.handleChange}
				error={extractGraphqlError('helpText', userErrors)}
				disabled={form.isSubmitting}
			/>
			{attribute.__typename === ATTRIBUTE_TYPENAME_COMPONENT && (
				<ComponentAttributeSettings
					onChange={(values) => {
						onUpdate({
							...attribute,
							allowedComponents: values,
						});
					}}
					attribute={attribute}
					disabled={form.isSubmitting}
					error={extractGraphqlError('allowedComponents', userErrors)}
				/>
			)}
			{attribute.__typename === ATTRIBUTE_TYPENAME_ENUM && (
				<EnumSelect
					value={isNew ? attribute.enumerationId : attribute.enumeration.id}
					onChange={(value) => updateAttribute('enumerationId', value)}
					error={extractGraphqlError('enumerationId', userErrors)}
					disabled={!isNew || form.isSubmitting}
				/>
			)}
			{attribute.__typename === ATTRIBUTE_TYPENAME_UNIREF && (
				<ReferenceAttributeSettings
					attribute={attribute}
					isNew={isNew}
					onChange={(settings) => {
						onUpdate({
							...attribute,
							...settings,
						});
					}}
					userErrors={userErrors}
					disabled={form.isSubmitting}
				/>
			)}
			{showList && (
				<CheckboxField
					name="isList"
					label="This attribute is a list"
					helpText="Select this if you want to store more than one value."
					checked={attribute.isList}
					onChange={(checked) => {
						onUpdate({
							...attribute,
							isList: checked,
						});
					}}
					disabled={!isNew || attribute.isUnique || form.isSubmitting}
					error={extractGraphqlError('isList', userErrors)}
				/>
			)}
			<CheckboxField
				name="isRequired"
				label="This attribute is required"
				helpText={
					attribute.isList
						? "You won't be able to save an item if this field does not contain at least 1 value."
						: "You won't be able to save an item if this field is empty."
				}
				checked={attribute.isRequired}
				onChange={(checked) => updateAttribute('isRequired', checked)}
				disabled={form.isSubmitting}
				error={extractGraphqlError('isRequired', userErrors)}
			/>

			{showUnique && (
				<CheckboxField
					disabled={attribute.isList || form.isSubmitting}
					name="isUnique"
					label="This attribute is unique"
					helpText="You won't be able to save an item if there is an existing item with an identical value."
					checked={attribute.isUnique}
					onChange={(checked) => updateAttribute('isUnique', checked)}
					error={extractGraphqlError('isUnique', userErrors)}
				/>
			)}
		</>
	);
};
