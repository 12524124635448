import React, { useContext } from 'react';
import { Filters } from '../filters/Filters';
import { FILTER_ENUM } from '../constants';
import Helpers from '../Helpers';
import { stringify } from 'qs';
import { encodeFiltersForQs, filtersForAttributes, sortByLabel } from '../util';
import { useHistory } from 'react-router-dom';
import {
	ASSET_FILTER_IMAGE,
	ASSET_FILTER_DOCUMENT,
	ASSET_FILTER_AUDIO,
	ASSET_FILTER_VIDEO,
	ASSET_FILTER_OTHER,
} from '../constants';
import { concat } from 'ramda';
import { EnvironmentContext } from '../context/EnvironmentContext';

export const AssetFilters = ({ appliedFilters, assetBlueprint }) => {
	const history = useHistory();
	const { generateEnvPath } = useContext(EnvironmentContext);

	const filterChanged = (filters) => {
		// Find only filters with non-null values
		const actualFilters = filters.filter((filter) => {
			return filter.value !== null;
		});

		let newUrl = generateEnvPath('assets');

		if (actualFilters.length) {
			const encodedFilters = encodeFiltersForQs(actualFilters);
			newUrl +=
				'?' +
				stringify({
					filters: encodedFilters,
				});
		}

		history.push(newUrl);
	};

	const filterDefs = [
		{
			key: 'type',
			type: FILTER_ENUM,
			label: Helpers.t('asset.filters.type'),
			persistent: true,
			values: [
				{
					label: 'Any',
					value: '',
				},
				{
					label: Helpers.t('asset.type.image'),
					value: ASSET_FILTER_IMAGE,
				},
				{
					label: Helpers.t('asset.type.document'),
					value: ASSET_FILTER_DOCUMENT,
				},
				{
					label: Helpers.t('asset.type.audio'),
					value: ASSET_FILTER_AUDIO,
				},
				{
					label: Helpers.t('asset.type.video'),
					value: ASSET_FILTER_VIDEO,
				},
				{
					label: Helpers.t('asset.type.other'),
					value: ASSET_FILTER_OTHER,
				},
			],
		},
		// {
		// 	key: 'status',
		// 	type: FILTER_ENUM,
		// 	label: Helpers.t('general.status'),
		// 	persistent: true,
		// 	values: [
		//         {
		//             label: Helpers.t('publishing.filters.any'),
		//             value: ''
		//         },
		//         {
		//             label: Helpers.t('publishing.published'),
		//             value: STATUS_PUBLISHED
		//         },
		//         {
		//             label: Helpers.t('publishing.draft'),
		//             value: STATUS_DRAFT
		//         },
		//         {
		//             label: Helpers.t('publishing.changed'),
		//             value: STATUS_CHANGED
		//         },
		// 	]
		// },
	];

	const nonPersistentFilters = assetBlueprint.isLoading
		? []
		: filtersForAttributes(
				assetBlueprint.node.attributes.filter((a) => a.apiId !== 'uniqueId')
		  );

	return (
		<Filters
			definitions={concat(filterDefs, sortByLabel(nonPersistentFilters))}
			filters={appliedFilters}
			onChange={(filters) => filterChanged(filters)}
		/>
	);
};
