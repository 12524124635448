import {
	TYPE_ASSET,
	TYPE_MONEY,
	ATTRIBUTE_TYPENAME_BASIC,
	ATTRIBUTE_TYPENAME_COMPONENT,
	ATTRIBUTE_TYPENAME_UNIREF,
	ATTRIBUTE_TYPENAME_BIREF,
} from '../constants';
import { getPinnableAttributes } from '../util/AttributeHelpers';

const PAGE_INFO_FRAGMENT = `
	pageInfo {
		hasNextPage
		hasPreviousPage
		startCursor
		endCursor
	}
`;

const IN_STAGES_FRAGMENT = `
inStages (stages: [DRAFT, PUBLISHED]) {
	stage
	publishedAt
	updatedAt
}
`;

const IMAGE_FRAGMENT = `
	id
	filename
	mimeType
	thumbnailUrl: url(transforms: { width: 200, height: 200 })
	${IN_STAGES_FRAGMENT}
`;

export const generateCreateContentMutation = (collection, expand = false) => {
	return [
		`mutation CreateContent ($input: Create${collection.apiId}Input!) {`,
		`  result: create${collection.apiId} (input: $input) {`,
		`    userErrors {`,
		`      message`,
		`      path`,
		`    }`,
		`    node {`,
		`      id`,
		...(collection.titleApiId
			? [
					`  ... on ${collection.apiId} {`,
					`  __title: ${collection.titleApiId}`,
					'}',
			  ]
			: []),
		...(collection.isPublishable ? [IN_STAGES_FRAGMENT] : []),
		...(expand ? expandAttributes(collection.attributes) : []),
		collection.apiId === 'Product' ? expandVariants() : '',
		...(collection.apiId === 'Asset'
			? [
					'thumbnailUrl: url(transforms: { width: 256, height: 256, format: WEBP })',
					'mediumThumbUrl: url(transforms: { width: 128, height: 128, format: WEBP })',
					'largeThumbUrl: url(transforms: { width: 200, height: 200, format: WEBP })',
			  ]
			: []),
		`    }`,
		`  }`,
		`}`,
	].join('\n');
};

export const generateUpdateContentMutation = (collection, expand = false) => {
	return [
		`mutation UpdateContent ($input: Update${collection.apiId}Input!) {`,
		`  result: update${collection.apiId} (input: $input) {`,
		`    userErrors {`,
		`      message`,
		`      path`,
		`    }`,
		`    node {`,
		`      id`,
		`      updatedAt`,
		...(collection.titleApiId
			? [
					`  ... on ${collection.apiId} {`,
					`  __title: ${collection.titleApiId}`,
					'}',
			  ]
			: []),
		...(expand ? expandAttributes(collection.attributes) : []),
		...(collection.isPublishable ? [IN_STAGES_FRAGMENT] : []),
		`    }`,
		`  }`,
		`}`,
	].join('\n');
};

const expandReferenceTitles = (referencedCollections) => {
	const query = [];
	for (const refdBp of referencedCollections) {
		if (refdBp.titleApiId) {
			query.push(`  ... on ${refdBp.apiId} {`);
			query.push(`    ${refdBp.titleApiId}`);
			query.push(`    __title: ${refdBp.titleApiId}`);
			if (refdBp.isPublishable) {
				query.push(IN_STAGES_FRAGMENT);
			}
			query.push('  }');
		}
	}
	return query.join('\n');
};

const expandAttributes = (attributes, alias = null) =>
	attributes.map((attr) => {
		const query = [];
		switch (attr.__typename) {
			case ATTRIBUTE_TYPENAME_BASIC: {
				switch (attr.type) {
					case TYPE_ASSET:
						query.push(`${attr.apiId} {`);
						query.push(IMAGE_FRAGMENT);
						query.push(`}`);
						break;
					case TYPE_MONEY:
						query.push(`${attr.apiId} {`);
						query.push('  amount');
						query.push('  currency');
						query.push('}');
						break;
					default:
						if (alias) {
							query.push(`${alias}: ${attr.apiId}`);
						} else {
							query.push(attr.apiId);
						}
						break;
				}
				break;
			}
			case ATTRIBUTE_TYPENAME_COMPONENT:
				query.push(`${attr.apiId} {`);
				query.push(`  __typename`);

				// Expand each allowed object type
				for (const type of attr.allowedComponents) {
					query.push(`  ... on ${type.apiId} {`);
					query.push(expandAttributes(type.attributes, `${type.apiId}_value`));
					query.push(`  }`);
				}

				query.push(`}`);
				break;
			case ATTRIBUTE_TYPENAME_UNIREF:
			case ATTRIBUTE_TYPENAME_BIREF:
				if (attr.isList) {
					query.push(`${attr.apiId} {`);
					query.push('  totalCount');
					query.push('  edges (first: 500) {');
					query.push('    node {');
					query.push('      __typename');
					query.push('      ... on Publishable {');
					query.push('        id');
					query.push('      }');
					query.push(expandReferenceTitles([attr.referencedCollection]));
					query.push('    }');
					query.push('  }');
					query.push('}');
				} else {
					query.push(`${attr.apiId} {`);
					query.push('  ... on Publishable {');
					query.push('    __typename');
					query.push('    id');
					query.push('  }');
					query.push(expandReferenceTitles([attr.referencedCollection]));
					query.push('}');
				}
				break;
			default:
				if (alias) {
					query.push(`${alias}: ${attr.apiId}`);
				} else {
					query.push(attr.apiId);
				}
				break;
		}
		return query.join('\n');
	});

export const generateContentNodeQuery = (
	blueprint,
	extra = [],
	includeMeta = true
) =>
	[
		`query GetItem ($id: ID!) {`,
		`  node (id: $id) {`,
		`    id`,
		`    ... on ${blueprint.apiId} {`,
		includeMeta && blueprint.isPublishable ? IN_STAGES_FRAGMENT : '',
		extra.join('\n'),
		expandAttributes(blueprint.attributes),
		blueprint.apiId === 'Product' ? expandVariants() : '',
		`    }`,
		`  }`,
		`}`,
	].join('\n');

const expandStages = (collection) => {
	if (collection.isPublishable) {
		return IN_STAGES_FRAGMENT;
	}
	return '';
};

// List content for a single blueprint
export const generateBlueprintContentQuery = (
	blueprint,
	{ includeTitle } = {}
) =>
	[
		`query GetItems ($filter: ${blueprint.apiId}FilterInput, $after: String, $before: String, $first: Int, $last: Int, $order: [${blueprint.apiId}OrderInput!]) {`,
		`  items: all${blueprint.apiIdPlural} (filter: $filter, first: $first, last: $last, after: $after, before: $before, order: $order) {`,
		`    totalCount`,
		PAGE_INFO_FRAGMENT,
		`    edges {`,
		`      node {`,
		`        __typename`,
		`        updatedAt`,
		((includeTitle && blueprint.titleApiId) || blueprint.isPublishable
			? [
					`        ... on ${blueprint.apiId} {`,
					includeTitle && blueprint.titleApiId
						? `__title: ${blueprint.titleApiId}`
						: '',
					expandStages(blueprint),
					`        }`,
			  ]
			: []
		).join('\n'),
		expandSortableAttributes(blueprint.attributes),
		...(blueprint.apiId === 'Product'
			? [
					`        variants (first: 500) {`,
					`          edges {`,
					`            node {`,
					// `              id`,
					`              updatedAt`,
					IN_STAGES_FRAGMENT,
					`              price {`,
					`                amount`,
					`                currency`,
					`              }`,
					`              inventoryItem {`,
					`                id`,
					`                available`,
					`                isTracked`,
					`              }`,
					`            }`,
					`          }`,
					`        }`,
			  ]
			: []),
		...(blueprint.apiId === 'Asset'
			? [
					'url',
					'thumbnailUrl: url(transforms: { width: 256, height: 256, format: WEBP })',
					'mediumThumbUrl: url(transforms: { width: 128, height: 128, format: WEBP })',
					'largeThumbUrl: url(transforms: { width: 200, height: 200, format: WEBP })',
			  ]
			: []),
		`        ... on Node {`,
		`          id`,
		`        }`,
		`      }`,
		`    }`,
		`  }`,
		`}`,
	].join('\n');

const expandSortableAttributes = (attributes) =>
	expandAttributes(getPinnableAttributes(attributes));

const expandVariants = () =>
	[
		`      variants (first: 500) {`,
		`        edges {`,
		`          node {`,
		`            id`,
		`            onSale`,
		`            options`,
		`            price {`,
		`              amount`,
		`              currency`,
		`            }`,
		`            salePrice {`,
		`              amount`,
		`              currency`,
		`            }`,
		`            sku`,
		`            requiresShipping`,
		`            updatedAt`,
		IN_STAGES_FRAGMENT,
		`            inventoryItem {`,
		`              id`,
		`              available`,
		`              isTracked`,
		`            }`,
		`          }`,
		`        }`,
		`      }`,
	].join('\n');
