import React, { useContext, useState } from 'react';
import { DateTime } from '@platformapp/ui';
import { find, isEmpty, isNil, pathEq, pathOr } from 'ramda';
import {
	ATTRIBUTE_TYPENAME_BIREF,
	ATTRIBUTE_TYPENAME_ENUM,
	ATTRIBUTE_TYPENAME_UNIREF,
	DATE_REFRESH,
	TYPE_ASSET,
	TYPE_BOOLEAN,
	TYPE_DATETIME,
} from '../constants';
import { EmptyTextValue } from '../common/EmptyTextValue';
import { Thumbnail } from '../common/Thumbnail';
import { ApiIdLabel } from '../common/ApiIdLabel';
import { Link } from '../common/Link';
import { EnvironmentContext } from '../context/EnvironmentContext';
import { AssetDetailsLayer } from '../assets/AssetDetailsLayer';
import { formatBytes } from '../util';

const AssetAttributeColumn = ({ value }) => {
	const [detailsVisible, setDetailsVisible] = useState(false);
	return (
		<>
			{detailsVisible && (
				<AssetDetailsLayer
					onCancel={() => setDetailsVisible(false)}
					onSave={() => {
						setDetailsVisible(false);
					}}
					assetId={value.id}
				/>
			)}
			<div className="flex-shrink-0 h-10 w-10">
				<Thumbnail
					setDetailsLayerVisible={() => setDetailsVisible(true)}
					value={value}
				/>
			</div>
		</>
	);
};

const getItemPath = (item) => {
	const { collectionByApiId } = useContext(EnvironmentContext);
	switch (item.__typename) {
		case 'Product':
			return `products/${item.id}`;
		case 'Asset':
		case 'ProductVariant':
			return null;
	}
	const bp = collectionByApiId(item.__typename);
	return `content/${bp.node.id}/${item.id}`;
};

const ReferenceAttributeColumn = ({ item }) => {
	const { generateEnvPath } = useContext(EnvironmentContext);
	if (!item.__title || isEmpty(item.__title)) {
		return <ApiIdLabel>{item.id}</ApiIdLabel>;
	}
	const path = getItemPath(item);
	if (path) {
		return <Link to={generateEnvPath(path)}>{item.__title}</Link>;
	}
	return item.__title;
};

export const AttributeColumn = ({ attribute, node }) => {
	const value = node[attribute.apiId];

	// Format `Asset.size` as KB/MB/etc
	if (node.__typename === 'Asset' && attribute.apiId === 'size') {
		return formatBytes(value);
	}

	if (attribute.type === TYPE_ASSET) {
		return <AssetAttributeColumn value={value} />;
	}

	if (isNil(value) || isEmpty(value)) {
		return <EmptyTextValue />;
	}

	if (
		attribute.__typename === ATTRIBUTE_TYPENAME_UNIREF ||
		attribute.__typename === ATTRIBUTE_TYPENAME_BIREF
	) {
		return <ReferenceAttributeColumn item={value} />;
	}

	if (attribute.__typename === ATTRIBUTE_TYPENAME_ENUM) {
		const enumVal = find(
			pathEq(['node', 'apiId'], value),
			attribute.enumeration.values.edges
		);
		if (!enumVal) {
			return value;
		}
		return pathOr(value, ['node', 'name'], enumVal);
	}

	switch (attribute.type) {
		case TYPE_DATETIME:
			return <DateTime refresh={DATE_REFRESH} value={value} />;
		case TYPE_BOOLEAN:
			return value ? 'True' : 'False';
	}

	return value;
};
