import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TeamNav } from '../team/TeamNav';
import { ProjectNav } from '../navigation/ProjectNav';
import { SideNav } from '../common/SideNav';
import { EnvironmentWarning } from './EnvironmentWarning';
import { UserNav } from '../user/UserNav';

export const PrimaryNavigation = () => (
	<>
		<SideNav>
			<Switch>
				<Route path={['/account', '/account/security']}>
					<UserNav />
				</Route>
				<Route
					exact
					path={[
						'/:teamSlug',
						'/:teamSlug/members',
						'/:teamSlug/billing',
						'/:teamSlug/change-plan',
						'/:teamSlug/plans',
						'/:teamSlug/usage',
						'/:teamSlug/settings',
					]}
				>
					<TeamNav />
				</Route>
				<Route path="/:teamSlug/:projectId">
					<ProjectNav />
				</Route>
			</Switch>
		</SideNav>
		<Route path="/:teamSlug/:projectId">
			<div className="relative">
				<EnvironmentWarning />
			</div>
		</Route>
	</>
);
