import React from 'react';
import { ErrorIcon, LandingMessage, TextStyle } from '@platformapp/ui';

export const ErrorLandingMessage = ({ title, body }) => (
	<LandingMessage icon={<ErrorIcon />} header={title} body={body} />
);

ErrorLandingMessage.defaultProps = {
	title: 'Something went wrong with the Dashboard.',
	body: (
		<p className="leading-normal">
			<TextStyle subdued>
				This issue has been reported and we will work on investigating it.
				<br />
				Please try again later, or contact support if the issue persists.
			</TextStyle>
		</p>
	),
};
