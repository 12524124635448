export type Metadata = MetadataEntry[];

export type MetadataEntry = {
	key: string;
	value: string;
}

export const getMetadataValue = (metadata: Metadata, key: string, fallback: string = null): string => {
	const res = metadata.find((m) => m.key === key);

	if (!res) {
		return fallback;
	}

	return res.value;
};

export const getMetadataCsv = (metadata: Metadata, key: string, fallback: string[] = []): string[] => {
	const val = getMetadataValue(metadata, key);

	if (!val) {
		return fallback;
	}

	return val.split(',');
};
