import React from 'react';
import { Link } from './Link';
import { P } from './Text';

export const PageNotFound = () => (
	<div>
		<h1 className="text-3xl text-center font-medium mt-24 mb-3">
			Page not found
		</h1>
		<P centered className="mb-3">
			You might not have permissions to access this page.
		</P>
		<P centered>
			<Link to="/">Back to home</Link>
		</P>
	</div>
);
