import React, { useState, useContext } from 'react';
import { withErrorBoundary } from '../common/ErrorBoundary';
import { Button, Message } from '@platformapp/ui';
import { CreateProject } from '../projects/CreateProject';
import { useParams, useHistory } from 'react-router-dom';
import { Heading } from '../common/Heading';
import { SCOPE_CREATE_PROJECT } from '../constants';
import { TeamContext } from '../context/TeamContext';
import { useTeamTitle } from '../hooks/useTitle';
import { P } from '../common/Text';
import { Link } from '../common/Link';
import { ProjectList } from './ProjectList';

// const trialDaysLeft = (trialExpires) => {
//     const date1 = new Date();
//     const date2 = Date.parse(trialExpires);
//     const diffTime = Math.abs(date2 - date1);
//     return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
// }

export const TeamProjects = withErrorBoundary(() => {
	const history = useHistory();
	const { activeTeam, canDo } = useContext(TeamContext);
	const { teamSlug } = useParams();
	const [isCreating, setIsCreating] = useState(false);

	useTeamTitle('Projects');

	return (
		<>
			{isCreating && (
				<CreateProject
					onCancel={() => setIsCreating(false)}
					onCreate={(project) => history.push(`/${teamSlug}/${project.id}`)}
					teamId={activeTeam.id}
				/>
			)}
			<div className="mt-24 pt-6 mx-5 md:mx-10">
				<div className="fade-in">
					<div className="md:flex mb-6 items-center">
						<div className="flex-grow flex items-center">
							<Heading>Projects</Heading>
						</div>
						<div>
							{activeTeam &&
								!activeTeam.isSoftBlocked &&
								canDo(SCOPE_CREATE_PROJECT) && (
									<Button
										primary
										className="flex-shrink-0"
										onClick={() => setIsCreating(true)}
									>
										Create a project
									</Button>
								)}
						</div>
					</div>

					{activeTeam && activeTeam.isSoftBlocked && (
						<Message className="mb-4" danger>
							<P weight="medium">
								All recent payment attempts for this team have failed, and it
								has been marked as unpaid.
							</P>
							<P>
								All projects associated with this team will be unavailable until
								you have{' '}
								<Link to={`/${teamSlug}/billing`}>
									updated the payment method
								</Link>
								.
							</P>
						</Message>
					)}

					<ProjectList />
				</div>
			</div>
		</>
	);
});
