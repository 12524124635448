import React, { useState, useEffect } from 'react';
import api from '../api';
import { Loader } from '@platformapp/ui';
import { useHistory, useLocation } from 'react-router-dom';

export const UserContext = React.createContext({});

export const UserConsumer = UserContext.Consumer;

export const UserProvider = ({ children }) => {
	const history = useHistory();
	const location = useLocation();
	const [user, setUser] = useState({
		id: null,
		name: null,
		is_logged_in: false,
		// deprecated
		is_initialised: false,

		isLoading: true,
	});

	// Fetch user data and set CSRF cookie
	useEffect(() => {
		api.get('auth/user').then(({ data: user }) => {
			setUser({
				is_initialised: true,
				isLoading: false,
				...user,
			});

			// If the user is not logged in, redirect to login route (unless this
			// is the login/callback route)
			if (
				!user.is_logged_in &&
				!['/login/callback', '/login'].includes(location.pathname)
			) {
				console.debug('User not logged in - redirecting to login route.');
				history.replace('/login');
			}
		});
	}, []);

	const val = {
		id: user.id,
		name: user.name,
		is_initialised: user.is_initialised,
		isLoading: user.isLoading,
		is_logged_in: user.is_logged_in,
		isLoggedIn: user.is_logged_in,
		setUser: (newData) =>
			setUser({
				...user,
				...newData,
			}),
		user,
	};

	return (
		<UserContext.Provider value={val}>
			{user.isLoading && <Loader />}
			{!user.isLoading && children}
		</UserContext.Provider>
	);
};
