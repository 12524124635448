import React, { Component } from 'react';
import { ErrorIcon, LandingMessage, TextStyle } from '@platformapp/ui';

export const withErrorBoundary = (BaseComponent) => {
	return (props) => {
		return (
			<ErrorBoundary>
				<BaseComponent {...props} />
			</ErrorBoundary>
		);
	};
};

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		console.log(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					<br />
					<LandingMessage
						icon={<ErrorIcon />}
						header="Something went wrong with the Dashboard."
						body={
							<p className="leading-normal">
								<TextStyle subdued>
									This issue has been reported and we will work on investigating
									it.
									<br />
									Please try again later, or contact support if the issue
									persists.
								</TextStyle>
							</p>
						}
					/>
				</div>
			);
		}
		return this.props.children;
	}
}
